import * as React from "react";
import { withRouter } from "react-router";
import { Col, FormGroup, Input, Label, Row } from "reactstrap";
import nameof from "ts-nameof.macro";
import { DocumentUpload } from "../helpers/DocumentUpload";
import { RouteHelper } from "../IntranetRouter";
import { AccesoDirectoEditModel } from "../models/dataModels";
import { IServiceForEdit } from "../utils/base/BaseServices";
import { NavigationHelper } from "../utils/base/Breadcrumb";
import { EditPageBase } from "../utils/base/EditPageBase";
import { GenericEditState } from "../utils/base/GenericEditState";
import { ModelValidation } from "../utils/base/ModelValidation";
import { ClientContext } from "../utils/ClientContext";
import { I18n } from "../utils/I18n";
import { validateUrl } from "../utils/ValuesFormatter";
import { AccesoDirectoService } from "./AccesoDirectoService";



export class EditComp extends EditPageBase<AccesoDirectoEditModel> {

    protected _setPageTitleForNew(): string {
        return "Nuevo acceso directo"
    }

    protected _setPageTitleForExisting(editModel: AccesoDirectoEditModel): string {
        return `Editar acceso directo`;
    }

    protected _createApiService(): IServiceForEdit<AccesoDirectoEditModel> {
        return new AccesoDirectoService();
    }

    protected updateBreadCrumb(model: AccesoDirectoEditModel) {
        var items = [{
            text: I18n.Strings.accesosDirectos.title,
            link: RouteHelper.listAccesosDirectos()
        }];
        if (model.id == 0) {
            items.push({
                text: I18n.Strings.addNew,
                link: ""
            });
        }
        else {
            items.push({
                text: `${model.titulo}`,
                link: RouteHelper.editAccesoDirecto(model.id)
            });
        }
        NavigationHelper.setBreadcrumbItems(items);
    }

    protected _generateForm(): JSX.Element {
        return <React.Fragment>
            <Row>
                <Col md={9}>
                    <Row>
                        <Col md={3}>
                            <FormGroup>

                                <Label for={nameof(this.state.editModel.titulo)}>
                                    {I18n.Strings.accesosDirectos.titulo}
                                </Label>

                                <Input
                                    onChange={(evt) => {
                                        var state = this._cloneStateForSetState();
                                        state.editModel.titulo = evt.target.value;
                                        this.setState(state);
                                    }}
                                    type="text"
                                    required={true}
                                    maxLength={255}
                                    className={"form-control " + this._errorClass(nameof(this.state.editModel.titulo))}
                                    name={nameof(this.state.editModel.titulo)}
                                    id={nameof(this.state.editModel.titulo)}
                                    value={this.state.editModel.titulo || ""}
                                />
                                {this._errorMessage(nameof(this.state.editModel.titulo))}

                            </FormGroup>

                        </Col>


                        <Col md="5">
                            <FormGroup>

                                <Label for={nameof(this.state.editModel.urlEnlace)}>
                                    {I18n.Strings.accesosDirectos.urlEnlace}
                                </Label>

                                <Input
                                    onChange={(evt) => {
                                        var state = this._cloneStateForSetState();
                                        state.editModel.urlEnlace = evt.target.value;
                                        this.setState(state);
                                    }}
                                    type="url"
                                    placeholder="https://www.direccion.com/"
                                    pattern="https?://.+"
                                    required={true}
                                    maxLength={255}
                                    className={"form-control " + this._errorClass(nameof(this.state.editModel.urlEnlace))}
                                    name={nameof(this.state.editModel.urlEnlace)}
                                    id={nameof(this.state.editModel.urlEnlace)}
                                    value={this.state.editModel.urlEnlace || ""}
                                />
                                {this._errorMessage(nameof(this.state.editModel.urlEnlace))}

                            </FormGroup>
                        </Col>

                        <Col>
                            <FormGroup>
                                <Label for={nameof(this.state.editModel.abrirEnNuevaVentana)}>{I18n.Strings.accesosDirectos.abrirEnNuevaVentana}</Label>
                                <Input
                                    onChange={(evt) => {
                                        var newState = this._cloneStateForSetState();
                                        newState.editModel.abrirEnNuevaVentana = evt.target.checked;
                                        this.setState(newState);
                                    }}
                                    type="checkbox"
                                    style={{ padding: 0, marginLeft: 10 }}
                                    id={nameof(this.state.editModel.abrirEnNuevaVentana)}
                                    checked={this.state.editModel.abrirEnNuevaVentana}
                                />
                            </FormGroup>
                        </Col>
                    </Row>
                </Col>

                <Col md={3}>
                    <div>
                        <div className="mainPhoto">
                            <img
                                className="mt-xl"
                                src={this.state.editModel.caratula == null ? '/img/caratula.png' : this.state.editModel.caratula}
                            />
                            {this.state.editModel.caratula != null && <div className="mainPhotoMenu">

                            </div>
                            }
                        </div>
                        {this.state.editModel.id > 0 && <div>
                            <DocumentUpload
                                manualUpload={false}
                                style={{ fontSize: 14, overflow: "ellipsis" }}
                                title={I18n.Strings.contenidos.subirFoto}
                                zoneStyle={{ margin: 0, padding: 10 }}
                                uploadUrl={`intranet/colegio/accesosdirectos/${this.state.editModel.id}/caratula`}
                                multiple={false}
                                onUpload={(data) => {
                                    this._updateStateValue(data.documentUrl, nameof.full(this.state.editModel.caratula, 2));
                                }}
                            />
                        </div>}
                    </div>
                </Col>

            </Row>
        </React.Fragment>;
    }


    protected _getEditUrl(id: number): string {
        return RouteHelper.editAccesoDirecto(id);
    }

    protected _getListUrl(): string {
        return RouteHelper.listAccesosDirectos();
    }

    protected _createStateForNew(): Promise<GenericEditState<AccesoDirectoEditModel>> {


        var model = new GenericEditState<AccesoDirectoEditModel>();
        model.editModel = {
            id: 0,
            colegioId: ClientContext.Current.colegioId,
            titulo: "",
            urlEnlace: "",
            abrirEnNuevaVentana: true
        } as AccesoDirectoEditModel;

        return Promise.resolve(model);
    }

    protected _validateModelLocal(): ModelValidation {
        let validation = new ModelValidation();

        if ((this.state.editModel.titulo || "").length == 0) {
            validation.addError(nameof(this.state.editModel.titulo), I18n.Strings.validationErrors.valueRequired(I18n.Strings.accesosDirectos.titulo));
        }

        if ((this.state.editModel.urlEnlace || "").length == 0) {
            validation.addError(nameof(this.state.editModel.urlEnlace), I18n.Strings.validationErrors.valueRequired(I18n.Strings.accesosDirectos.urlEnlace));
        }
        else if (!validateUrl(this.state.editModel.urlEnlace)){
            validation.addError(nameof(this.state.editModel.urlEnlace), I18n.Strings.validationErrors.formatNotValid(I18n.Strings.accesosDirectos.urlEnlace));
        }


        return validation;
    }
}

export const Edit = withRouter(EditComp)