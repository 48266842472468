import * as React from "react";
import nameof from "ts-nameof.macro";
import { ClaseMateriaListItem, ClaseMateriaSearch, SortDirection } from "../../models/dataModels";
import { DropDownBase, GenericSelectItem, IDropDownProps } from "../../utils/base/DropDownBase";

import { ClientContext } from "../../utils/ClientContext";
import { ClaseMateriaService } from "./ClaseMateriaService";

interface ClaseMateriaDropDownProps extends IDropDownProps {
    claseId?: number;
    profesorId?: number;
}

export class ClaseMateriaDropDown extends DropDownBase<ClaseMateriaDropDownProps> {

    protected GetItems(): Promise<GenericSelectItem[]> {

        var item = {} as ClaseMateriaListItem;

        var searchModel = {
            colegioId: ClientContext.Current.colegioId,
            claseId: this.props.claseId,
            
            noTotalCount: true,
            currentPage: 0,
            rowsPerPage: -1,
            orderByColumn: nameof(item.nombre),
            orderMode: SortDirection.Asc,
            bypassAutomaticSort: false,
            currentPageRows: -1
        } as ClaseMateriaSearch;

        var claseMateriaService = new ClaseMateriaService();
        var dataProm = claseMateriaService.search(searchModel);

        var resultProm = dataProm.then(data => {
            return data.items.map((item, index) => {
                return {
                    id: item.id,
                    name: `${item.nombre} - ${item.clase}`,
                    data: item
                }
            });
        });

        return Promise.resolve(resultProm);
    }

    protected shouldAddEmptyOption() {
        return true;
    }
};