import {ModelValidationResult} from "../../models/dataModels"

export class ModelValidation implements ModelValidationResult {
    public isOk: boolean;

    public validationErrors: { [key: string]: string; };

    constructor() {
        this.isOk = true;
        this.validationErrors = {};
    }

    public load(valResult: ModelValidationResult) {
        this.isOk = valResult.isOk;
        this.validationErrors = valResult.validationErrors;
    }

    public addError(fieldName: string, errorText: string) {
        this.validationErrors[fieldName.toLowerCase()] = errorText;
        this.isOk = false;
    }

    public getError(fieldName: string) {
        return this.validationErrors[fieldName.toLowerCase()];
    }

    public reset(): void {
        this.isOk = true;
        this.validationErrors = {};
    }
}