import React from 'react';
import {
    BrowserRouter,
    Route,
    Switch
} from "react-router-dom";
import { Accesos as AccesosAdmin, AccesosTabs } from './accesos/admin/Accesos';
import { Accesos as AccesosProfesor } from './accesos/profesor/Accesos';
import { Edit as EditAccesoDirecto } from "./accesosDirectos/Edit";
import { Index as AccesosDirectos } from "./accesosDirectos/Index";
import { ReportsIndex } from './reports/ReportsIndex';
import { AliasEditorialEditTabs, Edit as EditAliasEditoriales } from "./aliasEditoriales/Edit";
import { Index as AliasEditoriales } from "./aliasEditoriales/Index";
import { CargaFicherosTabs, Index as CargaFicheros } from "./cargaFicheros/Index";
import { Detail as DetailCargaFicheros } from "./cargaFicheros/Detail";
import { Edit as EditMiClaseProfesor, MiClaseEditTabs as ViewMiClaseEditTabs } from "./clases/profesor/Edit";
import { MisClases as MisClasesProfesor } from "./clases/profesor/MisClases";
import { Edit as EditUsuariosColegio, UsuarioEditTabs } from "./colegioUsuarios/Edit";
import { Index as UsuariosColegio } from "./colegioUsuarios/Index";

import { ContenidoColegioEditTabs, EditContenidoColegio as EditContenidosColegio } from "./contenidos/colegio/EditContenidoColegio";
import { IndexContenidoColegio as ContenidosColegio } from "./contenidos/colegio/IndexContenidoColegio";


import { IndexContenidoPapelColegio as ContenidosPapelColegio } from "./contenidos/colegioPapel/IndexContenidoPapelColegio";


import { ContenidoEditTabs, Edit as EditContenidos } from "./contenidos/maestros/Edit";
import { Index as Contenidos } from "./contenidos/maestros/Index";
import { Edit as EditCredenciales } from "./credenciales/Edit";
import { Index as Credenciales } from "./credenciales/Index";
import { Edit as EditGrupoClase, GrupoClaseEditTabs } from "./cursoEscolar/clase/Edit";
import { Edit as EditCursoEscolar } from "./cursoEscolar/Edit";
import { Index as CursoEscolar } from "./cursoEscolar/Index";
import Home from './home/Home';
import NotFound from './home/NotFound';
import { Edit as EditMiLicenciaAlumno } from "./licenciasColegio/alumno/Edit";
import { Edit as EditLicenciasColegio, LicenciaContenidosEditTabs } from "./licenciasColegio/Edit";
import { Index as LicenciasColegio } from "./licenciasColegio/Index";
import { MisLicencias } from "./licenciasColegio/MisLicencias";
import { Edit as EditMiLicenciaProfesor, LicenciaUsuarioEditTabs } from "./licenciasColegio/profesor/Edit";
import { AuthenticatedSection, UnAuthenticatedSection } from './login/AuthSections';
import { Login } from './login/Login';
import { LoginUserError } from './login/LoginUserError';
import { Edit as EditMatricula } from "./matriculas/Edit";
import { Index as Matriculas } from "./matriculas/Index";
import { Edit as MiPerfilAdmin } from "./miPerfil/admin/Edit";
import { Edit as MiPerfilAlumno } from "./miPerfil/alumno/Edit";
import { ColegioEditTabs, Edit as MiPerfilColegio } from "./miPerfil/colegio/Edit";
import { Edit as MiPerfilProfesor, ProfesorEditTabs } from "./miPerfil/profesor/Edit";
import { Edit as EditMiMatricula } from "./miPerfil/profesor/matriculas/Edit";
import { Index as MisMatriculas } from "./miPerfil/profesor/matriculas/Index";
import { TipoUsuario } from './models/dataModels';
import { Edit as EditNivelesContenidos } from "./nivelesContenidos/Edit";
import { Index as NivelesContenidos } from "./nivelesContenidos/Index";
import { Index as Notificaciones } from "./notificaciones/Index";
import { View as ViewNotificacion } from "./notificaciones/View";
import { AuthService } from './services/AuthService';
import { AlertDisplayer, AlertsService } from './utils/AlertsService';
import { AuthenticatedMaster } from './utils/AuthenticatedMaster';
import { ClientContext } from './utils/ClientContext';
import { Loading } from './utils/Loading';
import { logAndExtractInfoFromException } from './utils/ValuesFormatter';

import { Index as PedidosTienda } from "./pedidosTienda/Index";
import { Edit as DetallePedidoTienda } from "./pedidosTienda/Edit";

import { Index as PedidosColegio } from "./pedidosColegio/Index";
import { Edit as DetallePedidoColegio } from "./pedidosColegio/Edit";

import { Index as DevolucionesColegio } from "./devolucionesColegio/Index";
import { Edit as DetalleDevolucionesColegio } from "./devolucionesColegio/Edit";

interface RouteState {
    context: ClientContext;
    loading: boolean;
}

export class IntranetRouter extends React.Component<any, RouteState> {


    private shouldCancel: boolean;

    constructor(props: any) {
        super(props);


        this.state = {
            context: null,
            loading: true
        };
        this.shouldCancel = false;
    }

    public componentDidMount() {
        this.readUserContext()
            .then(ctx => {
                this.setState({
                    context: ctx,
                    loading: false
                });
            })
            .catch((error: any) => {

                if (error.error == "login_required") {
                    var authService = new AuthService();
                    authService.login();
                }
                else {
                    AlertsService.showError(error, () => {
                        var authService = new AuthService();
                        authService.logout();
                    });
                }
            });
    }

    public componentWillUnmount() {
        this.shouldCancel = true;
    }

    private readUserContext = (): Promise<ClientContext> => {

        var prom = ClientContext.init()
            .then(context => {

                if (!this.shouldCancel) {

                    return context;
                }
                return null;
            });

        return prom;
    };

    static getDerivedStateFromError(error: any) {
        alert(logAndExtractInfoFromException(error));
    }

    componentDidCatch(error, errorInfo) {
        // También puedes registrar el error en un servicio de reporte de errores
        alert(logAndExtractInfoFromException(error));
    }

    public render() {
        if (!this.state.loading && this.state.context == null) {
            var authService = new AuthService();
            authService.login();
            return;
        }

        return <React.Fragment>

            <AlertDisplayer ref={(ref: any) => AlertsService.setMaster(ref)} />

            {this.state.loading && <Loading />}

            {!this.state.loading &&
                <BrowserRouter>

                    <UnAuthenticatedSection userContext={this.state.context}>
                        <Switch>
                            <Route exact path="/" component={Login} />
                            <Route exact path={RouteHelper.loginUserError()} component={LoginUserError} />
                        </Switch>
                    </UnAuthenticatedSection>

                    <AuthenticatedSection userContext={this.state.context}>
                        <AuthenticatedMaster >
                            <Switch>
                                <Route exact path="/" component={Home} />
                                <Route exact path="/index.html" component={Home} />
                                <Route exact path="/home" component={Home} />

                                <Route exact path={RouteHelper.reports()} component={ReportsIndex} />

                                <Route exact path={RouteHelper.listUsuariosColegio()} component={UsuariosColegio} />
                                <Route exact path={RouteHelper.createUsuariosColegio()} component={EditUsuariosColegio} />
                                <Route exact path={RouteHelper.editUsuarioColegio(":id", ":tab")} component={EditUsuariosColegio} />

                                <Route exact path={RouteHelper.createLicenciasColegio()} render={(props) => <EditLicenciasColegio goBackToListAfterSaving={true} />} />
                                <Route exact path={RouteHelper.createLicenciasColegio(":usuarioId", null)} render={(props) => <EditLicenciasColegio goBackToListAfterSaving={true} />} />
                                <Route exact path={RouteHelper.createLicenciasColegio(null, ":contenidoId")} render={(props) => <EditLicenciasColegio goBackToListAfterSaving={true} />} />

                                <Route exact path={RouteHelper.editLicenciasColegio(":id", ":usuarioId", null, ":tab")} render={(props) => <EditLicenciasColegio goBackToListAfterSaving={true} />} />
                                <Route exact path={RouteHelper.editLicenciasColegio(":id", null, null, ":tab")} render={(props) => <EditLicenciasColegio goBackToListAfterSaving={true} />} />
                                <Route exact path={RouteHelper.editLicenciasColegio(":id", ":usuarioId", null, ":tab")} render={(props) => <EditLicenciasColegio goBackToListAfterSaving={true} />} />
                                <Route exact path={RouteHelper.editLicenciasColegio(":id", null, ":contenidoId", ":tab")} render={(props) => <EditLicenciasColegio goBackToListAfterSaving={true} />} />
                                <Route exact path={RouteHelper.editMatricula(":id", ":usuarioId")} render={(props) => <EditMatricula goBackToListAfterSaving={true} />} />


                                <Route exact path={RouteHelper.listCursosEscolares()} component={CursoEscolar} />
                                <Route exact path={RouteHelper.editCursoEscolar(":id")} component={EditCursoEscolar} />
                                <Route exact path={RouteHelper.editGrupoClase(":id")} component={EditGrupoClase} />
                                <Route exact path={RouteHelper.editGrupoClase(":id", ":cursoEscolarId", ":tab")} component={EditGrupoClase} />
                                {/*<Route exact path={RouteHelper.editGrupoClase(":id", ":cursoEscolarId")} component={EditGrupoClase} /> */}

                                <Route exact path={RouteHelper.myProfileStudent()} component={MiPerfilAlumno} />


                                <Route exact path={RouteHelper.listPedidosTienda()}
                                    render={(props) => <PedidosTienda isViewMode={true} />}
                                />
                                <Route exact path={RouteHelper.detallePedidoTienda(":id")}
                                    render={(props) => <DetallePedidoTienda viewMode={true} />}
                                />

                                <Route exact path={RouteHelper.listPedidosColegio()} render={(props) => <PedidosColegio isViewMode={true} />} />
                                <Route exact path={RouteHelper.detallePedidoColegio(":id")} render={(props) => <DetallePedidoColegio viewMode={true} />} />

                                <Route exact path={RouteHelper.listDevolucionesColegio()} render={(props) => <DevolucionesColegio isViewMode={true} />} />
                                <Route exact path={RouteHelper.detalleDevolucionesColegio(":id")} render={(props) => <DetalleDevolucionesColegio viewMode={true} />} />


                                <Route exact path={RouteHelper.myProfileTeacher()} component={MiPerfilProfesor} />
                                <Route exact path={RouteHelper.myProfileTeacher(":tab")} component={MiPerfilProfesor} />
                                <Route exact path={RouteHelper.listMisMatriculas()} component={MisMatriculas} />
                                <Route exact path={RouteHelper.editMiMatriculaProfesor(":id", ":usuarioId")} render={(props) => <EditMiMatricula goBackToListAfterSaving={true} />} />
                                <Route exact path={RouteHelper.listMisClasesProfesor()} render={(props) => <MisClasesProfesor isViewMode={true} renderResultsDiv={true} />} />
                                <Route exact path={RouteHelper.editMiClaseProfesor(":id")} component={EditMiClaseProfesor} />
                                <Route exact path={RouteHelper.editMiClaseProfesor(":id", ":tab")} component={EditMiClaseProfesor} />

                                <Route exact path={RouteHelper.myProfileAdmin()} component={MiPerfilAdmin} />

                                <Route exact path={RouteHelper.myProfileSchool()} component={MiPerfilColegio} />
                                <Route exact path={RouteHelper.myProfileSchool(":tab")} component={MiPerfilColegio} />
                                <Route exact path={RouteHelper.listMyLicenses()} render={(props) =>
                                    ClientContext.Current.tipoUsuario == TipoUsuario.Profesor ?
                                        <MisLicencias isViewMode={true} {...props} renderResultsDiv={true} /> :
                                        <MisLicencias isViewMode={true} {...props} renderResultsDiv={true} />
                                } />

                                <Route exact path={RouteHelper.detailMyLicenses(":id")} render={(props) =>
                                    ClientContext.Current.tipoUsuario == TipoUsuario.Profesor ?
                                        <EditMiLicenciaProfesor viewMode={true} /> : <EditMiLicenciaAlumno viewMode={true} />
                                } />

                                <Route exact path={RouteHelper.detailMyLicenses(":id", ":tab")} render={(props) =>
                                    ClientContext.Current.tipoUsuario == TipoUsuario.Profesor ?
                                        <EditMiLicenciaProfesor viewMode={true} /> : <EditMiLicenciaAlumno viewMode={true} />
                                } />

                                <Route exact path={RouteHelper.listLicenciasColegio()} component={LicenciasColegio} />
                                <Route exact path={RouteHelper.editLicenciasColegio(":id", null, null, LicenciaContenidosEditTabs.Datos)} component={EditLicenciasColegio} />


                                <Route exact path={RouteHelper.listContenidos()} component={Contenidos} />
                                <Route exact path={RouteHelper.createContenidos()} component={EditContenidos} />
                                <Route exact path={RouteHelper.editContenidos(":id", ":tab")} component={EditContenidos} />


                                <Route exact path={RouteHelper.listAliasEditoriales()} render={(props) => <AliasEditoriales {...props} isEmbedded={false} showTitle={true} />} />
                                <Route exact path={RouteHelper.editAliasEditorial(":id", ":tab")} component={EditAliasEditoriales} />

                                <Route exact path={RouteHelper.listContenidosColegio()} render={(props) => <ContenidosColegio {...props} hideDeleteButton={true} />} />
                                <Route exact path={RouteHelper.editContenidosColegio(":id", ":tab")} render={(props) => <EditContenidosColegio />} />


                                <Route exact path={RouteHelper.listContenidosPapelColegio()} render={(props) => <ContenidosPapelColegio {...props} hideDeleteButton={true} />} />
                                

                                <Route exact path={RouteHelper.editLicenciasColegio(":id", null, ":contenidoId", LicenciaContenidosEditTabs.Datos)} component={EditLicenciasColegio} />
                                <Route exact path={RouteHelper.editNivelContenido(":id", ":contenidoId")} component={EditNivelesContenidos} />
                                <Route exact path={RouteHelper.editNivelContenido("create", null)} component={EditNivelesContenidos} />

                                {/* Esto creo que ya nos va sobrando (va embebido) */}
                                <Route exact path={RouteHelper.listCredencialesColegio()} component={Credenciales} />
                                <Route exact path={RouteHelper.editCredenciales(":id")} component={EditCredenciales} />


                                <Route exact path={RouteHelper.listMatriculas()} component={Matriculas} />
                                <Route exact path={RouteHelper.editMatricula(":id")} render={(props) => <EditMatricula goBackToListAfterSaving={true} />} />

                                <Route exact path={RouteHelper.listNivelesContenidos()} component={NivelesContenidos} />
                                <Route exact path={RouteHelper.editNivelContenido(":id", null)} component={EditNivelesContenidos} />

                                <Route exact path={RouteHelper.listCredencialesColegio()} component={Credenciales} />
                                <Route exact path={RouteHelper.editCredenciales(":id")} component={EditCredenciales} />

                                <Route exact path={RouteHelper.listEstadisticasAdmin(":tab")} component={AccesosAdmin} />

                                <Route exact path={RouteHelper.listEstadisticasProfesor(":usuarioId")} component={AccesosProfesor} />
                                <Route exact path={RouteHelper.listEstadisticasProfesor(":usuarioId", ":tab")} component={AccesosProfesor} />

                                <Route exact path={RouteHelper.cargaFicheros(":tab")} component={CargaFicheros} />
                                <Route exact path={RouteHelper.detailCargaFicheros(":id")}
                                    render={(props) => <DetailCargaFicheros viewMode={true} />}
                                />

                                <Route exact path={RouteHelper.listNotificaciones()} render={(props) => <Notificaciones isViewMode={true} />} />
                                <Route exact path={RouteHelper.viewNotificacion(":id")} render={(props) =>
                                    <ViewNotificacion {...props} key={props.match.params.id} />
                                } />

                                <Route exact path={RouteHelper.listAccesosDirectos()} component={AccesosDirectos} />
                                <Route exact path={RouteHelper.editAccesoDirecto(":id")} component={EditAccesoDirecto} />

                                <Route component={NotFound} />
                            </Switch>
                        </AuthenticatedMaster>
                    </AuthenticatedSection>

                </BrowserRouter>
            }
        </React.Fragment>;
    }
}


export class RouteHelper {

    static home(): string {
        return "/";
    }

    static loginUserError(): string {
        return "/loginUserError";
    }

    static reports(): string {
        return "/reports";
    }

    static myProfileTeacher(tab: ColegioEditTabs | string = ColegioEditTabs.Datos): string {
        var path = `/teacherprofile`;
        if (tab != null) {
            path += `/${tab.toString()}`;
        }

        return path;
    }

    static myProfileAdmin(): string {
        return "/adminprofile";
    }

    static myProfileStudent(): string {
        return "/profile";
    }

    static myProfileSchool(tab: ColegioEditTabs | string = ColegioEditTabs.Datos): string {
        var path = `/schoolprofile`;
        if (tab != null) {
            path += `/${tab.toString()}`;
        }

        return path;
    }

    static listMyLicenses(): string {
        return "/mis-licencias";
    }
    static detailMyLicenses(id: number | string, tab: LicenciaUsuarioEditTabs | string = null): string {
        var path = `/mis-licencias/${id}`;

        if (tab != null) {
            path += `/${tab.toString()}`;
        }

        return path;
    }

    static createMyLicenses(): string {

        return `/mis-licencias/create`;
    }

    static listUsuariosColegio(): string {
        return "/usuarios-colegio";
    }

    static createUsuariosColegio(): string {
        return "/usuarios-colegio/create";
    }

    static editUsuarioColegio(id: number | string,
        tab: UsuarioEditTabs | string = ColegioEditTabs.Datos): string {
        var path = `/usuarios-colegio/${id}`;

        if (tab != null) {
            path += `/${tab.toString()}`;
        }

        return path;
    }


    static listLicenciasColegio(usuarioId?: number, contenidoColegioId?: number): string {
        if (usuarioId != null) {

            return this.editUsuarioColegio(usuarioId, UsuarioEditTabs.Licencias);
        }
        else if (contenidoColegioId != null) {
            return this.editContenidosColegio(contenidoColegioId, ContenidoColegioEditTabs.Licencias)
        }
        return `/licencias-colegio/`
    }

    static editLicenciasColegio(id: number | string,
        usuarioId: number | string,
        contenidoId: number | string,
        tab: LicenciaContenidosEditTabs | string): string {

        if (usuarioId != null) {
            var path = `/usuarios-colegio/${usuarioId}/licencias-colegio/${id}/${tab.toString()}`;
            return path
        }
        else if (contenidoId != null) {
            var path = `/contenidos-colegio/${contenidoId}/licencias-colegio/${id}/${tab.toString()}`;
            return path;
        }
        var path = `/licencias-colegio/${id}/${tab.toString()}`;
        return path;
    }

    static createLicenciasColegio(usuarioId?: number | string, contenidoId?: number | string): string {

        if (usuarioId != null) {
            return `/usuarios-colegio/${usuarioId}/licencias-colegio/create`;
        }
        else if (contenidoId != null) {
            return `/contenidos-colegio/${contenidoId}/licencias-colegio/create`;
        }
        return `/licencias-colegio/create`;
    }

    static listEstadisticasAdmin(tab: AccesosTabs | string = AccesosTabs.Accesos): string {
        var path = `/estadisticas`;
        if (tab != null) {
            path += `/${tab.toString()}`;
        }

        return path;
    }

    static listEstadisticasProfesor(usuarioId: number | string,
        tab: AccesosTabs | string = AccesosTabs.Accesos): string {
        var path = `/estadisticasProfesor/${usuarioId}`;
        if (tab != null) {
            path += `/${tab.toString()}`;
        }

        return path;
    }

    static cargaFicheros(tab: CargaFicherosTabs | string = CargaFicherosTabs.Listado): string {
        var path = `/carga-ficheros`;
        if (tab != null) {
            path += `/${tab.toString()}`;
        }

        return path;
    }

    static detailCargaFicheros(id: number | string): string {
        var path = `/carga-ficheros/detail/${id}`;

        return path;
    }


    static listCursosEscolares(): string {
        return "/curso-escolar";
    }

    static createCursoEscolar(returnUrl?: string): string {
        var parametro = returnUrl != null ? `?returnUrl=${encodeURIComponent(returnUrl)}` : '';
        return `/curso-escolar/create${parametro}`;
    }

    static editCursoEscolar(id: number | string, returnUrl?: string): string {
        var parametro = '';
        if (returnUrl != null) {
            parametro = `?returnUrl=${encodeURIComponent(returnUrl)}`;
        }
        return `/curso-escolar/${id}${parametro}`;
    }

    static editGrupoClase(id: number | string,
        cursoEscolarId?: number | string,
        tab: GrupoClaseEditTabs | string = GrupoClaseEditTabs.Datos): string {
        var path = `/clase/${id}`;

        if (cursoEscolarId != null) {
            path = `/curso-escolar/${cursoEscolarId}/clase/${id}`;
        }

        if (tab != null) {
            path += `/${tab.toString()}`;
        }

        return path;
    }


    static listContenidos(): string {
        return "/contenidos";
    }
    static createContenidos(): string {
        return "/contenidos/create";
    }

    static editContenidos(id: number | string, tab: ContenidoEditTabs | string): string {
        var path = `/contenidos/${id}/${tab.toString()}`;

        return path;
    }

    static listContenidosColegio(): string {
        return "/contenidos-colegio";
    }

    static editContenidosColegio(id: number | string, tab: ContenidoColegioEditTabs | string = ContenidoColegioEditTabs.Datos): string {
        var path = `/contenidos-colegio/${id}`;
        if (tab != null) {
            path += `/${tab.toString()}`;
        }

        return path;
    }

    static listContenidosPapelColegio(): string {
        return "/contenidos-papel-colegio";
    }

    static editContenidosPapelColegio(id: number | string, tab: ContenidoColegioEditTabs | string = ContenidoColegioEditTabs.Datos): string {
        var path = `/contenidos-papel-colegio/${id}`;
        if (tab != null) {
            path += `/${tab.toString()}`;
        }

        return path;
    }

    static listCredencialesColegio(): string {
        return RouteHelper.myProfileSchool(ColegioEditTabs.Credenciales);
    }

    static createCredenciales(returnUrl?: string): string {
        var parametro = returnUrl != null ? `?returnUrl=${encodeURIComponent(returnUrl)}` : '';
        return `/schoolprofile/credenciales/create${parametro}`;
    }

    static editCredenciales(id: number | string, returnUrl?: string): string {
        var parametro = '';
        if (returnUrl != null) {
            parametro = `?returnUrl=${encodeURIComponent(returnUrl)}`;
        }
        return `/schoolprofile/credenciales/${id}${parametro}`;
    }

    static listMisMatriculas(): string {
        return RouteHelper.myProfileTeacher(ProfesorEditTabs.MisClases);
    }

    static editMiMatriculaProfesor(id: number | string, usuarioId: number | string): string {

        return `/usuarios-colegio/${usuarioId}/mis-matriculas/${id}`;
    }

    static createMiMatriculaProfesor(usuarioId: number): string {

        return `/usuarios-colegio/${usuarioId}/mis-matriculas/create`;
    }

    static listMisClasesProfesor(): string {
        return `/mis-clases`;
    }

    static editMiClaseProfesor(id: number | string, tab: ViewMiClaseEditTabs | string = ViewMiClaseEditTabs.Datos): string {
        var path = `/mis-clases/${id}`;

        if (tab != null) {
            path += `/${tab.toString()}`;
        }

        return path;
    }

    static listMatriculas(usuarioId?: number): string {
        if (usuarioId != null) {

            return this.editUsuarioColegio(usuarioId, UsuarioEditTabs.Matriculas);
        }
        return "/matriculas";
    }

    static createMatricula(usuarioId?: number): string {

        if (usuarioId != null) {
            return `/usuarios-colegio/${usuarioId}/matriculas/create`;
        }
        return `/matriculas/create`;
    }

    static editMatricula(id: number | string, usuarioId?: number | string): string {

        if (usuarioId != null) {
            return `/usuarios-colegio/${usuarioId}/matriculas/${id}`;
        }
        return `/matriculas/${id}`;
    }

    static listNivelesContenidos(contenidoId?: number): string {
        if (contenidoId != null) {
            return this.editContenidosColegio(contenidoId, ContenidoColegioEditTabs.Niveles);
        }
        return "/niveles-contenidos";
    }

    static createNivelesContenido(contenidoId?: number): string {

        if (contenidoId != null) {
            return `/contenidos-colegio/${contenidoId}/niveles-contenidos/create`;
        }

        return `/niveles-contenidos/create`;
    }

    static editNivelContenido(id: number | string, contenidoId?: number | string): string {

        if (contenidoId != null) {
            return `/contenidos-colegio/${contenidoId}/niveles-contenidos/${id}`;
        }

        return `/niveles-contenidos/${id}`;
    }

    static listAliasEditoriales(): string {
        return "/alias-editoriales";
    }

    static createAliasEditorial(): string {
        return `/alias-editoriales/create`;
    }

    static editAliasEditorial(id: number | string, tab: AliasEditorialEditTabs | string): string {
        return `/alias-editoriales/${id}/${tab.toString()}`;
    }

    static listNotificaciones(): string {
        return "/notificaciones";
    }

    static viewNotificacion(id: number | string): string {
        return `/notificaciones/${id}`;
    }


    static listAccesosDirectos(): string {
        return "/accesos-directos";
    }

    static createAccesoDirecto(): string {
        return "/accesos-directos/create";
    }

    static editAccesoDirecto(id: number | string): string {
        return `/accesos-directos/${id}`;
    }

    static listPedidosTienda(): string {
        return "/pedidos-tienda";
    }

    static detallePedidoTienda(id: number | string): string {
        return `/pedidos-tienda/${id}`;
    }

    static listDevolucionesColegio(): string {
        return "/devoluciones-colegio";
    }

    static detalleDevolucionesColegio(id: number | string): string {
        return `/devoluciones-colegio/${id}`;
    }

    static listPedidosColegio(): string {
        return "/pedidos-colegio";
    }

    static detallePedidoColegio(id: number | string): string {
        return `/pedidos-colegio/${id}`;
    }
}