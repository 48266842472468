import * as React from "react";

import nameof from "ts-nameof.macro";

import { Link } from "react-router-dom";
import { ColegioUsuarioEditModel, MatriculaListItem, MatriculasSearch, TipoUsuario } from "../models/dataModels";
import { ColegioUsuariosService } from "./ColegioUsuariosService";
import { MatriculasService } from "../matriculas/MatriculasService";
import { Loading } from "../utils/Loading";
import { Button, Col, FormGroup, Label, Modal, ModalBody, ModalFooter, ModalHeader, Row } from "reactstrap";
import { I18n } from "../utils/I18n";
import { RouteHelper } from "../IntranetRouter";
import { UsuarioEditTabs } from "./Edit";
import { EditIcon } from "../utils/Icons";
import { ClientContext } from "../utils/ClientContext";

interface UserNameToolTipProps {
    userId: number;
    userName: string;
}

function UserNameToolTip(props: UserNameToolTipProps) {

    const [isLoading, setIsLoading] = React.useState(false);
    const [user, setUser] = React.useState<ColegioUsuarioEditModel | null>(null);
    const [matriculas, setMatriculas] = React.useState<MatriculaListItem[] | null>(null);
    const [isSending, setIsSending] = React.useState(false)

    const sendRequest = React.useCallback(async () => {
        if (isSending) return

        setIsSending(true);

        if ((props.userId || 0) !== 0) {
            setIsLoading(true);
            var srv = new ColegioUsuariosService();
            srv.getById(props.userId)
                .then((user) => {
                    setUser(user);
                    searchMatriculas(user);
                })
                .catch((error) => console.log(error));
        }

    }, [isSending])

    function searchMatriculas(user: ColegioUsuarioEditModel) {

        if (user.tipoUsuario == TipoUsuario.Alumno) {
            var search = {
                usuarioId: user.id,
                cursoEscolarId: ClientContext.Current.cursoEscolarActivoId
            } as MatriculasSearch;

            var srvMatriculas = new MatriculasService();
            srvMatriculas.search(search)
                .then((matriculas) => {
                    setMatriculas(matriculas.items);
                    setIsSending(false);
                    setIsLoading(false);
                })
                .catch((error) => console.log(error));
        }
        else {
            setIsSending(false);
            setIsLoading(false);
        }
    }

    function resetData() {
        setUser(null);
        setMatriculas(null);
    }

    if (ClientContext.Current.tipoUsuario == TipoUsuario.Alumno) {
        return (
            <span>{props.userName}</span>
        );
    }

    return (
        <React.Fragment>
            {isLoading && <Loading />}
            {!isLoading && <span
                style={{ cursor: "pointer" }}
                onClick={sendRequest}>
                <strong>{props.userName}</strong>
            </span>}
            {!isLoading && user != null && <Modal className="modal-lg" isOpen={true} toggle={() => {
                resetData();
            }} >
                <ModalHeader
                    close={<button className="btn-close" onClick={() => resetData()} />}
                >
                    {user.nombre} {user.apellidos} - {user.login }
                </ModalHeader>
                <ModalBody>
                    <Row>
                        <Col md={12}>
                            <FormGroup>
                                <Label className="form-label"><strong>{I18n.Strings.colegioUsuarios.claseActual}:</strong></Label>
                                <div>{user.claseActual || "-"}</div>
                            </ FormGroup>
                        </Col>
                    </Row>
                    {!isLoading && matriculas != null && matriculas.length > 0 && <React.Fragment>
                        <Row>
                            <Col md={12}>
                                <strong>{I18n.Strings.colegioUsuarios.tabMatriculas}:</strong>
                            </Col>
                        </Row>
                        <div className={"licencias"}>
                            {matriculas.map((matricula, index) => {
                                return <Row key={index}>
                                    <Col md={3}>
                                        {matricula.clase}
                                    </Col>
                                    <Col md={9}>
                                        {matricula.materia}
                                    </Col>
                                </Row>
                            })}
                        </div>
                    </React.Fragment>}
                </ModalBody>
                <ModalFooter>
                    <Link
                        target="_blank"
                        className="btn btn-primary"
                        to={RouteHelper.editUsuarioColegio(user.id, UsuarioEditTabs.Datos)}>
                        <EditIcon /> {I18n.Strings.edit}
                    </Link>
                    <Button
                        onClick={() => {
                            resetData();
                        }}>
                        {I18n.Strings.cancel}
                    </Button>
                </ModalFooter>
            </Modal>}
        </React.Fragment>
    );
}

export default UserNameToolTip;