
import { Campanna, CursoEscolarEditModel, CursoEscolarListItemPaginatedList, CursoEscolarSearch, ModelValidationResult } from "../models/dataModels";
import { ApiFetcher } from "../utils/ApiFetcher";
import { IServiceForEdit, IServiceForIndex } from "../utils/base/BaseServices";
import { ClientContext } from "../utils/ClientContext";

export class CursoEscolarService
    implements IServiceForIndex<CursoEscolarSearch, CursoEscolarListItemPaginatedList>, IServiceForEdit<CursoEscolarEditModel>
{


    getCampannas(): Promise<Campanna[]> {
        var apiFetcher = new ApiFetcher();
        var prom = apiFetcher.getByUrl<Campanna[]>("intranet/campannas");
        return prom;
    }

    search(searchData: CursoEscolarSearch): Promise<CursoEscolarListItemPaginatedList> {

        var apiFetcher = new ApiFetcher();
        var prom = apiFetcher.post<CursoEscolarSearch, CursoEscolarListItemPaginatedList>("intranet/colegio/cursos/search", searchData);
        return prom;
    }

    delete(id: number) : Promise<any> {
        var apiFetcher = new ApiFetcher();
        var prom = apiFetcher.delete<any>(`intranet/colegio/cursos/${id}`);
        return prom;
    }

    getById(id: string | number): Promise<CursoEscolarEditModel> {
        var apiFetcher = new ApiFetcher();
        var prom = apiFetcher.getByUrl<CursoEscolarEditModel>(`intranet/colegio/cursos/${id}`);
        prom.then(res => {
            res.fechaInicio = res.fechaInicio == null ? null : new Date(res.fechaInicio);
            res.fechaFin = (res.fechaFin == null || res.fechaFin.toString() == '0001-01-01T00:00:00Z') ? null : new Date(res.fechaFin);
            res.fechaInicioCampagna = (res.fechaInicioCampagna == null || res.fechaInicioCampagna.toString() == '0001-01-01T00:00:00Z') ? null : new Date(res.fechaInicioCampagna);
            res.fechaFinCampagna = (res.fechaFinCampagna == null || res.fechaFinCampagna.toString() == '0001-01-01T00:00:00Z' ) ? null : new Date(res.fechaFinCampagna);
        });
        return prom;
    }
    validate(model: CursoEscolarEditModel): Promise<ModelValidationResult> {
        var apiFetcher = new ApiFetcher();
        var prom = apiFetcher.post<CursoEscolarEditModel, ModelValidationResult>(`intranet/colegio/cursos/validate`, model);
        return prom;
    }

    create(model: CursoEscolarEditModel): Promise<CursoEscolarEditModel> {
        var apiFetcher = new ApiFetcher();
        var prom = apiFetcher.post<CursoEscolarEditModel, CursoEscolarEditModel>(`intranet/colegio/cursos/`, model);
        return prom;
    }

    update(id: string | number, model: CursoEscolarEditModel): Promise<CursoEscolarEditModel> {
        var apiFetcher = new ApiFetcher();
        var prom = apiFetcher.put<CursoEscolarEditModel, CursoEscolarEditModel>(`intranet/colegio/cursos/${id}`, model);
        ClientContext.Current.recargarNiveles = true;
        return prom;
    }

    exportExcel(search: CursoEscolarSearch): Promise<any> {
        throw new Error("Method not implemented.");
    }


}