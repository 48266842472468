import * as React from "react";

import nameof from "ts-nameof.macro";

import { I18n } from "../../utils/I18n";


import { ContenidosPapelColegioService } from "./ContenidosPapelColegioService";

import { GenericPicker, GenericPickerProps, IGenericPickerState } from "../../utils/base/GenericPickerBase";
import { TipoUsuarioContenido, ContenidosDigitalesColegioListItemPaginatedList, ContenidosDigitalesColegioListItem, ContenidosDigitalesColegioSearch, Editorial, ContenidosPapelColegioSearch, ContenidosPapelColegioListItem, ContenidosPapelColegioListItemPaginatedList } from "../../models/dataModels";
import { IServiceForIndex } from "../../utils/base/BaseServices";
import { TableColumn } from "../../utils/base/IndexPageBase";
import { FormGroup, Input, Label, Row, Col } from "reactstrap";
import { EditorialDropDown } from "../../helpers/EnumDropDown";
import { NivelEducativoDropDown } from "../../cursoEscolar/NivelEducativoDropDown";

interface ContenidosPickerProps extends GenericPickerProps<ContenidosPapelColegioSearchEx, ContenidosPapelColegioListItem> {

    errorMessage?: string;
    cursoEscolarId?: number;
    nivelEducativoId: number;
    claseId?: number;
    //excluirLicenciasUsuarioId?: number;
    //tipoUsuario?: TipoUsuarioContenido;
    mostrarSoloColegio: boolean;
    mostrarNiveles: boolean;
    //showCheckboxes?: boolean;
    usuarioColegioId?: number;
}

export  interface ContenidosPapelColegioSearchEx extends ContenidosPapelColegioSearch {
    nivelEducativo: string;
    clase: string;
}

export class ContenidosPicker extends
    GenericPicker<ContenidosPapelColegioSearchEx, ContenidosPapelColegioListItemPaginatedList, ContenidosPapelColegioListItem, ContenidosPickerProps>
{

    constructor(props: any) {
        super(props);

    }

    protected GetTitulo(): string {
        return `Seleccionar contenidos papel`;
    }

    protected GetSearchText(): string {
        return null;
    }
    


    protected GetInitialSearchParams(): ContenidosPapelColegioSearchEx {
        const searchParams = {
            ... {} as ContenidosPapelColegioSearch,
            nivelEducativoId: this.props.nivelEducativoId,
            nivelEducativo: null,
            claseId: this.props.claseId,
            clase: null,
            //excluirLicenciasUsuarioId: this.props.excluirLicenciasUsuarioId,
            cursoEscolarId: this.props.cursoEscolarId,
            usuarioColegioId: this.props.usuarioColegioId,
            rowsPerPage: 10,
            mostrarNivelesEducativos: this.props.mostrarNiveles,
            showOnlySchoolContents: this.props.mostrarSoloColegio
        } as ContenidosPapelColegioSearchEx;

        return searchParams;
    }
    /*
    protected _showCheckboxes(): boolean {
        return this.props.showCheckboxes;
    }
    */

    protected _createState(): IGenericPickerState<ContenidosPapelColegioSearchEx, ContenidosPapelColegioListItemPaginatedList> {
        const state = super._createState();
        return state;
    }

    protected _createApiService(): IServiceForIndex<ContenidosPapelColegioSearchEx, ContenidosPapelColegioListItemPaginatedList> {
        return new ContenidosPapelColegioService();
    }

    protected RenderSearchForm(): JSX.Element {
        return (
            <Row>
                <Col xs="12" md="5" lg="5">
                    <FormGroup>
                        <Label for={"usuarios" + nameof(this.state.searchParams.nombre)}>
                            {I18n.Strings.contenidos.nombre}
                        </Label>
                        <Input
                            onChange={(evt) => {
                                var state = this._cloneStateForSetState();
                                state.searchParams.nombre = evt.target.value;
                                this.setState(state);
                            }}
                            type="text"                            
                            maxLength={100}
                            className={"form-control"}
                            name={nameof(this.state.searchParams.nombre)}
                            id={"usuarios" + nameof(this.state.searchParams.nombre)}
                            value={this.state.searchParams.nombre || ""}
                        />

                    </FormGroup>
                </Col>
                <Col xs="12" md="4" lg="3">
                    <FormGroup>
                        <Label for={"usuarios" + nameof(this.state.searchParams.editorial)}>
                            {I18n.Strings.contenidos.editorial}
                        </Label>
                        <EditorialDropDown
                            label={I18n.Strings.licenciasContenido.editorial}
                            id={nameof(this.state.searchParams.editorial)}
                            value={this.state.searchParams.editorial}
                            required={true}
                            onChanged={(value: string) => {
                                var newState = this._cloneStateForSetState();
                                newState.searchParams.editorial = value == '' ? null : value as Editorial;
                                this.setState(newState, () => this._performSearch());
                            }}
                        />

                    </FormGroup>
                </Col>
                <Col xs="12" md="4" lg="4">
                    {(this.props.nivelEducativoId != null || this.props.claseId == null) && <FormGroup>
                        <Label for={"usuarios" + nameof(this.state.searchParams.nivelEducativoId)}>
                            {I18n.Strings.contenidos.nivelesEducativos}
                        </Label>

                        <NivelEducativoDropDown
                            id={nameof(this.state.searchParams.nivelEducativoId)}

                            cursoEscolarId={this.state.searchParams.cursoEscolarId}

                            value={this.state.searchParams.nivelEducativoId}
                            text={this.state.searchParams.nivelEducativo}
                            seleccionClases={false}

                            onChange={(id, nombre) => {
                                var newState = this._cloneStateForSetState();
                                newState.searchParams.nivelEducativoId = id;
                                newState.searchParams.nivelEducativo = nombre;
                                this.setState(newState, () => this._performSearch());
                            }}
                        />
                    </FormGroup>}
                    {this.props.claseId != null && <FormGroup>
                        <Label for={"usuarios" + nameof(this.state.searchParams.claseId)}>
                            {I18n.Strings.contenidos.clases}
                        </Label>

                        <NivelEducativoDropDown
                            id={nameof(this.state.searchParams.claseId)}

                            cursoEscolarId={this.state.searchParams.cursoEscolarId}

                            value={this.state.searchParams.claseId}
                            text={this.state.searchParams.clase}
                            seleccionClases={true}

                            onChange={(id, nombre) => {
                                var newState = this._cloneStateForSetState();
                                newState.searchParams.claseId = id;
                                newState.searchParams.clase = nombre;
                                this.setState(newState, () => this._performSearch());
                            }}
                        />
                    </FormGroup>}
                </Col>

                <Col xs="12" md="5" lg="5">
                    <FormGroup>
                        <Label for={"usuarios" + nameof(this.state.searchParams.sku)}>
                            {I18n.Strings.pedidos.sku}
                        </Label>
                        <Input
                            onChange={(evt) => {
                                var state = this._cloneStateForSetState();
                                state.searchParams.sku = evt.target.value;
                                this.setState(state);
                            }}
                            type="text"
                            maxLength={100}
                            className={"form-control"}
                            name={nameof(this.state.searchParams.sku)}
                            id={"usuarios" + nameof(this.state.searchParams.sku)}
                            value={this.state.searchParams.sku || ""}
                        />

                    </FormGroup>
                </Col>
            </Row>
        );
    }

    protected _getColumns(): TableColumn<ContenidosPapelColegioListItem>[] {

        const columns: TableColumn<ContenidosPapelColegioListItem>[] = [];

        var sample: ContenidosPapelColegioListItem;

        columns.push(
            {
                fieldName: nameof(sample.sku),
                title: I18n.Strings.contenidos.isbn,
                renderField: (item) => item.sku
            });

        columns.push(
            {
                fieldName: nameof(sample.nombre),
                title: I18n.Strings.contenidos.nombre,
                renderField: (item) => item.nombre
            });

        columns.push(
            {
                fieldName: nameof(sample.editorial),
                title: I18n.Strings.contenidos.editorial,
                renderField: (item) => {
                    return I18n.Strings.getEnumText(`Editorial.${item.editorial}`);
                }
            });

        columns.push(
            {
                fieldName: nameof(sample.nivelesEducativos),
                title: I18n.Strings.contenidos.nivelesEducativos,
                renderField: (item) => item.nivelesEducativos
            });

        

        return columns;
    }
}