import * as React from "react";

import { Modal, ModalHeader, ModalBody, ModalFooter, Button, Row, Col, FormGroup, Label, FormFeedback } from "reactstrap";
import { I18n } from "../../utils/I18n";
import { MiPerfilEditModel } from "../../models/dataModels";
import { SaveIcon } from "../../utils/Icons";
import { NivelEducativoDropDown } from "../../cursoEscolar/NivelEducativoDropDown";
import { ClientContext } from "../../utils/ClientContext";
import { ModelValidation } from "../../utils/base/ModelValidation";
import { Loading } from "../../utils/Loading";
import { cloneDeep } from "lodash-es";
import { MiPerfilService } from "../MiPerfilService";
import { ClasesPosiblesDropDown } from "../../clases/alumno/ClasesPosiblesDropDown";

interface SetClaseActualState {
    loading: boolean;
    validationResult: ModelValidation;
    editModel: MiPerfilEditModel;
}

interface SetClaseActualProps {
    onSaved: (claseId: number) => void;
}

export class SetClaseActualModal extends React.Component<SetClaseActualProps, SetClaseActualState> {

    public constructor(props: any) {
        super(props);

        this.state = {
            loading: false,
            validationResult: new ModelValidation(),
            editModel: null
        };
    }

    componentDidMount() {
        var service = new MiPerfilService();
        service.getMyEditModel()
            .then(myData => {
                myData.claseActualId = null;
                this.setState({
                    loading: false,
                    editModel: myData,
                });
            });
    }

    protected _validateModelLocal(): ModelValidation {
        let validation = new ModelValidation();
        if (this.state.editModel.claseActualId == null) {
            validation.addError("grupoClase", I18n.Strings.validationErrors.valueRequired(I18n.Strings.matriculas.nombreClase));
        }

        return validation;
    }

    protected _validateState(): Promise<ModelValidation> {

        var validationProm = Promise.resolve<ModelValidation>(this._validateModelLocal())
            .then(localValidationResult => {
                return localValidationResult;
            });

        return validationProm;
    }

    protected _validateAndSubmit(): void {
        this._validateState()
            .then(validationResultTemp => {

                if (validationResultTemp == null) {
                    alert("error validation");
                    return false;
                }
                var validationResult = new ModelValidation();
                validationResult.load(validationResultTemp);

                if (validationResult.isOk) {
                    console.info("La validación es correcta");
                    this.setState({ loading: true });
                    this._saveData();
                }
                else {
                    console.log("Validación no correcta");
                    console.warn(validationResult);
                    this.setState({ validationResult: validationResult });
                }
            });
    }

    protected _saveData(): void {
        var srv = new MiPerfilService();

        srv.update(this.state.editModel).then(ret => {
            this.setState({ loading: false }, () => {
                this.props.onSaved(ret.claseActualId);
            });
        });
    }

    protected _cloneStateForSetState(): SetClaseActualState {
        return cloneDeep(this.state) as SetClaseActualState;
    }

    protected _errorMessage(fieldName: string): JSX.Element {
        var error = this.state.validationResult.getError(fieldName);
        if (error) {
            return <FormFeedback>{error}</FormFeedback>;
        }
        return null;
    }

    render() {
        return <React.Fragment>
            {this.state.editModel != null && <Modal isOpen={true} className="wide-dialog">
                <ModalHeader>
                    {I18n.Strings.setClaseActual.titulo}
                </ModalHeader>
                <ModalBody>
                    <Row className="mb-4">
                        <Col md={12}>
                            {I18n.Strings.setClaseActual.texto}
                        </Col>
                    </Row>
                    <Row className="mb-4">
                        <Col md={12}>
                            <FormGroup>
                                <Label for={"grupoClase"}>{I18n.Strings.matriculas.nombreClase}</Label>

                                <NivelEducativoDropDown
                                    id={"grupoClase"}
                                    ocultarGrupoGeneral={true}
                                    cursoEscolarId={ClientContext.Current.cursoEscolarActivoId}

                                    value={this.state.editModel.claseActualId}
                                    text={this.state.editModel.claseActual}
                                    seleccionClases={true}

                                    onChange={(id, nombre) => {
                                        var state = this._cloneStateForSetState();
                                        state.editModel.claseActualId = id;
                                        state.editModel.claseActual = nombre;
                                        this.setState(state);
                                    }}
                                />
                                {this._errorMessage("grupoClase")}
                            </FormGroup>
                        </Col>
                    </Row>
                </ModalBody>
                <ModalFooter>
                    {!this.state.loading && <Button color="primary"
                        onClick={() => {
                            this._validateAndSubmit();
                        }}>
                        <SaveIcon />
                        {I18n.Strings.save}
                    </Button>}
                    {this.state.loading && <Button color="primary"
                        disabled={true}>
                        <SaveIcon />
                        {I18n.Strings.saving}
                    </Button>}
                </ModalFooter>
            </Modal>}
        </React.Fragment>;
    }
};