/*
 * La clase de estado base para los formularios de busqueda
 */

import { ModelValidation } from "./ModelValidation";

export class GenericEditState<TEditModel>
{
    constructor() {
        this.validationResult = new ModelValidation();
    }

    /**
     * El titulo de la pagina
     */
    public headerTitle?: string;


    /**
     * El modelo de edicion
     */
    public editModel: TEditModel;
    public originalEditModel: TEditModel;

    /**
     * El modelo con los resultados de las validaciones
     */
    public validationResult: ModelValidation;

    public showLoading?: boolean;

    /**
     *  En las pantallas con tabs, el tab activo
     */
    public activeTab?: any;

    /**
     * En las pantallas donde se permite borrar cosas... si se muestra la confirmación o no.
     * Si es null no se muestra, si no, pues se muestra con el texto que contenga
     */
    public showDeleteConfirmation?: string | boolean;
}