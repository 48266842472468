import nameof from "ts-nameof.macro";
import { ExportFileResult, MatriculaEditModel, ModelValidationResult, PedidosTiendaSearch, PedidoTiendaDetailResult, PedidoTiendaInformeCompletoMessage, PedidoTiendaListItemPaginatedList } from "../models/dataModels";
import { ApiFetcher } from "../utils/ApiFetcher";
import { IServiceForEdit, IServiceForIndex } from "../utils/base/BaseServices";
import { downloadFileFromBase64Array } from "../utils/ValuesFormatter";


export class PedidosTiendaService
    implements IServiceForIndex<PedidosTiendaSearch, PedidoTiendaListItemPaginatedList>, IServiceForEdit<PedidoTiendaDetailResult>
{


    search(searchData: PedidosTiendaSearch): Promise<PedidoTiendaListItemPaginatedList> {

        var apiFetcher = new ApiFetcher();
        var prom = apiFetcher.post<PedidosTiendaSearch, PedidoTiendaListItemPaginatedList>("intranet/pedidos-tienda/search", searchData);

        prom.then(res => {
            
            res.items.forEach((val) => {
                val.fechaPedido = val.fechaPedido != null ? new Date(val.fechaPedido) : null;
            });

            return res;
        });

        return prom;
    }

    delete(id: number): Promise<any> {
        throw new Error("Not Implemented");
    }

    getById(id: string | number): Promise<PedidoTiendaDetailResult> {
        
        var apiFetcher = new ApiFetcher();
        var prom = apiFetcher.getByUrl<PedidoTiendaDetailResult>(`intranet/pedidos-tienda/${id}`);
        prom = prom.then(model => {

            model.fechaPedido = model.fechaPedido == null ? null : new Date(model.fechaPedido);
            model.fechaDeProcesoEnJob = model.fechaDeProcesoEnJob == null ? null : new Date(model.fechaDeProcesoEnJob);
            return model;
        });
        return prom;
    }

    validate(model: PedidoTiendaDetailResult): Promise<ModelValidationResult> {
        throw new Error("Method not implemented.");
    }

    create(model: PedidoTiendaDetailResult): Promise<PedidoTiendaDetailResult> {
        throw new Error("Method not implemented.");
    }

    update(id: string | number, model: PedidoTiendaDetailResult): Promise<PedidoTiendaDetailResult> {
        throw new Error("Method not implemented.");
    }

    exportExcel(search: PedidosTiendaSearch): Promise<any> {
        var url = "intranet/pedidos-tienda/exportExcel";

        var apiFetcher = new ApiFetcher();
        var promise = apiFetcher.post<PedidosTiendaSearch, ExportFileResult>(url, search)
            .then((result) => {
                downloadFileFromBase64Array(result.content, result.fileName, "application/octet-stream");
                return result;
            });
        return promise;
    }

    exportExcelCompleto(colegioId: number, campannaId: number): Promise<any> {
        var url = "intranet/pedidos-tienda/exportExcelCompleto";

        var modelo = {
            campanaId: campannaId,
            colegioId: colegioId
        } as PedidoTiendaInformeCompletoMessage;

        var apiFetcher = new ApiFetcher();
        var promise = apiFetcher.post<PedidoTiendaInformeCompletoMessage, ExportFileResult>(url, modelo)
            .then((result) => {
                downloadFileFromBase64Array(result.content, result.fileName, "application/octet-stream");
                return result;
            });
        return promise;
    }

}