import React from "react";

import { ClientContext } from "../utils/ClientContext";
import { MisLicenciasHome } from "./MisLicenciasHome";
import { ComprasPosibles } from "./ComprasPosibles";
import { Alert, Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { ClaseListItem, SortDirection, TipoUsuario } from "../models/dataModels";
import { I18n } from "../utils/I18n";
import { SetClaseActualModal } from "../miPerfil/alumno/SetClaseActualModal";
import { MisClasesService } from "../clases/MisClasesService";
import nameof from "ts-nameof.macro";
import { AccesosDirectosHome } from "./AccesosDIrectosHome";
import { Diagnostico } from "./Diagnosticos";
import { NavigationHelper } from "../utils/base/Breadcrumb";
import { ImpersonationService } from "../login/Impersonate";
import { AlertsService } from "../utils/AlertsService";
import { RouteHelper } from "../IntranetRouter";
import { AuthService } from "../services/AuthService";
import { Cipher } from "crypto";

interface HomeState {
    loading: boolean;
    showSetClaseModal: boolean;
}

export default class Home extends React.Component<any, HomeState> {

    public constructor(props: any) {
        super(props);

        this.state = {
            loading: false,
            showSetClaseModal: false
        };

        NavigationHelper.clearItems();

    }

    componentDidMount() {

        if (!ClientContext.Current.estoyImpersonado &&
            ClientContext.Current.tipoUsuario == TipoUsuario.Alumno &&
            ClientContext.Current.estoyClaseGeneral &&
            ClientContext.Current.cursoEscolarActivoId != null) {

            var item = {} as ClaseListItem;

            var misClasesService = new MisClasesService();
            var prom = misClasesService.search({
                colegioId: ClientContext.Current.colegioId,
                profesorId: null,
                alumnoId: ClientContext.Current.usuarioId,
                posibles: true,
                nivelEducativoId: null,
                noTotalCount: true,
                currentPage: 0,
                rowsPerPage: -1,
                orderByColumn: nameof(item.clase),
                orderMode: SortDirection.Asc,
                bypassAutomaticSort: false,
                currentPageRows: -1
            });

            prom
                .then(clasesRes => {
                    this.setState({
                        loading: false,
                        showSetClaseModal: clasesRes.items.length == 0 || clasesRes.items.some((el) => el.clase.indexOf('General') < 0)
                    });
                })
                .catch((error) => this.setState({ loading: false }, () => AlertsService.showError(error)));
        }
    }

    render(): JSX.Element {
        
        var usuarioActual = ClientContext.Current;

        if (usuarioActual.cursoEscolarActivoId == null) {

            let closeModal = () => {
                if (usuarioActual.estoyImpersonado) {
                    var impersonationService = new ImpersonationService();
                    impersonationService.impersonate(0);
                }
                else {
                    let authService = new AuthService();
                    authService.logout();
                }
            }

            return <React.Fragment>
                <Modal isOpen={true}>
                    <ModalHeader
                        close={<button className="btn-close" onClick={() => closeModal()} />}
                    >
                        {I18n.Strings.home.noHayCursoEscolarActivoTitle}
                    </ModalHeader>
                    <ModalBody>
                        <p className="pt-3 pb-3 text-center">{I18n.Strings.home.noHayCursoEscolarActivoBody}</p>
                    </ModalBody>
                    <ModalFooter>
                        <Button className={"btn-rounded btn"} id="save" color="primary"
                            onClick={() => {
                                if (ClientContext.Current.estoyImpersonado) {
                                    var service = new ImpersonationService();
                                    service.deimpersonate();
                                }
                                else {
                                    closeModal()
                                }
                            }}
                        >
                            Salir
                        </Button>
                    </ModalFooter>
                </Modal>
            </React.Fragment>;
        }

        var modalErrorNombreApellidos = <></>;
        if (
            (usuarioActual.nombre == null || usuarioActual.nombre.indexOf("@") >= 0)
            ||
            ((usuarioActual.apellidos || "").length == 0)
        ) {

            var perfilUrl = null;
            switch (usuarioActual.tipoUsuario) {
                case TipoUsuario.Alumno:
                    perfilUrl = RouteHelper.myProfileStudent();
                    break;
                case TipoUsuario.Profesor:
                    perfilUrl = RouteHelper.myProfileTeacher();
                    break;
                case TipoUsuario.AdminColegio:
                case TipoUsuario.AdminGlobal:
                    perfilUrl = RouteHelper.myProfileAdmin();
                    break;
            }

            if (perfilUrl != null) {

                modalErrorNombreApellidos = <Modal isOpen={true}>
                    <ModalHeader>
                        Datos del usuario incompletos o erroneos
                    </ModalHeader>
                    <ModalBody>
                        <p className="pt-3 pb-3 text-center">
                            Parece que su nombre o apellidos no están correctamente especificados, por favor corr&iacute;jalos en su perfil.
                        </p>
                    </ModalBody>
                    <ModalFooter>
                        <a className={"btn-rounded btn btn-primary"} id="save" href={perfilUrl}>
                            Ir a mi perfil
                        </a>
                    </ModalFooter>
                </Modal>;
            }
        }

        return <React.Fragment>
            <div className="head-seccion">
                <AccesosDirectosHome />
                <h2>
                    <strong>
                        {I18n.Strings.home.saludo(usuarioActual.nombre)}
                    </strong>
                </h2>
                <p>
                    {I18n.Strings.home.subtituloPrincipal(usuarioActual.nombreColegio)}
                </p>


            </div>

            {modalErrorNombreApellidos}

            {
                this.state.showSetClaseModal &&
                <SetClaseActualModal onSaved={(claseId) => {
                    ClientContext.Current.claseActualId = claseId;
                    ClientContext.Current.estoyClaseGeneral = false;
                    this.setState({ showSetClaseModal: false });
                }}
                />
            }

            {(usuarioActual.tipoUsuario || TipoUsuario.None) == TipoUsuario.None &&
                <Alert color="danger">
                    {I18n.Strings.home.errorUsuarioSinTipo}
                </Alert>
            }

            {usuarioActual.tipoUsuario == TipoUsuario.Alumno && usuarioActual.claseActualId == null &&
                <Alert color="warning">
                    <div dangerouslySetInnerHTML={{ __html: I18n.Strings.home.errorAlumnoSinNivelEducativo(RouteHelper.myProfileStudent()) }} ></div>
                </Alert>
            }

            {(usuarioActual.tipoUsuario == TipoUsuario.AdminGlobal || usuarioActual.tipoUsuario == TipoUsuario.AdminColegio) && <Diagnostico />}

            <MisLicenciasHome />

            {usuarioActual.tipoUsuario == TipoUsuario.Alumno && <ComprasPosibles />}

        </React.Fragment >
    }


}