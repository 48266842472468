
import { ComunidadAutonomaListItem } from "../models/dataModels";
import { ApiFetcher } from "../utils/ApiFetcher";
import { IServiceForEdit, IServiceForIndex } from "../utils/base/BaseServices";
import { ClientContext } from "../utils/ClientContext";

export class ComunidadAutonomaService
{   
    getAll(): Promise<ComunidadAutonomaListItem[]> {

        var apiFetcher = new ApiFetcher();
        var prom = apiFetcher.getByUrl<ComunidadAutonomaListItem[]>("intranet/comunidades");
        return prom;
    }
}