import * as React from "react";

import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from "reactstrap";
import { I18n } from "../utils/I18n";
import { DeleteIcon } from "../utils/Icons";

class DeleteConfirmationProps {
    onConfirm: () => void;
    onCancelDelete: () => void;
    deleteConfirmBody?: string | null;
}

export class DeleteModal extends React.Component<DeleteConfirmationProps, any> {



    public render(): JSX.Element {

        var closeButton = <button className="btn-close" onClick={() => this.props.onCancelDelete()}>&nbsp;</button>;


        return <Modal isOpen={true} className="wide-dialog">
            <ModalHeader close={closeButton}>
                {I18n.Strings.deleteConfirmTitle}
            </ModalHeader>
            <ModalBody>
                <p>{this.props.deleteConfirmBody != null ? this.props.deleteConfirmBody : I18n.Strings.deleteConfirmBody}</p>
            </ModalBody>
            <ModalFooter>
                <Button color="primary"
                    onClick={() => this.props.onConfirm()}>
                    <DeleteIcon />
                    {I18n.Strings.delete}
                </Button>

                <Button color="secondary"
                    onClick={() => this.props.onCancelDelete()}
                >
                    {I18n.Strings.cancel}
                </Button>
            </ModalFooter>
        </Modal>;
    }
}


