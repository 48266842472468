import { AuthService } from '../services/AuthService';
import axios from "axios";
import { logAndExtractInfoFromException } from './ValuesFormatter';

export class Config {
    webApiUrlBase: string;
    stsAuthority: string;
    clientId: string;
    clientRoot: string;
    clientScope: string;
}


export class ConfigInitializer {

    public static Initialize(): Promise<Config> {

        var config = fetch("/config.json")
            .then((configResult) => {

                var configSrvProm = configResult.json() as any as Promise<Config>;

                return configSrvProm.then(configSrv => {

                    var subdomain = window.location.host.split(".")[0];
                    configSrv.webApiUrlBase = configSrv.webApiUrlBase.replace("###HOST###", subdomain);
                    configSrv.clientRoot = configSrv.clientRoot.replace("###HOST###", subdomain);
                    return configSrv;

                });

            })
            .catch((err) => {
                return {
                    webApiUrlBase: "https://localhost:44370/api/",
                    stsAuthority: 'https://localhost:44362/',
                    clientRoot: 'https://localhost:44305/',
                    clientId: 'reactclient',
                    clientScope: 'openid profile email puntoNeutroApi',
                } as Config;
            });


        return config;
    }

}


/**
 * Esta clase se encarga de realizar las llamadas al api
 */


class ApiFetcher {


    public static Init(config: Config) {

        ApiFetcher.WebApiUrlBase = config.webApiUrlBase;
        ApiFetcher.config = config;
    }

    /**
     * La url para realizar las peticiones a la web api
     */
    private static WebApiUrlBase: string = null;
    private static config: Config = null;

    private authService: AuthService;

    constructor() {
        this.authService = new AuthService();
    }


    public callApi(apiFunction: (token: string) => any): Promise<any> {
        return this.authService.getUser()
            .then(user => {

                if (user && user.access_token) {
                    return apiFunction(user.access_token)
                        .catch((error: { response: { status: number; }; }) => {

                            if (error.response == null) {
                                throw Error("Error en la llamada al servidor");
                            }

                            if (error.response.status === 401) {
                                return this.authService.renewToken().then(renewedUser => {
                                    return apiFunction(renewedUser.access_token);
                                });
                            }

                            throw error;
                        });
                } else if (user) {
                    return this.authService.renewToken().then(renewedUser => {
                        return apiFunction(renewedUser.access_token);
                    });
                } else {
                    this.authService.login();
                    //throw new Error('user is not logged in');
                }

            });
    }
    /**
     *
     * @param url La url contra la que hacer el get
     * @param request el objeto para hacer la request (se mete solo en el querystring)
     */
    public get<TRequestObject, TResultItem>(url: string,
        request?: TRequestObject, bearer?: string): Promise<TResultItem> {

        return this.callApi((accessToken) => {
            var apiUrl = ApiFetcher.WebApiUrlBase + url + (request == null
                ? ""
                : ("?" + this.urlEncodeQueryParams(request)));

            var promise = axios.get(apiUrl, {
                headers: {
                    'Authorization': "Bearer " + (bearer ? bearer : accessToken),
                }
            })
                .then((response: any) => response.data as Promise<TResultItem>);

            return promise;
        });
    }

    /**
     *
     * @param url La url contra la que hacer el get
     */
    public getByUrl<TResultItem>(url: string, bearer?: string): Promise<TResultItem> {

        return this.callApi((accessToken) => {

            var promise = axios
                .get(ApiFetcher.WebApiUrlBase + url, {
                    headers: {
                        'Authorization': "Bearer " + (bearer ? bearer : accessToken),
                    }
                })
                .then((response: any) => response.data as Promise<TResultItem>)
                .catch((error: any) => {
                    console.error(logAndExtractInfoFromException(error));
                    throw error;
                });

            return promise;
        });
    }


    //public getBlobInBase64(url: string): Promise<Blob> {

    //    var promise = axios.get(url,
    //        {
    //            responseType: "blob",
    //            headers: {
    //                'Authorization': "Bearer " + ClientContext.Current.bearer,
    //            }
    //        });

    //    var result = promise
    //        .then((p: any) => {
    //            return p.data;
    //        });

    //    return result;
    //}


    /**
     * Borra un objeto
     * @param url
     */
    public delete<TDeleteResult>(url: string): Promise<TDeleteResult> {


        return this.callApi((accessToken) => {

            var promise = axios
                .delete(ApiFetcher.WebApiUrlBase + url,
                    {
                        headers: {
                            'Authorization': "Bearer " + accessToken,
                        }
                    })
                .then((response: any) => {
                    return response.data as Promise<TDeleteResult>;
                })
                .catch((error: any) => {
                    console.error(logAndExtractInfoFromException(error));
                    //alert("Ha sucedido un error invocando al servicio");
                    throw error;
                });

            return promise;

        });

    }

    /**
     *
     * @param url La url contra la que hacer post
     * @param request El objeto Json a incluir
     */
    public post<TRequestObject, TResult>(url: string, request: TRequestObject): Promise<TResult> {

        return this.callApi((accessToken) => {

            var promise = axios
                .post(ApiFetcher.WebApiUrlBase + url, request,
                    {
                        headers: {
                            'Authorization': "Bearer " + accessToken,
                        }
                    })
                .then((response: any) => {
                    return response.data as Promise<TResult>;
                })
                .catch((error: any) => {
                    console.error(logAndExtractInfoFromException(error));
                    //alert("Ha sucedido un error invocando al servicio");
                    throw error;
                });

            return promise;
        });

    }

    /**
         *
         * @param url La url contra la que hacer put
         * @param request El objeto Json a incluir
         */
    public put<TRequestObject, TResult>(url: string, request: TRequestObject): Promise<TResult> {

        return this.callApi((accessToken) => {

            var promise = axios
                .put(ApiFetcher.WebApiUrlBase + url, request,
                    {
                        headers: {
                            'Authorization': "Bearer " + accessToken,
                        }
                    })
                .then((response: any) => {
                    return response.data as Promise<TResult>;
                })
                .catch((error: any) => {
                    console.error(logAndExtractInfoFromException(error));
                    //alert("Ha sucedido un error invocando al servicio");
                    throw error;
                });

            return promise;

        });
    }




    //public getAndDownloadBlob(url: string): Promise<Blob> {

    //    var instance = axios.create();
    //    instance.defaults.timeout = 1200000;

    //    var blobProm = instance.get<Blob>(url, {
    //        headers: {
    //            'Authorization': "Bearer " + ClientContext.Current.bearer,
    //        },
    //        responseType: "blob"
    //    });

    //    var result = blobProm
    //        .then((blobResponse: any) => {
    //            return blobResponse.data;
    //        });

    //    return result;
    //}

    //private getFileNameByContentDisposition(contentDisposition: string): string {

    //    if (contentDisposition == null) {
    //        throw new Error("No puedo obtener el nombre del fichero");
    //    }

    //    let regex = /filename\*[^;=\n]*=UTF-8''((['"]).*?\2|[^;\n]*)/;
    //    let matches = regex.exec(contentDisposition);
    //    let filename: any = null;

    //    if (matches != null && matches.length > 1) {
    //        filename = decodeURI(matches[1]);
    //    }

    //    return decodeURI(filename);
    //}

    private urlEncodeQueryParams(data: any) {
        const params = Object.keys(data).map(key => data[key] ? `${encodeURIComponent(key)}=${encodeURIComponent(data[key])}` : "");
        return params.filter(value => !!value).join("&");
    }
}

export interface BlobInfo {
    content: Blob;
    filename: string;
}

export { ApiFetcher };