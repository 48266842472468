import React, { useEffect, useState } from 'react'; // we need this to make JSX compile

export const Loading = function () {

    const [show, setShow] = useState(false);

    useEffect(() => {
        
        const timer = setTimeout(() => {
            setShow(true);
        }, 300);

        return () => clearTimeout(timer);
    }, []);

    if (!show) return null;

    return <div className="overlay">
        <div className="loading">
            <div className="lds-dual-ring" />
        </div>
    </div>;

}