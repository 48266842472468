import * as React from "react";
import { withRouter } from "react-router-dom";
import { Col } from "reactstrap";
import nameof from "ts-nameof.macro";
import { RouteHelper } from "../IntranetRouter";
import { AccesoDirectoListItem, AccesoDirectoListItemPaginatedList, AccesoDirectoSearch } from "../models/dataModels";
import { IServiceForIndex } from "../utils/base/BaseServices";
import { IndexPageBase, TableColumn } from "../utils/base/IndexPageBase";
import { I18n } from "../utils/I18n";
import { AccesoDirectoService } from "./AccesoDirectoService";



class IndexComp extends IndexPageBase<AccesoDirectoSearch, AccesoDirectoListItemPaginatedList, AccesoDirectoListItem>
{
    protected _renderSearchForm(): React.ReactNode {
        return <React.Fragment>
            <Col xs="12" md="6" lg="4">
                    

            </Col>
            <Col xs="12" md="6" lg="3">

            </Col>

        </React.Fragment>;
    }

    protected _getApiService(): IServiceForIndex<AccesoDirectoSearch, AccesoDirectoListItemPaginatedList> {
        return new AccesoDirectoService();
    }

    protected _getTableColumns(): TableColumn<AccesoDirectoListItem>[] {
        return [
            {
                fieldName: nameof(this.state.searchResults.items[0].titulo),
                title: I18n.Strings.matriculas.nombreClase,
                renderField: (item) => item.titulo
            }
        ];
    }

    protected _getPageTitle(): string {
        return I18n.Strings.accesosDirectos.title;
    }
    protected _getPageDescription(): string {
        return I18n.Strings.accesosDirectos.description;
    }

   
    protected _getEditionItemUrl(item: AccesoDirectoListItem): string {
        return RouteHelper.editAccesoDirecto(item.id);
    }

    protected _getNewItemUrl(): string {
        return RouteHelper.createAccesoDirecto();
    }

}

export const Index = withRouter(IndexComp);