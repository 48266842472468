import * as React from "react";
import { DropDownBase, GenericSelectItem, IDropDownProps } from "../utils/base/DropDownBase";

import { ClientContext } from "../utils/ClientContext";
import { CursoEscolarService } from "./CursoEscolarService";

export class CampannaDropDown extends DropDownBase<IDropDownProps> {


    protected GetItems(): Promise<GenericSelectItem[]> {

        var cursoEscolarSrv = new CursoEscolarService();

        var campannas = cursoEscolarSrv.getCampannas().then(campanas => {
            return campanas.map((c) => {
                return {
                    id: c.anno,
                    name: c.anno.toString(),
                } as GenericSelectItem;
            })

        });

        return campannas;
    }

    protected shouldAddEmptyOption() {
        return true;
    }
};
