import * as React from "react";
import { FormGroup, Label, FormFeedback } from "reactstrap";

import { ComponentBase } from "./ComponentBase";
import { toPairs } from "lodash-es";
import { I18n } from "../I18n";

export interface IEnumDropDownProps {
    value: string;
    required: boolean | true;
    id?: string;
    disabled?: boolean | false;
    showLabel?: boolean | false;
    label: string;
    className?: string;
    hideEmptyOption?: boolean;
    errorMessage?: string;
    onChanged: (value: string) => void;
    emptyText?: string;
}



export interface IEnumDropDownState {
    value: string;
    text: string;
    options: JSX.Element[];
    //isOpen: boolean;
}


export abstract class EnumDropDownBase extends ComponentBase<IEnumDropDownProps, IEnumDropDownState>
{
    abstract Elem(): any;

    abstract ElemName(): string;

    public constructor(props: IEnumDropDownProps) {
        super(props);

        const keysPrev = toPairs(this.Elem());

        let keys = keysPrev
            .map((elem, i) => {
                return {
                    key: elem[0].toString(),
                    text: I18n.Strings.getEnumText(`${this.ElemName()}.${keysPrev[i][1].toString()}`),
                };
            });

        //keys = keys.filter((item, index) => index < keys.length / 2);

        keys = keys.sort((a, b) => {

            var x = a.text.toLowerCase();
            var y = b.text.toLowerCase();
            if (x < y) { return -1; }
            if (x > y) { return 1; }
            return 0;
        });

        let defaultText = this.props.emptyText != null ? this.props.emptyText : " - ";

        if (!this.props.hideEmptyOption) {
            keys.unshift({
                key: "",
                text: defaultText,
            });
        }

        var val = keys.find(p => p.key.toString() == (this.props.value || "").toString())
        if (val != null) {
            defaultText = val.text;
        }



        var options: JSX.Element[] = [];

        keys.map((keyPair, index) => {

            options.push(
                <option key={index} value={keyPair.key} >
                    {keyPair.text}
                </option>
            );
        });

        this.state = {
            options: options,
            value: this.props.value,
            text: defaultText
        } as IEnumDropDownState;

    }

    public shouldComponentUpdate(nextProps: IEnumDropDownProps) {
        return true;
    }

    public static getDerivedStateFromProps(nextProps: IEnumDropDownProps, state: IEnumDropDownState) {

        if (nextProps.value !== state.value) {

            return {
                ...state,
                value: nextProps.value
            };
        }
        return state;
    }

    public render(): JSX.Element {
        return <FormGroup>
            <Label className={ this.props.showLabel ? "" : "visually-hidden"} for={this.props.id}>{this.props.label}</Label>

            <select disabled={this.props.disabled}
                id={this.props.id}
                value={this.state.value || ''}
                onChange={(evt) => {
                    var option = evt.target.options[evt.target.selectedIndex];
                    this.setState({
                        text: option.text,
                        value: option.value
                    }, () => {
                        this.props.onChanged(option.value);
                    });
                }}
                className={"form-select " + (!this.props.errorMessage ? "" : "is-invalid")}
            >

            {this.state.options}
            </select>

        { this.props.errorMessage && <FormFeedback>{this.props.errorMessage}</FormFeedback> }

        </FormGroup >;
    }

}
