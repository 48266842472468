import { stat } from "node:fs/promises";
import * as React from "react";
import { Button, Col, FormGroup, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader, Row } from "reactstrap";
import nameof from "ts-nameof.macro";
import { CampannaDropDown } from "../../cursoEscolar/CampannaDropDown";
import { EditorialDropDown } from "../../helpers/EnumDropDown";
import { RouteHelper } from "../../IntranetRouter";
import { ContenidosDigitalesListItem, ContenidosDigitalesListItemPaginatedList, ContenidosSearch, Editorial } from "../../models/dataModels";
import { AlertsService } from "../../utils/AlertsService";
import { IServiceForIndex } from "../../utils/base/BaseServices";
import { IndexPageBase, TableColumn } from "../../utils/base/IndexPageBase";
import { ClientContext } from "../../utils/ClientContext";
import { I18n } from "../../utils/I18n";
import { SyncIcon } from "../../utils/Icons";
import { SeleccionCampannaModal } from "../../utils/SeleccionCampannaModal";
import { logAndExtractInfoFromException, toCamelCase } from "../../utils/ValuesFormatter";
import { ContenidosService } from "./ContenidosService";
import { ContenidoEditTabs } from "./Edit";


interface ContenidosSearchEx extends ContenidosSearch {
    cursoEscolar: string;
    campannaToSyncId: number;
    campannaToSync: string;

    mostrarDialogoImportacion: boolean;
}

export class Index extends IndexPageBase<ContenidosSearchEx, ContenidosDigitalesListItemPaginatedList, ContenidosDigitalesListItem>
{


    _getInitialSearchParams(): ContenidosSearchEx {

        let datosCursoEscolar = ClientContext.Current.cursosEscolares.find(ce => ce.id == ClientContext.Current.cursoEscolarActivoId);

        return {
            ...{} as any,
            mostrarDialogoImportacion: false,
            campannaToSyncId: datosCursoEscolar.campannaId,
            campannaToSync: datosCursoEscolar.campanna,
            showPopupSync: false
        } as ContenidosSearchEx;
    }

    private _toggleDialogoImportacion() {
        var state = this._cloneStateForSetState();
        state.searchParams.mostrarDialogoImportacion = !state.searchParams.mostrarDialogoImportacion;
        this.setState(state);
    }

    protected _renderSearchForm(): React.ReactNode {
        return <React.Fragment>
            {this.state.searchParams.mostrarDialogoImportacion &&
                <SeleccionCampannaModal
                    mainText="Va a realizar la sincronización de los maestros de contenidos con Magento, seleccione a que ejercicio corresponde la sincronización."
                    campanna={this.state.searchParams.campannaToSync}
                    campannaId={this.state.searchParams.campannaToSyncId}
                    mostrarIsbns={true}
                    campannaSelected={(campannaId: number, campanna: string, isbnsJuntos: string) => {

                        var newState = this._cloneStateForSetState();
                        newState.showLoadingIcon = true;
                        newState.searchParams.campannaToSync = campanna;
                        newState.searchParams.campannaToSyncId = campannaId;
                        newState.searchParams.mostrarDialogoImportacion = false;
                        this.setState(newState, () => {
                            
                            var service = new ContenidosService();

                            var isbns = (isbnsJuntos || "").split(/[,\t;\n]+/).map(isbn => isbn.trim()).filter(i => i.length != 0);
                            var prom = service.queueContenidosSync(
                                newState.searchParams.campannaToSyncId,
                                isbns);
                            prom.then(ret => {
                                this.setState({ showLoadingIcon: false },
                                    () =>
                                        AlertsService.showSuccessMessage(I18n.Strings.notificaciones.procesoEnCola)
                                );
                            }).catch(err => {
                                this.setState({ showLoadingIcon: false },
                                    () => {
                                        AlertsService.showErrorMessage(logAndExtractInfoFromException(err));
                                    })
                            });
                        });
                    }}
                    toggleDialogoImportacion={() => this._toggleDialogoImportacion()}
                />
            }

            <Col xs="12" md="6" lg="7">
                <FormGroup>
                    <Label className="visually-hidden" for={"contenidos" + nameof(this.state.searchParams.nombre)}>
                        {I18n.Strings.licenciasContenido.contenidoBuscador}
                    </Label>
                    <Input
                        placeholder={I18n.Strings.licenciasContenido.contenidoBuscador + "..."}
                        onChange={(evt) => {
                            var state = this._cloneStateForSetState();
                            state.searchParams.nombre = evt.target.value;
                            this.setState(state);
                        }}
                        type="text"
                        maxLength={100}
                        className={"form-control"}
                        name={nameof(this.state.searchParams.nombre)}
                        id={"contenidos" + nameof(this.state.searchParams.nombre)}
                        value={this.state.searchParams.nombre || ""}
                    />

                </FormGroup>
            </Col>


            <Col xs="12" md="4" lg="3">

                <EditorialDropDown
                    emptyText={I18n.Strings.licenciasContenido.editorial + "..."}
                    label={I18n.Strings.licenciasContenido.editorial}
                    id={nameof(this.state.searchParams.editorial)}
                    value={this.state.searchParams.editorial}
                    required={true}
                    onChanged={(value: string) => {
                        var newState = this._cloneStateForSetState();
                        newState.searchParams.editorial = value == '' ? null : value as Editorial;
                        this.setState(newState, () => this._performSearch());
                    }}
                />

            </Col>

            <Col xs="12" md="4" lg="3">
                <FormGroup>
                    <Label className="visually-hidden" for={"contenidos" + nameof(this.state.searchParams.identificadorTienda)}>
                        {I18n.Strings.colegio.identificadorTienda}
                    </Label>
                    <Input
                        onChange={(evt) => {
                            var state = this._cloneStateForSetState();
                            state.searchParams.identificadorTienda = evt.target.value;
                            this.setState(state);
                        }}
                        placeholder={I18n.Strings.colegio.identificadorTienda}
                        type="text"
                        maxLength={100}
                        className={"form-control"}
                        name={nameof(this.state.searchParams.identificadorTienda)}
                        id={"contenidos" + nameof(this.state.searchParams.identificadorTienda)}
                        value={this.state.searchParams.identificadorTienda || ""}
                    />

                </FormGroup>

            </Col>

            <Col xs="12" md="6" lg="4">

                <FormGroup>

                    <Input
                        onChange={(evt) => {
                            var state = this._cloneStateForSetState();
                            state.searchParams.sinImagenYEnUso = evt.target.checked;
                            this.setState(state, () => this._performSearch());
                        }}
                        type="checkbox"
                        style={{ padding: 0, marginRight: 10 }}
                        name={nameof(this.state.searchParams.sinImagenYEnUso)}
                        id={nameof(this.state.searchParams.sinImagenYEnUso)}
                        checked={this.state.searchParams.sinImagenYEnUso || false}
                    />
                    <Label for={nameof(this.state.searchParams.sinImagenYEnUso)}>{I18n.Strings.contenidos.sinImagenYEnUso}</Label>
                </FormGroup>

            </Col>
            {/*
            <Col xs="12" md="6" lg="3">
                <FormGroup>
                    <Label className="visually-hidden" for={"contenidos" + nameof(this.state.searchParams.isbn)}>
                        {I18n.Strings.licenciasContenido.isbn}
                    </Label>
                    <Input
                        placeholder={I18n.Strings.licenciasContenido.isbn + "..."}
                        onChange={(evt) => {
                            var state = this._cloneStateForSetState();
                            state.searchParams.isbn = evt.target.value;
                            this.setState(state);
                        }}
                        type="text"
                        required={true}
                        maxLength={100}
                        className={"form-control"}
                        name={nameof(this.state.searchParams.isbn)}
                        id={"contenidos" + nameof(this.state.searchParams.isbn)}
                        value={this.state.searchParams.isbn || ""}
                    />

                </FormGroup>
            </Col>
            */}
        </React.Fragment>;
    }

    protected _toolbarRightContents(): JSX.Element[] {

        var items = super._toolbarRightContents();
        items.push(
            <Button id="sync" color="secondary" className="btn btn-rounded"
                onClick={() => {
                    this._toggleDialogoImportacion();
                }}
            >
                <SyncIcon />
                {I18n.Strings.sync}
            </Button>
        );

        return items;
    }

    protected _getApiService(): IServiceForIndex<ContenidosSearch, ContenidosDigitalesListItemPaginatedList> {
        return new ContenidosService();
    }

    protected _getTableColumns(): TableColumn<ContenidosDigitalesListItem>[] {
        return [
            {
                fieldName: nameof(this.state.searchResults.items[0].nombre),
                title: I18n.Strings.contenidos.nombre,
                renderField: (item) => item.nombre
            },
            {
                fieldName: nameof(this.state.searchResults.items[0].editorial),
                title: I18n.Strings.contenidos.editorial,
                renderField: (item) => {
                    return I18n.Strings.getEnumText(`Editorial.${item.editorial}`);
                }

            },
            {
                fieldName: nameof(this.state.searchResults.items[0].isbn),
                title: I18n.Strings.contenidos.isbn,
                renderField: (item) => item.isbn
            },
            {
                fieldName: nameof(this.state.searchResults.items[0].tipoContenido),
                title: I18n.Strings.contenidos.tipoContenido,
                renderField: (item) => I18n.Strings.getEnumText(`TipoContenido.${item.tipoContenido}`)
            },
        ];
    }

    protected _getPageTitle(): string {
        return "Maestro de contenidos"
    }
    protected _getPageDescription(): string {
        return "Puede ver y mantener todos los contenidos disponibles en la plataforma";
    }

    protected _getEditionItemUrl(item: ContenidosDigitalesListItem): string {
        return RouteHelper.editContenidos(item.id, ContenidoEditTabs.Datos);
    }

    protected _getNewItemUrl(): string {
        return RouteHelper.createContenidos();
    }

    protected _enableExcelExport(): boolean {
        return true;
    }

    protected _calculateCellClass(item: ContenidosDigitalesListItem) {
        return (item.editorial || Editorial.None) == Editorial.None ? "text-danger" : "";
    }


}
