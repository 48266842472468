import {
     ExportFileResult, CargaEditModel, CargaPersistResult,
    CargaListItemPaginatedList,
    ResultadosCargaUsuariosExcelExport, ResultadosCargaLicenciasExcelExport, ExcelTemplateGetMessage, CargasSearch, CargaGetDetailResult
} from "../models/dataModels";
import { ApiFetcher } from "../utils/ApiFetcher";
import { IServiceForIndex } from "../utils/base/BaseServices";
import { downloadFileFromBase64Array } from "../utils/ValuesFormatter";

export class CargaFicherosService implements IServiceForIndex<CargasSearch, CargaListItemPaginatedList> {

    delete(id: string | number, real?: boolean): Promise<number> {
        throw new Error("Method not implemented.");
    }

    exportExcel(search: CargasSearch): Promise<any> {
        throw new Error("Method not implemented.");
    }


    search(searchData: CargasSearch): Promise<CargaListItemPaginatedList> {

        var apiFetcher = new ApiFetcher();
        var prom = apiFetcher.post<CargasSearch, CargaListItemPaginatedList>("intranet/cargaFichero/search", searchData);
        prom.then(res => {
            res.items.forEach(item => {
                item.fecha = new Date(item.fecha);
            });
        });
        return prom;
    }

    getTemplate(model: ExcelTemplateGetMessage): Promise<ExportFileResult> {
        var apiFetcher = new ApiFetcher();
        var prom = apiFetcher.post<ExcelTemplateGetMessage, ExportFileResult>(`intranet/cargaFichero/plantilla`, model)
            .then((result) => {
                downloadFileFromBase64Array(result.content, result.fileName, "application/octet-stream");
                return result;
            });
        return prom;
    }

    descargarResultadosCargaUsuarios(model: ResultadosCargaUsuariosExcelExport): Promise<ExportFileResult> {
        var apiFetcher = new ApiFetcher();
        var prom = apiFetcher.post<ResultadosCargaUsuariosExcelExport, ExportFileResult>(`intranet/cargaFichero/descargarResultadosCargaUsuarios`, model)
            .then((result) => {
                downloadFileFromBase64Array(result.content, result.fileName, "application/octet-stream");
                return result;
            });
        return prom;
    }

    descargarResultadosCargaLicencias(model: ResultadosCargaLicenciasExcelExport): Promise<ExportFileResult> {
        var apiFetcher = new ApiFetcher();
        var prom = apiFetcher.post<ResultadosCargaLicenciasExcelExport, ExportFileResult>(`intranet/cargaFichero/descargarResultadosCargaLicencias`, model)
            .then((result) => {
                downloadFileFromBase64Array(result.content, result.fileName, "application/octet-stream");
                return result;
            });
        return prom;
    }

    cargarExcel(model: CargaEditModel): Promise<CargaPersistResult> {
        var apiFetcher = new ApiFetcher();
        var prom = apiFetcher.post<CargaEditModel, CargaPersistResult>(`intranet/cargaFichero/cargarExcel`, model);
        return prom;
    }

    getById(id: string | number): Promise<CargaGetDetailResult> {
        var apiFetcher = new ApiFetcher();
        var prom = apiFetcher.getByUrl<CargaGetDetailResult>(`intranet/cargaFichero/${id}`)
            .then((result) => {
                result.fecha = new Date(result.fecha);
                return result;
            });
        return prom;
    }

    /*
    getMyEditModel(): Promise<MiPerfilProfesorEditModel> {
        var apiFetcher = new ApiFetcher();
        var prom = apiFetcher.getByUrl<MiPerfilProfesorEditModel>(`intranet/miPerfilProfesor/getMyEditModel`);
        return prom;
    }

    validate(model: MiPerfilProfesorEditModel): Promise<ModelValidationResult> {
        var apiFetcher = new ApiFetcher();
        var prom = apiFetcher.post<MiPerfilProfesorEditModel, ModelValidationResult>(`intranet/miPerfilProfesor/validate`, model);
        return prom;
    }

    update(model: MiPerfilProfesorEditModel): Promise<MiPerfilProfesorEditModel> {
        var apiFetcher = new ApiFetcher();
        var prom = apiFetcher.put<MiPerfilProfesorEditModel, MiPerfilProfesorEditModel>(`intranet/miPerfilProfesor/update`, model);
        return prom;
    }*/
}