import * as React from "react";
import { Modal, ModalHeader, ModalBody, ModalFooter, Button, Row, Col, Input, Label } from "reactstrap";
import nameof from "ts-nameof.macro";
import { CampannaDropDown } from "../cursoEscolar/CampannaDropDown";
import { I18n } from "../utils/I18n";
import { toCamelCase } from "./ValuesFormatter";

export interface SeleccionCampannaProps {
    campannaId: number;
    campanna: string;
    mostrarIsbns: boolean;
    mainText: string;

    toggleDialogoImportacion: () => void;
    campannaSelected: (campannaId, campanna, contenidosSku) => void;
}

export interface SeleccionCampannaState {
    campannaId: number;
    campanna: string;

    contenidosSku: string;
}

export class SeleccionCampannaModal extends React.Component<SeleccionCampannaProps, SeleccionCampannaState> {


    public constructor(props: SeleccionCampannaProps) {
        super(props);

        this.state = {
            campannaId: props.campannaId,
            campanna: props.campanna,
            contenidosSku: null
        };
    }

    public render(): JSX.Element {
        return <Modal isOpen={true}  toggle={() => this.props.toggleDialogoImportacion()}>
            <ModalHeader toggle={() => this.props.toggleDialogoImportacion()}>
                {I18n.Strings.sync}
            </ModalHeader>
            <ModalBody>
                <p>
                    {this.props.mainText.length == 0
                        ? "Va a realizar la sincronización de los maestros de contenidos con Magento, seleccione a que ejercicio corresponde la sincronización."
                        : this.props.mainText}                    
                </p>
                <Row>
                    <Col>
                        <CampannaDropDown
                            label={toCamelCase(I18n.Strings.campannas.entityName)}
                            emptyText={toCamelCase(I18n.Strings.campannas.entityName)}
                            id={nameof(this.state.campannaId)}

                            selectedItemValue={this.state.campannaId}
                            selectedItemText={this.state.campanna}

                            onChanged={(id, nombre) => {
                                this.setState({ campanna: nombre, campannaId: id });
                            }}
                        />
                    </Col>
                </Row>
                {this.props.mostrarIsbns &&
                    <Row>
                        <Col>
                            <Label for={"contenidos" + nameof(this.state.contenidosSku)}>

                            </Label>
                            <Input
                                placeholder={I18n.Strings.contenidos.isbn + "s..."}
                                onChange={(evt) => {

                                    this.setState({
                                        contenidosSku: evt.currentTarget.value
                                    });
                                }}
                                type="textarea"
                                maxLength={250}
                                className={"form-control"}
                                name={nameof(this.state.contenidosSku)}
                                id={"contenidos" + nameof(this.state.contenidosSku)}
                                value={this.state.contenidosSku || ""}
                            />
                        </Col>
                    </Row>
                }
            </ModalBody>
            <ModalFooter>

                <Button color="primary" className="mr-md"
                    onClick={() => {
                        
                        this.props.campannaSelected(
                            this.state.campannaId,
                            this.state.campanna,
                            this.state.contenidosSku
                        );
                    }}
                >
                    {I18n.Strings.ok}
                </Button>


                <Button color="secondary"
                    onClick={() => this.props.toggleDialogoImportacion()}
                >
                    {I18n.Strings.cancel}
                </Button>
            </ModalFooter>
        </Modal>;
    }
}
