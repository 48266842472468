import * as React from "react";
import nameof from "ts-nameof.macro";

import { Row, Col, FormGroup, Label, Input, Button } from "reactstrap";

import { NotificationService } from "./NotificationService";
import { I18n } from "../utils/I18n";
import { NotificacionListItem, NotificacionListItemPaginatedList, NotificacionesSearch, EstadoNotificacion, NotificacionMarcarLeidaModel, SortDirection, TipoUsuario } from "../models/dataModels";
import { IServiceForIndex } from "../utils/base/BaseServices";
import { IndexPageBase, TableColumn } from "../utils/base/IndexPageBase";
import { RouteHelper } from "../IntranetRouter";
import { CancelIcon, CheckCircleIcon, CheckIcon, SpinnerIcon } from "../utils/Icons";
import { withRouter } from "react-router-dom";
import { EstadoNotificacionDropDown } from "../helpers/EnumDropDown";
import { AlertsService } from "../utils/AlertsService";
import { ClientContext } from "../utils/ClientContext";
import { ColegiosDropDown } from "../colegios/ColegiosDropDown";

interface NotificacionesSearchEx extends NotificacionesSearch {
    cursoEscolar?: string;
    nivel?: string;
    contenido?: string;
    colegio?: string;
}

export class IndexComp extends IndexPageBase<NotificacionesSearchEx, NotificacionListItemPaginatedList, NotificacionListItem>
{

    protected _showCheckboxes() {
        return true;
    }

    protected _getInitialSearchParams(): NotificacionesSearchEx {

        var search = {
            incluirOk: true,
            orderByColumn: 'fecha',
            orderMode: SortDirection.Desc
        } as NotificacionesSearchEx;

        return search;
    }

    protected _toolbarRightContents() {
        var contents = super._toolbarRightContents();

        contents.push(
            <Button
                key="markAsRead"
                className="btn-rounded"
                color="secondary"
                onClick={() => {
                    this.marcarComoLeidos(
                        this.state.checkedElements
                    );
                }}
            >
                {I18n.Strings.notificaciones.marcarSeleccionadosComoLeidos}
            </Button>
        );

        return contents;
    }

    protected _renderSearchForm(): React.ReactNode {
        return <React.Fragment>
            {ClientContext.Current.tipoUsuario == TipoUsuario.AdminGlobal && <Col xs="12" md="6" lg="4">
                <FormGroup>
                    <ColegiosDropDown
                        label={null}
                        disabled={ClientContext.Current.tipoUsuario != TipoUsuario.AdminGlobal}
                        onChanged={(colegioId, colegioNombre) => {

                            var state = this._cloneStateForSetState();
                            state.searchParams.colegioId = colegioId;
                            state.searchParams.colegio = colegioNombre;
                            this.setState(state, () => this._performSearch());                           
                        }}
                        id={nameof(this.state.searchParams.colegioId)}
                        selectedItemValue={this.state.searchParams.colegioId || 0}
                        selectedItemText={this.state.searchParams.colegio || ""}
                        emptyText={I18n.Strings.notificaciones.colegio + "..."}
                    />
                </FormGroup>
            </Col>}
            <Col xs="12" md="6" lg="4">
                <FormGroup>
                    <EstadoNotificacionDropDown
                        emptyText={I18n.Strings.notificaciones.estado + "..."}
                        label={null}
                        id={nameof(this.state.searchParams.estado)}
                        value={this.state.searchParams.estado}
                        required={true}
                        errorMessage={null}
                        onChanged={(value: string) => {
                            var newState = this._cloneStateForSetState();
                            if (value != '') {
                                newState.searchParams.estado = value as EstadoNotificacion;
                            }
                            else {
                                newState.searchParams.estado = null;
                            }
                            this.setState(newState, () => this._performSearch());
                        }}
                    />


                </FormGroup>
            </Col>
        </React.Fragment>;
    }

    protected _getApiService(): IServiceForIndex<NotificacionesSearch, NotificacionListItemPaginatedList> {
        return new NotificationService();
    }

    private GetEstado(item: NotificacionListItem): JSX.Element {
        if (item.estado == EstadoNotificacion.EnProceso || item.estado == EstadoNotificacion.EnProcesoConErrores) {
            return <SpinnerIcon />
        }

        if (item.estado == EstadoNotificacion.AcabadaOk) {
            return <CheckCircleIcon cssClass={"checkOK iconoGrande"} />
        }

        if (item.estado == EstadoNotificacion.AcabadaError) {
            return <CancelIcon cssClass={"checkKO iconoGrande"} />
        }
    }

    private GetCheckButton(item: NotificacionListItem): JSX.Element {
        if (item.pendiente) {
            return <a
                title={I18n.Strings.notificaciones.marcarComoLeida}
                href="javaScript:void(0)"
                onClick={() => { this.marcarComoLeidos([item.id]) }}>
                <CheckIcon />
            </a>
        }
        else {
            return null;
        }
    }

    protected _getTableColumns(): TableColumn<NotificacionListItem>[] {
        return [
            {
                fieldName: nameof(this.state.searchResults.items[0].fecha),
                title: I18n.Strings.notificaciones.fecha,
                renderField: (item) => I18n.Strings.formatDateTime(item.fecha)
            },
            {
                fieldName: nameof(this.state.searchResults.items[0].colegio),
                title: I18n.Strings.notificaciones.colegio,
                renderField: (item) => item.colegio
            },
            {
                fieldName: nameof(this.state.searchResults.items[0].texto),
                title: I18n.Strings.notificaciones.texto,
                renderField: (item) => item.pendiente ? <span className="negrita">{item.texto}</span> : <span>{item.texto}</span>
            },
            {
                fieldName: nameof(this.state.searchResults.items[0].tipoNotificacion),
                title: I18n.Strings.notificaciones.estado,
                renderField: (item) => this.GetEstado(item)
            },

            {
                fieldName: nameof(this.state.searchResults.items[0].id),
                title: "",
                renderField: (item) => this.GetCheckButton(item)
            }
        ];
    }

    private marcarComoLeidos(ids: number[]) {
        var service = new NotificationService();

        var data = {
            ids: ids
        } as NotificacionMarcarLeidaModel;
        this.setState({ showLoadingIcon: true }, () => {

            service.marcarComoLeidos(data)
                .then(notificaciones => {
                    this._performSearch();
                })
                .catch(err => this.setState({ showLoadingIcon: false }, () => AlertsService.showError(err)));

        })
    }

    protected _getPageTitle(): string {
        return I18n.Strings.notificaciones.titulo;
    }
    protected _getPageDescription(): string {
        return I18n.Strings.notificaciones.descripcion;
    }

    protected _getEditionItemUrl(item: NotificacionListItem): string {
        return RouteHelper.viewNotificacion(item.id);
    }

    protected _getNewItemUrl(): string {
        return "";
    }
}

export const Index = withRouter(IndexComp);