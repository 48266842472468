
import {
    ColegioUsuarioEditModel, ColegioUsuarioListItemPaginatedList, ColegioUsuariosSearch, ModelValidationResult, ColegioUsuarioBulkDeleteModel, ColegioUsuarioActivateModel, ColegioUsuariosChangeClassModel,
    ColegioUsuarioActivateResult, ColegioUsuariosChangeClassResult, EstadoUsuario, BasicData, ExportFileResult, ColegioUsuariosUndeleteResult, ColegioUsuariosUndeleteModel,
    ColegioUsuariosJoinMessage, ColegioUsuariosJoinResult
} from "../models/dataModels";
import { ApiFetcher } from "../utils/ApiFetcher";
import { IServiceForEdit, IServiceForIndex } from "../utils/base/BaseServices";
import { downloadFileFromBase64Array } from "../utils/ValuesFormatter";


export class ColegioUsuariosService
    implements IServiceForIndex<ColegioUsuariosSearch, ColegioUsuarioListItemPaginatedList>, IServiceForEdit<ColegioUsuarioEditModel>
{
    
    search(searchData: ColegioUsuariosSearch): Promise<ColegioUsuarioListItemPaginatedList> {

        var apiFetcher = new ApiFetcher();
        var prom = apiFetcher.post<ColegioUsuariosSearch, ColegioUsuarioListItemPaginatedList>("intranet/colegio/usuarios/search", searchData);
        prom.then(res => {
            res.items.forEach(item => {
                item.created = item.created == null ? null : new Date(item.created);
            });       
        });
        return prom;
    }

    delete(id: number) : Promise<any> {
        var apiFetcher = new ApiFetcher();
        var prom = apiFetcher.delete<any>(`intranet/colegio/usuarios/${id}`);
        return prom;
    }

    deleteFisico(id: number): Promise<any> {
        var apiFetcher = new ApiFetcher();
        var prom = apiFetcher.delete<any>(`intranet/colegio/usuarios/${id}/fisico`);
        return prom;
    }

    getById(id: string | number): Promise<ColegioUsuarioEditModel> {
        var apiFetcher = new ApiFetcher();
        var prom = apiFetcher.getByUrl<ColegioUsuarioEditModel>(`intranet/colegio/usuarios/${id}`);
        prom.then(item => {
            item.created = item.created == null ? null : new Date(item.created);
            item.modified = item.modified == null ? null : new Date(item.modified);
            item.fechaUltimoAcceso = item.fechaUltimoAcceso == null ? null : new Date(item.fechaUltimoAcceso);
        });
        return prom;
    }

    getBasicById(id: string | number): Promise<BasicData> {
        var apiFetcher = new ApiFetcher();
        var prom = apiFetcher.getByUrl<BasicData>(`intranet/colegio/usuarios/${id}/basic`);
        return prom;
    }

    validate(model: ColegioUsuarioEditModel): Promise<ModelValidationResult> {
        var apiFetcher = new ApiFetcher();
        var prom = apiFetcher.post<ColegioUsuarioEditModel, ModelValidationResult>(`intranet/colegio/usuarios/validate`, model);
        return prom;
    }

    create(model: ColegioUsuarioEditModel): Promise<ColegioUsuarioEditModel> {
        var apiFetcher = new ApiFetcher();
        var prom = apiFetcher.post<ColegioUsuarioEditModel, ColegioUsuarioEditModel>(`intranet/colegio/usuarios/`, model);
        prom.then(item => {
            item.created = item.created == null ? null : new Date(item.created);
            item.modified = item.modified == null ? null : new Date(item.modified);
            item.fechaUltimoAcceso = item.fechaUltimoAcceso == null ? null : new Date(item.fechaUltimoAcceso);
        });
        return prom;
    }

    update(id: string | number, model: ColegioUsuarioEditModel): Promise<ColegioUsuarioEditModel> {
        var apiFetcher = new ApiFetcher();
        var prom = apiFetcher.put<ColegioUsuarioEditModel, ColegioUsuarioEditModel>(`intranet/colegio/usuarios/${id}`, model);
        prom.then(item => {
            item.created = item.created == null ? null : new Date(item.created);
            item.modified = item.modified == null ? null : new Date(item.modified);
            item.fechaUltimoAcceso = item.fechaUltimoAcceso == null ? null : new Date(item.fechaUltimoAcceso);
        });
        return prom;
    }

    activate(ids: number[]): Promise<ColegioUsuarioActivateResult> {
        var model = {} as ColegioUsuarioActivateModel;
        model.colegioUsuarioIds = ids;

        var apiFetcher = new ApiFetcher();
        var prom = apiFetcher.post<ColegioUsuarioActivateModel, ColegioUsuarioActivateResult>(`intranet/colegio/usuarios/activate/`, model);
        return prom;
    }

    changeClass(ids: number[], claseId: number): Promise<ColegioUsuariosChangeClassResult> {
        var model = {} as ColegioUsuariosChangeClassModel;
        model.colegioUsuarioIds = ids;
        model.claseId = claseId;

        var apiFetcher = new ApiFetcher();
        var prom = apiFetcher.post<ColegioUsuariosChangeClassModel, ColegioUsuariosChangeClassResult>(`intranet/colegio/usuarios/changeClass/`, model);
        return prom;
    }

    joinUsers(usuarioEliminarId: number, usuarioDestinoId: number): Promise<ColegioUsuariosJoinResult> {
        var model = {} as ColegioUsuariosJoinMessage;
        model.usuarioEliminarId = usuarioEliminarId;
        model.usuarioDestinoId = usuarioDestinoId;

        var apiFetcher = new ApiFetcher();
        var prom = apiFetcher.post<ColegioUsuariosJoinMessage, ColegioUsuariosJoinResult>(`intranet/colegio/usuarios/joinUsers/`, model);
        return prom;
    }

    undelete(ids: number[]): Promise<ColegioUsuariosUndeleteResult> {
        var model = {} as ColegioUsuarioActivateModel;
        model.colegioUsuarioIds = ids;

        var apiFetcher = new ApiFetcher();
        var prom = apiFetcher.post<ColegioUsuariosUndeleteModel, ColegioUsuariosUndeleteResult>(`intranet/colegio/usuarios/undelete/`, model);
        return prom;
    }

    bulkDelete(ids: number[]): Promise<any> {
        var model = {} as ColegioUsuarioBulkDeleteModel;
        model.colegioUsuarioIds = ids;
        model.estado = EstadoUsuario.PendienteValidacion;

        var apiFetcher = new ApiFetcher();
        var prom = apiFetcher.post<ColegioUsuarioBulkDeleteModel, any>(`intranet/colegio/usuarios/bulkdelete/`, model);
        return prom;
    }

    exportExcel(search: ColegioUsuariosSearch): Promise<any> {
        var url = "intranet/colegio/usuarios/exportExcel";

        var apiFetcher = new ApiFetcher();
        var promise = apiFetcher.post<ColegioUsuariosSearch, ExportFileResult>(url, search)
            .then((result) => {
                downloadFileFromBase64Array(result.content, result.fileName, "application/octet-stream");
                return result;
            });
        return promise;
    }

    new(): Promise<ColegioUsuarioEditModel> {
        var apiFetcher = new ApiFetcher();
        var prom = apiFetcher.getByUrl<ColegioUsuarioEditModel>(`intranet/colegio/usuarios/new`);
        return prom;
    }
}