import * as React from "react";
import { Input } from "reactstrap";
import { DropDownBase, GenericSelectItem, IDropDownProps } from "../utils/base/DropDownBase";
import { AliasEditorialService } from "./AliasEditorialesService";


export class AliasEditorialDown extends DropDownBase<IDropDownProps> {



    protected GetItems(): Promise<GenericSelectItem[]> {
        var service = new AliasEditorialService();
        var itemsProm = service.getAll()
            .then((items) => {
                return items.map(alias => {
                    return {
                        id: alias.id,
                        name: alias.alias,
                    } as GenericSelectItem;
                });

            });

        return itemsProm;
    }

    
    protected shouldAddEmptyOption() {
        return true;
    }
};