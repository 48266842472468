import * as React from "react";
import nameof from "ts-nameof.macro";

import { Row, Col, FormGroup, Label, Input, Button, Modal, ModalFooter, ModalBody, ModalHeader, FormFeedback, ButtonDropdown } from "reactstrap";

import { ColegioUsuariosService } from "./ColegioUsuariosService";
import { I18n } from "../utils/I18n";
import { ColegioUsuarioListItem, ColegioUsuarioListItemPaginatedList, ColegioUsuariosSearch, EstadoUsuario, TipoUsuario } from "../models/dataModels";
import { IServiceForIndex } from "../utils/base/BaseServices";
import { IndexPageBase, TableColumn } from "../utils/base/IndexPageBase";
import { RouteHelper } from "../IntranetRouter";
import { TipoUsuarioDropDown, EstadoUsuarioDropDown } from "../helpers/EnumDropDown";
import { NivelEducativoDropDown } from "../cursoEscolar/NivelEducativoDropDown";
import { ClientContext } from "../utils/ClientContext";
import { CheckIcon, DeleteIcon, JoinUsersIcon } from "../utils/Icons";
import { DeleteModal } from "../utils/DeleteModal";
import { Link, withRouter } from "react-router-dom";
import UserNameToolTip from "./UserNameToolTip";
import { ColegioUsuariosPicker } from "./Picker";
import { ModelValidation } from "../utils/base/ModelValidation";
import { AlertsService } from "../utils/AlertsService";
import { stack } from "d3-shape";
import { scaleBand } from "d3-scale";


export interface ColegioUsuariosSearchEx extends ColegioUsuariosSearch {
    clase: string;
    disableState: boolean;
    showModalClase?: boolean;
    showModalUnirUsuarios?: boolean;
    claseNuevaId: number;
    usuarioEliminarId: number;
    usuarioEliminar: string;
    usuarioDestinoId: number;
    usuarioDestino: string;
    validationResult: ModelValidation;
}

class IndexComp extends IndexPageBase<ColegioUsuariosSearchEx, ColegioUsuarioListItemPaginatedList, ColegioUsuarioListItem>
{
    protected _getInitialSearchParams() {

        var searchModel = super._getInitialSearchParams();
        searchModel.showModalClase = false;
        searchModel.showModalUnirUsuarios = false;
        searchModel.soloAlumnosMatriculados = true;
        searchModel.orderByColumn = nameof(this.state.searchResults.items[0].nombre);
        searchModel.cursoEscolarId = ClientContext.Current.cursoEscolarActivoId;
        searchModel.validationResult = new ModelValidation();
        return searchModel;
    }

    protected _renderSearchForm(): React.ReactNode {
        return <React.Fragment>
            <Col xs="12" md="6" lg="3">
                <FormGroup>
                    <Label className="visually-hidden" for={"usuarios" + nameof(this.state.searchParams.nombre)}>
                        {I18n.Strings.colegioUsuarios.nombre}
                    </Label>
                    <Input
                        onChange={(evt) => {
                            var state = this._cloneStateForSetState();
                            state.searchParams.nombre = evt.target.value;
                            this.setState(state);
                        }}
                        placeholder={I18n.Strings.colegioUsuarios.nombre + "..."}
                        type="text"
                        maxLength={100}
                        className={"form-control"}
                        name={nameof(this.state.searchParams.nombre)}
                        id={"usuarios" + nameof(this.state.searchParams.nombre)}
                        value={this.state.searchParams.nombre || ""}
                    />

                </FormGroup>
            </Col>
            <Col xs="12" md="6" lg="3">
                <FormGroup>
                    <Label className="visually-hidden" for={"usuarios" + nameof(this.state.searchParams.apellidos)}>
                        {I18n.Strings.colegioUsuarios.apellidos}
                    </Label>
                    <Input
                        onChange={(evt) => {
                            var state = this._cloneStateForSetState();
                            state.searchParams.apellidos = evt.target.value;
                            this.setState(state);
                        }}
                        placeholder={I18n.Strings.colegioUsuarios.apellidos + "..."}
                        type="text"
                        maxLength={100}
                        className={"form-control"}
                        name={nameof(this.state.searchParams.apellidos)}
                        id={"usuarios" + nameof(this.state.searchParams.apellidos)}
                        value={this.state.searchParams.apellidos || ""}
                    />

                </FormGroup>
            </Col>

            <Col xs="12" md="6" lg="3">
                <FormGroup>
                    <Label className="visually-hidden" for={"usuarios" + nameof(this.state.searchParams.login)}>
                        {I18n.Strings.colegioUsuarios.login}
                    </Label>
                    <Input
                        onChange={(evt) => {
                            var state = this._cloneStateForSetState();
                            state.searchParams.login = evt.target.value;
                            this.setState(state);
                        }}
                        placeholder={I18n.Strings.colegioUsuarios.login + "..."}
                        type="text"
                        maxLength={100}
                        className={"form-control"}
                        name={nameof(this.state.searchParams.login)}
                        id={"usuarios" + nameof(this.state.searchParams.login)}
                        value={this.state.searchParams.login || ""}
                    />

                </FormGroup>
            </Col>
            <Col xs="12" md="6" lg="3">
                <TipoUsuarioDropDown
                    emptyText={I18n.Strings.colegioUsuarios.tipoUsuario}
                    label={null}
                    id={nameof(this.state.searchParams.tipoUsuario)}
                    value={this.state.searchParams.tipoUsuario}
                    required={false}
                    errorMessage={null}
                    onChanged={(value: string) => {
                        var newState = this._cloneStateForSetState();
                        if (value != '') {
                            newState.searchParams.tipoUsuario = value as TipoUsuario;
                        }
                        else {
                            newState.searchParams.tipoUsuario = null;
                        }
                        this.setState(newState, () => this._performSearch());
                    }}
                />
            </Col>
            {/*
            <Col xs="12" md="6" lg="3">
                <EstadoUsuarioDropDown
                    emptyText={I18n.Strings.colegioUsuarios.estado}
                    label={null}
                    id={nameof(this.state.searchParams.estado)}
                    value={this.state.searchParams.estado}
                    required={false}
                    disabled={this.state.searchParams.disableState}
                    errorMessage={null}
                    onChanged={(value: string) => {
                        var newState = this._cloneStateForSetState();
                        if (value != '') {
                            newState.searchParams.estado = value as EstadoUsuario;
                        }
                        else {
                            newState.searchParams.estado = null;
                        }
                        this.setState(newState, () => this._performSearch());
                    }}
                />
            </Col>
            */}
            <Col xs="12" md="6" lg="3">
                <NivelEducativoDropDown
                    id={nameof(this.state.searchParams.claseId)}
                    emptyText={I18n.Strings.matriculas.nombreClase + "..."}
                    cursoEscolarId={ClientContext.Current.cursoEscolarActivoId}
                    value={this.state.searchParams.claseId}

                    text={this.state.searchParams.clase}
                    seleccionClases={true}
                    onChange={(id, nombre) => {

                        var state = this._cloneStateForSetState();
                        state.searchParams.claseId = id;
                        state.searchParams.clase = nombre;
                        state.searchParams.claseNuevaId = id;

                        this.setState(state, () => this._performSearch());
                    }}
                />
            </Col>
            <Col xs="6" md="6" lg="2">
                <FormGroup>
                    <Label for={"usuarios" + nameof(this.state.searchParams.repetidor)}>{I18n.Strings.colegioUsuarios.repetidor}</Label>
                    <Input
                        onChange={(evt) => {
                            var newState = this._cloneStateForSetState();
                            newState.searchParams.repetidor = evt.target.checked;
                            this.setState(newState, () => this._performSearch());
                        }}
                        type="checkbox"
                        style={{ padding: 0, marginLeft: 10 }}
                        id={"usuarios" + nameof(this.state.searchParams.repetidor)}
                        checked={this.state.searchParams.repetidor}
                    />
                </FormGroup>
            </Col>

            <Col xs="6" md="6" lg="3">
                <FormGroup>
                    <Label for={"usuarios" + nameof(this.state.searchParams.soloAlumnosMatriculados)}>{I18n.Strings.colegioUsuarios.soloAlumnosMatriculados}</Label>
                    <Input
                        onChange={(evt) => {
                            var checked = evt.target.checked;
                            var newState = this._cloneStateForSetState();
                            newState.searchParams.soloAlumnosMatriculados = checked;
                            this.setState(newState, () => this._performSearch());
                        }}
                        type="checkbox"
                        style={{ padding: 0, marginLeft: 10 }}
                        id={nameof(this.state.searchParams.soloAlumnosMatriculados)}
                        checked={this.state.searchParams.soloAlumnosMatriculados}
                    />

                </FormGroup>
            </Col>

            <Col xs="6" md="6" lg="2">
                <FormGroup>
                    <Label for={"usuarios" + nameof(this.state.searchParams.borrado)}>{I18n.Strings.colegioUsuarios.borrado}</Label>
                    <Input
                        onChange={(evt) => {
                            var checked = evt.target.checked;
                            var newState = this._cloneStateForSetState();
                            newState.searchParams.borrado = checked;
                            this.setState(newState, () => this._performSearch());
                        }}
                        type="checkbox"
                        style={{ padding: 0, marginLeft: 10 }}
                        id={"usuarios" + nameof(this.state.searchParams.borrado)}
                        checked={this.state.searchParams.borrado}
                    />

                </FormGroup>
            </Col>
        </React.Fragment>;
    }

    protected _getApiService(): IServiceForIndex<ColegioUsuariosSearch, ColegioUsuarioListItemPaginatedList> {
        return new ColegioUsuariosService();
    }

    protected _getTableColumns(): TableColumn<ColegioUsuarioListItem>[] {



        var columns = [
            {
                fieldName: nameof(this.state.searchResults.items[0].nombreCompleto),
                title: I18n.Strings.colegioUsuarios.nombre,
                renderField: (item) => <UserNameToolTip userName={item.nombreCompleto} userId={item.id} key={item.id} />,
            },
            {
                fieldName: nameof(this.state.searchResults.items[0].tipoUsuario),
                title: I18n.Strings.colegioUsuarios.tipoUsuario,
                renderField: (item) => I18n.Strings.getEnumText("TipoUsuario." + item.tipoUsuario)
            },
            {
                fieldName: nameof(this.state.searchResults.items[0].cursoEscolar),
                title: I18n.Strings.colegioUsuarios.cursoEscolar,
                renderField: (item) => item.cursoEscolar
            },
            {
                fieldName: nameof(this.state.searchResults.items[0].clase),
                title: I18n.Strings.colegioUsuarios.grupoClase,
                renderField: (item) => item.clase
            },
            /*{
                fieldName: nameof(this.state.searchResults.items[0].estado),
                title: I18n.Strings.colegioUsuarios.estado,
                renderField: (item) => I18n.Strings.getEnumText("EstadoUsuario." + item.estado)
            },*/
            {
                fieldName: nameof(this.state.searchResults.items[0].fechaUltimoAcceso),
                title: I18n.Strings.colegioUsuarios.fechaUltimoAcceso,
                renderField: (item) => I18n.Strings.formatLastAccessDate(item.fechaUltimoAcceso)
            },
            {
                fieldName: nameof(this.state.searchResults.items[0].created),
                title: I18n.Strings.colegioUsuarios.fechaCreacion,
                renderField: (item) => I18n.Strings.formatDate(item.created) || ""
            }
        ];

        if (this.props.searchModel?.claseId != null) {
            columns = columns.filter(c =>
                c.fieldName != nameof(this.state.searchResults.items[0].clase)
                && c.fieldName != nameof(this.state.searchResults.items[0].cursoEscolar)
            );
        }

        if (this.props.searchModel?.tipoUsuario != null) {
            columns = columns.filter(c => c.fieldName != nameof(this.state.searchResults.items[0].tipoUsuario));
        }

        return columns;
    }

    protected _getPageTitle(): string {
        return I18n.Strings.colegioUsuarios.title;
    }
    protected _getPageDescription(): string {
        return I18n.Strings.colegioUsuarios.description;
    }

    

    private getIdUsuariosAlumnosChecked() {
        var checkedElements = this.state.checkedElements;
        if (this.state.searchResults != null && this.state.searchResults.items != null) {
            var alumnosChecked = this.state.searchResults.items.filter(function (el) {
                return el.tipoUsuario == TipoUsuario.Alumno && checkedElements.find(x => x === el.id) != null
            });
        }

        return alumnosChecked;
    }

    protected _renderExtraContent(): JSX.Element {
        

        if (this.state.searchParams.showModalClase) {
            return this._renderModalCambiarClase();
        }

        if (this.state.searchParams.showModalUnirUsuarios) {
            return this._renderModalUnirUsuarios();
        }
    }

    protected _renderModalUnirUsuarios(): JSX.Element {

        return <Modal
            title={I18n.Strings.unirUsuarios.titulo}
            isOpen={this.state.searchParams.showModalUnirUsuarios}
            size="lg"
            toggle={() => {
                var state = this._cloneStateForSetState();

                state.searchParams.showModalUnirUsuarios = !state.searchParams.showModalUnirUsuarios;
                state.showLoadingIcon = false;
                this.setState(state);
            }}
        >
            {
                <ModalHeader close={<button className="btn-close" onClick={() => {
                    var state = this._cloneStateForSetState();

                    state.searchParams.showModalUnirUsuarios = false;
                    state.showLoadingIcon = false;
                    this.setState(state);
                }} />}>
                    {I18n.Strings.unirUsuarios.titulo}
                </ModalHeader>
            }
            <ModalBody>
                <Row>
                    <Col md={6}>
                        <ColegioUsuariosPicker
                            label={I18n.Strings.unirUsuarios.usuarioEliminar}
                            required={true}
                            itemId={this.state.searchParams.usuarioEliminarId}
                            itemName={this.state.searchParams.usuarioEliminar}
                            getIdFromItem={(item) => item.id}
                            getNameFromItem={(item) => item.nombre + ' ' + item.apellidos}
                            errorMessage={this.state.searchParams.validationResult.getError(nameof(this.state.searchParams.usuarioEliminarId))}
                            onSelected={(usuarioId, usuario, item) => {
                                var state = this._cloneStateForSetState();
                                state.searchParams.usuarioEliminarId = usuarioId;
                                state.searchParams.usuarioEliminar = usuario;
                                this.setState(state);
                            }}
                        />
                    </Col>
                    <Col md={6}>
                        <ColegioUsuariosPicker
                            label={I18n.Strings.unirUsuarios.usuarioDestino}
                            required={true}
                            itemId={this.state.searchParams.usuarioDestinoId}
                            itemName={this.state.searchParams.usuarioDestino}
                            getIdFromItem={(item) => item.id}
                            getNameFromItem={(item) => item.nombre + ' ' + item.apellidos}
                            errorMessage={this.state.searchParams.validationResult.getError(nameof(this.state.searchParams.usuarioDestinoId))}
                            onSelected={(usuarioId, usuario, item) => {
                                var state = this._cloneStateForSetState();
                                state.searchParams.usuarioDestinoId = usuarioId;
                                state.searchParams.usuarioDestino = usuario;
                                this.setState(state);
                            }}
                        />
                    </Col>
                </Row>
            </ModalBody>
            <ModalFooter>
                <Button color="secondary" size="lg" onClick={() => {
                    let validation = new ModelValidation();
                    var error = false;

                    if (this.state.searchParams.usuarioEliminarId == null) {
                        error = true;
                        validation.addError(nameof(this.state.searchParams.usuarioEliminarId), I18n.Strings.validationErrors.valueRequired(I18n.Strings.unirUsuarios.usuarioEliminar));
                    }
                    else if (this.state.searchParams.usuarioEliminarId == this.state.searchParams.usuarioDestinoId) {
                        error = true;
                        validation.addError(nameof(this.state.searchParams.usuarioDestinoId), I18n.Strings.unirUsuarios.usuariosRepetidos);
                    }

                    if (this.state.searchParams.usuarioDestinoId == null) {
                        error = true;
                        validation.addError(nameof(this.state.searchParams.usuarioDestinoId), I18n.Strings.validationErrors.valueRequired(I18n.Strings.unirUsuarios.usuarioDestino));
                    }

                    if (error) {
                        var state = this._cloneStateForSetState();
                        state.searchParams.validationResult = validation;
                        this.setState(state);
                    }
                    else {
                        this.setState({ showLoadingIcon: true }, () => {
                            var service = new ColegioUsuariosService();
                            service.joinUsers(this.state.searchParams.usuarioEliminarId, this.state.searchParams.usuarioDestinoId)
                                .then((ret) => {
                                    if (!ret.isOk) {
                                        var state = this._cloneStateForSetState();
                                        state.showLoadingIcon = false;
                                        state.searchParams.showModalUnirUsuarios = false;
                                        this.setState(state, () => AlertsService.showErrorMessage(ret.errorMsg));
                                    }
                                    else {
                                        var state = this._cloneStateForSetState();
                                        state.searchParams.showModalUnirUsuarios = false;
                                        this.setState(state, () => this._performSearch());
                                    }
                                })
                                .catch((error) => AlertsService.showError(error));
                        })
                    }
                }}>
                    {I18n.Strings.ok}
                </Button>
                <Button color="secondary" size="lg" onClick={() => {
                    var state = this._cloneStateForSetState();

                    state.searchParams.showModalUnirUsuarios = false;
                    this.setState(state);
                }}>
                    {I18n.Strings.cancel}
                </Button>
            </ModalFooter>
        </Modal>
    }

    protected _renderModalCambiarClase(): JSX.Element {
        var alumnosChecked = this.getIdUsuariosAlumnosChecked();

        return <Modal
            title={I18n.Strings.colegioUsuarios.elegirClaseNueva}
            isOpen={this.state.searchParams.showModalClase}
            size="lg"
            toggle={() => {
                var state = this._cloneStateForSetState();

                state.searchParams.showModalClase = !state.searchParams.showModalClase;
                this.setState(state);
            }}
        >
            {
                <ModalHeader close={<button className="btn-close" onClick={() => {
                    var state = this._cloneStateForSetState();

                    state.searchParams.showModalClase = false;
                    this.setState(state);
                }} />}>
                    {I18n.Strings.colegioUsuarios.elegirClaseNueva}
                </ModalHeader>
            }
            <ModalBody>
                <Row>
                    <Col md={6}>
                        <FormGroup>
                            <Label for={nameof(this.state.searchParams.claseNuevaId)}>
                                {I18n.Strings.colegioUsuarios.claseNueva}
                            </Label>

                            <NivelEducativoDropDown
                                id={nameof(this.state.searchParams.claseNuevaId)}

                                cursoEscolarId={ClientContext.Current.cursoEscolarActivoId}
                                value={this.state.searchParams.claseNuevaId}

                                text={this.state.searchParams.clase}
                                seleccionClases={true}
                                onChange={(id, nombre) => {
                                    var state = this._cloneStateForSetState();
                                    state.searchParams.claseNuevaId = id;
                                    state.searchParams.clase = nombre;
                                    this.setState(state);
                                }}
                            />
                            {this._errorMessage(nameof(this.state.searchParams.claseNuevaId))}
                        </FormGroup>
                    </Col>
                </Row>
            </ModalBody>
            <ModalFooter>
                <Button color="primary" size="lg" onClick={() => {
                    let validation = new ModelValidation();

                    if (this.state.searchParams.claseNuevaId == null) {
                        validation.addError(nameof(this.state.searchParams.claseNuevaId), I18n.Strings.validationErrors.valueRequired(I18n.Strings.colegioUsuarios.claseNueva));

                        var state = this._cloneStateForSetState();
                        state.searchParams.validationResult = validation;
                        this.setState(state);
                    }
                    else {
                        this.setState({ showLoadingIcon: true }, () => {
                            var service = new ColegioUsuariosService();
                            service.changeClass(alumnosChecked.map((item) => item.id), this.state.searchParams.claseNuevaId)
                                .then((ret) => {
                                    this._performSearch();
                                    var state = this._cloneStateForSetState();

                                    state.searchParams.showModalClase = false;
                                    this.setState(state);
                                });
                        })
                    }
                }}>
                    {I18n.Strings.ok}
                </Button>
                <Button color="secondary" size="lg" onClick={() => {
                    var state = this._cloneStateForSetState();

                    state.searchParams.showModalClase = false;
                    this.setState(state);
                }}>
                    {I18n.Strings.cancel}
                </Button>
            </ModalFooter>
        </Modal>
    }

    protected _errorMessage(fieldName: string): JSX.Element {
        var error = this.state.searchParams.validationResult.getError(fieldName);
        if (error) {
            return <FormFeedback>{error}</FormFeedback>;
        }
        return null;
    }

    protected _toolbarRightContents(): JSX.Element[] {

        var contents = super._toolbarRightContents();

        if (ClientContext.Current.tipoUsuario == TipoUsuario.AdminGlobal || ClientContext.Current.tipoUsuario == TipoUsuario.AdminColegio) {
            contents.push(<Button
                id="unir"
                color="secondary"
                className="btn btn-rounded btn-sm"
                onClick={() => {
                    var state = this._cloneStateForSetState();

                    state.searchParams.showModalUnirUsuarios = !state.searchParams.showModalUnirUsuarios;
                    state.searchParams.validationResult = new ModelValidation();
                    state.searchParams.usuarioDestinoId = null;
                    state.searchParams.usuarioDestino = '';
                    state.searchParams.usuarioEliminarId = null;
                    state.searchParams.usuarioEliminar = '';
                    this.setState(state);
                }}>
                <JoinUsersIcon />
                {I18n.Strings.unirUsuarios.titulo}
            </Button>);
        }

        var alumnosChecked = this.getIdUsuariosAlumnosChecked();

        if (alumnosChecked != null && alumnosChecked.length > 0) {
            contents.push(<Button
                id="cambiar"
                key="cambiar"
                color="primary"
                className="btn-rounded text-end"
                onClick={() => {
                    var state = this._cloneStateForSetState();

                    state.searchParams.showModalClase = !state.searchParams.showModalClase;
                    state.searchParams.validationResult = new ModelValidation();
                    state.searchParams.claseNuevaId = null;

                    this.setState(state);
                }}>
                <CheckIcon cssClass="me-2" />
                {I18n.Strings.formatBotonCambiarClase(alumnosChecked.length)}
            </Button>);
        }

        return contents;

    }

    

    protected _showCheckboxes(): boolean {
        return true;
    }

    protected _getEditionItemUrl(item: ColegioUsuarioListItem): string {
        return RouteHelper.editUsuarioColegio(item.id);
    }

    protected _getNewItemUrl(): string {
        return RouteHelper.createUsuariosColegio();
    }

    protected _enableExcelExport() {
        return true;
    }

}
export const Index = withRouter(IndexComp);