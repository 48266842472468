import * as React from "react";
import { withRouter } from "react-router-dom";
import { Col, FormGroup, Input, Label, Nav, NavItem, NavLink, Row, TabContent, TabPane } from "reactstrap";
import nameof from "ts-nameof.macro";
import { AliasEditorialEditTabs } from "../../aliasEditoriales/Edit";
import { AccedePlataformaDropDown, EditorialDropDown, TipoAccesoDropDownAll, YesNoDropDown } from "../../helpers/EnumDropDown";
import { RouteHelper } from "../../IntranetRouter";
import { Index as LicenciasColegioIndex, LicenciasContenidosSearchEx } from "../../licenciasColegio/Index";
import { TipoAccesoDropDown } from "../../licenciasColegio/TipoAccesoDropDown";
import { AccedePlataforma, ContenidosColegioEditModel, Editorial, NivelContenidosSearch, TipoAcceso, YesNo } from "../../models/dataModels";
import { Index as NivelesContenidosIndex } from "../../nivelesContenidos/Index";
import { IServiceForEdit } from "../../utils/base/BaseServices";
import { NavigationHelper } from "../../utils/base/Breadcrumb";
import { EditPageBase } from "../../utils/base/EditPageBase";
import { GenericEditState } from "../../utils/base/GenericEditState";
import { ModelValidation } from "../../utils/base/ModelValidation";
import { I18n } from "../../utils/I18n";
import { EditIcon } from "../../utils/Icons";
import { ContenidosColegioService } from "./ContenidosColegioService";

export enum ContenidoColegioEditTabs {
    Datos = "datos",
    Licencias = "licencias-colegio",
    Niveles = "niveles",
}

export class EditContenidoColegioComp extends EditPageBase<ContenidosColegioEditModel> {



    protected _setPageTitleForNew(): string {
        return "Nuevo"
    }

    protected _setPageTitleForExisting(editModel: ContenidosColegioEditModel): string {
        return `Editar ${editModel.nombre}`;
    }

    protected _createApiService(): IServiceForEdit<ContenidosColegioEditModel> {
        return new ContenidosColegioService() as any as IServiceForEdit<ContenidosColegioEditModel>;
    }

    protected _generateForm(): JSX.Element {

        if ((this.state.editModel.id || 0) == 0) {
            return this._paintMainForm();
        }

        return <React.Fragment>
            <Nav tabs>
                <NavItem>
                    <NavLink
                        active={this.state.activeTab == ContenidoColegioEditTabs.Datos}
                        onClick={() => this.props.history.push(RouteHelper.editContenidosColegio(this.state.editModel.id, ContenidoColegioEditTabs.Datos))}
                    >
                        {I18n.Strings.contenidos.tabDatos}
                    </NavLink>
                </NavItem>
                <NavItem>
                    <NavLink
                        active={this.state.activeTab == ContenidoColegioEditTabs.Licencias}
                        onClick={() => this.props.history.push(RouteHelper.editContenidosColegio(this.state.editModel.id, ContenidoColegioEditTabs.Licencias))}
                    >
                        {I18n.Strings.licenciasContenido.entityPluralName}
                    </NavLink>
                </NavItem>
                <NavItem>
                    <NavLink
                        active={this.state.activeTab == ContenidoColegioEditTabs.Niveles}
                        onClick={() => this.setState(
                            { activeTab: ContenidoColegioEditTabs.Niveles },
                            () => this.props.history.push(RouteHelper.editContenidosColegio(this.state.editModel.id, ContenidoColegioEditTabs.Niveles))
                        )}                     >
                        {I18n.Strings.contenidos.tabNiveles}
                    </NavLink>
                </NavItem>
            </Nav>
            <TabContent activeTab={this.state.activeTab || ContenidoColegioEditTabs.Datos}>
                <TabPane tabId={ContenidoColegioEditTabs.Datos}>
                    {this._paintMainForm()}
                </TabPane>

                <TabPane tabId={ContenidoColegioEditTabs.Licencias}>
                    {this.state.activeTab == ContenidoColegioEditTabs.Licencias &&
                        <LicenciasColegioIndex isEmbedded={true} searchModel={{ ...{} as LicenciasContenidosSearchEx, contenidoId: this.state.editModel.id }} />
                    }
                </TabPane>

                <TabPane tabId={ContenidoColegioEditTabs.Niveles}>
                    {this.state.activeTab == ContenidoColegioEditTabs.Niveles &&
                        <NivelesContenidosIndex isEmbedded={true} searchModel={{ ...{} as NivelContenidosSearch, contenidoId: this.state.editModel.id }} />
                    }
                </TabPane>


            </TabContent>
        </React.Fragment>
    }

    private _paintMainForm(): JSX.Element {

        return <React.Fragment>
            <Row>
                <Col md={9}>
                    <Row>
                        <Col md={7}>
                            <FormGroup>
                                <Label for={"contenidos" + nameof(this.state.editModel.nombre)}>{I18n.Strings.contenidos.nombre}</Label>
                                <Input
                                    onChange={(evt) => {
                                        var state = this._cloneStateForSetState();
                                        state.editModel.nombre = evt.target.value;
                                        this.setState(state);
                                    }}
                                    type="text"
                                    disabled={true}
                                    required={true}
                                    maxLength={100}
                                    className={"form-control " + this._errorClass(nameof(this.state.editModel.nombre))}
                                    name={nameof(this.state.editModel.nombre)}
                                    id={"contenidos" + nameof(this.state.editModel.nombre)}
                                    value={this.state.editModel.nombre || ""}
                                />
                                {this._errorMessage(nameof(this.state.editModel.nombre))}
                            </FormGroup>
                        </Col>

                        <Col md={5}>
                            <FormGroup>
                                <Label for={"contenidos" + nameof(this.state.editModel.isbn)}>{I18n.Strings.contenidos.isbn}</Label>
                                <Input
                                    onChange={(evt) => {
                                        var state = this._cloneStateForSetState();
                                        state.editModel.isbn = evt.target.value;
                                        this.setState(state);
                                    }}
                                    type="text"
                                    required={true}
                                    disabled={true}
                                    maxLength={100}
                                    className={"form-control " + this._errorClass(nameof(this.state.editModel.isbn))}
                                    name={nameof(this.state.editModel.isbn)}
                                    id={"contenidos" + nameof(this.state.editModel.isbn)}
                                    value={this.state.editModel.isbn || ""}
                                />
                                {this._errorMessage(nameof(this.state.editModel.isbn))}
                            </FormGroup>
                        </Col>

                        <Col md={7}>
                            <FormGroup>
                                <Label for={"contenidos" + nameof(this.state.editModel.descripcion)}>{I18n.Strings.contenidos.descripcion}</Label>
                                <Input
                                    onChange={(evt) => {
                                        var state = this._cloneStateForSetState();
                                        state.editModel.descripcion = evt.target.value;
                                        this.setState(state);
                                    }}
                                    type="textarea"
                                    required={true}
                                    disabled={true}
                                    maxLength={500}
                                    className={"form-control " + this._errorClass(nameof(this.state.editModel.descripcion))}
                                    name={nameof(this.state.editModel.descripcion)}
                                    id={"contenidos" + nameof(this.state.editModel.descripcion)}
                                    value={this.state.editModel.descripcion || ""}
                                />
                                {this._errorMessage(nameof(this.state.editModel.descripcion))}
                            </FormGroup>
                        </Col>

                        <Col md={5}>
                            <FormGroup>
                                <a href={RouteHelper.editAliasEditorial(this.state.editModel.aliasEditorialId, AliasEditorialEditTabs.Datos)}
                                    className="pointer ps-1" target="_blank" >
                                    {`${I18n.Strings.contenidos.editorial} - (${this.state.editModel.aliasEditorial})`} <EditIcon />
                                </a>

                                <EditorialDropDown
                                    showLabel={false}
                                    label={`${I18n.Strings.contenidos.editorial} - (${this.state.editModel.aliasEditorial})`}
                                    id={nameof(this.state.editModel.editorial)}
                                    value={this.state.editModel.editorial}
                                    errorMessage={this.state.validationResult.getError(nameof(this.state.editModel.editorial))}
                                    required={true}
                                    disabled={true}
                                    onChanged={(value: Editorial) => {
                                        var newState = this._cloneStateForSetState();
                                        newState.editModel.editorial = value;
                                        this.setState(newState);
                                    }}
                                />
                            </FormGroup>
                        </Col>
                        <Col md={4}>

                            <Label for={"credenciales" + nameof(this.state.editModel.tipoAccesoPorDefecto)}>
                                {I18n.Strings.colegiocredenciales.tipoLicencia}
                            </Label>
                            <TipoAccesoDropDown
                                emptyText={I18n.Strings.colegiocredenciales.tipoLicencia + "..."}
                                editorial={this.state.editModel.editorial}
                                id={nameof(this.state.editModel.tipoAccesoPorDefecto)}
                                value={this.state.editModel.tipoAccesoPorDefecto}
                                onChange={(value: string) => {
                                    var newState = this._cloneStateForSetState();
                                    if (value != '') {
                                        newState.editModel.tipoAccesoPorDefecto = value as TipoAcceso;
                                    }
                                    else {
                                        newState.editModel.tipoAccesoPorDefecto = null;
                                    }
                                    this.setState(newState);
                                }}

                            />
                            {this._errorMessage(nameof(this.state.editModel.tipoAccesoPorDefecto))}

                        </Col>
                        

                        <Col md={3}>
                            <YesNoDropDown
                                label={I18n.Strings.nivelesContenidos.mostrarAccesoDirecto}
                                showLabel={true}
                                id={"cursos" + nameof(this.state.editModel.mostrarAccesoDirecto)}
                                value={this.state.editModel.mostrarAccesoDirecto == null ? null : this.state.editModel.mostrarAccesoDirecto ? YesNo.Yes.toString() : YesNo.No.toString()}
                                className={"form-control"}
                                required={true}
                                onChanged={(value: YesNo) => {
                                    var newState = this._cloneStateForSetState();
                                    newState.editModel.mostrarAccesoDirecto = value == YesNo.Yes.toString() ? true : value == YesNo.No.toString() ? false : null;
                                    this.setState(newState);
                                }}
                            />
                        </Col>

                        <Col md="6">
                            <FormGroup>
                                <Label for={nameof(this.state.editModel.mensajeAcceso)}>
                                    {I18n.Strings.colegiocredenciales.mensajeDeshabilitarAcceso}
                                </Label>
                                <Input
                                    onChange={(evt) => {
                                        var state = this._cloneStateForSetState();
                                        state.editModel.mensajeAcceso = evt.target.value;
                                        this.setState(state);
                                    }}
                                    type="textarea"
                                    required={this.state.editModel.tipoAccesoPorDefecto == TipoAcceso.NoAccedeMuestraMensaje}
                                    disabled={this.state.editModel.tipoAccesoPorDefecto != TipoAcceso.NoAccedeMuestraMensaje}
                                    maxLength={500}
                                    className={"form-control " + this._errorClass(nameof(this.state.editModel.mensajeAcceso))}
                                    name={nameof(this.state.editModel.mensajeAcceso)}
                                    id={nameof(this.state.editModel.mensajeAcceso)}
                                    value={this.state.editModel.mensajeAcceso || ""}
                                />
                                {this._errorMessage(nameof(this.state.editModel.mensajeAcceso))}
                            </FormGroup>
                        </Col>

                    </Row>
                </Col>
                <Col md={3}>
                    <div>
                        <div className="mainPhoto">
                            <img
                                className="mt-xl"
                                src={this.state.editModel.caratula == null ? '/img/caratula.png' : this.state.editModel.caratula}
                            />
                            {this.state.editModel.caratula != null && <div className="mainPhotoMenu">

                            </div>
                            }
                        </div>
                    </div>
                </Col>
            </Row>
        </React.Fragment>;
    }

    protected _getEditUrl(id: number): string {
        return RouteHelper.editContenidosColegio(id, ContenidoColegioEditTabs.Datos);
    }

    protected _getListUrl(): string {
        return RouteHelper.listContenidosColegio();
    }

    protected _createStateForNew(): Promise<GenericEditState<ContenidosColegioEditModel>> {

        var model = new GenericEditState<ContenidosColegioEditModel>();
        model.editModel = {
            ...{} as ContenidosColegioEditModel,
            id: 0,
        } as ContenidosColegioEditModel;

        return Promise.resolve(model);
    }

    protected _validateModelLocal(): ModelValidation {
        let validation = new ModelValidation();


        if ((this.state.editModel.nombre || "").trim().length == 0) {
            validation.addError(nameof(this.state.editModel.nombre), I18n.Strings.validationErrors.valueRequired(I18n.Strings.contenidos.nombre));
        }

        if ((this.state.editModel.descripcion || "").trim().length == 0) {
            validation.addError(nameof(this.state.editModel.descripcion), I18n.Strings.validationErrors.valueRequired(I18n.Strings.contenidos.descripcion));
        }

        if ((this.state.editModel.editorial || "").trim().length == 0) {
            validation.addError(nameof(this.state.editModel.editorial), I18n.Strings.validationErrors.valueRequired(I18n.Strings.contenidos.editorial));
        }

        if ((this.state.editModel.tipoAccesoPorDefecto || "").trim().length == 0) {
            validation.addError(nameof(this.state.editModel.tipoAccesoPorDefecto), I18n.Strings.validationErrors.valueRequired(I18n.Strings.nivelesContenidos.tipoAccesoPorDefecto));
        }

        return validation;
    }

    protected updateBreadCrumb(model: ContenidosColegioEditModel) {
        var items = [{
            text: I18n.Strings.contenidos.contenidosColegioTitle,
            link: RouteHelper.listContenidosColegio()
        }];
        if (model.id == 0) {
            items.push({
                text: I18n.Strings.addNew,
                link: ""
            });
        }
        else {
            items.push({
                text: `${model.nombre}`,
                link: RouteHelper.editContenidosColegio(model.id)
            });

            switch (this.state.activeTab) {
                case ContenidoColegioEditTabs.Datos: {
                    items.push({ text: I18n.Strings.contenidos.tabDatos, link: RouteHelper.editContenidosColegio(this.state.editModel.id, ContenidoColegioEditTabs.Datos) });
                    break;
                }
                case ContenidoColegioEditTabs.Licencias: {
                    items.push({ text: I18n.Strings.licenciasContenido.entityPluralName, link: RouteHelper.editContenidosColegio(this.state.editModel.id, ContenidoColegioEditTabs.Licencias) });
                    break;
                }
                case ContenidoColegioEditTabs.Niveles: {
                    items.push({ text: I18n.Strings.contenidos.tabNiveles, link: RouteHelper.editContenidosColegio(this.state.editModel.id, ContenidoColegioEditTabs.Niveles) });
                    break;
                }

            }
        }

        NavigationHelper.setBreadcrumbItems(items);
    }
}

export const EditContenidoColegio = withRouter(EditContenidoColegioComp)