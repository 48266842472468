import { AliasEditorial, AliasEditorialEditModel, AliasEditorialListItemPaginatedList, AliasEditorialSearch, ModelValidationResult } from "../models/dataModels";
import { ApiFetcher } from "../utils/ApiFetcher";
import { IServiceForEdit, IServiceForIndex } from "../utils/base/BaseServices";
import { I18n } from "../utils/I18n";

export class AliasEditorialService
    implements IServiceForIndex<AliasEditorialSearch, AliasEditorialListItemPaginatedList>, IServiceForEdit<AliasEditorialEditModel>
{
    
    search(searchData: AliasEditorialSearch): Promise<AliasEditorialListItemPaginatedList> {

        var apiFetcher = new ApiFetcher();
        var prom = apiFetcher.post<AliasEditorialSearch, AliasEditorialListItemPaginatedList>("intranet/aliasEditoriales/search", searchData);
        return prom;
    };

    

    delete(id: number) : Promise<any> {
        var apiFetcher = new ApiFetcher();
        var prom = apiFetcher.delete<any>(`intranet/aliasEditoriales/${id}`);
        return prom;
    };

    getById(id: string | number): Promise<AliasEditorialEditModel> {
        var apiFetcher = new ApiFetcher();
        var prom = apiFetcher.getByUrl<AliasEditorialEditModel>(`intranet/aliasEditoriales/${id}`);
        return prom;
    };

    validate(model: AliasEditorialEditModel): Promise<ModelValidationResult> {
        var apiFetcher = new ApiFetcher();
        var prom = apiFetcher.post<AliasEditorialEditModel, ModelValidationResult>(`intranet/aliasEditoriales/validate`, model);
        return prom;
    }

    create(model: AliasEditorialEditModel): Promise<AliasEditorialEditModel> {
        var apiFetcher = new ApiFetcher();
        var prom = apiFetcher.post<AliasEditorialEditModel, AliasEditorialEditModel>(`intranet/aliasEditoriales/`, model);
        return prom;
    };

    update(id: string | number, model: AliasEditorialEditModel): Promise<AliasEditorialEditModel> {
        var apiFetcher = new ApiFetcher();
        var prom = apiFetcher.put<AliasEditorialEditModel, AliasEditorialEditModel>(`intranet/aliasEditoriales/${id}`, model);
        return prom;
    };

    getAll(): Promise<AliasEditorial[]> {

        var apiFetcher = new ApiFetcher();
        var prom = apiFetcher.getByUrl<AliasEditorial[]>("intranet/aliasEditoriales");

        prom = prom.then(p => {
            p.forEach(alias => {
                alias.alias = `${(I18n.Strings.getEnumText("Editorial." + alias.editorial) || "N/A")} - ${alias.alias}`;
            });
            return p.sort((a,b) => a.alias.localeCompare(b.alias));
        });
        
        return prom;
    }


    exportExcel(search: AliasEditorialSearch): Promise<any> {
        throw new Error("Method not implemented.");
    };

}