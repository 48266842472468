import { Log, User, UserManager, UserManagerSettings, WebStorageStateStore } from 'oidc-client';

import { Config } from '../utils/ApiFetcher';

export class AuthService {

    private static config: Config;

    public static Init(config: Config) {
        AuthService.config = config;
    }

    public userManager: UserManager;

    constructor() {

        var settings = {
            authority: AuthService.config.stsAuthority,
            client_id: AuthService.config.clientId,
            redirect_uri: `${AuthService.config.clientRoot}signin-callback.html`,
            silent_redirect_uri: `${AuthService.config.clientRoot}silent-renew.html`,
            // tslint:disable-next-line:object-literal-sort-keys
            post_logout_redirect_uri: `${AuthService.config.clientRoot}`,
            response_type: 'code',
            scope: AuthService.config.clientScope,
            userStore: new WebStorageStateStore({ store: localStorage })
        } as UserManagerSettings;

        this.userManager = new UserManager(settings);

        Log.logger = console;
        Log.level = Log.WARN;
    }

    public getUser(): Promise<User | null> {
        return this.userManager.getUser();
    }

    public login(): Promise<void> {
        return this.userManager.signinRedirect();
    }

    public renewToken(): Promise<User> {
        return this.userManager.signinSilent();
    }

    public logout(): Promise<void> {
        return this.userManager.signoutRedirect();
    }
}
