import React from "react";
import { AccesoDirectoHomeItem } from "../models/dataModels";


interface AccesoDirectoEnlaceProps {
    accesoDirecto: AccesoDirectoHomeItem;
}


export default class AccesoDirectoEnlace extends React.Component<AccesoDirectoEnlaceProps, any> {

    public constructor(props: any) {
        super(props);
    }

    public render() {

        return <a href={this.props.accesoDirecto.urlEnlace} title={this.props.accesoDirecto.titulo} target={this.props.accesoDirecto.abrirEnNuevaVentana ? "_blank" : "_self"}>
            <img src={this.props.accesoDirecto.urlCaratula} className="img-fluid" alt={this.props.accesoDirecto.titulo} />
        </a>;
    }

}