
import { ColegioListItem, CursoEscolarEditModel, CursoEscolarListItemPaginatedList, CursoEscolarSearch, ModelValidationResult } from "../models/dataModels";
import { ApiFetcher } from "../utils/ApiFetcher";
import { IServiceForEdit, IServiceForIndex } from "../utils/base/BaseServices";
import { ClientContext } from "../utils/ClientContext";

export class ColegioService
{
    

    getAll(): Promise<ColegioListItem[]> {

        var apiFetcher = new ApiFetcher();
        var prom = apiFetcher.getByUrl<ColegioListItem[]>("intranet/colegios");
        return prom;
    }


}