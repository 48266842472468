import * as React from "react";
import nameof from "ts-nameof.macro";

import { TipoGrafico } from "../../models/dataModels";
import { Col, FormGroup, Input, Label, Row, Button, FormFeedback, TabContent, TabPane, Nav, NavItem, NavLink } from "reactstrap";
import { I18n } from "../../utils/I18n";
import { RouteHelper } from "../../IntranetRouter";
import { cloneDeep } from "lodash-es";
import { AccesosSistema } from "../AccesosSistema";
import { AccesosContenido } from "../AccesosContenido";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { NavigationHelper } from "../../utils/base/Breadcrumb";

export enum AccesosTabs {
    Accesos = "Accesos",
    Contenidos = "Contenidos"
}

interface AccesosState {
    activeTab?: AccesosTabs;
}

export class AccesosComp extends React.Component<RouteComponentProps<any>, AccesosState> {

    public constructor(props: RouteComponentProps<any>) {
        super(props);

        this.state = {
            activeTab: props.match.params.tab || AccesosTabs.Accesos
        };
    }

    protected _cloneStateForSetState(): AccesosState {
        return cloneDeep(this.state) as AccesosState;
    }

    render() {
        this.updateBreadCrumb();

        var a = this.state.activeTab;
        return <React.Fragment>
            <div className="head-seccion">
                <h2>
                    <strong>
                        {I18n.Strings.estadisticas.cuadroMando}
                    </strong>
                </h2>
            </div>

            <Nav tabs>
                <NavItem>
                    <NavLink
                        active={this.state.activeTab == AccesosTabs.Accesos}
                        onClick={() => this.setState(
                            { activeTab: AccesosTabs.Accesos },
                            () => this.props.history.push(RouteHelper.listEstadisticasProfesor(this.props.match.params.usuarioId, AccesosTabs.Accesos))
                        )}
                    >
                        {I18n.Strings.colegioUsuarios.tabEstadisticasAccesos}
                    </NavLink>
                </NavItem>

                <NavItem>
                    <NavLink
                        active={this.state.activeTab == AccesosTabs.Contenidos}
                        onClick={() => this.setState(
                            { activeTab: AccesosTabs.Contenidos },
                            () => this.props.history.push(RouteHelper.listEstadisticasProfesor(this.props.match.params.usuarioId, AccesosTabs.Contenidos))
                        )}
                    >
                        {I18n.Strings.colegioUsuarios.tabEstadisticasContenidos}
                    </NavLink>
                </NavItem>
            </Nav>
            <TabContent activeTab={this.state.activeTab || AccesosTabs.Accesos}>

                <TabPane tabId={AccesosTabs.Accesos}>
                    {this.state.activeTab == AccesosTabs.Accesos &&
                        <AccesosSistema
                            profesorId={this.props.match.params.usuarioId}
                            tipo={TipoGrafico.Profesor} />
                    }
                </TabPane>
                <TabPane tabId={AccesosTabs.Contenidos}>
                    {this.state.activeTab == AccesosTabs.Contenidos &&
                        <AccesosContenido
                            profesorId={this.props.match.params.usuarioId}
                            emptyText={I18n.Strings.contenidos.todosSeleccionados}
                            tipo={TipoGrafico.Profesor} />
                    }
                </TabPane>
            </TabContent>
        </React.Fragment>;
    }

    protected updateBreadCrumb() {
        var items = [{
            text: I18n.Strings.estadisticas.titulo,
            link: RouteHelper.listEstadisticasAdmin()
        }];

        switch (this.state.activeTab) {
            case AccesosTabs.Accesos: {
                items.push({ text: I18n.Strings.colegioUsuarios.tabEstadisticasAccesos, link: RouteHelper.listEstadisticasProfesor(AccesosTabs.Accesos) });
                break;
            }
            case AccesosTabs.Contenidos: {
                items.push({ text: I18n.Strings.colegioUsuarios.tabEstadisticasContenidos, link: RouteHelper.listEstadisticasProfesor(AccesosTabs.Contenidos) });
                break;
            }
        }


        NavigationHelper.setBreadcrumbItems(items);
    }
};

export const Accesos = withRouter(AccesosComp)