import React from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { Button, Col, Row } from "reactstrap";

import { AuthService } from '../services/AuthService';

interface LoginProps extends RouteComponentProps<any> {
    className?: string;
}

class LogoffComp extends React.Component<LoginProps, any> {

    public authService: AuthService;
    private shouldCancel: boolean;

    constructor(props: LoginProps) {
        super(props);

        this.authService = new AuthService();
        this.state = { user: {}, api: {} };
        this.shouldCancel = false;
    }

    public componentDidMount() {
        this.getUser();
    }

    public componentWillUnmount() {
        this.shouldCancel = true;
    }

    public logout = () => {
        this.authService.logout();
    };

    public getUser = () => {
        this.authService.getUser().then(user => {

            if (!this.shouldCancel) {
                this.setState({ user });
            }
        });

    };
    render() {
        return <React.Fragment>
            <a className={this.props.className} onClick={this.logout}>
                Cerrar sesi&oacute;n
            </a>
        </React.Fragment>
    }

    onLogoffError(err: any): void {
        console.error(err);
    }

    private onLogoffSuccess() {
        this.props.history.push("/");
    }
}

export const Logoff = withRouter(LogoffComp);