import * as React from "react";

import nameof from "ts-nameof.macro";

import { TipoGrafico } from "../models/dataModels";
import { LicenciasColegioService } from "../licenciasColegio/LicenciasColegioService"
import { I18n } from "../utils/I18n";
import { Loading } from "../utils/Loading";
import { AccesosContenido } from "../accesos/AccesosContenido";
import { Row, Col } from "reactstrap";

interface EstadisticasProps {
    usuarioId: number;
}

export class TabEstadisiticasContenidos extends React.Component<EstadisticasProps, any> {

    render() {

        return <div className={"estadisticas"}>
            <h2>{I18n.Strings.estadisticas.accesosLibros}</h2>
            <Row>
                <Col md={12}>
                    <AccesosContenido
                        emptyText={I18n.Strings.contenidos.todosSeleccionados}
                        tipo={TipoGrafico.Usuario}
                        usuarioId={this.props.usuarioId} />
                </Col>
            </Row>
        </div>;
    }
}