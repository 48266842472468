import * as React from "react";
import nameof from "ts-nameof.macro";

import { Row, Col, FormGroup, Label, Input, Button } from "reactstrap";

import { MisMatriculasService } from "./MisMatriculasService";
import { I18n } from "../../../utils/I18n";
import { MatriculaProfesorListItem, MatriculaProfesorListItemPaginatedList, MatriculasProfesorSearch } from "../../../models/dataModels";
import { IServiceForIndex } from "../../../utils/base/BaseServices";
import { IndexPageBase, TableColumn } from "../../../utils/base/IndexPageBase";
import { RouteHelper } from "../../../IntranetRouter";

import { ColegioUsuariosPicker } from "../../../colegioUsuarios/Picker";
import { NivelEducativoDropDown } from "../../../cursoEscolar/NivelEducativoDropDown";
import { ClientContext } from "../../../utils/ClientContext";
import { withRouter } from "react-router-dom";
import { ClaseMateriaDropDown } from "../../../cursoEscolar/materia/ClaseMateriaDropDown";

interface MatriculaProfesorSearchEx extends MatriculasProfesorSearch {
    usuario?: string;
    clase?: string;
    materia?: string;
}

class IndexComp extends IndexPageBase<MatriculaProfesorSearchEx, MatriculaProfesorListItemPaginatedList, MatriculaProfesorListItem>
{
    protected _renderSearchForm(): React.ReactNode {
        if (this.props.isEmbedded) {
            return null;
        }
        return <React.Fragment>
            <Col xs="12" md="6" lg="4">
                <ColegioUsuariosPicker
                    label={I18n.Strings.matriculas.nombreUsuario}
                    required={false}
                    itemId={this.state.searchParams.usuarioId}
                    itemName={this.state.searchParams.usuario}
                    getIdFromItem={(item) => item.id}
                    getNameFromItem={(item) => `${item.nombre} ${item.apellidos}`}

                    errorMessage={null}
                    onSelected={(usuarioId, usuario, item) => {
                        var state = this._cloneStateForSetState();
                        state.searchParams.usuarioId = usuarioId;
                        state.searchParams.usuario = usuario;

                        this.setState(state);
                    }}
                />
            </Col>
            <Col xs="12" md="6" lg="3">
                <FormGroup>
                    <Label for={nameof(this.state.searchParams.clase)}>
                        {I18n.Strings.matriculas.nombreClase}
                    </Label>

                    <NivelEducativoDropDown
                        id={nameof(this.state.searchParams.clase)}

                        cursoEscolarId={ClientContext.Current.cursoEscolarActivoId}
                        value={this.state.searchParams.claseId}

                        text={this.state.searchParams.clase}
                        seleccionClases={true}
                        onChange={(id, nombre) => {

                            var state = this._cloneStateForSetState();
                            state.searchParams.claseId = id;
                            state.searchParams.clase = nombre;
                            state.searchParams.materia = null;
                            state.searchParams.claseMateriaId = null;

                            this.setState(state);
                        }}
                    />

                </FormGroup>
            </Col>
            <Col xs="12" md="6" lg="3">
                <ClaseMateriaDropDown
                    label={I18n.Strings.matriculas.nombreMateria}
                    key={this.state.searchParams.claseId}
                    selectedItemValue={this.state.searchParams.claseMateriaId}
                    claseId={this.state.searchParams.claseId}
                    selectedItemText={this.state.searchParams.materia}
                    onChanged={(materiaId, materia, item) => {
                        var state = this._cloneStateForSetState();
                        state.searchParams.claseMateriaId = materiaId;
                        state.searchParams.materia = materia;
                        this.setState(state, () => this._performSearch());
                    }}
                />
            </Col>
        </React.Fragment>;
    }

    protected _getApiService(): IServiceForIndex<MatriculasProfesorSearch, MatriculaProfesorListItemPaginatedList> {
        return new MisMatriculasService();
    }

    protected _getTableColumns(): TableColumn<MatriculaProfesorListItem>[] {
        return [
            {
                fieldName: nameof(this.state.searchResults.items[0].clase),
                title: I18n.Strings.matriculas.nombreClase,
                renderField: (item) => item.clase
            },
            {
                fieldName: nameof(this.state.searchResults.items[0].materia),
                title: I18n.Strings.matriculas.nombreMateria,
                renderField: (item) => item.materia
            },
            {
                fieldName: nameof(this.state.searchResults.items[0].usuario),
                title: I18n.Strings.matriculas.nombreUsuario,
                renderField: (item) => item.usuario
            },
            {
                fieldName: nameof(this.state.searchResults.items[0].rol),
                title: I18n.Strings.matriculas.rol,
                renderField: (item) => item.rol
            },
        ];
    }

    protected _getPageTitle(): string {
        return I18n.Strings.matriculas.title;
    }
    protected _getPageDescription(): string {
        return I18n.Strings.matriculas.description;
    }

   
    protected _getEditionItemUrl(item: MatriculaProfesorListItem): string {
        return RouteHelper.editMiMatriculaProfesor(item.id, this.props.searchModel.usuarioId);
    }

    protected _getNewItemUrl(): string {
        return RouteHelper.createMiMatriculaProfesor(this.props.searchModel.usuarioId);
    }

}

export const Index = withRouter(IndexComp);