import * as React from "react";
import * as Dropzone from "react-dropzone";
import { ClientContext } from "../utils/ClientContext";
import * as Models from "../models/dataModels";
import { ApiFetcher } from "../utils/ApiFetcher";
import { I18n } from "../utils/I18n";
import * as Formatter from "../utils/ValuesFormatter";
import { Loading } from "../utils/Loading";
import { AlertsService } from "../utils/AlertsService";
import { ComponentBase } from "../utils/base/ComponentBase";

class DocumentUploadProps {
    public onBeforeUpload?: () => boolean;
    public onUpload?: (result: Models.DocumentUploadResult) => void;
    public uploadUrl: string;
    public formatosAceptados?: string;
    public tipoCarga?: Models.TipoCarga;
    public multiple?: boolean;
    public title?: string;
    public manualUpload?: boolean;
    public style?: React.CSSProperties;
    public zoneStyle?: React.CSSProperties;

    public onDrop?: (accepted: Dropzone.FileWithPreview[], rejected: Dropzone.FileWithPreview[]) => void;

    public filesToUpload?: Dropzone.FileWithPreview[];
}

class DocumentUploadState {
    public bytesUploaded: number;
    public totalFileSize: number;
    public currentFile: string;
    public inProgress: boolean;
    public filesToUpload: Dropzone.FileWithPreview[];
}

export class DocumentUpload extends ComponentBase<DocumentUploadProps, DocumentUploadState>
{      
    constructor(props: DocumentUploadProps) {
        super(props);

        this.state = this._createNewState();
    }


    public componentWillReceiveProps(newProps: DocumentUploadProps) {
        var state = this._cloneStateForSetState();
        state.filesToUpload = newProps.filesToUpload;
        this.setState(state);
    }

    private _createNewState(): DocumentUploadState {

        var state = new DocumentUploadState();
        state.bytesUploaded = 0;
        state.totalFileSize = 0;
        state.currentFile = "";
        state.inProgress = false;
        state.filesToUpload = this.props.filesToUpload;

        return state;
    }

    public render(): JSX.Element {
        return (
            <div className="dropZoneContainer" style={this.props.zoneStyle}>
                <Dropzone.default
                    className={"dropZone"}
                    activeClassName={"active"}
                    preventDropOnDocument={this.state.inProgress}
                    disablePreview={true}
                    multiple={this.props.multiple}
                    accept={this.props.formatosAceptados}
                    onDrop={
                        (accepted: Dropzone.FileWithPreview[], rejected: Dropzone.FileWithPreview[]) => {

                            if (accepted.some(p => p.size == 0)) {
                                alert(I18n.Strings.documentUpload.dropFolderNotSupported);
                                return;
                            }

                            if (accepted.length == 0) {
                                alert(I18n.Strings.documentUpload.dropNotSupported);
                                return;
                            }

                            if (this.props.onDrop) {
                                this.props.onDrop(accepted, rejected);
                            }
                            else {
                                this._defaultOnDrop(accepted, rejected);
                            }
                        }}
                >

                    {!this.state.inProgress && (!this.state.filesToUpload || this.state.filesToUpload.length == 0) &&
                        <p style={this.props.style}>{this.props.title || I18n.Strings.documentUpload.dropHere}</p>
                    }

                    {this.state.inProgress &&
                        <div>
                            <p style={this.props.style} className="files">{this.state.currentFile}</p>
                            <p style={this.props.style} className="small">{(this.state.bytesUploaded / 1000).toLocaleString()} / {(this.state.totalFileSize / 1000).toLocaleString()}</p>
                            <div style={{ height: 25 }}>
                                <Loading />
                            </div>
                            </div>
                    }

                    {this.props.manualUpload && this.state.filesToUpload && this.state.filesToUpload.length > 0 &&
                                <div>
                                    <p style={this.props.style} className="files">
                                        {this.state.filesToUpload.map((item) => {
                                            return item.name + " ";
                                        })}
                                    </p>
                                </div>
                            }

                </Dropzone.default>
            </div>

        );
    }

    private _defaultOnDrop(accepted: Dropzone.FileWithPreview[], rejected: Dropzone.FileWithPreview[]) {

        var state = this._cloneStateForSetState();
        if (this.props.manualUpload && state.filesToUpload != null) {
                    accepted.forEach(file => state.filesToUpload.push(file));
        }
        else {
                    state.filesToUpload = accepted;
        }

        this.setState(state, () => {
            if (!this.props.manualUpload) {

                    this._uploadFiles(this.state.filesToUpload)
                        .then(_ => {
                            var newState = this._createNewState();
                            this.setState(newState);
                        })
                        .catch(reason => {
                            alert(Formatter.logAndExtractInfoFromException(reason));
                        });
            }
        });
    }


    private _uploadFiles(filesToUpload: Dropzone.FileWithPreview[]): Promise<any> {

        if (this.props.onBeforeUpload) {
                        this.props.onBeforeUpload()
                    }

        var promises = [] as Promise<any>[];

                    const promiseSerial = funcs =>
                    funcs.reduce((promise, func) =>
                    promise
                    .then(result => func().then(Array.prototype.concat.bind(result))),
                    Promise.resolve([]))

                    const funcs = filesToUpload.map((file) => () => this._processFileQueue(file));


                    return promiseSerial(funcs);
                    }

    public uploadQueuedFiles(callback: (isOk: boolean) => void) {

        if (this.state.filesToUpload && this.state.filesToUpload.length > 0) {

            var promises = [] as Promise<any>[];

                        const promiseSerial = funcs =>
                        funcs.reduce((promise, func) =>
                        promise.then(result => func().then(Array.prototype.concat.bind(result))),
                        Promise.resolve([]))

                        const funcs = this.state.filesToUpload.map((file) => () => this._processFileQueue(file));

                        promiseSerial(funcs)
                .then(_ => {
                    if (callback) {
                                callback(true);
                    }
                })
                .catch(_ => {
                    if (callback) {
                                callback(false);
                    }
                });

        }
        else {
            if (callback) {
                                callback(true);
            }
        }

    }

    /**
     * Procesa los ficheros secuencialmente
     * @param fileToProcess
     * @param accepted
     */
    private _processFileQueue(fileToUpload: Dropzone.FileWithPreview): Promise<Models.DocumentUploadResult> {

        var prom = this._uploadFile(fileToUpload)
            .then((uploadResult) => {
                if (uploadResult == null) {
                                    alert("Ha sucedido un error subiendo el fichero");
                }

                const newState = this._cloneStateForSetState();
                newState.inProgress = false;
                newState.currentFile = null;
                newState.totalFileSize = 0;
                this.setState(newState, () => {
                    if (this.props.onUpload && uploadResult != null) {
                                    this.props.onUpload(uploadResult);
                    }
                });
                return uploadResult;
            })
            .catch(reason => {
                const newState = this._cloneStateForSetState();
                newState.inProgress = false;
                newState.currentFile = null;
                newState.totalFileSize = 0;
                this.setState(newState, () => {
                    AlertsService.showError(reason);
                });

                return null;
            });
        return prom;
    }

    /**
     * Sube un fichero
     * @param file
     * @param chunkSize
     */
    private _uploadFile(file: Dropzone.FileWithPreview): Promise<Models.DocumentUploadResult> {

        var newState = this._cloneStateForSetState();
        newState.totalFileSize = 0;
        newState.currentFile = file.name;
        newState.totalFileSize = file.size;
        newState.bytesUploaded = 0;
        newState.inProgress = true;
        this.setState(newState);

        let dataToPost = new FormData();

        dataToPost.append(file.name, file);
        if (this.props.tipoCarga != null) {
            dataToPost.append("TipoCarga", Models.TipoCarga[this.props.tipoCarga]);
        }

        var fetcher = new ApiFetcher();

        var promise = fetcher.post<any, Models.DocumentUploadResult>(this.props.uploadUrl, dataToPost)
            .catch((reason) => {
                var errDesc = Formatter.logAndExtractInfoFromException(reason);
                console.error(errDesc);
                alert(errDesc);

                var state = this._createNewState();
                this.setState(state);
                return null;
            });

        return promise;

    }



}