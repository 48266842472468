import React from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { Button, Col, Modal, ModalBody, ModalFooter, ModalHeader, Row } from "reactstrap";
import nameof from "ts-nameof.macro";
import { ColegioUsuariosPicker } from "../colegioUsuarios/Picker";

import { AuthService } from '../services/AuthService';
import { ApiFetcher } from "../utils/ApiFetcher";
import { ComponentBase } from "../utils/base/ComponentBase";
import { ClientContext } from "../utils/ClientContext";
import { I18n } from "../utils/I18n";

interface ImpersonateProps extends RouteComponentProps<any> {
    onClose: () => void;
}

interface ImpersonateState {
    userIdToImpersonate: number;
    userName: string;

}

class ImpersonateComp extends ComponentBase<ImpersonateProps, ImpersonateState> {

    public authService: AuthService;

    constructor(props: ImpersonateProps) {
        super(props);

        this.state = {
            userIdToImpersonate: 0,
            userName: null,
        }
    }

    render() {
        return <React.Fragment>
            <Modal isOpen={true} toggle={() => this.props.onClose()} >
                <ModalHeader
                    close={<button className="btn-close" onClick={() => this.props.onClose()} />}
                >
                    {I18n.Strings.emularUsuario.emularUsuario}
                </ModalHeader>
                {!ClientContext.Current.estoyImpersonado &&
                    <React.Fragment>
                        <ModalBody>
                            <ColegioUsuariosPicker
                                label={I18n.Strings.licenciasContenido.usuario}
                                required={true}
                                itemId={this.state.userIdToImpersonate}
                                itemName={this.state.userName}
                                getIdFromItem={(item) => item.id}
                                getNameFromItem={(item) => item.nombre}
                                getName2FromItem={(item) => item.apellidos}
                                errorMessage={null}
                                onSelected={(usuarioId, usuario, item) => {
                                    var state = this._cloneStateForSetState();
                                    state.userIdToImpersonate = usuarioId;
                                    state.userName = usuario;
                                    this.setState(state);
                                }}
                            />

                        </ModalBody>
                        <ModalFooter>
                            <Button
                                disabled={this.state.userIdToImpersonate == 0}
                                onClick={() => {
                                    var impService = new ImpersonationService();
                                    impService.impersonate(this.state.userIdToImpersonate);
                                }}>
                                {I18n.Strings.emularUsuario.emularUsuario}
                            </Button>
                        </ModalFooter>
                    </React.Fragment>
                }

                {ClientContext.Current.estoyImpersonado &&
                    <React.Fragment>
                        <ModalBody>
                            <p>{I18n.Strings.emularUsuario.yaEstaEmulando}</p>
                        </ModalBody>
                        <ModalFooter>
                            <Button onClick={() => {
                                var service = new ImpersonationService();
                                service.deimpersonate();
                            }}>
                                {I18n.Strings.emularUsuario.volverAMiUsuario}
                            </Button>
                        </ModalFooter>
                    </React.Fragment>
                }
            </Modal>

        </React.Fragment>
    }


}

export class ImpersonationService {

    public impersonate(usuarioId: number) {

        var apiFetcher = new ApiFetcher();
        var prom = apiFetcher.post<any, any>(`intranet/miPerfil/impersonate/${usuarioId}`, null);

        prom.then(p => {
            window.location.href = "/"
        });

    }

    public deimpersonate() {

        var apiFetcher = new ApiFetcher();
        var prom = apiFetcher.post<any, any>(`intranet/miPerfil/deimpersonate`, null);

        prom.then(p => {
            window.location.href = "/"
        });

    }
}

export const Impersonate = withRouter(ImpersonateComp);