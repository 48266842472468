import { ClaseListItemPaginatedList, ClasesSearch, TipoUsuario } from "../models/dataModels";
import { ApiFetcher } from "../utils/ApiFetcher";
import { ClientContext } from "../utils/ClientContext";

export class MisClasesService    
{

    
    search(searchData: ClasesSearch): Promise<ClaseListItemPaginatedList> {

        var apiFetcher = new ApiFetcher();
        var prom = apiFetcher.post<ClasesSearch, ClaseListItemPaginatedList>("intranet/clases/search", searchData);
        return prom;
    }

    puedeAccederAMisClasesProfe(): boolean {
        return (ClientContext.Current.tipoUsuario != TipoUsuario.Alumno && ClientContext.Current.tipoUsuario != TipoUsuario.None);
    }

}