
import {
    ContenidosColegioEditModel, ModelValidationResult,    
    ExportFileResult,
    ContenidosPapelColegioSearch,
    ContenidosPapelColegioListItemPaginatedList
} from "../../models/dataModels";
import { ApiFetcher } from "../../utils/ApiFetcher";
import { IServiceForEdit, IServiceForIndex } from "../../utils/base/BaseServices";
import { downloadFileFromBase64Array } from "../../utils/ValuesFormatter";

export class ContenidosPapelColegioService
    implements IServiceForIndex<ContenidosPapelColegioSearch, ContenidosPapelColegioListItemPaginatedList>, IServiceForEdit<ContenidosColegioEditModel>
{

    search(searchData: ContenidosPapelColegioSearch): Promise<ContenidosPapelColegioListItemPaginatedList> {

        var apiFetcher = new ApiFetcher();
        var prom = apiFetcher.post<ContenidosPapelColegioSearch, ContenidosPapelColegioListItemPaginatedList>("intranet/contenidosPapelColegio/search", searchData);
        return prom;
    }

    delete(id: number): Promise<any> {
        throw new Error("Method not implemented.");
        //var apiFetcher = new ApiFetcher();
        //var prom = apiFetcher.delete<any>(`intranet/contenidosPapelColegio/${id}`);
        //return prom;
    }

    getById(id: string | number): Promise<ContenidosColegioEditModel> {
        throw new Error("Method not implemented.");
        //var apiFetcher = new ApiFetcher();
        //var prom = apiFetcher.getByUrl<ContenidosColegioEditModel>(`intranet/contenidosPapelColegio/${id}`);
        //return prom;
    }
    validate(model: ContenidosColegioEditModel): Promise<ModelValidationResult> {
        throw new Error("Method not implemented.");
        //var apiFetcher = new ApiFetcher();
        //var prom = apiFetcher.post<ContenidosColegioEditModel, ModelValidationResult>(`intranet/contenidosPapelColegio/validate`, model);
        //return prom;
    }

    create(model: ContenidosColegioEditModel): Promise<ContenidosColegioEditModel> {
        throw new Error("Method not implemented.");
        //var apiFetcher = new ApiFetcher();
        //var prom = apiFetcher.post<ContenidosColegioEditModel, ContenidosColegioEditModel>(`intranet/contenidosPapelColegio/`, model);
        //return prom;
    }

    update(id: string | number, model: ContenidosColegioEditModel): Promise<ContenidosColegioEditModel> {
        throw new Error("Method not implemented.");
        //var apiFetcher = new ApiFetcher();
        //var prom = apiFetcher.put<ContenidosColegioEditModel, ContenidosColegioEditModel>(`intranet/contenidosPapelColegio/${id}`, model);
        //return prom;
    }

    exportExcel(searchModel: ContenidosPapelColegioSearch): Promise<ExportFileResult> {
        throw new Error("Method not implemented.");

        var url = "intranet/contenidosPapelColegio/exportExcel";

        var apiFetcher = new ApiFetcher();
        var promise = apiFetcher.post<ContenidosPapelColegioSearch, ExportFileResult>(url, searchModel)
            .then((result) => {
                downloadFileFromBase64Array(result.content, result.fileName, "application/octet-stream");
                return result;
            });
        return promise;
    }

}