import { AccesoDirectoEditModel, AccesoDirectoSearch, ModelValidationResult, AccesoDirectoListItemPaginatedList } from "../models/dataModels";
import { ApiFetcher } from "../utils/ApiFetcher";
import { IServiceForEdit, IServiceForIndex } from "../utils/base/BaseServices";

export class AccesoDirectoService
    implements IServiceForIndex<AccesoDirectoSearch, AccesoDirectoListItemPaginatedList>, IServiceForEdit<AccesoDirectoEditModel>
{
    

    search(searchData: AccesoDirectoSearch): Promise<AccesoDirectoListItemPaginatedList> {

        var apiFetcher = new ApiFetcher();
        var prom = apiFetcher.post<AccesoDirectoSearch, AccesoDirectoListItemPaginatedList>("intranet/colegio/accesosDirectos/search", searchData);
        return prom;
    }

    delete(id: number) : Promise<any> {
        var apiFetcher = new ApiFetcher();
        var prom = apiFetcher.delete<any>(`intranet/colegio/accesosDirectos/${id}`);
        return prom;
    }

    getById(id: string | number): Promise<AccesoDirectoEditModel> {
        var apiFetcher = new ApiFetcher();
        var prom = apiFetcher.getByUrl<AccesoDirectoEditModel>(`intranet/colegio/accesosDirectos/${id}`);
        return prom;
    }
    validate(model: AccesoDirectoEditModel): Promise<ModelValidationResult> {
        var apiFetcher = new ApiFetcher();
        var prom = apiFetcher.post<AccesoDirectoEditModel, ModelValidationResult>(`intranet/colegio/accesosDirectos/validate`, model);
        return prom;
    }

    create(model: AccesoDirectoEditModel): Promise<AccesoDirectoEditModel> {
        var apiFetcher = new ApiFetcher();
        var prom = apiFetcher.post<AccesoDirectoEditModel, AccesoDirectoEditModel>(`intranet/colegio/accesosDirectos/`, model);
        return prom;
    }

    update(id: string | number, model: AccesoDirectoEditModel): Promise<AccesoDirectoEditModel> {
        var apiFetcher = new ApiFetcher();
        var prom = apiFetcher.put<AccesoDirectoEditModel, AccesoDirectoEditModel>(`intranet/colegio/accesosDirectos/${id}`, model);
        return prom;
    }

    exportExcel(search: AccesoDirectoSearch): Promise<any> {
        throw new Error("Method not implemented.");
    }

}