
import { GrupoClaseEditModel, LicenciaContenido, CursoEscolarEditModel, CursoEscolarListItemPaginatedList, CursoEscolarSearch, ModelValidationResult } from "../../models/dataModels";
import { ApiFetcher } from "../../utils/ApiFetcher";
import { IServiceForEdit, IServiceForIndex } from "../../utils/base/BaseServices";

export class GrupoClaseService
    implements IServiceForIndex<any, any>, IServiceForEdit<GrupoClaseEditModel>
{
    

    search(searchData: any): Promise<any> {
        throw new Error("Method not implemented.");
    }

    delete(id: number) : Promise<any> {
        throw new Error("Method not implemented.");
    }

    getById(id: string | number): Promise<GrupoClaseEditModel> {
        var apiFetcher = new ApiFetcher();
        var prom = apiFetcher.getByUrl<GrupoClaseEditModel>(`intranet/grupoclase/${id}`);
        return prom;
    }
    validate(model: GrupoClaseEditModel): Promise<ModelValidationResult> {
        var apiFetcher = new ApiFetcher();
        var prom = apiFetcher.post<GrupoClaseEditModel, ModelValidationResult>(`intranet/grupoclase/validate`, model);
        return prom;
    }

    create(model: GrupoClaseEditModel): Promise<GrupoClaseEditModel> {
        throw new Error("Method not implemented.");
    }

    update(id: string | number, model: GrupoClaseEditModel): Promise<GrupoClaseEditModel> {
        var apiFetcher = new ApiFetcher();
        var prom = apiFetcher.put<GrupoClaseEditModel, GrupoClaseEditModel>(`intranet/grupoclase/${id}`, model);       
        return prom;
    }

    exportExcel(search: CursoEscolarSearch): Promise<any> {
        throw new Error("Method not implemented.");
    }
}