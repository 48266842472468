import * as React from "react";
import { Button, Col, FormFeedback, FormGroup, Input, InputGroup, Label, Modal, ModalBody, ModalFooter, ModalHeader, Row } from "reactstrap";
import { SearchModelBase, SortDirection } from "../../models/dataModels";
import { AlertsService } from "../AlertsService";
import { I18n } from "../I18n";
import { DownIcon, SearchIcon, UpIcon } from "../Icons";
import { IEntity, Pager, PaginatedListBase } from "../Pager";
import { IServiceForIndex } from "./BaseServices";
import { ComponentBase } from "./ComponentBase";
import { TableColumn } from "./IndexPageBase";



export class GenericPickerProps<TSearch extends SearchModelBase, TListItem> {
    public label: string | JSX.Element;
    public labelForSearchWindow?: string;
    public itemId: number;
    public itemName: string;
    public required: boolean;
    public disabled?: boolean;
    public onSelected: (value: number, name: string, item: TListItem, checkedElements?: number[]) => void;
    public onTextSelected?: (textSelected: string) => void;
    public getNameFromItem: (item: TListItem) => string;
    public getName2FromItem?: (item: TListItem) => string;
    public getIdFromItem?: (item: TListItem) => number;
    public errorMessage?: string;
    public helpText?: string;
    public searchParams?: TSearch;
    public allowTextSearch?: boolean;
    public checkedElements?: number[];
    public size?: string ;
}

export interface IGenericPickerState<TSearchModel extends SearchModelBase, TResult> {
    showSearchWindow: boolean;
    searchParams: TSearchModel;
    searchResults: TResult;
    canSelect: boolean;
    allChecked: boolean;
    checkedElements: number[];
}


export abstract class GenericPicker<TSearchModel extends SearchModelBase,
    TSearchResult,
    TResultItem extends IEntity,
    TPickerProps extends GenericPickerProps<TSearchModel, TResultItem>>
    extends ComponentBase<TPickerProps, IGenericPickerState<TSearchModel, TSearchResult>>
{

    protected abstract _createApiService(): IServiceForIndex<TSearchModel, TSearchResult>;
    protected abstract _getColumns(): TableColumn<TResultItem>[];
    protected abstract RenderSearchForm(): JSX.Element;

    protected abstract GetInitialSearchParams(paramsFromProps: TSearchModel): TSearchModel;

    protected GetTitulo(): string {
        var texto = "";
        if (typeof this.props.label === "string") {
            texto = this.props.label;
        }

        return `Seleccionar ${texto}`;
    }

    protected constructor(props: any) {
        super(props);

        const state = this._createState();
        state.searchParams = this.GetInitialSearchParams(this.props.searchParams);
        this.state = state;
    }


    public componentWillReceiveProps(newProps: TPickerProps) {
        const state = this._createState();
        state.searchParams = this.GetInitialSearchParams(newProps.searchParams);
        this.setState(state);
    }

    protected _showCheckboxes(): boolean {
        return false;
    }

    componentDidUpdate(prevProps: TPickerProps) {
        if (prevProps.checkedElements !== this.props.checkedElements) {
            this.setState({ checkedElements: this.props.checkedElements });
        }
    }

    protected _createState(): IGenericPickerState<TSearchModel, TSearchResult> {
        const state = {
            showSearchWindow: false,
            searchParams: (this.props.searchParams || {}),
            canSelect: false,
            checkedElements: this.props.checkedElements != null ? this.props.checkedElements : []

        } as IGenericPickerState<TSearchModel, TSearchResult>;
        return state;
    }


    public render(): JSX.Element {
        var empty = (!this.state.searchResults || !(this.state.searchResults as any).items || (this.state.searchResults as any).items.length == 0);

        var columns = this._getColumns()
        var searchResults = this.state.searchResults as any as PaginatedListBase<TResultItem>;

        var hasError = (this.props.errorMessage || "").trim().length != 0;
        return (
            <React.Fragment>
                <FormGroup>
                    <Label>
                        {this.props.label}
                    </Label>

                    <InputGroup onClick={() => this._textFieldClicked()} className={(hasError ? "is-invalid" : "")}>

                        <Input
                            className={"form-control picker" + (hasError ? "is-invalid" : "")}
                            value={this.props.itemName || ""}
                            disabled={this.props.disabled}
                            readOnly={true}
                        />

                        {!this.props.disabled &&
                            <Button color="primary" >
                                <SearchIcon />
                            </Button>
                        }
                    </InputGroup>
                    {hasError &&
                        <FormFeedback>{this.props.errorMessage}</FormFeedback>
                    }
                </FormGroup>
                {/*
                <TextFieldWithHelp
                    label={this.props.label}
                    disabled={this.props.disabled}
                    readOnly={true}
                    value={this.props.itemName || ""}
                    onClick={() => this._textFieldClicked()}
                    required={this.props.required}
                    iconProps={{ iconName: "Search" }}
                    className="pointer"
                    errorMessage={this.props.errorMessage}
                    helpText={this.props.helpText}
                />
                */}

                {this.state.showSearchWindow &&
                    <Modal backdrop={true}
                        fade={true}
                        toggle={this._closeModal.bind(this)}
                        centered={true}
                        isOpen={true}
                        size={this.props.size || "lg"}
                    >

                        <ModalHeader close={<button className="btn-close" onClick={this._closeModal.bind(this)} />}>
                            {this.GetTitulo()}
                        </ModalHeader>

                        <ModalBody>

                            {this._renderSearchForm()}

                            {empty && <p className="mt-3">{I18n.Strings.noResults}</p>}

                            {!empty && <div className="mb-lg mt-3">


                                {searchResults != null && searchResults.items.length == 0 && <React.Fragment>
                                    <p>{I18n.Strings.noResults}</p>
                                </React.Fragment>}

                                {searchResults != null && searchResults.items.length != 0 && <React.Fragment>
                                    <table className="table table-striped table-hover table-sm">
                                        <thead>
                                            <tr>
                                                {this._showCheckboxes() && <th>
                                                    <Input
                                                        onChange={(evt) => {
                                                            var checked = evt.target.checked;
                                                            var newState = this._cloneStateForSetState();
                                                            newState.allChecked = checked;
                                                            if (checked) {
                                                                newState.checkedElements = searchResults.items.map((item) => item.id);
                                                            }
                                                            else {
                                                                newState.checkedElements = [];
                                                            }
                                                            this.setState(newState);
                                                        }}
                                                        type="checkbox"
                                                        style={{ padding: 0, }}
                                                        id={"allChecked"}
                                                        checked={this.state.allChecked || false}
                                                    />
                                                </th>}
                                                {columns.map((col, index) => {
                                                    return <th key={index}>
                                                        <a href="#"
                                                            onClick={() => {
                                                                this.setState(
                                                                    { searchParams: this._getSearchForColumnClicked(col.fieldName) },
                                                                    () => this._performSearch()
                                                                );
                                                            }}>
                                                            {col.title}
                                                            {this.renderSortIcon(col.fieldName)}
                                                        </a>
                                                    </th>
                                                })}

                                                {!this._showCheckboxes() && <th style={{ width: 50 }}></th>}

                                            </tr>
                                        </thead>
                                        <tbody>
                                            {searchResults.items.map((item, index) => {
                                                return <tr key={index}>
                                                    {this._showCheckboxes() && <td>
                                                        <Input
                                                            onChange={(evt) => {
                                                                var checked = evt.target.checked;
                                                                var newState = this._cloneStateForSetState();

                                                                if (checked) {
                                                                    var itemId = newState.checkedElements.find(x => x === item.id);
                                                                    if (itemId == null) {
                                                                        newState.checkedElements.push(item.id);
                                                                    }
                                                                }
                                                                else {
                                                                    newState.checkedElements = newState.checkedElements.filter(x => {
                                                                        return x != item.id;
                                                                    })
                                                                }
                                                                this.setState(newState);
                                                            }}
                                                            type="checkbox"
                                                            style={{ padding: 0, }}
                                                            id={"checkbox_" + index}
                                                            checked={this.state.checkedElements.find(x => x === item.id) != null}
                                                        />
                                                    </td>}
                                                    {columns.map((col, index) => {
                                                        return <td key={index}>
                                                            {col.renderField == null && <React.Fragment>item[col.FieldName]</React.Fragment>}
                                                            {col.renderField != null && <React.Fragment>{col.renderField(item)}</React.Fragment>}
                                                        </td>;
                                                    })}

                                                    {!this._showCheckboxes() && <td>
                                                        <Button size="sm" outline onClick={() => this.itemSelected(item, [])}>
                                                            {I18n.Strings.select}
                                                        </Button>
                                                    </td>}

                                                </tr>
                                            })}
                                        </tbody>
                                    </table>

                                </React.Fragment>}


                                <Pager
                                    paginatedList={this.state.searchResults}
                                    pageSize={this.state.searchParams.rowsPerPage}
                                    onClick={(pNum) => this._changePage(pNum)}
                                    onPageSizeChange={(newSize) => this._changePageSize(newSize)}
                                />
                            </div>


                            }
                        </ModalBody>
                        <ModalFooter>

                        </ModalFooter>
                    </Modal>
                }
            </React.Fragment>
        );
    }

    private itemSelected(item: TResultItem, checkedElements: number[]) {
        var newState = this._cloneStateForSetState();
        newState.searchResults = null;
        newState.showSearchWindow = false;
        newState.allChecked = false;
        newState.checkedElements = checkedElements;
        newState.searchParams = this.GetInitialSearchParams(this.props.searchParams);
        if (this._showCheckboxes()) {
            this.setState(newState,
                () => {
                    if (this.props.onSelected) {
                        this.props.onSelected(null, null, null, checkedElements);
                    }
                });
        }
        else {
            this.setState(newState,
                () => {
                    var selectedId: number;
                    var selectedName: string;

                    if (item == null) {
                        selectedId = null;
                        selectedName = "";
                    }
                    else {
                        selectedId = this.props.getIdFromItem ? this.props.getIdFromItem(item) : item.id;
                        if (this.props.getName2FromItem) {
                            selectedName = this.props.getNameFromItem(item) + ' ' + this.props.getName2FromItem(item) || "";
                        }
                        else {
                            selectedName = this.props.getNameFromItem(item) || "";
                        }
                    }

                    if (this.props.onSelected) {
                        this.props.onSelected(selectedId, selectedName, item);
                    }
                });
        }
    }

    /*
    private textSelected(text: string) {
        var newState = this._cloneStateForSetState();
        newState.searchResults = null;
        newState.showSearchWindow = false;
        newState.searchParams = this.GetInitialSearchParams(this.props.searchParams);
        this.setState(newState,
            () => {

                if (this.props.onTextSelected) {
                    this.props.onTextSelected(text);
                }
            });

    }
    */

    public shouldComponentUpdate(nextProps: TPickerProps) {
        return true;
    }


    private _renderSearchForm(): JSX.Element {

        return (
            <div className="ms-Grid searchForm">
                <form onSubmit={(evt) => this._searchOnEnter(evt)}>
                    <Row>
                        <Col>
                            {this.RenderSearchForm()}
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Button type="submit" onClick={() => this._performSearch()} >
                                {I18n.Strings.search}
                            </Button>


                            <Button type="button" onClick={() => this.itemSelected(null, [])} >
                                {I18n.Strings.clean}
                            </Button>

                            {this._showCheckboxes() && this.state.checkedElements.length > 0 && < Button onClick={() => this.itemSelected(null, this.state.checkedElements)} >
                                {I18n.Strings.select}
                            </Button>}
                        </Col>
                    </Row>
                </form>
            </div>
        );
    }



    protected _searchOnEnter(evt: React.FormEvent<HTMLFormElement>): boolean {

        evt.preventDefault();
        var newState = this._cloneStateForSetState();
        newState.searchParams.currentPage = 0;
        this.setState(newState, () => {
            this._performSearch();
        });

        return false;
    }

    private _changePage(pageNum: number) {
        var newState = this._cloneStateForSetState();
        newState.searchParams.currentPage = pageNum;
        this.setState(newState, () => {
            this._performSearch();
        });
    }

    private _changePageSize(newSize: number) {
        var newState = this._cloneStateForSetState();
        newState.searchParams.rowsPerPage = newSize;
        newState.searchParams.currentPage = 0;
        this.setState(newState,
            () => this._performSearch()
        );

    }

    private renderSortIcon(columnName: string) {
        if (columnName == this.state.searchParams.orderByColumn) {
            return this.state.searchParams.orderMode == SortDirection.Asc ? <UpIcon /> : <DownIcon />;
        }
    }

    /**
    * Realiza la busqueda usando el SearchModel y hace el binding de los datos
    */
    protected _performSearch() {


        this._createApiService().search(this.state.searchParams)
            .then((data) => {
                var newState = this._cloneStateForSetState();
                newState.showSearchWindow = true;
                newState.searchResults = data;
                this.setState(newState);
            })
            .catch((reason) => {
                AlertsService.showError(reason);
            });
    }

    /**
    * Se le llama cuando el usuario hace click en la caja de texto y se abre el modal de busqueda
    */
    private _textFieldClicked() {

        if (this.props.disabled) return;

        var newState = this._cloneStateForSetState();
        newState.showSearchWindow = true;
        newState.searchParams.currentPage = 0;
        this.setState(newState, () => {
            this._performSearch();
        });
    }

    /**
    * Cierra el modal
    */
    private _closeModal() {
        this.setState({ showSearchWindow: false } as IGenericPickerState<TSearchModel, TSearchResult>);
    }


    protected _getSearchForColumnClicked(fieldName: string): TSearchModel {

        var newSearch = { ... this.state.searchParams };

        if (newSearch.orderByColumn != fieldName) {
            newSearch.orderMode = SortDirection.Asc;
            newSearch.orderByColumn = fieldName;
        }
        else {
            newSearch.orderMode = newSearch.orderMode == SortDirection.Asc ? SortDirection.Desc : SortDirection.Asc;
        }

        return newSearch;

    }
}
