import * as React from "react";
import nameof from "ts-nameof.macro";

import { Row, Col, FormGroup, Label, Input, Button } from "reactstrap";
import { YesNoDropDown } from "../helpers/EnumDropDown";
import { CursoEscolarService } from "./CursoEscolarService";
import { I18n } from "../utils/I18n";
import { CursoEscolarListItem, CursoEscolarListItemPaginatedList, CursoEscolarSearch, YesNo } from "../models/dataModels";
import { IServiceForIndex } from "../utils/base/BaseServices";
import { IndexPageBase, TableColumn } from "../utils/base/IndexPageBase";
import { RouteHelper } from "../IntranetRouter";
import { withRouter } from "react-router-dom";




class IndexComp extends IndexPageBase<CursoEscolarSearch, CursoEscolarListItemPaginatedList, CursoEscolarListItem>
{

    protected _renderSearchForm(): React.ReactNode {
        return <React.Fragment>
            <Col xs="12" md="12" lg="4">
                <FormGroup>
                    <Label className="visually-hidden" for={"cursos" + nameof(this.state.searchParams.nombre)}>
                        {I18n.Strings.cursoEscolar.nombre}
                    </Label>
                    <Input
                        onChange={(evt) => {
                            var state = this._cloneStateForSetState();
                            state.searchParams.nombre = evt.target.value;
                            this.setState(state);
                        }}
                        placeholder={I18n.Strings.cursoEscolar.nombre + "..."}
                        type="text"
                        required={false}
                        maxLength={100}
                        className={"form-control"}
                        name={nameof(this.state.searchParams.nombre)}
                        id={"cursos" + nameof(this.state.searchParams.nombre)}
                        value={this.state.searchParams.nombre || ""}
                    />

                </FormGroup>
            </Col>
            <Col xs="4" md="3" lg="2">
                <FormGroup>
                    <YesNoDropDown
                        emptyText={I18n.Strings.cursoEscolar.activo + "..."}
                        label={I18n.Strings.cursoEscolar.activo}
                        id={"cursos" + nameof(this.state.searchParams.activo)}
                        value={this.state.searchParams.activo == null ? null : this.state.searchParams.activo ? YesNo.Yes.toString() : YesNo.No.toString()}
                        className={"form-control"}
                        required={true}
                        onChanged={(value: YesNo) => {
                            var newState = this._cloneStateForSetState();
                            newState.searchParams.activo = value == YesNo.Yes.toString() ? true : value == YesNo.No.toString() ? false : null;
                            this.setState(newState, () => this._performSearch());
                        }}
                    />

                </FormGroup>
            </Col>
            <Col xs="8" md="4" lg="3">
                <FormGroup>
                    <YesNoDropDown
                        emptyText={I18n.Strings.cursoEscolar.visibleAlumnos + "..."}
                        label={I18n.Strings.cursoEscolar.visibleAlumnos}
                        id={"cursos" + nameof(this.state.searchParams.visibleAlumnos)}
                        value={this.state.searchParams.visibleAlumnos == null ? null : this.state.searchParams.visibleAlumnos ? YesNo.Yes.toString() : YesNo.No.toString()}
                        className={"form-control"}
                        required={true}
                        onChanged={(value: YesNo) => {
                            var newState = this._cloneStateForSetState();
                            newState.searchParams.visibleAlumnos = value == YesNo.Yes.toString() ? true : value == YesNo.No.toString() ? false : null;
                            this.setState(newState, () => this._performSearch());
                        }}
                    />

                </FormGroup>
            </Col>
        </React.Fragment>;
    }

    protected _getApiService(): IServiceForIndex<CursoEscolarSearch, CursoEscolarListItemPaginatedList> {
        return new CursoEscolarService();
    }

    protected _getTableColumns(): TableColumn<CursoEscolarListItem>[] {
        return [
            {
                fieldName: nameof(this.state.searchResults.items[0].nombre),
                title: I18n.Strings.cursoEscolar.nombre,
                renderField: (item) => item.nombre
            },
            {
                fieldName: nameof(this.state.searchResults.items[0].activo),
                title: I18n.Strings.cursoEscolar.activo,
                renderField: (item) => I18n.Strings.formatBool(item.activo)
            },
            {
                fieldName: nameof(this.state.searchResults.items[0].visibleAlumnos),
                title: I18n.Strings.cursoEscolar.visibleAlumnos,
                renderField: (item) => I18n.Strings.formatBool(item.visibleAlumnos)
            },
            {
                fieldName: nameof(this.state.searchResults.items[0].contenidoAccesibleAlumnos),
                title: I18n.Strings.cursoEscolar.contenidoAccesibleAlumnos,
                renderField: (item) => I18n.Strings.formatBool(item.contenidoAccesibleAlumnos)
            },
            {
                fieldName: nameof(this.state.searchResults.items[0].syncroPorDefecto),
                title: I18n.Strings.cursoEscolar.syncroPorDefecto,
                renderField: (item) => I18n.Strings.formatBool(item.syncroPorDefecto)
            }
        ];
    }

    protected _getPageTitle(): string {
        return I18n.Strings.cursoEscolar.title;
    }
    protected _getPageDescription(): string {
        return "Aqui ves los cursos escolares del colegio";
    }

    
    protected _getEditionItemUrl(item: CursoEscolarListItem): string {
        return RouteHelper.editCursoEscolar(item.id);
    }

    protected _getNewItemUrl(): string {
        return RouteHelper.createCursoEscolar();
    }

}

export const Index = withRouter(IndexComp);