import { MiPerfilEditModel, ModelValidationResult } from "../models/dataModels";
import { ApiFetcher } from "../utils/ApiFetcher";
import { IServiceForEdit, IServiceForIndex } from "../utils/base/BaseServices";

export class MiPerfilService {
    getMyEditModel(): Promise<MiPerfilEditModel> {
        var apiFetcher = new ApiFetcher();
        var prom = apiFetcher.getByUrl<MiPerfilEditModel>(`intranet/miPerfil/getMyEditModel`);
        return prom;
    }

    validate(model: MiPerfilEditModel): Promise<ModelValidationResult> {
        var apiFetcher = new ApiFetcher();
        var prom = apiFetcher.post<MiPerfilEditModel, ModelValidationResult>(`intranet/miPerfil/validate`, model);
        return prom;
    }

    update(model: MiPerfilEditModel): Promise<MiPerfilEditModel> {
        var apiFetcher = new ApiFetcher();
        var prom = apiFetcher.put<MiPerfilEditModel, MiPerfilEditModel>(`intranet/miPerfil/update`, model);
        return prom;
    }
}