import React from "react";
import { Col, FormGroup, Input, Label } from "reactstrap";
import nameof from "ts-nameof.macro";
import { CursoEscolarDropDown } from "../cursoEscolar/CursoEscolarDropDown";
import { EditorialDropDown } from "../helpers/EnumDropDown";
import { RouteHelper } from "../IntranetRouter";
import { CursoEscolarListItem, Editorial, LicenciasContenidosListItem, LicenciasContenidosListItemPaginatedList, LicenciasContenidosSearch, TipoUsuario } from "../models/dataModels";
import { AlertsService } from "../utils/AlertsService";
import { IServiceForIndex } from "../utils/base/BaseServices";
import { IndexPageBase, TableColumn } from "../utils/base/IndexPageBase";
import { ClientContext } from "../utils/ClientContext";
import { I18n } from "../utils/I18n";
import { LicenciasColegioService } from "./LicenciasColegioService";



export interface MisLicenciasSearchEx extends LicenciasContenidosSearch {
    cursoEscolar: string;
}

export class MisLicencias extends IndexPageBase<MisLicenciasSearchEx, LicenciasContenidosListItemPaginatedList, LicenciasContenidosListItem>
{

    protected _getInitialSearchParams(): MisLicenciasSearchEx {

        var cursoEscolarActivo = ClientContext.Current.cursosEscolares.find(c => c.id == ClientContext.Current.cursoEscolarActivoId);

        return {
            ...{} as MisLicenciasSearchEx,
            usuarioId: ClientContext.Current.usuarioId,
            cursoEscolar: cursoEscolarActivo.nombre,
            cursoEscolarId: cursoEscolarActivo.id,
            orderByColumn: nameof(this.state.searchResults.items[0].contenido)
        }
    }

    protected _renderSearchForm(): React.ReactNode {
        return <React.Fragment>

            <Col xs="12" md="4" lg="3">

                <EditorialDropDown
                    emptyText={I18n.Strings.licenciasContenido.editorial + "..."}
                    label={I18n.Strings.licenciasContenido.editorial}
                    id={nameof(this.state.searchParams.editorial)}
                    value={this.state.searchParams.editorial}
                    required={false}
                    onChanged={(value: string) => {
                        var newState = this._cloneStateForSetState();
                        newState.searchParams.editorial = value == "" ? null : value as Editorial;
                        this.setState(newState);
                    }}
                />

            </Col>

            <Col xs="12" md="4" lg="3">
                <CursoEscolarDropDown
                    label={null}
                    emptyText={I18n.Strings.licenciasContenido.cursoEscolar}
                    id={nameof(this.state.searchParams.cursoEscolarId)}

                    selectedItemValue={this.state.searchParams.cursoEscolarId}
                    selectedItemText={this.state.searchParams.cursoEscolar}

                    onChanged={(id, nombre, data: CursoEscolarListItem) => {
                        var state = this._cloneStateForSetState();
                        state.searchParams.cursoEscolarId = id;
                        state.searchParams.cursoEscolar = nombre;
                        this.setState(state);
                    }}
                />
            </Col>

            <Col xs="12" md="6" lg="3">
                <FormGroup>
                    <Label className="visually-hidden" for={"usuarios" + nameof(this.state.searchParams.contenidoNombre)}>
                        {I18n.Strings.licenciasContenido.contenido}
                    </Label>
                    <Input
                        placeholder={I18n.Strings.licenciasContenido.contenido + "..."}
                        onChange={(evt) => {
                            var state = this._cloneStateForSetState();
                            state.searchParams.contenidoNombre = evt.target.value;
                            this.setState(state);
                        }}
                        type="text"
                        required={false}
                        maxLength={100}
                        className={"form-control"}
                        name={nameof(this.state.searchParams.contenidoNombre)}
                        id={"usuarios" + nameof(this.state.searchParams.contenidoNombre)}
                        value={this.state.searchParams.contenidoNombre || ""}
                    />

                </FormGroup>
            </Col>

        </React.Fragment>;
    }

    protected _getApiService(): IServiceForIndex<LicenciasContenidosSearch, LicenciasContenidosListItemPaginatedList> {
        return new LicenciasColegioService();
    }

    protected _getTableColumns(): TableColumn<LicenciasContenidosListItem>[] {

        var columnas = [
            {
                fieldName: nameof(this.state.searchResults.items[0].cursoEscolar),
                title: I18n.Strings.licenciasContenido.cursoEscolar,
                renderField: (item: LicenciasContenidosListItem) => item.cursoEscolar,
                colMdWidth: 2
            },
            {
                fieldName: nameof(this.state.searchResults.items[0].contenido),
                title: I18n.Strings.licenciasContenido.contenido,
                renderField: (item: LicenciasContenidosListItem) => {
                    var titulo = item.contenido;

                    if ((item.contenidoId || 0) == 0) {
                        titulo += " (Papel)";
                    }

                    return titulo;
                },
                colMdWidth: ClientContext.Current.tipoUsuario == TipoUsuario.Alumno ? 4 : 3
            },
            {
                fieldName: nameof(this.state.searchResults.items[0].clase),
                title: I18n.Strings.licenciasContenido.grupoClase,
                renderField: (item: LicenciasContenidosListItem) => item.clase,
                colMdWidth: 3
            },
            {
                fieldName: nameof(this.state.searchResults.items[0].credenciales),
                title: I18n.Strings.licenciasContenido.credenciales,
                renderField: (item: LicenciasContenidosListItem) => item.credenciales,
                colMdWidth: 2
            },
            {
                fieldName: nameof(this.state.searchResults.items[0].editorial),
                title: I18n.Strings.licenciasContenido.editorial,
                renderField: (item: LicenciasContenidosListItem) => {

                    return I18n.Strings.getEnumText(`Editorial.${item.editorial}`);
                },
                colMdWidth: 2
            }

        ];

        if (ClientContext.Current.tipoUsuario == TipoUsuario.Alumno) {
            columnas = columnas.filter(col => col.fieldName != nameof(this.state.searchResults.items[0].credenciales));
        }

        return columnas;
    }

    protected _getPageTitle(): string {
        return "Mis licencias"
    }
    protected _getPageDescription(): string {
        return "Aquí puedes ver todas las licencias que tienes para tu curso.";
    }

    protected hideGridButtonsOverride(item: LicenciasContenidosListItem) {
        if (item == null) return true;

        return (item.contenidoId || 0) == 0;
    }

    protected _getEditionItemUrl(item: LicenciasContenidosListItem): string {


        if (item.contenidoId == null) {
            //AlertsService.showAlertMessage("El contenido es en formato papel, acción no disponible");
            return null;
        }

        return RouteHelper.detailMyLicenses(item.id);
    }

    protected _getNewItemUrl(): string {
        return RouteHelper.createMyLicenses();
    }
}