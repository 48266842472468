import React from 'react'; // we need this to make JSX compile

import nameof from "ts-nameof.macro";

import { FormFeedback, Input, Label } from 'reactstrap';
import { Editorial, TipoAcceso } from '../models/dataModels';

import { ModelValidation } from '../utils/base/ModelValidation';
import { UserPassword } from '../utils/UserPassword';
import { I18n } from '../utils/I18n';


interface CredencialesEditProps {

    editorial: Editorial,
    tipoAcceso: TipoAcceso,

    value: string;
    name: string;
    required: boolean;
    disabled?: boolean;
    formValidation: ModelValidation;
    onChange: (json: string, user: string, pwd: string) => void;
}

export const CredencialesEdit = function (props: CredencialesEditProps) {

    if ((props.editorial == Editorial.Santillana && (props.tipoAcceso == TipoAcceso.Custom || props.tipoAcceso == TipoAcceso.SantillanaAV4))
        || props.editorial == Editorial.Milton || props.editorial == Editorial.Planeta) {
        return <UserPassword
            onChange={(json, user, password) => {
                props.onChange(json, user, password);
            }}
            name={props.name}
            value={props.value || ""}
            formValidation={props.formValidation}
            required={props.required}
            disabled={props.disabled || false}
        />;
    }

    return <React.Fragment>
        <Label for={"licencias" + nameof(this.state.editModel.credenciales)}>{I18n.Strings.licenciasContenido.credenciales}</Label>

        <Input
            onChange={(evt) => {
                props.onChange(evt.target.value, null, null);
            }}
            type="textarea"
            required={props.required}
            className={"form-control " + _errorClass(props.name)}
            name={props.name}
            id={"licencias" + props.name}
            disabled={props.disabled || false}
            value={props.value || ""}
        />

        {props.formValidation.getError(props.name) &&
            <FormFeedback>{props.formValidation.getError(props.name)}</FormFeedback>
        }
    </React.Fragment>;

    function _errorClass(fieldName: string): string {
        var error = props.formValidation.getError(fieldName);
        if (error) {
            return "is-invalid";
        }
        return null;
    }
}

