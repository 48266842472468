import * as React from "react";
import { Input } from "reactstrap";
import { DropDownBase, GenericSelectItem, IDropDownProps } from "../utils/base/DropDownBase";
import { ComunidadAutonomaService } from "./ComunidadAutonomaService";


export class ComunidadesAutonomasDropDown extends DropDownBase<IDropDownProps> {

    

    protected GetItems(): Promise<GenericSelectItem[]> {
        var service = new ComunidadAutonomaService();
        var itemsProm = service.getAll()
            .then((items) => {
                return items.map(center => {
                    return {
                        id: center.id,
                        name: center.nombre,
                    } as GenericSelectItem;
                });
            
        });

        return itemsProm;
    }

    protected shouldAddEmptyOption() {
        return true;
    }
};