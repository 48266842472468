import * as React from "react";
import { withRouter } from "react-router-dom";
import { Col, FormGroup, Label } from "reactstrap";
import nameof from "ts-nameof.macro";
import { ColegioUsuariosPicker } from "../colegioUsuarios/Picker";
import UserNameToolTip from "../colegioUsuarios/UserNameToolTip";
import { ClaseMateriaDropDown } from "../cursoEscolar/materia/ClaseMateriaDropDown";
import { NivelEducativoDropDown } from "../cursoEscolar/NivelEducativoDropDown";
import { RouteHelper } from "../IntranetRouter";
import { EntradaAuditoriaListItem, EntradaAuditoriaListItemPaginatedList, EntradaAuditoriaSearch, MatriculaListItem, MatriculaListItemPaginatedList, MatriculasSearch, SortDirection, TipoAuditoria } from "../models/dataModels";
import { IServiceForIndex } from "../utils/base/BaseServices";
import { IndexPageBase, TableColumn } from "../utils/base/IndexPageBase";
import { ClientContext } from "../utils/ClientContext";
import { I18n } from "../utils/I18n";
import { AuditoriasService } from "./AuditoriaService";




export interface EntradaAuditoriaSearchEx extends EntradaAuditoriaSearch {
    usuarioDatos: string;
}

class IndexComp extends IndexPageBase<EntradaAuditoriaSearchEx, EntradaAuditoriaListItemPaginatedList, EntradaAuditoriaListItem>
{
    protected _getInitialSearchParams(): EntradaAuditoriaSearchEx {
        var params = super._getInitialSearchParams();

        params.orderMode = SortDirection.Desc;
        return params;
    }

    protected hideGridButtonsOverride() {
        return true;
    }

    protected _renderSearchForm(): React.ReactNode {
        if (this.props.isEmbedded) {
            return null;
        }
        return <React.Fragment>
            <Col xs="12" md="6" lg="4">
                <ColegioUsuariosPicker
                    label={I18n.Strings.matriculas.nombreUsuario}
                    required={false}
                    itemId={this.state.searchParams.usuarioDatosId}
                    itemName={this.state.searchParams.usuarioDatos}
                    getIdFromItem={(item) => item.id}
                    getNameFromItem={(item) => `${item.nombre} ${item.apellidos}`}

                    errorMessage={null}
                    onSelected={(usuarioId, usuario, item) => {
                        var state = this._cloneStateForSetState();
                        state.searchParams.usuarioDatosId = usuarioId;
                        state.searchParams.usuarioDatos = usuario;

                        this.setState(state, () => this._performSearch());
                    }}
                />
            </Col>
            
        </React.Fragment>;
    }

    protected _getApiService(): IServiceForIndex<EntradaAuditoriaSearchEx, EntradaAuditoriaListItemPaginatedList> {
        return new AuditoriasService() as IServiceForIndex<EntradaAuditoriaSearchEx, EntradaAuditoriaListItemPaginatedList>;
    }


    protected _getTableColumns(): TableColumn<EntradaAuditoriaListItem>[] {
        
        let columns = [
            {
                fieldName: nameof(this.state.searchResults.items[0].fecha),
                title: I18n.Strings.entradasAuditoria.fecha,                
                renderField: (item) => {
                    return I18n.Strings.formatDateTime(item.fecha);
                }
            },
            {
                fieldName: nameof(this.state.searchResults.items[0].tipoAuditoria),
                title: I18n.Strings.entradasAuditoria.tipoAuditoria,
                renderField: (item) => {
                    return I18n.Strings.getEnumText(`TipoAuditoria.${TipoAuditoria[item.tipoAuditoria]}`)
                }
            },
            {
                fieldName: nameof(this.state.searchResults.items[0].descripcion),
                title: I18n.Strings.entradasAuditoria.descripcion,
                renderField: (item) => item.descripcion,
            },
            ,
            {
                fieldName: nameof(this.state.searchResults.items[0].usuarioDatos),
                title: I18n.Strings.entradasAuditoria.usuarioDatos,
                renderField: (item) => item.usuarioDatos,
            },
            {
                fieldName: nameof(this.state.searchResults.items[0].usuarioAccion),
                title: I18n.Strings.entradasAuditoria.usuarioAccion,
                renderField: (item) => item.usuarioAccion,
            }
        ] as TableColumn<EntradaAuditoriaListItem>[];

        if ((this.props.searchModel?.usuarioDatosId || 0) != 0) {
            columns = columns.filter(c => c.fieldName != nameof(this.state.searchResults.items[0].usuarioDatos));
        }

        return columns;
    }

    

    protected _getPageTitle(): string {
        return I18n.Strings.matriculas.title;
    }
    protected _getPageDescription(): string {
        return I18n.Strings.matriculas.description;
    }


    protected _getEditionItemUrl(item: EntradaAuditoriaListItem): string {
        return null;
    }

    protected _getNewItemUrl(): string {
        return null;
    }

}

export const Index = withRouter(IndexComp);