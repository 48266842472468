import { MiColegioEditModel, ModelValidationResult, SyncPedidosRequest, OdooSyncMasterDataMessage, OdooSyncPedidosMessage } from "../../models/dataModels";
import { ApiFetcher } from "../../utils/ApiFetcher";
import { IServiceForEdit, IServiceForIndex } from "../../utils/base/BaseServices";
import { ClientContext } from "../../utils/ClientContext";

export class MiPerfilColegioService {
    getMyEditModel(): Promise<MiColegioEditModel> {
        var apiFetcher = new ApiFetcher();
        var prom = apiFetcher.getByUrl<MiColegioEditModel>(`intranet/miPerfilColegio/getMyEditModel`);
        return prom;
    }

    validate(model: MiColegioEditModel): Promise<ModelValidationResult> {
        var apiFetcher = new ApiFetcher();
        var prom = apiFetcher.post<MiColegioEditModel, ModelValidationResult>(`intranet/miPerfilColegio/validate`, model);
        return prom;
    }

    update(model: MiColegioEditModel): Promise<MiColegioEditModel> {
        var apiFetcher = new ApiFetcher();
        var prom = apiFetcher.put<MiColegioEditModel, MiColegioEditModel>(`intranet/miPerfilColegio/update`, model);
        return prom;
    }

    syncEstructuraColegio(campannaId: number, todosColegios: boolean): Promise<boolean> {

        var url = `intranet/miPerfilColegio/syncEstructura/${campannaId}`;
        if (todosColegios) {
            url += "?todosColegios=true"
        }

        var apiFetcher = new ApiFetcher();
        var prom = apiFetcher.post<any, boolean>(url, null);
        ClientContext.Current.recargarNiveles = true;
        return prom;
    }

    syncPedidosColegio(fechaDesde: Date, numPedidos: string, pedidosIsbns: string, soloPedidosTienda: boolean): Promise<any> {
        var model = {
            fechaDesde: fechaDesde,
            numPedidos: numPedidos,
            isbns: pedidosIsbns,
            soloPedidosTienda: soloPedidosTienda
        } as SyncPedidosRequest;

        var apiFetcher = new ApiFetcher();
        var prom = apiFetcher.post<SyncPedidosRequest, any>(`intranet/miPerfilColegio/syncPedidos`, model);
        return prom;
    }

    syncPedidosEstructuraForAllCenters(): Promise<any> {
        var model = {};

        var apiFetcher = new ApiFetcher();
        var prom = apiFetcher.post<any, any>(`intranet/miPerfilColegio/syncPedidosEstructuraForAllCenters`, model);
        return prom;
    }

    syncOdooMasterData(sincronizarProductos: boolean, sincronizarDesde: Date): Promise<boolean> {


        var apiFetcher = new ApiFetcher();
        var prom = apiFetcher.post<OdooSyncMasterDataMessage, boolean>(`intranet/miPerfilColegio/syncOdooMasterData`,
            {
                sincronizarProductos: sincronizarProductos,
                sincronizarDesde: sincronizarDesde
            } as OdooSyncMasterDataMessage
        );
        return prom;

    }

    syncOdooPedidos(sincronizarDesde: Date): Promise<boolean> {

        var apiFetcher = new ApiFetcher();
        var prom = apiFetcher.post<OdooSyncPedidosMessage, boolean>(`intranet/miPerfilColegio/syncOdooPedidos`, {
            sincronizarDesde: sincronizarDesde
        } as OdooSyncPedidosMessage);
        return prom;
    }

    getServerIp(): Promise<string> {
        var apiFetcher = new ApiFetcher();
        var prom = apiFetcher.getByUrl<string>(`intranet/utils/getServerIp`);
        return prom;
    }

    fixCredenciales(): Promise<any> {

        var apiFetcher = new ApiFetcher();
        var prom = apiFetcher.post<any, any>(`intranet/utils/fixCredenciales`, null);
        return prom;
    }

    crearUsuarios(): Promise<any> {

        var apiFetcher = new ApiFetcher();
        var prom = apiFetcher.post<any, any>(`intranet/utils/CrearUsuariosPedidos`, null);
        return prom;
    }

    fixClasesPorDefecto(): Promise<any> {

        var apiFetcher = new ApiFetcher();
        var prom = apiFetcher.post<any, any>(`intranet/utils/fixClasesPorDefecto`, null);
        return prom;
    }
}