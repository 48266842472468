import * as React from "react";

import { BarChart, Bar, LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import { I18n } from "../utils/I18n";
import { StackedGraphBar } from "../models/dataModels"; 


interface AccesosGraphProps {
    data: any[];
    bars: StackedGraphBar[];
}

export class AccesosContenidoGraph extends React.Component<AccesosGraphProps, any> {
    formatXAxis = (tickItem) => {
        return I18n.Strings.formatDate(new Date(tickItem));
    }
    render() {
        return <div className={"grafico_accesos" }>
            <ResponsiveContainer className="mt-lg"  width="100%">
                <BarChart
                    data={this.props.data}

                >
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="dia" ticks={['']} />
                    <YAxis />
                    <Legend />
                    <Tooltip labelFormatter={t => t} />
                    {this.props.bars != null && this.props.bars.map((bar, index) => {
                        return <Bar width={100} key={index} stackId="a" dataKey={bar.name} name={bar.name} fill={bar.rgbColor} />
                    })}
                </BarChart>
            </ResponsiveContainer>
        </div>;
    }
};