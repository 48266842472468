import * as React from "react";
import { ModelValidationResult } from "../../models/dataModels";
import { ComponentBase } from "./ComponentBase";
import { GenericEditState } from "./GenericEditState";
import { IServiceForEdit } from "./BaseServices";
import { Button, Col, FormFeedback, Row } from "reactstrap";
import { I18n } from "../I18n";
import { Loading } from "./../Loading";
import { ModelValidation } from "./ModelValidation";

import { Prompt, RouteComponentProps } from "react-router";
import { cloneDeep, isEqual } from "lodash-es";
import { CancelIcon, SaveIcon } from "../Icons";
import { AlertsService } from "../AlertsService";
import { Location } from 'history';



interface EditProps extends RouteComponentProps<any> {
    viewMode?: boolean;
    goBackToListAfterSaving?: boolean;
}

export abstract class EditPageBase<EditModel>
    extends ComponentBase<EditProps & RouteComponentProps<any>, GenericEditState<EditModel>>
{

    protected abstract _setPageTitleForNew(): string;

    protected abstract _setPageTitleForExisting(editModel: EditModel): string;

    protected abstract _createApiService(): IServiceForEdit<EditModel>;

    protected abstract _generateForm(): JSX.Element;

    protected abstract _getEditUrl(id: number): string;

    protected abstract _getListUrl(): string;

    protected abstract _validateModelLocal(): ModelValidation;

    protected _extraButtons(): JSX.Element {
        return null;
    }

    /**
    * Crea el estado "inicial" para un nuevo elemento
    */
    protected abstract _createStateForNew(): Promise<GenericEditState<EditModel>>;

    protected updateBreadCrumb(model: EditModel, initialMount?: boolean) { }

    constructor(props: EditProps & RouteComponentProps<any>) {
        super(props);

        this.state = {
            ...this._createInitialState(props) as GenericEditState<EditModel>,
            showLoading: true,
            activeTab: props.match.params.tab
        };
    }

    protected _createInitialState(props): GenericEditState<EditModel> {
        return {} as GenericEditState<EditModel>;
    }


    public componentDidMount() {
        this._loadStateFromServer(this.props.match.params.id, (data) => this.updateBreadCrumb(data, true));
    }

    protected _loadStateFromServer(id?: number, callback?: (data: EditModel) => void) {

        if (id && id !== 0 && !isNaN(parseInt(id.toString()))) {

            const state = this._cloneStateForSetState();
            state.showLoading = true;
            state.activeTab = this.props.match.params.tab;

            this.setState(state, () => {
                var service = this._createApiService();
                service.getById(id)
                    .then((data) => {
                        
                        this._applyDataFromServerInState(data, null, () => {
                            if (callback != null) {
                                callback(data);
                            }
                        });
                    })
                    .catch((reason) => {
                        
                        this.setState({ showLoading: false }, () => AlertsService.showError(reason))
                    });
            });
        }
        else {
            this._createStateForNew()
                .then((state: GenericEditState<EditModel>) => {
                    state.headerTitle = this._setPageTitleForNew();
                    state.showLoading = false;
                    state.validationResult.reset();
                    state.originalEditModel = cloneDeep(state.editModel);
                    this.setState(state, () => {
                        if (callback != null) {
                            callback(state.editModel);
                        }
                    });
                })
                .catch((reason) => this.setState({ showLoading: false }, () => AlertsService.showError(reason)));

        }
    }

    static getDerivedStateFromProps(props: EditProps & RouteComponentProps<any>, state: GenericEditState<any>) {

        if (state != null && props.match.params.tab != null && props.match.params.tab != state.activeTab) {
            var newState = {
                activeTab: props.match.params.tab
            };
            return newState;
        }
        return null;
    }

    protected _applyDataFromServerInState(data: EditModel, userNotificationText?: string, callback?: () => void) {
        const newState = this._cloneStateForSetState();
        newState.editModel = data;
        newState.originalEditModel = cloneDeep(data);
        newState.showLoading = false;
        newState.headerTitle = this._setPageTitleForExisting(data);
        newState.validationResult = new ModelValidation();
        this.setState(newState, () => {
            if ((userNotificationText || "").length != 0) {
                AlertsService.showAlertMessage(userNotificationText, () => {
                    if (callback) {
                        callback();
                    }
                });
            }
            else {
                if (callback) {
                    callback();
                }
            }

        });
    }


    public render(): JSX.Element {

        if (!this.state) {
            return <Loading />;
        }

        if (!this.state || !this.state.editModel) {
            return <Loading />;
        }

        // Actualizo el rastro de migas...
        this.updateBreadCrumb(this.state.editModel);

        return (
            <div>
                <h1 dangerouslySetInnerHTML={{ __html: this.state.headerTitle }}></h1>

                {this.state.showLoading && <Loading />}

                <Prompt
                    when={!this._editModelIsEqual(this.state.editModel, this.state.originalEditModel)}
                    message={(location, action) => {

                        if (this._skipExitPrompt(location)) {
                            return true;
                        }

                        return "Perderá los cambios realizados, ¿está seguro?";
                    }}
                />

                {this._generateForm()}

                {this._paintBottomButtons()}
            </div >
        );

    }

    protected _editModelIsEqual(model: EditModel, oldModel: EditModel): boolean {
        return isEqual(model, oldModel);
    }

    protected _skipExitPrompt(location: Location) {
        return false;
    }

    protected _paintBottomButtons(): JSX.Element {
        return <Row className="accesos-directos">
            <Col xs="6" md="8" lg="8">

                {!this.props.viewMode &&
                    <Button id="save" color="primary" className="btn-rounded" onClick={() => this._validateAndSubmit()} >
                        <SaveIcon />
                        {I18n.Strings.save}
                    </Button>
                }

                {this._extraButtons()}
            </Col>
            <Col xs="6" md="4" lg="4" className="text-end">
                <Button id="cancel" color="secondary" className="btn-rounded text-end" onClick={() => this._onBackButtonClicked()}>
                    <CancelIcon />
                    {I18n.Strings.cancel}
                </Button>
            </Col>
        </Row>;
    }

    protected _validateAndSubmit(): void {
        // Validamos...
        this._validateState()
            .then(validationResultTemp => {

                if (validationResultTemp == null) {
                    AlertsService.showErrorMessage("error validation");
                    return false;
                }
                var validationResult = new ModelValidation();
                validationResult.load(validationResultTemp);

                if (validationResult.isOk) {
                    console.info("La validación es correcta");

                    var state = this._cloneStateForSetState();
                    state.showLoading = true;
                    state.validationResult = validationResult;
                    this.setState(state, () => {

                        var isNew = (this.state.editModel as any).id == 0;

                        this._saveStateInServer()
                            .then((data) => {
                                if (isNew) {
                                    this._actionWhenNewIsCreated(data);
                                }
                                else {
                                    this._actionWhenIsUpdated(data);
                                }

                                AlertsService.showSuccessMessage(I18n.Strings.savedOk);
                                return;
                            })
                            .catch((reason) => this.setState({ showLoading: false }, () => AlertsService.showError(reason)));
                    });
                }
                else {
                    console.log("Validación no correcta");
                    console.warn(validationResult);
                    this._processValidationError(validationResult);
                }
            })
            .catch((reason) => this.setState({ showLoading: false }, () => AlertsService.showError(reason)));
    }

    protected _validateState(): Promise<ModelValidation> {

        var validationProm = Promise.resolve<ModelValidation>(this._validateModelLocal())
            .then(localValidationResult => {
                if (localValidationResult.isOk) {
                    var service = this._createApiService();

                    // Si en local ha ido bien, probamos en remoto, si no, no lo intentamos
                    return service.validate(this.state.editModel)
                        .then(serverValResult => {
                            var validation = new ModelValidation();
                            validation.load(serverValResult);
                            return validation;
                        });
                }
                else {
                    return localValidationResult;
                }
            })
            .catch((reason) => {
                AlertsService.showError(reason);
                return reason;
            });

        return validationProm;
    }


    protected _actionWhenNewIsCreated(data: any): void {
        this._actionWhenIsUpdated(data);
    }

    protected _actionWhenIsUpdated(data: any): void {
        if (this.props.goBackToListAfterSaving) {
            this.setState({ originalEditModel: cloneDeep(this.state.editModel) },
                () => {
                    this.props.history.push(this._getListUrl());
                    AlertsService.showSuccessMessage(I18n.Strings.success);
                });
        }
        else if (data != null && data !== "") {
            // Cambio la ruta a la edición (como es el mismo componente no hace nada)

            this._applyDataFromServerInState(data, I18n.Strings.success,
                () => {
                    this.props.history.push(this._getEditUrl(data.id));
                });
        }
        else {
            this.setState({ showLoading: false }, () => AlertsService.showSuccessMessage(I18n.Strings.success));
        }
    }

    protected _onBackButtonClicked() {

        this.props.history.push(this._getListUrl());
    }

    /**
     * Se llama en local para validar el modelo de edicion antes de mandarlo al servidor para su validación
     */



    protected _saveStateInServer(): Promise<any> {

        var apiService = this._createApiService();
        var itemId = (this.state.editModel as any).id;
        if (itemId === 0) {
            let promise = apiService.create(this.state.editModel);
            return promise;
        }
        else {
            let promise = apiService.update(itemId, this.state.editModel);
            return promise;
        }
    }

    /**
     *
     * @param validation Muestra el error de validacion
     */
    protected _processValidationError(validation: ModelValidation): void {
        var newState = this._cloneStateForSetState();
        newState.validationResult = validation;
        this.setState(newState);
    }


    protected _errorMessage(fieldName: string): JSX.Element {
        var error = this.state.validationResult.getError(fieldName);
        if (error) {
            return <FormFeedback>{error}</FormFeedback>;
        }
        return null;
    }

    protected _errorClass(fieldName: string): string {
        var error = this.state.validationResult.getError(fieldName);
        if (error) {
            return "is-invalid";
        }
        return null;
    }

}




