import React, { useEffect, useState } from 'react'; // we need this to make JSX compile
import { Button, Col, FormFeedback, FormGroup, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader, Row } from 'reactstrap';
import { InfoConexion } from '../models/dataModels';
import { ModelValidation } from "../utils/base/ModelValidation";
import { I18n } from '../utils/I18n';
import { ColegioCredencialesEditorialesService } from './ColegioCredencialesEditorialesService';

interface Lti13ConfigCentroProps {
    value: string;
    required: boolean;

    formValidation: ModelValidation;
    onChange: (json: string) => void;
}

class Lti13Creds {
    LoginUrl: string;
    LaunchUrl: string;
    DeepLinkingUrl: string;
    MostrarCredencialAlAcceder: boolean;
}
export const Lti13ConfigCentro = function (props: Lti13ConfigCentroProps) {

    var [creds, setCreds] = useState<Lti13Creds>({} as Lti13Creds);
    var [showInfo, setShowInfo] = useState<InfoConexion>(null);

    useEffect(() => {
        try {
            var valueObj = JSON.parse(props.value) as Lti13Creds;
            setCreds(valueObj);
        } catch { }
    }, []);


    useEffect(() => {
        props.onChange(JSON.stringify(creds));
    }, [creds]);


    const url_creds = "loginUrlCreds"
    const consumerKey_creds = "consumerKeyCreds"
    const sharedSecret_creds = "sharedSecretCreds"

    var errorUrl = props.formValidation.getError(url_creds) || "";
    var errorConsumerKey = props.formValidation.getError(consumerKey_creds) || "";
    var errorSharedSecret = props.formValidation.getError(sharedSecret_creds) || "";


    return <React.Fragment>
        <Col md="12" lg="4">
            <FormGroup>
                <Label for={"url"}>URL</Label>
                <Input
                    onChange={(evt) => {
                        setCreds({ ...creds, LoginUrl: evt.currentTarget.value });
                    }}
                    type="text"
                    required={true}
                    maxLength={100}
                    className={"form-control " + (errorUrl.length == 0 ? "" : "is-invalid")}
                    name={"url"}
                    id={"url"}
                    value={creds.LoginUrl || ""}
                />

                {(errorUrl || "").length != 0 && <FormFeedback>
                    {errorUrl}
                </FormFeedback>}

            </FormGroup>
        </Col>
        <Col md="12" lg="4">
            <FormGroup>
                <Label for={"launchUrl"}>Launch Url</Label>
                <Input
                    onChange={(evt) => {
                        setCreds({ ...creds, LaunchUrl: evt.currentTarget.value });
                    }}
                    type="text"
                    required={true}
                    maxLength={100}
                    className={"form-control " + (errorConsumerKey.length == 0 ? "" : "is-invalid")}
                    name={"launchUrl"}
                    id={"launchUrl"}
                    value={creds.LaunchUrl || ""}
                />

                {(errorConsumerKey || "").length != 0 && <FormFeedback>
                    {errorConsumerKey}
                </FormFeedback>}

            </FormGroup>
        </Col>
        <Col md="12" lg="4">
            <FormGroup>
                <Label for={"deepLinking"}> DeepLinking Url </Label>
                <Input
                    onChange={(evt) => {
                        setCreds({ ...creds, DeepLinkingUrl: evt.currentTarget.value });
                    }}
                    type="text"
                    required={true}
                    maxLength={100}
                    className={"form-control " + (errorSharedSecret.length == 0 ? "" : "is-invalid")}
                    name={"deepLinking"}
                    id={"deepLinking"}
                    value={creds.DeepLinkingUrl || ""}
                />

                {(errorSharedSecret || "").length != 0 && <FormFeedback>
                    {errorSharedSecret}
                </FormFeedback>}

            </FormGroup>
        </Col>

        <Col md="12" lg="4">
            <FormGroup>
                <Label for={errorSharedSecret}> Mostrar Credencial Al Acceder </Label>
                <Input
                    onChange={(evt) => {
                        setCreds({ ...creds, MostrarCredencialAlAcceder: evt.currentTarget.checked });
                    }}
                    type="checkbox"
                    required={true}
                    maxLength={100}
                    className={"form-control"}
                    name={errorSharedSecret}
                    id={errorSharedSecret}
                    checked={creds.MostrarCredencialAlAcceder || false}
                />

            </FormGroup>
        </Col>
        <Col md="12" lg="4">
            <Button color="primary"
                onClick={() => {
                    var srv = new ColegioCredencialesEditorialesService();
                    srv.getLti13ConfigCentro()
                        .then((data) => {
                            setShowInfo(data);
                        });
                }} >
                Ver datos de conexión
            </Button>
        </Col>
        {showInfo && <Modal
            title={"Info conexión LTI 1.3"}
            size="lg"
            isOpen={true}
            toggle={() => setShowInfo(null)}
        >

            <ModalHeader close={<button className="btn-close" onClick={() => setShowInfo(null)} />}>
                Info conexión LTI 1.3
            </ModalHeader>

            <ModalBody>
                <Row>
                    <Col>
                        <b>Platform</b>: Scholarum Hub<br />
                        <b>Client ID</b>: {showInfo.clientId}<br />
                        <b>Deployment ID</b>: {showInfo.clientId}<br />
                        <b>Issuer</b>: {showInfo.issuer}<br />
                        <b>Authorization URL</b>: {showInfo.authorizationUrl}<br />
                        <b>Token URL</b>: {showInfo.accessTokenUrl}<br />
                        <b>JWK Set Url</b>: {showInfo.jwkSetUrl}<br />
                    </Col>
                </Row>
            </ModalBody>
            <ModalFooter>
                <Button color="secondary" onClick={() => setShowInfo(null)}>
                    {I18n.Strings.cancel}
                </Button>
            </ModalFooter>
        </Modal>
        }
    </React.Fragment >;
}
