import React from "react";
import { ApiFetcher } from "../utils/ApiFetcher";

import { GetLicenciasContenidosResponse, LicenciaContenidosItem } from "../models/dataModels";
import { Loading } from "../utils/Loading";
import { AlertsService } from "../utils/AlertsService";
import { ClientContext } from "../utils/ClientContext";
import { Collapse } from "reactstrap";
import { DownIcon, UpIcon } from "../utils/Icons";
import { I18n } from "../utils/I18n";

interface ContenidoState {
    loading: boolean;
    items: LicenciaContenidosItem[];
    toggle: boolean;
}

export class ComprasPosibles extends React.Component<any, ContenidoState> {

    public constructor(props: any) {
        super(props);
        this.state = {
            loading: true,
            toggle: true,
            items: []
        };
    }

    componentDidMount() {
        this.readComprasPosibles();
    }

    render() {

        return <React.Fragment>

            <div className="sub-seccion" style={{ cursor: "pointer" }} onClick={() => {
                this.setState({ toggle: !this.state.toggle });
            }}>
                <h3>Licencias de mi curso no compradas &nbsp;
                    {this.state.toggle && <DownIcon />}
                    {!this.state.toggle && <UpIcon />}
                </h3>
            </div>

            {this.state.loading && <Loading />}

            {!this.state.loading && <Collapse isOpen={this.state.toggle}>

                <div className="licencias-comprar">

                    {this.state.items.length == 0 && <React.Fragment>
                        No hay licencias pendientes asociadas al curso del usuario.
                    </React.Fragment>}

                    {this.state.items.length != 0 && <React.Fragment>
                        <div className="d-flex fichas">
                            {this.state.items.map((item, index) => {
                                return <div key={index} className="ficha-cont">
                                    <div className="row align-items-start">
                                        <div className="col-3">
                                            {(item.caratula || "").length !== 0 &&
                                                <div className="imagen-cont">
                                                    <img src={item.caratula} className="img-fluid" alt={item.nombre} />
                                                </div>
                                            }
                                        </div>
                                        <div className="col-9">
                                            <div className="tit-cont">
                                                {item.nombre}
                                            </div>
                                            <div className="autor-cont">
                                                {I18n.Strings.getEnumText(`Editorial.${item.editorial}`)}
                                                <br />
                                                {item.clase}
                                            </div>
                                            {/* <button type="button" className="btn btn-primary btn-sm btn-comprar">Comprar</button> */}
                                        </div>
                                    </div>
                                </div>;
                            })}

                        </div>
                    </React.Fragment>}
                </div>
            </Collapse>
            }


        </React.Fragment>
    }


    private readComprasPosibles() {
        if (ClientContext.Current.cursoEscolarActivoId == null) {
            return;
        }
        var apiFetcher = new ApiFetcher();
        var prom = apiFetcher.getByUrl<GetLicenciasContenidosResponse>(
            `intranet/licencias/comprasPosibles/${ClientContext.Current.cursoEscolarActivoId}`
        );

        prom.then((result: GetLicenciasContenidosResponse) => {
            this.setState({
                items: result.contenidos,
                loading: false
            });
        })
            .catch((error) => this.setState({}, () => AlertsService.showError(error)));

    }

}