import nameof from "ts-nameof.macro";
import { MatriculaListItem, SortDirection } from "../models/dataModels";
import { DropDownBase, GenericSelectItem, IDropDownProps } from "../utils/base/DropDownBase";
import { ClientContext } from "../utils/ClientContext";
import { MatriculasService } from "./MatriculasService";


interface MatriculasUsuarioDropDownProps extends IDropDownProps {
    usuarioId?: number;
    claseId?: number;
    cursoEscolarId: number;
}

export class MatriculasUsuarioDropDown extends DropDownBase<MatriculasUsuarioDropDownProps> {



    protected GetItems(): Promise<GenericSelectItem[]> {

        var item = {} as MatriculaListItem;

        var pertenenciaGruposSrv = new MatriculasService();
        var dataProm = pertenenciaGruposSrv.search({
            colegioId: ClientContext.Current.colegioId,
            claseId: this.props.claseId,
            claseMateriaId: null,
            cursoEscolarId: this.props.cursoEscolarId,
            usuarioId: this.props.usuarioId,
            noTotalCount: true,
            currentPage: 0,
            rowsPerPage: -1,
            orderByColumn: this.props.usuarioId != null ? nameof(item.clase) : nameof(item.usuario),
            orderMode: SortDirection.Asc,
            bypassAutomaticSort: false,
            currentPageRows: -1
        });

        var resultProm = dataProm.then(data => {
            
            var items = data.items.map((item, index) => {
                return {
                    id: item.id,
                    name: `${item.usuario} - ${item.clase} - ${item.materia}`,
                    data: item
                }
            });

            return items.sort((a, b) => a.name.localeCompare(b.name));
        });

        return Promise.resolve(resultProm);
    }

    protected shouldAddEmptyOption() {
        return true;
    }
};