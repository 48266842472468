import * as React from "react";

import nameof from "ts-nameof.macro";

import { withRouter } from "react-router-dom";
import { AliasEditorialEditModel, Editorial } from "../models/dataModels";
import { GenericEditState } from "../utils/base/GenericEditState";
import { EditPageBase } from "../utils/base/EditPageBase";
import { IServiceForEdit } from "../utils/base/BaseServices";
import { AliasEditorialService } from "./AliasEditorialesService";
import { RouteHelper } from "../IntranetRouter";
import { Col, FormGroup, Input, Label, Nav, NavItem, NavLink, Row, TabContent, TabPane } from "reactstrap";
import { I18n } from "../utils/I18n";
import { ModelValidation } from "../utils/base/ModelValidation";


import { EditorialDropDown } from "../helpers/EnumDropDown";

import { Location as HistoryLocation } from "history";
import { NavigationHelper } from "../utils/base/Breadcrumb";
import { AliasEditorialesContenidos } from "./AliasEditorialesContenidos";

export enum AliasEditorialEditTabs {
    Datos = "Datos",
    Usos = "Usos"
}

export class EditComp extends EditPageBase<AliasEditorialEditModel> {


    protected updateBreadCrumb(model: AliasEditorialEditModel) {
        NavigationHelper.setBreadcrumbItems([
            {
                text: I18n.Strings.aliasEditorial.title,
                link: RouteHelper.listAliasEditoriales()
            },
            {
                text: model.alias,
                link: ""
            }
        ]);
    }

    protected _setPageTitleForNew(): string {
        return "Nuevo"
    }

    protected _setPageTitleForExisting(editModel: AliasEditorialEditModel): string {
        return `Editar ${editModel.alias}`;
    }

    protected _createApiService(): IServiceForEdit<AliasEditorialEditModel> {
        return new AliasEditorialService();
    }

    protected _generateForm(): JSX.Element {

        if (this.state.editModel.id == 0) {
            return this._showForm();
        }

        return <React.Fragment>
            <Nav tabs>
                <NavItem>
                    <NavLink
                        active={(this.state.activeTab || AliasEditorialEditTabs.Datos) == AliasEditorialEditTabs.Datos}
                        onClick={() => this.props.history.push(RouteHelper.editAliasEditorial(this.state.editModel.id, AliasEditorialEditTabs.Datos))}
                    >
                        {I18n.Strings.aliasEditorial.tabDatos}
                    </NavLink>
                </NavItem>
                <NavItem>
                    <NavLink
                        active={(this.state.activeTab || AliasEditorialEditTabs.Usos) == AliasEditorialEditTabs.Usos}
                        onClick={() => this.props.history.push(RouteHelper.editAliasEditorial(this.state.editModel.id, AliasEditorialEditTabs.Usos))}
                    >
                        {I18n.Strings.aliasEditorial.tabUsos}
                    </NavLink>
                </NavItem>
            </Nav>

            <TabContent activeTab={this.state.activeTab || AliasEditorialEditTabs.Datos}>
                <TabPane tabId={AliasEditorialEditTabs.Datos}>
                    {this._showForm()}
                </TabPane>
                <TabPane tabId={AliasEditorialEditTabs.Usos}>
                    <AliasEditorialesContenidos
                        isEmbedded={true}
                        isViewMode={true}
                        showTitle={false}
                        hideGridButtons={true}
                        searchModel={
                            {
                                ...{} as any,
                                aliasEditorialId: this.state.editModel.id
                            }}
                    />
                </TabPane>
            </TabContent>
        </React.Fragment>;
    }

    protected _showForm() : JSX.Element {
        return <Row>
            <Col md="4">
                <FormGroup>
                    <Label for={"alias" + nameof(this.state.editModel.alias)}>{I18n.Strings.aliasEditorial.alias}</Label>
                    <Input
                        onChange={(evt) => {
                            var newState = this._cloneStateForSetState();
                            newState.editModel.alias = evt.target.value;
                            this.setState(newState);
                        }}
                        type="text"
                        required={true}
                        className={"form-control"}
                        name={nameof(this.state.editModel.alias)}
                        value={this.state.editModel.alias}
                        id={"alias" + nameof(this.state.editModel.alias)}
                    />
                    {this._errorMessage(nameof(this.state.editModel.alias))}
                </FormGroup>
            </Col>
            <Col md="4">

                <EditorialDropDown
                    showLabel={true}
                    label={I18n.Strings.aliasEditorial.proveedor}
                    id={nameof(this.state.editModel.editorial)}
                    value={this.state.editModel.editorial}
                    errorMessage={this.state.validationResult.getError(nameof(this.state.editModel.editorial))}
                    required={true}
                    onChanged={(value: Editorial) => {
                        var newState = this._cloneStateForSetState();
                        newState.editModel.editorial = value;
                        this.setState(newState);
                    }}
                />
                {this._errorMessage(nameof(this.state.editModel.editorial))}
            </Col>
        </Row>;
    }

    protected _skipExitPrompt(location: HistoryLocation): boolean {
        if (location.pathname == RouteHelper.editAliasEditorial(this.state.editModel.id, AliasEditorialEditTabs.Datos)) {
            return true;
        }
        return super._skipExitPrompt(location);
    }

    protected _getEditUrl(id: number): string {
        return RouteHelper.editAliasEditorial(id, AliasEditorialEditTabs.Datos);
    }

    protected _getListUrl(): string {
        return RouteHelper.listAliasEditoriales();
    }

    protected _createStateForNew(): Promise<GenericEditState<AliasEditorialEditModel>> {

        var model = new GenericEditState<AliasEditorialEditModel>();
        model.editModel = {
            id: 0,
            editorial: Editorial.None,
            alias: "",
        } as AliasEditorialEditModel;

        return Promise.resolve(model);
    }

    protected _validateModelLocal(): ModelValidation {
        let validation = new ModelValidation();

        if ((this.state.editModel.editorial || 0) == null) {
            validation.addError(nameof(this.state.editModel.editorial), I18n.Strings.validationErrors.valueRequired(I18n.Strings.aliasEditorial.editorial));
        }

        if ((this.state.editModel.alias || "").length === 0) {
            validation.addError(nameof(this.state.editModel.alias), I18n.Strings.validationErrors.valueRequired(I18n.Strings.aliasEditorial.alias));
        }

        return validation;
    }

}

export const Edit = withRouter(EditComp)