import {
    AlumnosDeClaseSearch, AlumnoListItem, AlumnoListItemPaginatedList, ModelValidationResult, ColegioUsuarioEditModel
} from "../../models/dataModels";
import { ApiFetcher } from "../../utils/ApiFetcher";
import { IServiceForEdit, IServiceForIndex } from "../../utils/base/BaseServices";

export class AlumnosService
    implements IServiceForIndex<AlumnosDeClaseSearch, AlumnoListItemPaginatedList>, IServiceForEdit<ColegioUsuarioEditModel>
{
    
    search(searchData: AlumnosDeClaseSearch): Promise<AlumnoListItemPaginatedList> {

        var apiFetcher = new ApiFetcher();
        var prom = apiFetcher.post<AlumnosDeClaseSearch, AlumnoListItemPaginatedList>("intranet/miPerfil/searchStudents", searchData);
        prom.then(res => {
            res.items.forEach(item => {
                item.fechaUltimoAcceso = item.fechaUltimoAcceso == null ? null : new Date(item.fechaUltimoAcceso);
            });
        });
        return prom;
    }

    delete(id: number) : Promise<any> {
        throw new Error("Method not implemented.");
    }

    getById(id: string | number): Promise<ColegioUsuarioEditModel> {
        throw new Error("Method not implemented.");
    }
    validate(model: ColegioUsuarioEditModel): Promise<ModelValidationResult> {
        throw new Error("Method not implemented.");
    }

    create(model: ColegioUsuarioEditModel): Promise<ColegioUsuarioEditModel> {
        throw new Error("Method not implemented.");
    }

    update(id: string | number, model: ColegioUsuarioEditModel): Promise<ColegioUsuarioEditModel> {
        throw new Error("Method not implemented.");
    }

    exportExcel(search: AlumnosDeClaseSearch): Promise<any> {
        throw new Error("Method not implemented.");
    }
}