import React from "react";
import { AccesoDirectoHomeItem } from "../models/dataModels";
import { AlertsService } from "../utils/AlertsService";
import { ApiFetcher } from "../utils/ApiFetcher";
import AccesoDirectoEnlace from "./AccesoDirectoEnlace";



interface AccesosDirectosState {
    items: AccesoDirectoHomeItem[];
}

export class AccesosDirectosHome extends React.Component<any, AccesosDirectosState> {

    public constructor(props: any) {
        super(props);
        this.state = {
            items: []
        };
    }

    componentDidMount() {
        this.readAccesosDirectos();
    }

    render() {
        if ((this.state.items || []).length == 0) {
            return null;
        }

        return <React.Fragment>
            {this.state.items.map((item, index) => {
                return <div className="google-classroom ms-1" key={index} >
                    <AccesoDirectoEnlace accesoDirecto={item} />
                </div>;
            })}

        </React.Fragment>
    }


    private readAccesosDirectos() {

        var apiFetcher = new ApiFetcher();
        var prom = apiFetcher.getByUrl<AccesoDirectoHomeItem[]>(`intranet/colegio/accesosDirectos`);

        prom.then((accesosDirectos: AccesoDirectoHomeItem[]) => {
            this.setState({ items: accesosDirectos });
        })
            .catch((error: Error) => () => AlertsService.showError(error));

    }

}