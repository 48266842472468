import * as React from "react";
import nameof from "ts-nameof.macro";

import { Row, Col, FormGroup, Label, Input, Button, ModalHeader, Modal, ModalBody, ModalFooter, FormFeedback } from "reactstrap";

import { ContenidosPapelColegioService } from "./ContenidosPapelColegioService";
import { I18n } from "../../utils/I18n";
import { Editorial, ContenidosDigitalesColegioListItem, ContenidosDigitalesColegioListItemPaginatedList,  TipoUsuario, AccedePlataforma, TipoAcceso, YesNo, ContenidosDigitalesColegioSearch, ContenidosPapelColegioSearch, ContenidosPapelColegioListItem, ContenidosPapelColegioListItemPaginatedList } from "../../models/dataModels";
import { IServiceForIndex } from "../../utils/base/BaseServices";
import { IndexPageBase, TableColumn } from "../../utils/base/IndexPageBase";
import { RouteHelper } from "../../IntranetRouter";
import { AccedePlataformaDropDown, EditorialDropDown, TodosTipoAccesoDropDown, YesNoDropDown } from "../../helpers/EnumDropDown";
import { SyncIcon, SaveIcon, CheckIcon, ViewIcon } from "../../utils/Icons";
import { AlertsService } from "../../utils/AlertsService";
import { CursoEscolarDropDown } from "../../cursoEscolar/CursoEscolarDropDown";
import { ClientContext } from "../../utils/ClientContext";
import { NivelEducativoDropDown } from "../../cursoEscolar/NivelEducativoDropDown";
import { ModelValidation } from "../../utils/base/ModelValidation";


interface ContenidosPapelSearchEx extends ContenidosPapelColegioSearch {
    cursoEscolar: string;
    nivelEducativo: string;
    
}

export class IndexContenidoPapelColegio extends IndexPageBase<ContenidosPapelSearchEx, ContenidosPapelColegioListItemPaginatedList, ContenidosPapelColegioListItem>
{
    _getInitialSearchParams(): ContenidosPapelSearchEx {


        var cursoEscolar = ClientContext.getCursoEscolarActivo();

        var searchParams = {
            ... {} as ContenidosPapelColegioSearch,
            nivelEducativo: null,
            showOnlySchoolContents: true,            
            cursoEscolarId: cursoEscolar.id,
            cursoEscolar: cursoEscolar.nombre,
            showAccessData: true,
            cambiarTipoAccesoValidationResult: new ModelValidation()
        } as ContenidosPapelSearchEx;

        return searchParams;
    }


    protected _renderSearchForm(): React.ReactNode {
        return <React.Fragment>

            <Col xs="12" md="6" lg="6">
                <FormGroup>
                    <Label className="visually-hidden" for={"usuarios" + nameof(this.state.searchParams.nombre)}>
                        {I18n.Strings.licenciasContenido.contenidoBuscador}
                    </Label>
                    <Input
                        placeholder={I18n.Strings.licenciasContenido.contenidoBuscador + "..."}
                        onChange={(evt) => {
                            var state = this._cloneStateForSetState();
                            state.searchParams.nombre = evt.target.value;
                            this.setState(state);
                        }}
                        type="text"
                        maxLength={100}
                        className={"form-control"}
                        name={nameof(this.state.searchParams.nombre)}
                        id={"usuarios" + nameof(this.state.searchParams.nombre)}
                        value={this.state.searchParams.nombre || ""}
                    />

                </FormGroup>
            </Col>


            <Col xs="12" md="4" lg="3">

                <EditorialDropDown
                    emptyText={I18n.Strings.licenciasContenido.editorial + "..."}
                    label={I18n.Strings.licenciasContenido.editorial}
                    id={nameof(this.state.searchParams.editorial)}
                    required={false}
                    value={this.state.searchParams.editorial}
                    onChanged={(value: string) => {
                        var newState = this._cloneStateForSetState();
                        newState.searchParams.editorial = value == '' ? null : value as Editorial;
                        this.setState(newState, () => this._performSearch());
                    }}
                />

            </Col>
            {/*
            <Col xs="12" md="6" lg="3">
                <FormGroup>
                    <Label className="visually-hidden" for={"usuarios" + nameof(this.state.searchParams.isbn)}>
                        {I18n.Strings.licenciasContenido.isbn}
                    </Label>
                    <Input
                        placeholder={I18n.Strings.licenciasContenido.isbn + "..."}
                        onChange={(evt) => {
                            var state = this._cloneStateForSetState();
                            state.searchParams.isbn = evt.target.value;
                            this.setState(state);
                        }}
                        type="text"
                        required={true}
                        maxLength={100}
                        className={"form-control"}
                        name={nameof(this.state.searchParams.isbn)}
                        id={"usuarios" + nameof(this.state.searchParams.isbn)}
                        value={this.state.searchParams.isbn || ""}
                    />

                </FormGroup>
            </Col>
            */}
            <Col xs="12" md="4" lg="3">

                <CursoEscolarDropDown
                    label={null}
                    emptyText={I18n.Strings.licenciasContenido.cursoEscolar}
                    id={nameof(this.state.searchParams.cursoEscolarId)}

                    selectedItemValue={this.state.searchParams.cursoEscolarId}
                    selectedItemText={this.state.searchParams.cursoEscolar}

                    onChanged={(id, nombre) => {

                        var state = this._cloneStateForSetState();
                        state.searchParams.cursoEscolarId = id;
                        state.searchParams.cursoEscolar = nombre;
                        state.searchParams.nivelEducativo = null;
                        state.searchParams.nivelEducativoId = null;
                        this.setState(state, () => this._performSearch());
                    }}
                />

            </Col>

            <Col xs="12" md="4" lg="3">
                <FormGroup>
                    <Label className="visually-hidden" for={nameof(this.state.searchParams.nivelEducativoId)}>
                        {I18n.Strings.matriculas.nombreClase}
                    </Label>

                    <NivelEducativoDropDown
                        emptyText={I18n.Strings.contenidos.nivelesEducativos + "..."}
                        id={nameof(this.state.searchParams.nivelEducativoId)}

                        cursoEscolarId={this.state.searchParams.cursoEscolarId}

                        value={this.state.searchParams.nivelEducativoId}
                        text={this.state.searchParams.nivelEducativo}
                        seleccionClases={false}

                        onChange={(id, nombre) => {
                            var state = this._cloneStateForSetState();
                            state.searchParams.nivelEducativoId = id;
                            state.searchParams.nivelEducativo = nombre;
                            this.setState(state, () => this._performSearch());
                        }}
                    />

                </FormGroup>
            </Col>
        </React.Fragment>;
    }


    protected _getApiService(): IServiceForIndex<ContenidosPapelColegioSearch, ContenidosPapelColegioListItemPaginatedList> {
        return new ContenidosPapelColegioService();
    }

    protected _showToolbar() {
        return false;
    }

    protected _getTableColumns(): TableColumn<ContenidosPapelColegioListItem>[] {
        return [

            {
                fieldName: nameof(this.state.searchResults.items[0].sku),
                title: I18n.Strings.pedidos.sku,
                renderField: (item) => item.sku,

            },

            {
                fieldName: nameof(this.state.searchResults.items[0].nombre),
                title: I18n.Strings.contenidos.nombre,
                renderField: (item) => item.nombre
            },
            {
                fieldName: nameof(this.state.searchResults.items[0].editorial),
                title: I18n.Strings.contenidos.editorial,
                renderField: (item) => {
                    return I18n.Strings.getEnumText(`Editorial.${item.editorial}`);
                }
            },

            {
                fieldName: nameof(this.state.searchResults.items[0].cursoEscolar),
                title: I18n.Strings.contenidos.cursoEscolar,
                renderField: (item) => item.cursoEscolar
            },
            {
                fieldName: nameof(this.state.searchResults.items[0].nivelesEducativos),
                title: I18n.Strings.contenidos.nivelesEducativos,
                renderField: (item) => item.nivelesEducativos
            },
            
        ];
    }

    protected hideGridButtonsOverride() {
        return false;
    }

    protected editButtonOverride(item: ContenidosPapelColegioListItem) {
        return;
        return <a target="_blank" href={this._getEditionItemUrl(item)}>
            <ViewIcon></ViewIcon>
        </a>
    }
    protected deleteButtonOverride(item) {
        return null;
    }

    protected _getPageTitle(): string {
        return I18n.Strings.contenidosPapel.contenidosPapelColegioTitle;
    }
    protected _getPageDescription(): string {
        return "Listado de contenidos en papel del colegio";
    }

    protected _getEditionItemUrl(item: ContenidosPapelColegioListItem): string {
        return null;  RouteHelper.editContenidosPapelColegio(item.id);
    }

    protected _getNewItemUrl(): string {
        return RouteHelper.createNivelesContenido();
    }

    protected _enableExcelExport(): boolean {
        return true;
    }

}
