
import { ClaseMateriaEditModel, LicenciasContenidosListItemPaginatedList, ClaseMateriaSearch, ClaseMateriaListItemPaginatedList, ModelValidationResult } from "../../models/dataModels";
import { ApiFetcher } from "../../utils/ApiFetcher";
import { IServiceForEdit, IServiceForIndex } from "../../utils/base/BaseServices";

export class ClaseMateriaService
    implements IServiceForIndex<any, any>, IServiceForEdit<ClaseMateriaEditModel>
{
    search(searchData: ClaseMateriaSearch): Promise<ClaseMateriaListItemPaginatedList> {
        var apiFetcher = new ApiFetcher();
        var prom = apiFetcher.post<ClaseMateriaSearch, ClaseMateriaListItemPaginatedList>("intranet/claseMateria/search", searchData);

        return prom;
    }

    getClaseMateriaLicencias(claseMateriaID: number): Promise<LicenciasContenidosListItemPaginatedList> {
        var apiFetcher = new ApiFetcher();
        var prom = apiFetcher.getByUrl<LicenciasContenidosListItemPaginatedList>(`intranet/claseMateria/${claseMateriaID}/licencias`);

        return prom;
    }

    delete(id: number): Promise<any> {
        throw new Error("Method not implemented.");
    }

    getById(id: string | number): Promise<ClaseMateriaEditModel> {
        throw new Error("Method not implemented.");
    }
    validate(model: ClaseMateriaEditModel): Promise<ModelValidationResult> {
        throw new Error("Method not implemented.");
    }

    create(model: ClaseMateriaEditModel): Promise<ClaseMateriaEditModel> {
        throw new Error("Method not implemented.");
    }

    update(id: string | number, model: ClaseMateriaEditModel): Promise<ClaseMateriaEditModel> {
        throw new Error("Method not implemented.");
    }

    exportExcel(search: ClaseMateriaSearch): Promise<any> {
        throw new Error("Method not implemented.");
    }
}