import { NivelProductosTiendaSearch, NivelProductosTiendasListItemPaginatedList } from "../models/dataModels";
import { ApiFetcher } from "../utils/ApiFetcher";


export class NivelProductosTiendaService {

    search(searchData: NivelProductosTiendaSearch): Promise<NivelProductosTiendasListItemPaginatedList> {

        var apiFetcher = new ApiFetcher();
        var prom = apiFetcher.post<NivelProductosTiendaSearch, NivelProductosTiendasListItemPaginatedList>("intranet/colegio/nivelesProductosTiendas/search", searchData);
        return prom;
    }
}
