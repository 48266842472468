import { AliasEditorialContenidosListItemPaginatedList, AliasEditorialContenidosSearch } from "../models/dataModels";
import { ApiFetcher } from "../utils/ApiFetcher";
import { IServiceForIndex } from "../utils/base/BaseServices";


export class AliasEditorialContenidosService
    implements IServiceForIndex<AliasEditorialContenidosSearch, AliasEditorialContenidosListItemPaginatedList>
{
    delete(id: string | number, real?: boolean): Promise<number> {
        throw new Error("Method not implemented.");
    }
    exportExcel(search: AliasEditorialContenidosSearch): Promise<any> {
        throw new Error("Method not implemented.");
    }

    search(searchData: AliasEditorialContenidosSearch): Promise<AliasEditorialContenidosListItemPaginatedList> {

        var apiFetcher = new ApiFetcher();
        var prom = apiFetcher.post<AliasEditorialContenidosSearch, AliasEditorialContenidosListItemPaginatedList>(
            "intranet/aliasEditoriales/contenidos/search", searchData);
        return prom;
    };
}
