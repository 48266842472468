import * as React from "react";

import nameof from "ts-nameof.macro";

import { I18n } from "../utils/I18n";


import { ColegioUsuariosService } from "./ColegioUsuariosService";

import { GenericPicker, GenericPickerProps, IGenericPickerState } from "../utils/base/GenericPickerBase";
import { ColegioUsuarioListItem, ColegioUsuarioListItemPaginatedList, ColegioUsuariosSearch } from "../models/dataModels";
import { IServiceForIndex } from "../utils/base/BaseServices";
import { TableColumn } from "../utils/base/IndexPageBase";
import { Col, FormGroup, Input, Label, Row } from "reactstrap";

class ColegioUsuariosPickerProps extends GenericPickerProps<ColegioUsuariosSearch, ColegioUsuarioListItem> {

    public errorMessage?: string;
    public profesorId?: number;
    public claseMateriaId?: number;
}


export class ColegioUsuariosPicker extends
    GenericPicker<ColegioUsuariosSearch, ColegioUsuarioListItemPaginatedList, ColegioUsuarioListItem, ColegioUsuariosPickerProps>
{

    protected GetTitulo(): string {
        return `Seleccionar ${this.props.label}`;
    }

    public componentWillReceiveProps(props: ColegioUsuariosPickerProps) {
        var newState = this._cloneStateForSetState();
        this.setState(newState);
    }


    protected GetInitialSearchParams(): ColegioUsuariosSearch {
        const searchParams = {
            ... {} as ColegioUsuariosSearch,
            profesorId: this.props.profesorId,
            claseMateriaId: this.props.claseMateriaId,
            rowsPerPage: 10
        } as ColegioUsuariosSearch;

        return searchParams;
    }

    protected _createState(): IGenericPickerState<ColegioUsuariosSearch, ColegioUsuarioListItemPaginatedList> {
        const state = super._createState();
        return state;
    }

    protected _createApiService(): IServiceForIndex<ColegioUsuariosSearch, ColegioUsuarioListItemPaginatedList> {
        return new ColegioUsuariosService();
    }

    protected RenderSearchForm(): JSX.Element {
        return (
            <Row>
                <Col md={4}>
                    <FormGroup>
                        <Label for={"usuariosPicker" + nameof(this.state.searchParams.nombre)}>
                            {I18n.Strings.colegioUsuarios.nombre}
                        </Label>
                        <Input
                            onChange={(evt) => {
                                var state = this._cloneStateForSetState();
                                state.searchParams.nombre = evt.target.value;
                                this.setState(state);
                            }}
                            type="text"
                            maxLength={100}
                            autoComplete="off"
                            required={false}
                            className={"form-control"}
                            name={nameof(this.state.searchParams.nombre)}
                            id={"usuariosPicker" + nameof(this.state.searchParams.nombre)}
                            value={this.state.searchParams.nombre || ""}
                        />

                    </FormGroup>
                </Col>

                <Col md={4}>
                    <FormGroup>
                        <Label for={"usuariosPicker" + nameof(this.state.searchParams.apellidos)}>
                            {I18n.Strings.colegioUsuarios.apellidos}
                        </Label>
                        <Input
                            onChange={(evt) => {
                                var state = this._cloneStateForSetState();
                                state.searchParams.apellidos = evt.target.value;
                                this.setState(state);
                            }}
                            type="text"
                            maxLength={100}
                            autoComplete="off"
                            required={false}
                            className={"form-control"}
                            name={nameof(this.state.searchParams.apellidos)}
                            id={"usuariosPicker" + nameof(this.state.searchParams.apellidos)}
                            value={this.state.searchParams.apellidos || ""}
                        />

                    </FormGroup>
                </Col>

                <Col md={4}>
                    <FormGroup>
                        <Label for={"usuariosPicker" + nameof(this.state.searchParams.login)}>
                            {I18n.Strings.colegioUsuarios.login}
                        </Label>
                        <Input
                            onChange={(evt) => {
                                var state = this._cloneStateForSetState();
                                state.searchParams.login = evt.target.value;
                                this.setState(state);
                            }}
                            type="text"
                            maxLength={100}
                            autoComplete="off"
                            required={false}
                            className={"form-control"}
                            name={nameof(this.state.searchParams.login)}
                            id={"usuariosPicker" + nameof(this.state.searchParams.login)}
                            value={this.state.searchParams.login || ""}
                        />

                    </FormGroup>
                </Col>
            </Row>
        );
    }

    protected _getColumns(): TableColumn<ColegioUsuarioListItem>[] {
        const columns: TableColumn<ColegioUsuarioListItem>[] = [];

        var sample: ColegioUsuarioListItem;

        columns.push({
            fieldName: nameof(sample.nombre),
            title: I18n.Strings.colegioUsuarios.nombre,
            renderField: (item) => item.nombre
        });

        columns.push({
            fieldName: nameof(sample.apellidos),
            title: I18n.Strings.colegioUsuarios.apellidos,
            renderField: (item) => item.apellidos
        });

        columns.push({
            fieldName: nameof(sample.login),
            title: I18n.Strings.colegioUsuarios.login,
            renderField: (item) => item.login
        });

        columns.push({
            fieldName: nameof(sample.tipoUsuario),
            title: I18n.Strings.colegioUsuarios.tipoUsuario,
            renderField: (item) => I18n.Strings.getEnumText(`TipoUsuario.${item.tipoUsuario}`)
        });

        return columns;
    }
}