
import {
    EstadisticasAccesoGraphSearch, EstadisticasAccesoGraphSearchResult, EstadisticasAccesoGraphExcelExport, ExportFileResult, GraphSearch
} from "../models/dataModels";
import { ApiFetcher } from "../utils/ApiFetcher";
import * as Utils from "../utils/ValuesFormatter";

export class AccesosSistemaService
{
    accesosGraphSearch(searchData: GraphSearch): Promise<EstadisticasAccesoGraphSearchResult> {

        var apiFetcher = new ApiFetcher();
   
        var prom = apiFetcher.post<EstadisticasAccesoGraphSearch, EstadisticasAccesoGraphSearchResult>("intranet/colegio/usuarios/estadisticasAccesoGraphSearch", searchData);
        prom.then(res => {
            res.items.forEach(item => {
                item.dia = item.dia == null ? null : new Date(item.dia);
            });
        });
        return prom;
    }

    exportExcel(searchData: EstadisticasAccesoGraphExcelExport): Promise<ExportFileResult> {

        var apiFetcher = new ApiFetcher();

        var prom = apiFetcher.post<EstadisticasAccesoGraphExcelExport, ExportFileResult>("intranet/colegio/usuarios/estadisticasAccesoExportExcel", searchData)
            .then((result) => {
                Utils.downloadFileFromBase64Array(result.content, result.fileName, "application/octet-stream");
                return result;
            });
        return prom;
    }
}