import React from "react";

import { Row, Col } from "reactstrap";
import { withRouter, RouteComponentProps } from "react-router-dom";
import { debug } from "console";


interface LoginProps extends RouteComponentProps<any> {

}
interface LoginState {

}

class LoginUserErrorComp extends React.Component<LoginProps, LoginState> {


    constructor(props: any) {
        super(props);

        this.state = {};
    }

    render() {
        return <React.Fragment>
            <div className="head-seccion">
                <h2><strong>Error de acceso</strong></h2>
                <p>Parece que ha habido un error cuando trataba de entrar con el usuario:</p>           
            </div>
        </React.Fragment>

    }
}

export const LoginUserError = withRouter(LoginUserErrorComp);
