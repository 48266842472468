import * as React from "react";
import { withRouter } from "react-router-dom";
import { Button, Col, FormGroup, Input, InputGroup, Label, Row } from "reactstrap";
import nameof from "ts-nameof.macro";
import { RouteHelper } from "../IntranetRouter";
import { CargaGetDetailResult, EstadoCargaItem, ResultadosCargaLicenciasExcelExport, ResultadosCargaUsuariosExcelExport, TipoCarga } from "../models/dataModels";
import { AlertsService } from "../utils/AlertsService";
import { IServiceForEdit } from "../utils/base/BaseServices";
import { LinkItem, NavigationHelper } from "../utils/base/Breadcrumb";
import { EditPageBase } from "../utils/base/EditPageBase";
import { GenericEditState } from "../utils/base/GenericEditState";
import { ModelValidation } from "../utils/base/ModelValidation";
import { I18n } from "../utils/I18n";
import { DownloadIcon } from "../utils/Icons";
import { CargaFicherosService } from "./CargaFicherosService";



export class DetailComp extends EditPageBase<CargaGetDetailResult> {

    protected _setPageTitleForNew(): string {
        return "Nueva carga";
    }

    protected _setPageTitleForExisting(editModel: CargaGetDetailResult): string {
        return `Detalle de carga de registros`;
    }

    protected _createApiService(): IServiceForEdit<CargaGetDetailResult> {
        return null;
    }

    protected _loadStateFromServer(id?: number, callback?: (data: CargaGetDetailResult) => void) {

        if (id && id !== 0 && !isNaN(parseInt(id.toString()))) {

            const state = new GenericEditState<CargaGetDetailResult>();
            state.showLoading = true;
            state.activeTab = this.props.match.params.tab;

            this.setState(state, () => {
                var service = new CargaFicherosService();
                service.getById(id)
                    .then((data) => {
                        this._applyDataFromServerInState(data, null, () => {
                            if (callback != null) {
                                callback(data);
                            }
                        });
                    })
                    .catch((reason) => this.setState({ showLoading: false }, () => AlertsService.showError(reason)));
            });
        }
    }

    protected _generateForm(): JSX.Element {
        return <React.Fragment>
            <Row>

                <Col md="4">
                    <FormGroup>
                        <Label for={nameof(this.state.editModel.fecha)}>
                            {I18n.Strings.cargaFicheros.fecha}
                        </Label>

                        <Input
                            value={I18n.Strings.formatDateTime(this.state.editModel.fecha)}
                            readOnly={true}
                        />
                    </FormGroup>
                </Col>

                <Col md="4">
                    <FormGroup>
                        <Label for={nameof(this.state.editModel.tipoCarga)}>
                            {I18n.Strings.cargaFicheros.tipoCarga}
                        </Label>

                        <Input
                            value={I18n.Strings.getEnumText(`TipoCarga.${this.state.editModel.tipoCarga}`)}
                            readOnly={true}
                        />
                    </FormGroup>
                </Col>

            </Row>

            <Row>

                <Col md="4">
                    <FormGroup>
                        <Label for={nameof(this.state.editModel.correctos)}>
                            {I18n.Strings.cargaFicheros.correctos}
                        </Label>
                        <InputGroup>
                            <Input
                                value={I18n.Strings.formatNumber(this.state.editModel.correctos, 0)}
                                readOnly={true}
                            />
                            {this.state.editModel.correctos != 0 &&
                                <Button onClick={() => this._downloadResultsExcel(EstadoCargaItem.Correcto)}>
                                    <DownloadIcon />
                                </Button>
                            }
                        </InputGroup>
                    </FormGroup>
                </Col>

                <Col md="4">
                    <FormGroup>
                        <Label for={nameof(this.state.editModel.duplicados)}>
                            {I18n.Strings.cargaFicheros.duplicados}

                        </Label>
                        <InputGroup>
                            <Input
                                value={I18n.Strings.formatNumber(this.state.editModel.duplicados, 0)}
                                readOnly={true}
                            />
                            {this.state.editModel.duplicados != 0 &&
                                <Button onClick={() => this._downloadResultsExcel(EstadoCargaItem.Duplicado)}>
                                    <DownloadIcon />
                                </Button>
                            }
                        </InputGroup>
                    </FormGroup>
                </Col>


                <Col md="4">
                    <FormGroup>
                        <Label for={nameof(this.state.editModel.erroneos)}>
                            {I18n.Strings.cargaFicheros.erroneos}
                        </Label>
                        <InputGroup>
                            <Input
                                value={I18n.Strings.formatNumber(this.state.editModel.erroneos, 0)}
                                readOnly={true}
                            />
                            {this.state.editModel.erroneos != 0 &&
                                <Button onClick={() => this._downloadResultsExcel(EstadoCargaItem.Erroneo)}>
                                    <DownloadIcon />
                                </Button>
                            }
                        </InputGroup>
                    </FormGroup>
                </Col>


            </Row>

        </React.Fragment >;
    }

    protected _downloadResultsExcel(estado: EstadoCargaItem) {
        if (this.state.editModel.tipoCarga == TipoCarga.Licencia) {
            this.setState({ showLoading: true },
                () => {
                    var service = new CargaFicherosService();
                    var msg = {
                        cargaId: this.state.editModel.id,
                        estadoCarga: estado,
                        tipoCarga: this.state.editModel.tipoCarga
                    } as ResultadosCargaLicenciasExcelExport;

                    service.descargarResultadosCargaLicencias(msg)
                        .then(() => this.setState({ showLoading: false }))
                });
        }
        else if (this.state.editModel.tipoCarga == TipoCarga.Usuario){
            this.setState({ showLoading: true },
                () => {
                    var service = new CargaFicherosService();
                    var msg = {
                        cargaId: this.state.editModel.id,
                        estadoCarga: estado,
                        tipoCarga: this.state.editModel.tipoCarga
                    } as ResultadosCargaUsuariosExcelExport;

                    service.descargarResultadosCargaUsuarios(msg)
                        .then(() => this.setState({ showLoading: false }))
                });
        }
    }

    protected _extraButtons(): JSX.Element {

        return <React.Fragment>
            <a className="btn btn-primary btn-rounded" href={this.state.editModel.ficheroUrl}>
                Descargar fichero
            </a>
        </React.Fragment>;
    }

    protected _getEditUrl(id: number): string {
        return RouteHelper.detailCargaFicheros(id);
    }

    protected _getListUrl(): string {
        return RouteHelper.cargaFicheros();
    }

    protected _createStateForNew(): Promise<GenericEditState<CargaGetDetailResult>> {
        return null;
    }

    protected _validateModelLocal(): ModelValidation {
        let validation = new ModelValidation();

        return validation;
    }


    protected updateBreadCrumb(model: CargaGetDetailResult, isInitialMount?: boolean) {

        if (!isInitialMount) return;

        var items = [] as LinkItem[];

        items.push({
            text: "Carga de ficheros",
            link: RouteHelper.cargaFicheros()
        });

        items.push({
            text: I18n.Strings.getEnumText(`TipoCarga.${model.tipoCarga}`) + " - " + I18n.Strings.formatDate(model.fecha), 
            link: null
        });

        NavigationHelper.setBreadcrumbItems(items);
    }

}

export const Detail = withRouter(DetailComp)