import React from "react";

import { Row, Col } from "reactstrap";

import { withRouter, RouteComponentProps } from "react-router-dom";

import { AuthService } from '../services/AuthService';

interface LoginProps extends RouteComponentProps<any> {

}
interface LoginState {

}

class LoginComp extends React.Component<LoginProps, LoginState> {

    public authService: AuthService;
    private shouldCancel: boolean;

    constructor(props: any) {
        super(props);

        this.authService = new AuthService();
        this.state = { user: {}, api: {} };
        this.shouldCancel = false;
    }

    public componentDidMount() {
        this.getUser();
    }

    public login = () => {
        this.authService.login();
    };


    public componentWillUnmount() {
        this.shouldCancel = true;
    }

    public renewToken = () => {
        this.authService
            .renewToken()
            .then(user => {                
                this.getUser();
            });
    };

    public logout = () => {
        this.authService.logout();
    };

    public getUser = () => {
        this.authService.getUser().then(user => {

            if (!this.shouldCancel) {
                this.setState({ user });
            }
        });
    };

    render() {
        return <React.Fragment>

            <p>Esto es el login!</p>

            <Row>
                <Col md={6}>
                    <React.Fragment>
                        <button className="btn btn-primary btn-login" style={{ margin: '10px' }} onClick={this.login}>
                            Login
                        </button>
                    </React.Fragment>
                </Col>
            </Row>

        </React.Fragment>

    }



    private continueAfterSuccessfulLogin() {
        this.props.history.push("/home");
    }

}



export const Login = withRouter(LoginComp);
