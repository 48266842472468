import React from "react";



export default class NotFound extends React.Component<any, any> {

    render(): JSX.Element {



        return <React.Fragment>

            <h1>
                <strong>
                    Oops - Esta página no existe!
                </strong>
            </h1>

        </React.Fragment>
    }


}