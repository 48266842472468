import {
    MatriculaProfesorEditModel, MatriculaProfesorListItemPaginatedList, MatriculasProfesorSearch, ModelValidationResult,
    MateriaProfesorPersistResult, MateriaProfesorEditModel
} from "../../../models/dataModels";
import { ApiFetcher } from "../../../utils/ApiFetcher";
import { IServiceForEdit, IServiceForIndex } from "../../../utils/base/BaseServices";

export class MisMatriculasService
    implements IServiceForIndex<MatriculasProfesorSearch, MatriculaProfesorListItemPaginatedList>, IServiceForEdit<MatriculaProfesorEditModel>
{
    
    

    search(searchData: MatriculasProfesorSearch): Promise<MatriculaProfesorListItemPaginatedList> {

        var apiFetcher = new ApiFetcher();
        var prom = apiFetcher.post<MatriculasProfesorSearch, MatriculaProfesorListItemPaginatedList>("intranet/miPerfil/searchClasses", searchData);
        return prom;
    }

    delete(id: number) : Promise<any> {
        var apiFetcher = new ApiFetcher();
        var prom = apiFetcher.delete<any>(`intranet/miPerfil/deleteClass/${id}`);
        return prom;
    }

    getById(id: string | number): Promise<MatriculaProfesorEditModel> {
        var apiFetcher = new ApiFetcher();
        var prom = apiFetcher.getByUrl<MatriculaProfesorEditModel>(`intranet/miPerfil/editClass/${id}`);
        return prom;
    }
    validate(model: MatriculaProfesorEditModel): Promise<ModelValidationResult> {
        var apiFetcher = new ApiFetcher();
        var prom = apiFetcher.post<MatriculaProfesorEditModel, ModelValidationResult>(`intranet/miPerfil/validateClass`, model);
        return prom;
    }

    create(model: MatriculaProfesorEditModel): Promise<MatriculaProfesorEditModel> {
        var apiFetcher = new ApiFetcher();
        var prom = apiFetcher.post<MatriculaProfesorEditModel, MatriculaProfesorEditModel>(`intranet/miPerfil/createClass`, model);
        return prom;
    }

    update(id: string | number, model: MatriculaProfesorEditModel): Promise<MatriculaProfesorEditModel> {
        var apiFetcher = new ApiFetcher();
        var prom = apiFetcher.put<MatriculaProfesorEditModel, MatriculaProfesorEditModel>(`intranet/miPerfil/updateClass/${id}`, model);
        return prom;
    }

    exportExcel(search: MatriculasProfesorSearch): Promise<any> {
        throw new Error("Method not implemented.");
    }

    updateMateria(model: MateriaProfesorEditModel): Promise<MateriaProfesorPersistResult> {
        var apiFetcher = new ApiFetcher();
        var prom = apiFetcher.post<MateriaProfesorEditModel, MateriaProfesorPersistResult>(`intranet/miPerfil/updateMateria`, model);
        return prom;
    }
}