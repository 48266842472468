import React from "react";

import nameof from "ts-nameof.macro";
import { withRouter } from "react-router-dom";
import { ClientContext } from "../../utils/ClientContext";
import { ComponentBase } from "../../utils/base/ComponentBase";
import { ModelValidation } from "../../utils/base/ModelValidation";
import { MiColegioEditModel, TipoUsuario } from "../../models/dataModels";
import { MiPerfilColegioService } from "./MiPerfilColegioService";
import { Modal, ModalHeader, ModalBody, ModalFooter, TabContent, TabPane, NavItem, Nav, NavLink, Col, FormGroup, Input, Label, Row, FormFeedback, Button } from "reactstrap";
import { I18n } from "../../utils/I18n";
import { DocumentUpload } from "../../helpers/DocumentUpload";
import { SyncIcon, SaveIcon } from "../../utils/Icons";
import { AlertsService } from "../../utils/AlertsService";
import { Loading } from "../../utils/Loading";
import { Index as CursosEscolares } from "../../cursoEscolar/Index";
import { Index as Credenciales } from "../../credenciales/Index";
import { RouteHelper } from "../../IntranetRouter";
import { CustomDatePicker } from "../../helpers/CustomDatePicker";

import { NavigationHelper } from "../../utils/base/Breadcrumb";
import { DropDownButton } from "../../helpers/DropDownButton";
import { ComunidadesAutonomasDropDown } from "../../comunidadesAutonomas/ComunidadesAutonomasDropDown";
import { SeleccionCampannaModal } from "../../utils/SeleccionCampannaModal";
import { YesNoCancelModal } from "../../utils/YesNoCancelModal";


interface MyProfileState {
    showLoading: boolean;
    editModel: MiColegioEditModel;
    validationResult: ModelValidation;
    searchValidationResult: ModelValidation;
    activeTab: ColegioEditTabs;
    showPedidosMagentoPopUp: boolean;

    // La fecha desde la que se hara la sincronización
    syncFechaDesde: Date;

    pedidosNumPedidos: string;
    pedidosIsbns: string;
    pedidosSoloTienda: boolean;

    tipoSyncPedidos: TipoSyncPedidos;

    campannaToSyncId: number;
    campannaToSync: string;

    mostrarDialogoImportacionEstructura: boolean;

    tipoSyncOdoo: TipoSyncOdoo;
    mostrarDialogoSyncCompletaOdoo: boolean;
}

enum TipoSyncPedidos {
    PorFecha,
    PorNumPedidos,
    PorIsbn
}

export enum ColegioEditTabs {
    Datos = "datos",
    CursoEscolar = "curso-escolar",
    Credenciales = "credenciales",
}

enum TipoSyncOdoo {
    Maestros = 1,
    MaestrosYProds = 2,
    Pedidos = 3
}

export class EditComp extends ComponentBase<any, MyProfileState> {



    public constructor(props: any) {
        super(props);

        let datosCursoEscolar = ClientContext.Current.cursosEscolares.find(ce => ce.id == ClientContext.Current.cursoEscolarActivoId);

        var start = new Date();
        start.setDate(start.getDate() - 1);

        this.state = {
            editModel: null,
            showLoading: true,
            validationResult: new ModelValidation(),
            searchValidationResult: new ModelValidation(),
            activeTab: ColegioEditTabs.Datos,
            showPedidosMagentoPopUp: false,
            syncFechaDesde: start,
            pedidosNumPedidos: '',
            pedidosIsbns: '',
            pedidosSoloTienda: false,
            tipoSyncPedidos: TipoSyncPedidos.PorFecha,
            mostrarDialogoImportacionEstructura: false,
            campannaToSyncId: datosCursoEscolar.campannaId,
            campannaToSync: datosCursoEscolar.campanna,
            
            tipoSyncOdoo: null,
            mostrarDialogoSyncCompletaOdoo: false
        };


    }

    protected updateBreadCrumb(model: MiColegioEditModel) {
        var items = [{
            text: I18n.Strings.schoolProfile,
            link: RouteHelper.myProfileSchool(ColegioEditTabs.Datos)
        }];

        switch (this.state.activeTab) {
            case ColegioEditTabs.Datos: {
                items.push({ text: I18n.Strings.colegio.tabDatos, link: RouteHelper.myProfileSchool(ColegioEditTabs.Datos) });
                break;
            }
            case ColegioEditTabs.Credenciales: {
                items.push({ text: I18n.Strings.colegio.tabCredenciales, link: RouteHelper.myProfileSchool(ColegioEditTabs.Credenciales) });
                break;
            }
            case ColegioEditTabs.CursoEscolar: {
                items.push({ text: I18n.Strings.colegio.tabCursoEscolar, link: RouteHelper.myProfileSchool(ColegioEditTabs.CursoEscolar) });
                break;
            }
        }
        NavigationHelper.setBreadcrumbItems(items);
    }

    componentDidMount() {
        var service = new MiPerfilColegioService();
        service.getMyEditModel()
            .then(myData => {
                this.setState({
                    showLoading: false,
                    editModel: myData,
                    activeTab: this.props.match.params.tab
                }, () => this.updateBreadCrumb(this.state.editModel));

            })
            .catch((err) => {
                this.setState({ showLoading: false }, () => AlertsService.showError(err))
            });
    }

    protected _validateModelLocal(): ModelValidation {
        let validation = new ModelValidation();

        if ((this.state.editModel.nombre || "").trim().length == 0) {
            validation.addError(nameof(this.state.editModel.nombre), I18n.Strings.validationErrors.valueRequired(I18n.Strings.colegioUsuarios.nombre));
        }

        if (this.state.editModel.comunidadAutonomaId == null) {
            validation.addError(nameof(this.state.editModel.comunidadAutonomaId), I18n.Strings.validationErrors.valueRequired(I18n.Strings.colegio.comunidadAutonoma));
        }

        return validation;
    }

    protected _validateState(): Promise<ModelValidation> {

        var validationProm = Promise.resolve<ModelValidation>(this._validateModelLocal())
            .then(localValidationResult => {
                if (localValidationResult.isOk) {
                    var service = new MiPerfilColegioService();

                    // Si en local ha ido bien, probamos en remoto, si no, no lo intentamos
                    return service.validate(this.state.editModel)
                        .then(serverValResult => {
                            var validation = new ModelValidation();
                            validation.load(serverValResult);
                            return validation;
                        });
                }
                else {
                    return localValidationResult;
                }
            })
            .catch((reason) => {
                AlertsService.showError(reason);
                return reason;
            });

        return validationProm;
    }

    protected _validateAndSubmit(): void {
        this._validateState()
            .then(validationResultTemp => {

                if (validationResultTemp == null) {
                    alert("error validation");
                    return false;
                }
                var validationResult = new ModelValidation();
                validationResult.load(validationResultTemp);

                if (validationResult.isOk) {
                    console.info("La validación es correcta");

                    var state = this._cloneStateForSetState();
                    state.showLoading = true;
                    state.validationResult = validationResult;
                    this.setState(state, () => {
                        var service = new MiPerfilColegioService();
                        service.update(this.state.editModel)
                            .then((data) => {
                                this.setState({
                                    showLoading: false,
                                }, () => {
                                    ClientContext.Current.nombreColegio = this.state.editModel.nombre;
                                    if (data.error !== null) {
                                        AlertsService.showErrorMessage(data.error);
                                    }
                                    else {
                                        AlertsService.showSuccessMessage(I18n.Strings.savedOk);
                                    }

                                });

                                return;
                            })
                            .catch((reason) => AlertsService.showError(reason));
                    });
                }
                else {
                    console.log("Validación no correcta");
                    console.warn(validationResult);
                    this._processValidationError(validationResult);
                }
            })
            .catch((reason) => AlertsService.showError(reason));
    }


    protected _processValidationError(validation: ModelValidation): void {
        var newState = this._cloneStateForSetState();
        newState.validationResult = validation;
        this.setState(newState);
    }

    protected _errorMessage(fieldName: string): JSX.Element {
        var error = this.state.validationResult.getError(fieldName);
        if (error) {
            return <FormFeedback>{error}</FormFeedback>;
        }
        return null;
    }

    protected _searchErrorMessage(fieldName: string): JSX.Element {
        var error = this.state.searchValidationResult.getError(fieldName);
        if (error) {
            return <FormFeedback>{error}</FormFeedback>;
        }
        return null;
    }

    protected _errorClass(fieldName: string): string {
        var error = this.state.validationResult.getError(fieldName);
        if (error) {
            return "is-invalid";
        }
        return null;
    }

    protected SyncOdoo() {

        var service = new MiPerfilColegioService();
        var promSync = this.state.tipoSyncOdoo == TipoSyncOdoo.Pedidos
            ? service.syncOdooPedidos(this.state.syncFechaDesde)
            : service.syncOdooMasterData(this.state.tipoSyncOdoo == TipoSyncOdoo.MaestrosYProds, this.state.syncFechaDesde);

        promSync.then(ret => {
            this.setState({ showLoading: false, mostrarDialogoSyncCompletaOdoo: false }, () => {
                AlertsService.showSuccessMessage(I18n.Strings.notificaciones.procesoEnCola);
            });
        })
            .catch((err) => this.setState(
                { showLoading: false, mostrarDialogoSyncCompletaOdoo: false },
                () => AlertsService.showError(err))
            );
    }

    protected renderPedidosMagentoPopUp(): JSX.Element {
        var closeButton = <button className="btn-close" onClick={() => {
            this.setState({ showPedidosMagentoPopUp: false })
        }}></button>;

        return this.state.showPedidosMagentoPopUp && <Modal isOpen={true} className="wide-dialog">
            <ModalHeader close={closeButton}>
                {I18n.Strings.pedidos.sync}
            </ModalHeader>
            <ModalBody>
                <Row>
                    <Col md={12}>
                        <FormGroup>
                            <input
                                type={"radio"}
                                id={"buscarPorFecha"}
                                style={{ marginRight: "10px" }}
                                checked={this.state.tipoSyncPedidos == TipoSyncPedidos.PorFecha}
                                onChange={(evt) => {
                                    this.setState({ tipoSyncPedidos: TipoSyncPedidos.PorFecha, pedidosNumPedidos: null, pedidosIsbns: null })
                                }}
                            />
                            <Label for={"buscarPorFecha"}>{I18n.Strings.estadisticas.fechaDesde}</Label>
                            <CustomDatePicker
                                key={nameof(this.state.syncFechaDesde)}
                                value={this.state.syncFechaDesde}
                                yearsFront={2}
                                yearsBack={22}
                                disabled={this.state.tipoSyncPedidos != TipoSyncPedidos.PorFecha}
                                onChange={(val) => {
                                    this.setState({ syncFechaDesde: val })
                                }}
                            />
                            {this._searchErrorMessage(nameof(this.state.syncFechaDesde))}
                        </FormGroup>
                    </Col>
                </Row>
                <Row>
                    <Col md={12}>
                        <FormGroup>
                            <input
                                type={"radio"}
                                id={"buscarPorNum"}
                                style={{ marginRight: "10px" }}
                                checked={this.state.tipoSyncPedidos == TipoSyncPedidos.PorNumPedidos}
                                onChange={(evt) => {
                                    this.setState({ tipoSyncPedidos: TipoSyncPedidos.PorNumPedidos, pedidosIsbns: null })
                                }}
                            />
                            <Label for={"buscarPorNum"}>{I18n.Strings.pedidos.numPedidos}</Label>
                            <Input
                                onChange={(evt) => {
                                    this.setState({ pedidosNumPedidos: evt.target.value })
                                }}
                                type="textarea"
                                required={true}
                                readOnly={this.state.tipoSyncPedidos != TipoSyncPedidos.PorNumPedidos}
                                className={"form-control " + this._errorClass(nameof(this.state.pedidosNumPedidos))}
                                name={nameof(this.state.pedidosNumPedidos)}
                                id={"usuarios" + nameof(this.state.pedidosNumPedidos)}
                                value={this.state.pedidosNumPedidos || ""}
                                onSelect={(evt) => {
                                    this.setState({ tipoSyncPedidos: TipoSyncPedidos.PorNumPedidos, pedidosIsbns: null });
                                }}
                            />
                            {this._errorMessage(nameof(this.state.pedidosNumPedidos))}
                        </FormGroup>
                    </Col>
                </Row>
                <Row>
                    <Col md={12}>
                        <FormGroup>
                            <input
                                type={"radio"}
                                id={"buscarPorIsbn"}
                                style={{ marginRight: "10px" }}
                                checked={this.state.tipoSyncPedidos == TipoSyncPedidos.PorIsbn}
                                onChange={(evt) => {
                                    this.setState({ tipoSyncPedidos: TipoSyncPedidos.PorIsbn, pedidosNumPedidos: null })
                                }}
                            />
                            <Label for={"buscarPorIsbn"}>{I18n.Strings.pedidos.isbns}</Label>
                            <Input
                                onChange={(evt) => {
                                    this.setState({ pedidosIsbns: evt.target.value })
                                }}
                                type="textarea"
                                required={true}
                                readOnly={this.state.tipoSyncPedidos != TipoSyncPedidos.PorIsbn}
                                className={"form-control " + this._errorClass(nameof(this.state.pedidosIsbns))}
                                name={nameof(this.state.pedidosIsbns)}
                                id={"usuarios" + nameof(this.state.pedidosIsbns)}
                                value={this.state.pedidosIsbns || ""}
                                onSelect={(evt) => {
                                    this.setState({ tipoSyncPedidos: TipoSyncPedidos.PorIsbn, pedidosNumPedidos: null });
                                }}
                            />
                            {this._errorMessage(nameof(this.state.pedidosIsbns))}
                        </FormGroup>
                    </Col>
                </Row>
                <Row>
                    <Col md={12}>
                        <FormGroup>

                            <Input
                                onChange={(evt) => {
                                    var newState = this._cloneStateForSetState();
                                    newState.pedidosSoloTienda = evt.target.checked;
                                    this.setState(newState);
                                }}
                                type="checkbox"
                                style={{ padding: 0, marginLeft: 10 }}
                                id={nameof(this.state.pedidosSoloTienda)}
                                checked={this.state.pedidosSoloTienda}
                            />
                            <Label for={nameof(this.state.pedidosSoloTienda)}>
                                Importar solo pedidos (no licencias)
                            </Label>
                        </FormGroup>
                    </Col>
                </Row>
            </ModalBody>
            <ModalFooter>
                <Button color="primary"
                    onClick={() => {
                        var validationResult = this._validarSyncPedidosMagentoForm();
                        if (validationResult.isOk) {
                            this.setState({ showLoading: true }, () => {

                                var service = new MiPerfilColegioService();
                                var promesa = service.syncPedidosColegio(
                                    this.state.tipoSyncPedidos != TipoSyncPedidos.PorFecha ? null : this.state.syncFechaDesde,
                                    this.state.tipoSyncPedidos != TipoSyncPedidos.PorNumPedidos ? null : this.state.pedidosNumPedidos,
                                    this.state.tipoSyncPedidos != TipoSyncPedidos.PorIsbn ? null : this.state.pedidosIsbns,
                                    this.state.pedidosSoloTienda
                                );

                                promesa.then(ret => {
                                    this.setState({ showLoading: false, showPedidosMagentoPopUp: false },
                                        () => {
                                            AlertsService.showSuccessMessage(I18n.Strings.notificaciones.procesoEnCola);
                                        });
                                })
                                    .catch(err => AlertsService.showError(err));
                            });
                        }
                        else {
                            this.setState({ searchValidationResult: validationResult });
                        }
                    }}>
                    <SyncIcon />
                    {I18n.Strings.pedidos.sync}
                </Button>



                <Button color="secondary"
                    onClick={() => this.setState({ showPedidosMagentoPopUp: false })}
                >
                    {I18n.Strings.cancel}
                </Button>
            </ModalFooter>
        </Modal>
    }


    protected renderSincronizacionOdooPopUp(): JSX.Element {

        var closeButton = <button className="btn-close" onClick={() => {
            this.setState({ mostrarDialogoSyncCompletaOdoo: false })
        }}></button>;

        return this.state.mostrarDialogoSyncCompletaOdoo && <Modal isOpen={true} className="wide-dialog">
            <ModalHeader close={closeButton}>
                {this.state.tipoSyncOdoo == TipoSyncOdoo.Maestros && <>Sincr. maestros de Odoo</>}
                {this.state.tipoSyncOdoo == TipoSyncOdoo.MaestrosYProds && <>Sincr. maestros y prods. de Odoo</>}
                {this.state.tipoSyncOdoo == TipoSyncOdoo.Pedidos && <>Sincr. pedidos de Odoo</>}
                
            </ModalHeader>
            <ModalBody>
                <Row>
                    <Col md={12}>
                        <FormGroup>
                            <input
                                type={"radio"}
                                id={"syncDesde"}
                                style={{ marginRight: "10px" }}
                                checked={this.state.syncFechaDesde != null}
                                onChange={(evt) => {
                                    var fechaActual = new Date();
                                    fechaActual.setDate(fechaActual.getDate() - 7);
                                    this.setState({
                                        syncFechaDesde: fechaActual
                                    })
                                }}
                            />
                            <Label for={"syncDesde"}>{I18n.Strings.estadisticas.fechaDesde}</Label>
                            <CustomDatePicker
                                key={nameof(this.state.syncFechaDesde)}
                                value={this.state.syncFechaDesde}
                                yearsFront={2}
                                yearsBack={22}
                                disabled={this.state.tipoSyncPedidos != TipoSyncPedidos.PorFecha}
                                onChange={(val) => {
                                    this.setState({ syncFechaDesde: val })
                                }}
                            />
                            {this._searchErrorMessage(nameof(this.state.syncFechaDesde))}
                        </FormGroup>
                    </Col>
                </Row>
                <Row>
                    <Col md={12}>
                        <FormGroup>
                            <input
                                type={"radio"}
                                id={"syncIncremental"}
                                style={{ marginRight: "10px" }}
                                checked={this.state.syncFechaDesde == null}
                                onChange={(evt) => {
                                    this.setState({ syncFechaDesde: null })
                                }}
                            />
                            <Label for={"syncIncremental"}>Incremental</Label>
                            
                        </FormGroup>
                    </Col>
                </Row>
            </ModalBody>
            <ModalFooter>
                <Button color="primary" onClick={() => this.SyncOdoo()}>
                    <SyncIcon />
                    Sincronizar
                </Button>



                <Button color="secondary"
                    onClick={() => this.setState({ mostrarDialogoSyncCompletaOdoo: false })}
                >
                    {I18n.Strings.cancel}
                </Button>
            </ModalFooter>
        </Modal>
    }


    protected _validarSyncPedidosMagentoForm(): ModelValidation {
        let validation = new ModelValidation();

        if (this.state.syncFechaDesde == null) {
            validation.addError(nameof(this.state.syncFechaDesde), I18n.Strings.validationErrors.valueRequired(I18n.Strings.estadisticas.fechaDesde));
        }

        return validation;
    }

    private _toggleDialogoImportacion() {
        var state = this._cloneStateForSetState();
        state.mostrarDialogoImportacionEstructura = !state.mostrarDialogoImportacionEstructura;
        this.setState(state);
    }

    render() {
        var botones = this._getBotones();

        return <React.Fragment>



            {this.state.mostrarDialogoImportacionEstructura &&
                <SeleccionCampannaModal
                    mainText="Va a realizar la sincronización de la estructura de ESTE colegio ."
                    campanna={this.state.campannaToSync}
                    campannaId={this.state.campannaToSyncId}
                    mostrarIsbns={false}
                    campannaSelected={(campannaId: number, campanna: string) => {

                        var newState = this._cloneStateForSetState();
                        newState.showLoading = true;
                        newState.campannaToSyncId = campannaId;
                        newState.campannaToSync = campanna;
                        newState.mostrarDialogoImportacionEstructura = false;
                        this.setState(newState, () => {
                            var service = new MiPerfilColegioService();

                            let prom = service.syncEstructuraColegio(newState.campannaToSyncId, false);
                            prom.then(ret => {
                                this.setState({ showLoading: false }, () => {
                                    AlertsService.showSuccessMessage(I18n.Strings.notificaciones.procesoEnCola);
                                });
                            });

                        });
                    }}
                    toggleDialogoImportacion={() => this._toggleDialogoImportacion()}
                />
            }

            <div className="head-seccion">
                <h2>
                    <strong>Perfil del colegio</strong>
                </h2>
            </div>
            {this.renderPedidosMagentoPopUp()}
            {this.renderSincronizacionOdooPopUp()}
            <Nav tabs>
                <NavItem>
                    <NavLink
                        active={(this.state.activeTab || ColegioEditTabs.Datos) == ColegioEditTabs.Datos}
                        onClick={() => this.setState({ activeTab: ColegioEditTabs.Datos },
                            () => {
                                this.props.history.push(RouteHelper.myProfileSchool(ColegioEditTabs.Datos));
                                this.updateBreadCrumb(this.state.editModel);
                            }
                        )}
                    >
                        {I18n.Strings.colegio.tabDatos}
                    </NavLink>
                </NavItem>
                <NavItem>
                    <NavLink
                        active={this.state.activeTab == ColegioEditTabs.CursoEscolar}
                        onClick={() => this.setState({ activeTab: ColegioEditTabs.CursoEscolar },
                            () => {
                                this.props.history.push(RouteHelper.myProfileSchool(ColegioEditTabs.CursoEscolar));
                                this.updateBreadCrumb(this.state.editModel);
                            }
                        )}
                    >
                        {I18n.Strings.colegio.tabCursoEscolar}
                    </NavLink>
                </NavItem>
                <NavItem>
                    <NavLink
                        active={this.state.activeTab == ColegioEditTabs.Credenciales}
                        onClick={() => this.setState({ activeTab: ColegioEditTabs.Credenciales },
                            () => {
                                this.props.history.push(RouteHelper.myProfileSchool(ColegioEditTabs.Credenciales));
                                this.updateBreadCrumb(this.state.editModel);
                            }
                        )}
                    >
                        {I18n.Strings.colegio.tabCredenciales}
                    </NavLink>
                </NavItem>
            </Nav>
            <TabContent activeTab={this.state.activeTab || ColegioEditTabs.Datos}>
                <TabPane tabId={ColegioEditTabs.Datos}>
                    {this._paintMainForm()}
                </TabPane>
                <TabPane tabId={ColegioEditTabs.CursoEscolar}>
                    <CursosEscolares isEmbedded={true} {...this.props.history} />
                </TabPane>
                <TabPane tabId={ColegioEditTabs.Credenciales}>
                    <Credenciales isEmbedded={true} {...this.props.history} />
                </TabPane>
            </TabContent>
            <Row className="accesos-directos">
                <Col xs="6" md="4" lg="4">

                    <Button id="save" color="primary" className="btn-rounded" onClick={() => this._validateAndSubmit()} >
                        <SaveIcon />
                        {I18n.Strings.save}
                    </Button>
                </Col>
                <Col xs="6" md="8" lg="8" className="text-end">
                    <DropDownButton botones={botones} />
                </Col>
            </Row>
        </React.Fragment>;
    };

    protected _getBotones(): JSX.Element[] {
        var botones = [];

        botones.push(<Button id="sync" color="secondary"
            disabled={this.state.editModel == null || !this.state.editModel.sincronizar || this.state.editModel.identificadorCategoriaTienda == null || this.state.editModel.identificadorCategoriaTienda == ''}
            className="btn-rounded"
            onClick={() => this.setState({ mostrarDialogoImportacionEstructura: true })}
        >
            <SyncIcon />
            Sincronizar estructura
        </Button>);

        botones.push(<Button id="syncPedidos" color="secondary"
            disabled={this.state.editModel == null || !this.state.editModel.sincronizar || this.state.editModel.identificadorTienda == null || this.state.editModel.identificadorTienda == ''}
            className="btn-rounded"
            onClick={() => {
                var start = new Date();
                start.setDate(start.getDate() - 1);
                this.setState({ showPedidosMagentoPopUp: true, syncFechaDesde: start, pedidosNumPedidos: null, pedidosIsbns: null, tipoSyncPedidos: TipoSyncPedidos.PorFecha });
            }} >
            <SyncIcon />
            {I18n.Strings.pedidos.sync}
        </Button>);

        if (ClientContext.Current.tipoUsuario == TipoUsuario.AdminGlobal) {

            botones.push(<Button color="secondary" className="btn-rounded"
                onClick={() => {
                    var service = new MiPerfilColegioService();
                    service.getServerIp()
                        .then((response) => {
                            alert(response);
                        })
                        .catch((err) => this.setState({ showLoading: false }, () => AlertsService.showError(err)));
                }}
            >
                {I18n.Strings.utils.getServerIp}
            </Button>);


            botones.push(<Button id="syncOdooNoProds" color="secondary"
                className="btn-rounded"
                onClick={() => { this.setState({ tipoSyncOdoo: TipoSyncOdoo.Maestros, mostrarDialogoSyncCompletaOdoo: true }) }}
            >
                <SyncIcon />
                Sincronizar Maestros Odoo (sin productos)
            </Button>);

            botones.push(<Button id="syncOdooProds" color="secondary"
                className="btn-rounded"
                onClick={() => this.setState({ tipoSyncOdoo: TipoSyncOdoo.MaestrosYProds, mostrarDialogoSyncCompletaOdoo: true })}
            >
                <SyncIcon />
                Sincronizar Maestros Odoo (completo)
            </Button>);

            botones.push(<Button id="syncOdooColegios" color="secondary"
                className="btn-rounded"
                onClick={() => this.setState({ tipoSyncOdoo: TipoSyncOdoo.Pedidos, mostrarDialogoSyncCompletaOdoo: true })}
            >
                <SyncIcon />
                Sincronizar Pedidos Colegios Odoo
            </Button>);

            botones.push(<Button id="syncPedidosEstructuraForAllCenters" color="secondary"
                className="btn-rounded"
                onClick={() => {
                    this.setState({ showLoading: true },
                        () => {

                            var service = new MiPerfilColegioService();
                            service.syncPedidosEstructuraForAllCenters()
                                .then(ret => {
                                    this.setState({ showLoading: false }, () => {

                                        AlertsService.showSuccessMessage(I18n.Strings.notificaciones.procesoEnCola);
                                    });
                                })
                                .catch((err) => this.setState({ showLoading: false }, () => AlertsService.showError(err)));

                        });
                }}
            >
                <SyncIcon />
                Estructura y pedidos de todos los centros
            </Button>);

            botones.push(<Button id="fixCredenciales" color="secondary"
                className="btn-rounded"
                onClick={() => {
                    this.setState({ showLoading: true },
                        () => {
                            var service = new MiPerfilColegioService();
                            service.fixCredenciales()
                                .then(ret => {
                                    this.setState({ showLoading: false }, () => {
                                        AlertsService.showSuccessMessage(I18n.Strings.ok);
                                    });
                                })
                                .catch((err) => this.setState({ showLoading: false }, () => AlertsService.showError(err)));

                        });
                }} >
                <SyncIcon />
                Arreglar credenciales
            </Button>);

            botones.push(<Button id="crearUsuarios" color="secondary"
                className="btn-rounded"
                onClick={() => {
                    this.setState({ showLoading: true },
                        () => {
                            var service = new MiPerfilColegioService();
                            service.crearUsuarios()
                                .then(ret => {
                                    this.setState({ showLoading: false }, () => {
                                        AlertsService.showSuccessMessage(I18n.Strings.ok);
                                    });
                                })
                                .catch((err) => this.setState({ showLoading: false }, () => AlertsService.showError(err)));

                        });
                }} >
                <SyncIcon />
                Crear usuarios
            </Button>);

            botones.push(<Button id="fixClasesPorDefecto" color="secondary"
                className="btn-rounded"
                onClick={() => {
                    this.setState({ showLoading: true },
                        () => {
                            var service = new MiPerfilColegioService();
                            service.fixClasesPorDefecto()
                                .then(ret => {
                                    this.setState({ showLoading: false }, () => {
                                        AlertsService.showSuccessMessage(I18n.Strings.ok);
                                    });
                                })
                                .catch((err) => this.setState({ showLoading: false }, () => AlertsService.showError(err)));

                        });
                }} >
                <SyncIcon />
                Arreglar clases por defecto
            </Button>);
        }

        return botones;
    }

    private _paintMainForm(): JSX.Element {
        return <React.Fragment>
            {this.state.showLoading && <Loading />}
            {this.state.editModel != null && <Row>
                <Col md={9}>
                    <Row>
                        <Col md={5}>
                            <FormGroup>
                                <Label for={"usuarios" + nameof(this.state.editModel.nombre)}>{I18n.Strings.colegio.nombre}</Label>
                                <Input
                                    onChange={(evt) => {
                                        var state = this._cloneStateForSetState();
                                        state.editModel.nombre = evt.target.value;
                                        this.setState(state);
                                    }}
                                    type="text"
                                    required={true}
                                    maxLength={100}
                                    className={"form-control " + this._errorClass(nameof(this.state.editModel.nombre))}
                                    name={nameof(this.state.editModel.nombre)}
                                    id={"usuarios" + nameof(this.state.editModel.nombre)}
                                    value={this.state.editModel.nombre || ""}
                                />
                                {this._errorMessage(nameof(this.state.editModel.nombre))}
                            </FormGroup>
                        </Col>
                        <Col md={4}>
                            <FormGroup>
                                <ComunidadesAutonomasDropDown
                                    label={I18n.Strings.colegio.comunidadAutonoma}
                                    onChanged={(comunidadId, comunidadNombre) => {
                                        var state = this._cloneStateForSetState();
                                        state.editModel.comunidadAutonomaId = comunidadId;
                                        state.editModel.comunidadAutonoma = comunidadNombre;
                                        this.setState(state);
                                    }}
                                    id={nameof(this.state.editModel.comunidadAutonomaId)}
                                    selectedItemValue={this.state.editModel.comunidadAutonomaId || 0}
                                    selectedItemText={this.state.editModel.comunidadAutonoma || ""}
                                    emptyText=""
                                />
                                {this._errorMessage(nameof(this.state.editModel.comunidadAutonomaId))}
                            </FormGroup>
                        </Col>
                        <Col md={3}>
                            <FormGroup>
                                <Label for={"usuarios" + nameof(this.state.editModel.codMinisterio)}>{I18n.Strings.colegio.codMinisterio}</Label>
                                <Input
                                    onChange={(evt) => {
                                        var state = this._cloneStateForSetState();
                                        state.editModel.codMinisterio = evt.target.value;
                                        this.setState(state);
                                    }}
                                    type="text"
                                    required={false}
                                    maxLength={50}
                                    className={"form-control " + this._errorClass(nameof(this.state.editModel.codMinisterio))}
                                    name={nameof(this.state.editModel.codMinisterio)}
                                    id={"usuarios" + nameof(this.state.editModel.codMinisterio)}
                                    value={this.state.editModel.codMinisterio || ""}
                                />
                                {this._errorMessage(nameof(this.state.editModel.codMinisterio))}
                            </FormGroup>
                        </Col>
                        <Col md={3}>
                            <FormGroup>
                                <Label for={"identificadorTienda" + nameof(this.state.editModel.identificadorTienda)}>{I18n.Strings.colegio.identificadorTienda}</Label>
                                <Input
                                    onChange={(evt) => {
                                        var state = this._cloneStateForSetState();
                                        state.editModel.identificadorTienda = evt.target.value;
                                        this.setState(state);
                                    }}
                                    type="text"
                                    required={false}
                                    readOnly={true}
                                    maxLength={255}
                                    className={"form-control " + this._errorClass(nameof(this.state.editModel.identificadorTienda))}
                                    name={nameof(this.state.editModel.identificadorTienda)}
                                    id={"identificadorTienda"}
                                    value={this.state.editModel.identificadorTienda || ""}
                                />
                            </FormGroup>
                        </Col>
                        <Col md={3}>
                            <FormGroup>
                                <Label for={"identificadorCategoriaTienda" + nameof(this.state.editModel.identificadorCategoriaTienda)}>{I18n.Strings.colegio.identificadorCategoriaTienda}</Label>
                                <Input
                                    onChange={(evt) => {
                                        var state = this._cloneStateForSetState();
                                        state.editModel.identificadorCategoriaTienda = evt.target.value;
                                        this.setState(state);
                                    }}
                                    type="text"
                                    required={false}
                                    readOnly={true}
                                    maxLength={255}
                                    className={"form-control " + this._errorClass(nameof(this.state.editModel.identificadorCategoriaTienda))}
                                    name={nameof(this.state.editModel.identificadorCategoriaTienda)}
                                    id={"identificadorCategoriaTienda"}
                                    value={this.state.editModel.identificadorCategoriaTienda || ""}
                                />
                            </FormGroup>
                        </Col>
                        <Col md={3}>
                            <FormGroup>
                                <Label for={nameof(this.state.editModel.sincronizar)}>{I18n.Strings.colegio.sincronizar}</Label>
                                <Input
                                    onChange={(evt) => {
                                        var state = this._cloneStateForSetState();
                                        state.editModel.sincronizar = evt.target.checked;
                                        this.setState(state);
                                    }}
                                    type="checkbox"
                                    style={{ padding: 0 }}
                                    className={"form-control " + this._errorClass(nameof(this.state.editModel.identificadorTienda))}
                                    name={nameof(this.state.editModel.sincronizar)}
                                    id={nameof(this.state.editModel.sincronizar)}
                                    checked={this.state.editModel.sincronizar || false}
                                />
                            </FormGroup>
                        </Col>
                        <Col md={3}>
                            <FormGroup>
                                <Label for={nameof(this.state.editModel.sincronizarLicencias)}>
                                    {I18n.Strings.colegio.sincronizarLicencias}
                                </Label>
                                <Input
                                    onChange={(evt) => {
                                        var state = this._cloneStateForSetState();
                                        state.editModel.sincronizarLicencias = evt.target.checked;
                                        this.setState(state);
                                    }}
                                    type="checkbox"
                                    style={{ padding: 0 }}
                                    className={"form-control " + this._errorClass(nameof(this.state.editModel.sincronizarLicencias))}
                                    name={nameof(this.state.editModel.sincronizarLicencias)}
                                    id={nameof(this.state.editModel.sincronizarLicencias)}
                                    checked={this.state.editModel.sincronizarLicencias || false}
                                />
                            </FormGroup>
                        </Col>
                        <Col md={5}>
                            <FormGroup>
                                <Label for={nameof(this.state.editModel.nombreColegioPedidosTienda)}>
                                    {I18n.Strings.colegio.nombreColegioPedidosTienda}
                                </Label>
                                <Input
                                    onChange={(evt) => {
                                        var state = this._cloneStateForSetState();
                                        state.editModel.nombreColegioPedidosTienda = evt.target.value;
                                        this.setState(state);
                                    }}
                                    type="text"
                                    required={false}
                                    maxLength={150}
                                    className={"form-control " + this._errorClass(nameof(this.state.editModel.nombreColegioPedidosTienda))}
                                    name={nameof(this.state.editModel.nombreColegioPedidosTienda)}
                                    id={"nombreColegioPedidosTienda"}
                                    value={this.state.editModel.nombreColegioPedidosTienda || ""}
                                />
                            </FormGroup>
                        </Col>
                        <Col md={7}>
                            <FormGroup>
                                <Label for={"identificadorCategoriaTienda" + nameof(this.state.editModel.dominiosAutorizados)}>{I18n.Strings.colegio.dominiosAutorizados}</Label>
                                <Input
                                    onChange={(evt) => {
                                        var state = this._cloneStateForSetState();
                                        state.editModel.dominiosAutorizados = evt.target.value;
                                        this.setState(state);
                                    }}
                                    type="text"
                                    required={false}
                                    maxLength={500}
                                    className={"form-control " + this._errorClass(nameof(this.state.editModel.dominiosAutorizados))}
                                    name={nameof(this.state.editModel.dominiosAutorizados)}
                                    id={"dominiosAutorizados"}
                                    value={this.state.editModel.dominiosAutorizados || ""}
                                />
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={4}>
                            <FormGroup>
                                <Label for={nameof(this.state.editModel.allowGoogleLogin)}>
                                    {I18n.Strings.colegio.allowGoogleLogin}</Label>
                                <Input
                                    onChange={(evt) => {
                                        var state = this._cloneStateForSetState();
                                        state.editModel.allowGoogleLogin = evt.target.checked;
                                        this.setState(state);
                                    }}
                                    type="checkbox"
                                    style={{ padding: 0 }}
                                    className={"form-control " + this._errorClass(nameof(this.state.editModel.allowGoogleLogin))}
                                    name={nameof(this.state.editModel.allowGoogleLogin)}
                                    id={"allowGoogleLogin"}
                                    checked={this.state.editModel.allowGoogleLogin || false}
                                />
                            </FormGroup>
                        </Col>
                        <Col md={4}>
                            <FormGroup>
                                <Label for={nameof(this.state.editModel.allowMicrosoftLogin)}>
                                    {I18n.Strings.colegio.allowMicrosoftLogin}</Label>
                                <Input
                                    onChange={(evt) => {
                                        var state = this._cloneStateForSetState();
                                        state.editModel.allowMicrosoftLogin = evt.target.checked;
                                        this.setState(state);
                                    }}
                                    type="checkbox"
                                    style={{ padding: 0 }}
                                    className={"form-control " + this._errorClass(nameof(this.state.editModel.allowMicrosoftLogin))}
                                    name={nameof(this.state.editModel.allowMicrosoftLogin)}
                                    id={"allowMicrosoftLogin"}
                                    checked={this.state.editModel.allowMicrosoftLogin || false}
                                />
                            </FormGroup>
                        </Col>
                        <Col md={4}>
                            <FormGroup>
                                <Label for={nameof(this.state.editModel.allowCustomLogin)}>
                                    {I18n.Strings.colegio.allowCustomLogin}</Label>
                                <Input
                                    onChange={(evt) => {
                                        var state = this._cloneStateForSetState();
                                        state.editModel.allowCustomLogin = evt.target.checked;
                                        this.setState(state);
                                    }}
                                    type="checkbox"
                                    style={{ padding: 0 }}
                                    className={"form-control " + this._errorClass(nameof(this.state.editModel.allowCustomLogin))}
                                    name={nameof(this.state.editModel.allowCustomLogin)}
                                    id={"allowCustomLogin"}
                                    checked={this.state.editModel.allowCustomLogin || false}
                                />
                            </FormGroup>
                        </Col>
                        <Col md={4}>
                            <FormGroup>
                                <Label for={nameof(this.state.editModel.comunicarAsignacionLicencias)}>
                                    {I18n.Strings.colegio.comunicarAsignacionLicencias}</Label>
                                <Input
                                    onChange={(evt) => {
                                        var state = this._cloneStateForSetState();
                                        state.editModel.comunicarAsignacionLicencias = evt.target.checked;
                                        this.setState(state);
                                    }}
                                    type="checkbox"
                                    style={{ padding: 0 }}
                                    className={"form-control " + this._errorClass(nameof(this.state.editModel.comunicarAsignacionLicencias))}
                                    name={nameof(this.state.editModel.comunicarAsignacionLicencias)}
                                    id={"comunicarAsignacionLicencias"}
                                    checked={this.state.editModel.comunicarAsignacionLicencias || false}
                                />
                            </FormGroup>
                        </Col>
                        <Col md={4}>
                            <FormGroup>
                                <Label for={nameof(this.state.editModel.comunicarCredencialesLicenciasAlAsignar)}>
                                    {I18n.Strings.colegio.comunicarCredencialesLicenciasAlAsignar}</Label>
                                <Input
                                    onChange={(evt) => {
                                        var state = this._cloneStateForSetState();
                                        state.editModel.comunicarCredencialesLicenciasAlAsignar = evt.target.checked;
                                        this.setState(state);
                                    }}
                                    type="checkbox"
                                    style={{ padding: 0 }}
                                    className={"form-control " + this._errorClass(nameof(this.state.editModel.comunicarCredencialesLicenciasAlAsignar))}
                                    name={nameof(this.state.editModel.comunicarCredencialesLicenciasAlAsignar)}
                                    id={"comunicarCredencialesLicenciasAlAsignar"}
                                    checked={this.state.editModel.comunicarCredencialesLicenciasAlAsignar || false}
                                />
                            </FormGroup>
                        </Col>
                        <Col md={8}>
                            <FormGroup>
                                <Label for={"usuarios" + nameof(this.state.editModel.emailColegio)}>{I18n.Strings.colegio.emailColegio}</Label>
                                <Input
                                    onChange={(evt) => {
                                        var state = this._cloneStateForSetState();
                                        state.editModel.emailColegio = evt.target.value;
                                        this.setState(state);
                                    }}
                                    type="text"
                                    required={true}
                                    maxLength={100}
                                    className={"form-control " + this._errorClass(nameof(this.state.editModel.emailColegio))}
                                    name={nameof(this.state.editModel.emailColegio)}
                                    id={"usuarios" + nameof(this.state.editModel.emailColegio)}
                                    value={this.state.editModel.emailColegio || ""}
                                />
                                {this._errorMessage(nameof(this.state.editModel.emailColegio))}
                            </FormGroup>
                        </Col>
                    </Row>
                </Col>
                <Col md={3}>
                    <div>
                        <div className="mainPhoto">
                            <img
                                className="mt-xl"
                                src={this.state.editModel.foto == null ? '/img/caratula.png' : this.state.editModel.foto}
                            />
                            {this.state.editModel.foto != null && <div className="mainPhotoMenu">

                            </div>
                            }
                        </div>
                        {this.state.editModel.id > 0 && <div>
                            <DocumentUpload
                                manualUpload={false}
                                style={{ fontSize: 14, overflow: "ellipsis" }}
                                title={I18n.Strings.colegio.subirFoto}
                                zoneStyle={{ margin: 0, padding: 10 }}
                                uploadUrl={`intranet/miPerfilColegio/updateProfilePhoto`}
                                multiple={false}
                                onUpload={(data) => {
                                    this._updateStateValue(data.documentUrl, nameof.full(this.state.editModel.foto, 2));
                                    ClientContext.Current.logoColegioUrl = this.state.editModel.foto;
                                }}
                            />
                        </div>}
                    </div>
                </Col>
            </Row>}
        </React.Fragment>;
    }
}

export const Edit = withRouter(EditComp)