import React from 'react'; // we need this to make JSX compile

const propsFijas = {
    size: 16,
    style: {
        verticalAlign: "text-bottom"
    }
};

export interface PropsIcono {
    //tag?: string
    cssClass?: string
    onClick?: React.MouseEventHandler<any>
}


export const SaveIcon = (props: PropsIcono) => <i className="bi bi-save" onClick={props.onClick} />;
export const CancelIcon = (props: PropsIcono) => <i className={`bi bi-x-circle ${props.cssClass}` } onClick={props.onClick} />;
export const SearchIcon = (props: PropsIcono) => <i className={`bi bi-search ${props.cssClass}`} onClick={props.onClick} />;
export const ViewIcon = (props: PropsIcono) => <i className={`bi bi-search ${props.cssClass}`} onClick={props.onClick} />;
export const UserIcon = (props: PropsIcono) => <i className={`bi bi-person ${props.cssClass}`} onClick={props.onClick} />;

export const AddNewIcon = (props: PropsIcono) => <i className={`bi bi-plus ${props.cssClass}`} onClick={props.onClick} />;
export const AddNewItemIcon = (props: PropsIcono) => <i className="bi bi-file-earmark-plus" onClick={props.onClick} />;
export const CheckIcon = (props: PropsIcono) => <i className={`bi bi-check ${props.cssClass}`} onClick={props.onClick} />;
export const CheckCircleIcon = (props: PropsIcono) => <i className={`bi bi-check-circle ${props.cssClass}`} onClick={props.onClick} />;
export const DeleteIcon = (props: PropsIcono) => <i className={`bi bi-trash ${props.cssClass}`} onClick={props.onClick} {...propsFijas} />;
export const EditIcon = (props: PropsIcono) => <i className={`bi bi-pen ${props.cssClass}`} onClick={props.onClick} />;
export const SyncIcon = (props: PropsIcono) => <i className={`bi bi-cloud-download ${props.cssClass}`} onClick={props.onClick} />;
export const GraphIcon = (props: PropsIcono) => <i className={`bi bi-graph-up ${props.cssClass}`} onClick={props.onClick} />;
export const DownIcon = (props: PropsIcono) => <i className={`bi bi-chevron-down ${props.cssClass}`} onClick={props.onClick} {...propsFijas} />;
export const UpIcon = (props: PropsIcono) => <i className={`bi bi-chevron-up ${props.cssClass}`} onClick={props.onClick} {...propsFijas} />;
export const ExcelIcon = (props: PropsIcono) => <i className={`bi bi-filetype-xlsx ${props.cssClass}`} onClick={props.onClick} />;
export const FileIcon = (props: PropsIcono) => <i className={`bi bi-file-earmark-text ${props.cssClass}`} onClick={props.onClick} />;
export const DownloadIcon = (props: PropsIcono) => <i className={`bi bi-download ${props.cssClass}`} onClick={props.onClick} />;
export const JoinUsersIcon = (props: PropsIcono) => <i className={`bi bi-person-plus-fill ${props.cssClass}`} onClick={props.onClick} />;

export const RefreshIcon = (props: PropsIcono) => <i className={`bi bi-arrow-clockwise ${props.cssClass}`} onClick={props.onClick} />;

export const SpinnerIcon = (props: PropsIcono) => <div className="spinner-border text-primary" role="status"><span className="sr-only"></span></div>

