
import {
    ContenidosColegioEditModel, ContenidosDigitalesColegioListItemPaginatedList, ContenidosDigitalesColegioSearch, ModelValidationResult,
    ContenidosSyncResult, 
    ExportFileResult,
    ContenidosColegioChangeAccesoMessage
} from "../../models/dataModels";
import { ApiFetcher } from "../../utils/ApiFetcher";
import { IServiceForEdit, IServiceForIndex } from "../../utils/base/BaseServices";
import { downloadFileFromBase64Array } from "../../utils/ValuesFormatter";

export class ContenidosColegioService
    implements IServiceForIndex<ContenidosDigitalesColegioSearch, ContenidosDigitalesColegioListItemPaginatedList>, IServiceForEdit<ContenidosColegioEditModel>
{

    search(searchData: ContenidosDigitalesColegioSearch): Promise<ContenidosDigitalesColegioListItemPaginatedList> {

        var apiFetcher = new ApiFetcher();
        var prom = apiFetcher.post<ContenidosDigitalesColegioSearch, ContenidosDigitalesColegioListItemPaginatedList>("intranet/contenidosColegio/search", searchData);
        return prom;
    }

    delete(id: number): Promise<any> {
        throw new Error("Method not implemented.");
        //var apiFetcher = new ApiFetcher();
        //var prom = apiFetcher.delete<any>(`intranet/contenidosColegio/${id}`);
        //return prom;
    }

    getById(id: string | number): Promise<ContenidosColegioEditModel> {
        var apiFetcher = new ApiFetcher();
        var prom = apiFetcher.getByUrl<ContenidosColegioEditModel>(`intranet/contenidosColegio/${id}`);
        return prom;
    }
    validate(model: ContenidosColegioEditModel): Promise<ModelValidationResult> {
        var apiFetcher = new ApiFetcher();
        var prom = apiFetcher.post<ContenidosColegioEditModel, ModelValidationResult>(`intranet/contenidosColegio/validate`, model);
        return prom;
    }

    create(model: ContenidosColegioEditModel): Promise<ContenidosColegioEditModel> {
        throw new Error("Method not implemented.");
        //var apiFetcher = new ApiFetcher();
        //var prom = apiFetcher.post<ContenidosColegioEditModel, ContenidosColegioEditModel>(`intranet/contenidosColegio/`, model);
        //return prom;
    }

    update(id: string | number, model: ContenidosColegioEditModel): Promise<ContenidosColegioEditModel> {
        var apiFetcher = new ApiFetcher();
        var prom = apiFetcher.put<ContenidosColegioEditModel, ContenidosColegioEditModel>(`intranet/contenidosColegio/${id}`, model);
        return prom;
    }

    syncContenidosColegio(campannaId: number): Promise<ContenidosSyncResult> {
        var apiFetcher = new ApiFetcher();
        var prom = apiFetcher.post<any, ContenidosSyncResult>(`intranet/contenidos/syncContenidosColegio/${campannaId}`, null);
        return prom;
    }

    exportExcel(searchModel: ContenidosDigitalesColegioSearch): Promise<ExportFileResult> {

        var url = "intranet/contenidosColegio/exportExcel";

        var apiFetcher = new ApiFetcher();
        var promise = apiFetcher.post<ContenidosDigitalesColegioSearch, ExportFileResult>(url, searchModel)
            .then((result) => {
                downloadFileFromBase64Array(result.content, result.fileName, "application/octet-stream");
                return result;
            });
        return promise;
    }

    cambiarAcceso(model: ContenidosColegioChangeAccesoMessage): Promise<boolean> {

        var url = "intranet/contenidosColegio/cambiarAcceso";

        var apiFetcher = new ApiFetcher();
        var promise = apiFetcher.post<ContenidosColegioChangeAccesoMessage, boolean>(url, model);           
        return promise;
    }
}