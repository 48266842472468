
import { ContenidosEditModel, ContenidosDigitalesListItemPaginatedList, ContenidosSearch, ModelValidationResult, ContenidosSyncResult, BasicData, ExportFileResult, SyncContenidoModel } from "../../models/dataModels";
import { ApiFetcher } from "../../utils/ApiFetcher";
import { IServiceForEdit, IServiceForIndex } from "../../utils/base/BaseServices";
import { downloadFileFromBase64Array } from "../../utils/ValuesFormatter";

export class ContenidosService
    implements IServiceForIndex<ContenidosSearch, ContenidosDigitalesListItemPaginatedList>, IServiceForEdit<ContenidosEditModel>
{

    search(searchData: ContenidosSearch): Promise<ContenidosDigitalesListItemPaginatedList> {

        var apiFetcher = new ApiFetcher();
        var prom = apiFetcher.post<ContenidosSearch, ContenidosDigitalesListItemPaginatedList>("intranet/contenidos/search", searchData);
        return prom;
    }

    delete(id: number): Promise<any> {
        var apiFetcher = new ApiFetcher();
        var prom = apiFetcher.delete<any>(`intranet/contenidos/${id}`);
        return prom;
    }

    getById(id: string | number): Promise<ContenidosEditModel> {
        var apiFetcher = new ApiFetcher();
        var prom = apiFetcher.getByUrl<ContenidosEditModel>(`intranet/contenidos/${id}`);
        return prom;
    }
    validate(model: ContenidosEditModel): Promise<ModelValidationResult> {
        var apiFetcher = new ApiFetcher();
        var prom = apiFetcher.post<ContenidosEditModel, ModelValidationResult>(`intranet/contenidos/validate`, model);
        return prom;
    }

    create(model: ContenidosEditModel): Promise<ContenidosEditModel> {
        var apiFetcher = new ApiFetcher();
        var prom = apiFetcher.post<ContenidosEditModel, ContenidosEditModel>(`intranet/contenidos/`, model);
        return prom;
    }

    update(id: string | number, model: ContenidosEditModel): Promise<ContenidosEditModel> {
        var apiFetcher = new ApiFetcher();
        var prom = apiFetcher.put<ContenidosEditModel, ContenidosEditModel>(`intranet/contenidos/${id}`, model);
        return prom;
    }

    getBasicById(id: string | number): Promise<BasicData> {
        var apiFetcher = new ApiFetcher();
        var prom = apiFetcher.getByUrl<BasicData>(`intranet/contenidos/${id}/basic`);
        return prom;
    }

    queueContenidosSync(campanaId: number, isbns: string[]): Promise<ContenidosSyncResult> {
        
        var model = {
            campannaId: campanaId,    
            contenidoIsbns: isbns
        } as SyncContenidoModel;

        var apiFetcher = new ApiFetcher();
        var prom = apiFetcher.post<SyncContenidoModel, ContenidosSyncResult>(`intranet/contenidos/syncContenidosColegio/${campanaId}`, model);
        return prom;
    }


    syncEstructura(contenidoId: number): Promise<boolean> {
       
        var apiFetcher = new ApiFetcher();
        var prom = apiFetcher.post<any, boolean>(`intranet/contenidos/${contenidoId}/syncEstructura`, null);
        return prom;
    }

    exportExcel(searchModel: ContenidosSearch): Promise<ExportFileResult> {

        var url = "intranet/contenidos/exportExcel";

        var apiFetcher = new ApiFetcher();
        var promise = apiFetcher.post<ContenidosSearch, ExportFileResult>(url, searchModel)
            .then((result) => {
                downloadFileFromBase64Array(result.content, result.fileName, "application/octet-stream");
                return result;
            });
        return promise;
    }
}