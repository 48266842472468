import React from "react";
import { ApiFetcher } from "../utils/ApiFetcher";
import { GetLicenciasContenidosResponse, LicenciaContenidosItem } from "../models/dataModels";
import { Loading } from "../utils/Loading";
import LicenciaEnlace from "./LicenciaEnlace";
import { AlertsService } from "../utils/AlertsService";
import { ClientContext } from "../utils/ClientContext";



interface MisLicenciasState {
    loading: boolean;
    items: LicenciaContenidosItem[];
}

export class MisLicenciasHome extends React.Component<any, MisLicenciasState> {

    public constructor(props: any) {
        super(props);
        this.state = {
            loading: true,
            items: []
        };
    }

    componentDidMount() {
        this.readLicenses();
    }

    render() {

        return <React.Fragment>

            <div className="accesos-directos">
                {this.state.loading && <Loading />}

                {this.state.items.length == 0 && <React.Fragment>
                    El usuario no tiene licencias activas.
                </React.Fragment>}

                {this.state.items.length != 0 && <React.Fragment>
                    <div className="d-flex fichas">
                        {this.state.items.map((item, index) => {
                            return <LicenciaEnlace key={index} licencia={item} />;
                        })}
                    </div>
                </React.Fragment>}
            </div>

        </React.Fragment>
    }


    private readLicenses() {
        if (ClientContext.Current.cursoEscolarActivoId == null) {
            return;
        }


        var apiFetcher = new ApiFetcher();
        var prom = apiFetcher.getByUrl<GetLicenciasContenidosResponse>(`intranet/licencias/mias/${ClientContext.Current.cursoEscolarActivoId}`);
        prom.then((result: GetLicenciasContenidosResponse) => {
                this.setState({
                    items: result.contenidos,
                    loading: false
                });
            })
            .catch((error) => this.setState({}, () => AlertsService.showError(error)));

    }

}