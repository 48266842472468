import * as React from "react";

import nameof from "ts-nameof.macro";

import { withRouter } from "react-router-dom";
import { MatriculaEditModel, TipoUsuarioContenido } from "../../../models/dataModels";
import { GenericEditState } from "../../../utils/base/GenericEditState";
import { EditPageBase } from "../../../utils/base/EditPageBase";
import { IServiceForEdit } from "../../../utils/base/BaseServices";
import { MisMatriculasService } from "./MisMatriculasService";
import { RouteHelper } from "../../../IntranetRouter";
import { Col, FormGroup, Input, Label, Row } from "reactstrap";
import { I18n } from "../../../utils/I18n";
import { ModelValidation } from "../../../utils/base/ModelValidation";

import { NivelEducativoDropDown } from "../../../cursoEscolar/NivelEducativoDropDown";
import { ClaseMateriaDropDown } from "../../../cursoEscolar/materia/ClaseMateriaDropDown";
import { ClientContext } from "../../../utils/ClientContext";

import { ColegioUsuariosService } from "../../../colegioUsuarios/ColegioUsuariosService";
import { TipoUsuarioContenidoDropDown } from "../../../helpers/EnumDropDown";

export class EditComp extends EditPageBase<MatriculaEditModel> {

    protected _setPageTitleForNew(): string {
        return "Nueva matrícula"
    }

    protected _setPageTitleForExisting(editModel: MatriculaEditModel): string {
        return `Editar matrícula`;
    }

    protected _createApiService(): IServiceForEdit<MatriculaEditModel> {
        return new MisMatriculasService();
    }

    protected _generateForm(): JSX.Element {
        return <React.Fragment>
            <Row>
                <Col md={3}>
                    <FormGroup>
                        <Label for={nameof(this.state.editModel.usuario)}>
                            {I18n.Strings.licenciasContenido.usuario}
                        </Label>
                        <Input
                            type="text"
                            disabled={true}
                            className={"form-control"}
                            name={nameof(this.state.editModel.usuario)}
                            id={"usuarios" + nameof(this.state.editModel.usuario)}
                            value={this.state.editModel.usuario || ""}
                        />
                    </FormGroup>
                    {/* <ColegioUsuariosPicker
                        label={I18n.Strings.licenciasContenido.usuario}
                        required={true}
                        itemId={this.state.editModel.usuarioId}
                        itemName={this.state.editModel.usuario}
                        getIdFromItem={(item) => item.id}
                        getNameFromItem={(item) => item.nombre}
                        disabled={true}
                        errorMessage={this.state.validationResult.getError(nameof(this.state.editModel.usuarioId))}
                        onSelected={(usuarioId, usuario, item) => {
                            var state = this._cloneStateForSetState();
                            state.editModel.usuarioId = usuarioId;
                            state.editModel.usuario = usuario;
                            this.setState(state);
                        }}
                    /> */}

                </Col>
                <Col md="3">
                    <FormGroup>
                        <Label for={nameof(this.state.editModel.clase)}>
                            {I18n.Strings.matriculas.nombreClase}
                        </Label>

                        <NivelEducativoDropDown
                            id={nameof(this.state.editModel.clase)}

                            cursoEscolarId={ClientContext.Current.cursoEscolarActivoId}

                            value={this.state.editModel.claseId}
                            text={this.state.editModel.clase}
                            seleccionClases={true}

                            onChange={(id, nombre) => {
                                var state = this._cloneStateForSetState();
                                state.editModel.claseId = id;
                                state.editModel.clase = nombre;
                                state.editModel.materia = null;
                                state.editModel.claseMateriaId = null;
                                this.setState(state);
                            }}
                        />

                        {this._errorMessage(nameof(this.state.editModel.claseId))}
                    </FormGroup>
                </Col>
                <Col md="4">
                    <ClaseMateriaDropDown
                        label={I18n.Strings.matriculas.nombreMateria}
                        key={this.state.editModel.claseId}
                        selectedItemValue={this.state.editModel.claseMateriaId}
                        claseId={this.state.editModel.claseId}
                        errorMessage={this.state.validationResult.getError(nameof(this.state.editModel.claseMateriaId))}
                        selectedItemText={this.state.editModel.materia}
                        onChanged={(materiaId, materia, item) => {
                            var state = this._cloneStateForSetState();
                            state.editModel.claseMateriaId = materiaId;
                            state.editModel.materia = materia;
                            this.setState(state);
                        }}
                    />
                </Col>
                <Col md={2}>
                    <TipoUsuarioContenidoDropDown
                        label={I18n.Strings.matriculas.rol}
                        showLabel={true}
                        id={nameof(this.state.editModel.rol)}
                        disabled={true}
                        value={this.state.editModel.rol}
                        required={true}
                        errorMessage={this.state.validationResult.getError(nameof(this.state.editModel.rol))}
                        onChanged={(value: string) => {
                            var newState = this._cloneStateForSetState();
                            if (value != '') {
                                newState.editModel.rol = value as TipoUsuarioContenido;
                            }
                            else {
                                newState.editModel.rol = null;
                            }
                            this.setState(newState);
                        }}
                    />
                </Col>
            </Row>
        </React.Fragment>;
    }

    protected _getEditUrl(id: number): string {
        return RouteHelper.editMiMatriculaProfesor(id, this.props.match.params.usuarioId);
    }

    protected _getListUrl(): string {
        return RouteHelper.listMisMatriculas();
    }

    protected _createStateForNew(): Promise<GenericEditState<MatriculaEditModel>> {


        var model = new GenericEditState<MatriculaEditModel>();
        model.editModel = {
            id: 0,
            colegioId: 0,
            clase: "",
            claseId: null,
            rol: TipoUsuarioContenido.Profesor,
            usuario: "",
            usuarioId: null
        } as MatriculaEditModel;

        if (this.props.match.params.usuarioId != null) {
            var colegioUsuariosSrv = new ColegioUsuariosService();
            var promData = colegioUsuariosSrv.getBasicById(this.props.match.params.usuarioId);

            var modelWithDataProm = promData.then(data => {
                model.editModel.usuario = data.nombre;
                model.editModel.usuarioId = data.id;
                return model;
            });

            return modelWithDataProm;
        }
        else {

            return Promise.resolve(model);
        }
    }

    protected _validateModelLocal(): ModelValidation {
        let validation = new ModelValidation();

        if (this.state.editModel.usuarioId == null) {
            validation.addError(nameof(this.state.editModel.usuarioId), I18n.Strings.validationErrors.valueRequired(I18n.Strings.matriculas.nombreUsuario));
        }

        if (this.state.editModel.claseId == null) {
            validation.addError(nameof(this.state.editModel.claseId), I18n.Strings.validationErrors.valueRequired(I18n.Strings.matriculas.nombreClase));
        }

        if (this.state.editModel.claseMateriaId == null) {
            validation.addError(nameof(this.state.editModel.claseMateriaId), I18n.Strings.validationErrors.valueRequired(I18n.Strings.matriculas.nombreMateria));
        }

        if (this.state.editModel.rol == null) {
            validation.addError(nameof(this.state.editModel.rol), I18n.Strings.validationErrors.valueRequired(I18n.Strings.matriculas.rol));
        }

        return validation;
    }
}

export const Edit = withRouter(EditComp)