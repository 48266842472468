import React from 'react';
import ReactDOM from 'react-dom';
import { IntranetRouter } from "./IntranetRouter";
import { AuthService } from './services/AuthService';
import { ApiFetcher, Config, ConfigInitializer } from './utils/ApiFetcher';

// Este es el fichero de inicializacion


ConfigInitializer.Initialize()
    .then((config: Config) => {
        ApiFetcher.Init(config);
        AuthService.Init(config);
        
        var authService = new AuthService();
        var prom = authService.getUser();
        prom.then(user => {
            renderApp();
        });

    });


function renderApp() {
    ReactDOM.render(
        <React.StrictMode>
            <React.Suspense fallback={<span>Cargando...</span>}>                
                <IntranetRouter />
            </React.Suspense>
        </React.StrictMode>,
        document.getElementById('root')
    );
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
//reportWebVitals();