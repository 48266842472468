import * as React from "react";
import { Link, RouteComponentProps } from "react-router-dom";
import { Alert, Button, Col, Input, Row } from "reactstrap";
import { DropDownButton } from "../../helpers/DropDownButton";
import { SearchModelBase, SortDirection, TipoUsuario } from "../../models/dataModels";
import { AlertsService } from "../AlertsService";
import { ClientContext } from '../ClientContext';
import { DeleteModal } from "../DeleteModal";
import { I18n } from "../I18n";
import { AddNewIcon, DeleteIcon, DownIcon, EditIcon, ExcelIcon, SearchIcon, UpIcon } from "../Icons";
import { Loading } from "../Loading";
import { IEntity, Pager, PaginatedListBase } from "../Pager";
import { SimpleSearchState } from "../PaginatedSearchState";
import { IServiceForIndex } from "./BaseServices";
import { NavigationHelper } from "./Breadcrumb";
import { ComponentBase } from "./ComponentBase";


interface IndexProps<TSearchModel, TSearchResult> {
    isEmbedded?: boolean;
    renderResultsDiv?: boolean;
    searchModel?: TSearchModel;
    showTitle?: boolean;
    isViewMode?: boolean;
    hideDeleteButton?: boolean;
    hideToolbar?: boolean;
    hideGridButtons?: boolean;
    onReadData?: (TSearchResult) => void;
}

export interface TableColumn<ItemType> {
    title: string;
    fieldName: string,
    colMdWidth?: number,
    renderField?: (row: ItemType) => JSX.Element | string
}

export abstract class IndexPageBase<TSearchModel extends SearchModelBase, TSearchResult, TResultItem extends IEntity>
    extends ComponentBase<IndexProps<TSearchModel, TSearchResult> & RouteComponentProps<any>, SimpleSearchState<TSearchModel, TSearchResult>>
{

    protected hideGridButtonsOverride(item: TResultItem) {
        return this.props.hideDeleteButton;
    }

    /**
     * El nombre de la clave de cache para guardar el estado inicial de la pantalla
     */
    protected _cacheKeyName: string = window.location.pathname;

    public constructor(props: IndexProps<TSearchModel, TSearchResult> & RouteComponentProps<any>) {
        super(props);

        var searchParams = {
            rowsPerPage: 10,
            orderMode: SortDirection.Asc,
            ...this._getInitialSearchParams(),
        };

        if (!this.props.isEmbedded && ClientContext.globalCache[this._cacheKeyName]) {
            var cachedSearchParams = ClientContext.globalCache[this._cacheKeyName];

            searchParams = cachedSearchParams;
        }

        this.state = {
            searchParams: searchParams,
            showLoadingIcon: true,     
            searchResults: null,
            checkedElements: []
        };
    }

    componentDidMount() {
        this._performSearch();
        if (!this.props.isEmbedded) {
            NavigationHelper.setBreadcrumbItems([
                {
                    text: this._getPageTitle(),
                    link: "."
                }
            ]);
        }
    }



    /**
     * Obtiene el modelo de busqueda inicial
     * @returns
     */
    protected _getInitialSearchParams(): TSearchModel {

        return (this.props.searchModel || {}) as TSearchModel;
    }

    /**
     * Obtiene el servicio del API     
     */
    protected abstract _getApiService(): IServiceForIndex<TSearchModel, TSearchResult>;

    /**
     * Obtiene las columnas de la tabla a pintar     
     */
    protected abstract _getTableColumns(): TableColumn<TResultItem>[];

    /**
     * El titulo de la cabcera de la p�gina
     */
    protected abstract _getPageTitle(): string;

    /**
     * La descripci�n de la p�gina
     */
    protected abstract _getPageDescription(): string;

    /**     
     * Si se habilita o no la exportaci�n de excel
     * @returns
     */
    protected _enableExcelExport(): boolean {
        return false;
    }

    protected _toolbarLeftExtraContents(): JSX.Element[] {
        var results = [];
        return results;
    }

    protected _renderExtraContent(): JSX.Element {
        return <React.Fragment></ React.Fragment>
    }

    protected _toolbarRightContents(): JSX.Element[] {
        var results = [];

        if (this._enableExcelExport()) {
            results.push(
                <Button
                    key="excelExport"
                    className="btn-rounded"
                    color="secondary"
                    onClick={() => this._exportExcel(this.state.searchParams)}
                >
                    <ExcelIcon cssClass="pe-2" />
                    {I18n.Strings.export}
                </Button>
            );
        }

        return results;
    }

    /**
     * Si se muestran o no los checkboxes en las filas
     * @returns
     */
    protected _showCheckboxes(): boolean {
        return false;
    }

    /**
     * Realiza las busquedas (invocando al metodo search del servicio)
     */
    protected _performSearch() {

        this.setState({ showLoadingIcon: true },
            () => {
                var service = this._getApiService();
                service.search(this.state.searchParams)
                    .then(data => {

                        if (!this.props.isEmbedded && this._cacheKeyName) {
                            ClientContext.globalCache[this._cacheKeyName] = this.state.searchParams;
                        }

                        this.setState({
                            searchResults: data,
                            showLoadingIcon: false,
                            showDeleteConfirmation: null,
                            checkedElements: [],
                            allChecked: false
                        }, () => {
                            if (this.props.onReadData != null) {
                                this.props.onReadData(data);
                            }
                        })
                    })
                    .catch(error => this._showError(error));
            }
        );
    }



    /**
     * Muestra un mensaje de error
     * @param error: El mensaje de error a mostrar o la excepci�n que se ha capturado
     */
    protected _showError(error: any) {
        this.setState(
            {
                showDeleteConfirmation: null,
                showLoadingIcon: false
            },
            () => AlertsService.showError(error)
        );
    }

    private renderSortIcon(columnName: string) {
        if (columnName === this.state.searchParams.orderByColumn) {
            return this.state.searchParams.orderMode === SortDirection.Asc ? <UpIcon /> : <DownIcon />;
        }
    }

    /**
     * Obtiene la url a la que va a enviarse el usuario cuando quiere editar un item
     * @param item El item que se va editar
     */
    protected abstract _getEditionItemUrl(item: TResultItem): string;

    /**
     * Obtiene la URL a la que se va reenviar al usuario cuando se quiera crear un elemento nuevo
     */
    protected abstract _getNewItemUrl(): string;


    public render() {

        var columns = this._getTableColumns();
        var searchResults = this.state.searchResults as any as PaginatedListBase<TResultItem>;
        var buttons = this._toolbarRightContents();

        return <div>
            {this.state.showLoadingIcon && <Loading />}

            {this.state.showDeleteConfirmation != null &&
                <DeleteModal
                    onCancelDelete={() => this.setState({ showDeleteConfirmation: null })}
                    onConfirm={() => this._performDelete(this.state.showDeleteConfirmation)}
                />
            }

            

            {this._renderExtraContent()}

            {(!this.props.isEmbedded || this.props.showTitle) &&
                <div className="head-seccion">
                    <h2><strong>{this._getPageTitle()}</strong></h2>
                    <p>{this._getPageDescription()}</p>
                </div>
            }
            {!this.props.isEmbedded &&
                <div>
                    <form autoComplete="off"
                        onSubmit={(evt) => {
                            evt.preventDefault();
                            return false;
                        }}
                        className="form-buscar">
                        <Row>

                            {this._renderSearchForm()}

                            <div className="col-auto pt-4">
                                <Button type="submit" className="btn-secondary btn-rounded"
                                    onClick={() => {
                                        var newState = this._cloneStateForSetState();
                                        newState.searchParams.currentPage = 0;
                                        this.setState(newState, () => this._performSearch());
                                    }
                                    }>
                                    {I18n.Strings.search}
                                </Button>
                            </div>
                        </Row>
                    </form>
                </div>
            }
            <div className="licencias">
                {this._showToolbar() &&
                    <div className="tools-colegio">
                        <Row>
                            <Col xs="6" md="4" lg="4">
                                {/* Botones de la barra a la izquierda */}
                                {!this.props.isViewMode && this._canAddItems() &&
                                    <Link className="btn btn-secondary btn-rounded" to={this._getNewItemUrl()}>
                                        <AddNewIcon />
                                        {I18n.Strings.addNew}
                                    </Link>
                                }
                                {this._toolbarLeftExtraContents()}
                            </Col>
                            <Col xs="6" md="8" lg="8" className="text-end">
                                {/* Botones de la barra a la derecha */}
                                {buttons.length > 0 && <React.Fragment>

                                    {buttons.length == 1 && buttons[0]}

                                    {buttons.length > 1 &&
                                        <DropDownButton botones={buttons} />
                                    }
                                </React.Fragment>}

                            </Col>
                        </Row>
                    </div>
                }
                {this.state.allChecked && searchResults != null && searchResults.totalCount > this.state.searchParams.rowsPerPage &&
                    <Row>
                        <Col xs="12">
                            <Alert color="warning">{I18n.Strings.grid.allCheckedWarning}</Alert>
                        </Col>
                    </Row>
                }

                <Row>
                    <Col xs="12">
                        {searchResults != null && searchResults.items.length === 0 && <React.Fragment>
                            <p>{I18n.Strings.noResults}</p>
                        </React.Fragment>}
                        {searchResults != null && searchResults.items.length !== 0 && <React.Fragment>
                            {!this.props.renderResultsDiv && this.renderSearchResultsTable(columns, searchResults)}
                            {this.props.renderResultsDiv && this.renderSearchResultsDivs(columns, searchResults)}
                            <Pager
                                paginatedList={searchResults}
                                pageSize={this.state.searchParams.rowsPerPage}

                                onClick={(pNum) => {
                                    var state = this._cloneStateForSetState();
                                    state.searchParams.currentPage = pNum;
                                    this.setState(state, () => this._performSearch());
                                }}
                                onPageSizeChange={(newSize) => {
                                    var state = this._cloneStateForSetState();
                                    state.searchParams.rowsPerPage = newSize;
                                    state.searchParams.currentPage = 0;
                                    this.setState(state, () => this._performSearch());
                                }}
                            />
                        </React.Fragment>}
                    </Col>
                </Row>
            </div>
        </div >
    }


    protected _showToolbar(): boolean {
        return !this.props.hideToolbar;
    }


    protected _canAddItems(): boolean {
        return true;
    }

    protected renderSearchResultsTable(columns: TableColumn<TResultItem>[], searchResults: PaginatedListBase<TResultItem>) {
        return <table className="table table-striped table-hover table-sm">
            <thead>
                <tr>
                    {this._showCheckboxes() && <th>
                        <Input
                            onChange={(evt) => {
                                var checked = evt.target.checked;
                                var newState = this._cloneStateForSetState();
                                newState.allChecked = checked;
                                if (checked) {
                                    newState.checkedElements = searchResults.items.map((item) => item.id);
                                }
                                else {
                                    newState.checkedElements = [];
                                }
                                this.setState(newState);
                            }}
                            type="checkbox"
                            style={{ padding: 0, }}
                            id={"allChecked"}
                            checked={this.state.allChecked || false}
                        />
                    </th>}
                    {columns.map((col, index) => {
                        return <th key={index}>
                            <a href="#"
                                onClick={() => {
                                    this.setState(
                                        { searchParams: this._getSearchForColumnClicked(col.fieldName) },
                                        () => this._performSearch()
                                    );
                                }}>
                                {col.title}
                                {this.renderSortIcon(col.fieldName)}
                            </a>
                        </th>
                    })}


                    <React.Fragment>
                        <th style={{ width: 50 }}></th>
                        <th style={{ width: 50 }}></th>
                    </React.Fragment>

                </tr>
            </thead>
            <tbody>
                {searchResults.items.map((item, index) => {
                    return <tr key={index}
                        className={this._calculateRowClass(item)}
                        onDoubleClick={(evt) => {
                            var url = this._getEditionItemUrl(item);
                            if (url != null) {
                                this.props.history.push(url);
                            }
                        }}
                    >
                        {this._showCheckboxes() && <td>
                            <Input
                                onChange={(evt) => {
                                    var checked = evt.target.checked;
                                    var newState = this._cloneStateForSetState();

                                    if (checked) {
                                        var itemId = newState.checkedElements.find(x => x === item.id);
                                        if (itemId == null) {
                                            newState.checkedElements.push(item.id);
                                        }
                                    }
                                    else {
                                        newState.checkedElements = newState.checkedElements.filter(x => {
                                            return x != item.id;
                                        })
                                    }
                                    this.setState(newState);
                                }}
                                type="checkbox"
                                style={{ padding: 0, }}
                                id={"checkbox_" + index}
                                checked={this.state.checkedElements.find(x => x === item.id) != null}
                            />
                        </td>}
                        {columns.map((col, index) => {
                            return <td key={index} className={this._calculateCellClass(item, col.fieldName)}>
                                {col.renderField == null && <React.Fragment>item[col.FieldName]</React.Fragment>}
                                {col.renderField != null && <React.Fragment>{col.renderField(item)}</React.Fragment>}
                            </td>;
                        })}
                        {!this.hideGridButtonsOverride(item) &&
                            <React.Fragment>
                                <td>
                                    {this.editButtonOverride(item)}
                                </td>
                                <td>
                                    {this.deleteButtonOverride(item)}
                                </td>
                            </React.Fragment>
                        }
                        {this.hideGridButtonsOverride(item) &&
                            <React.Fragment>
                                <td></td>
                                <td></td>
                            </React.Fragment>
                        }
                    </tr>
                })}
            </tbody>
        </table>
    }

    protected editButtonOverride(item: TResultItem): JSX.Element {
        var url = this._getEditionItemUrl(item);
        if (url == null) return;

        return <Link to={url}>
            {this.props.isViewMode && <SearchIcon />}
            {!this.props.isViewMode && <EditIcon />}
        </Link>;
    }

    protected deleteButtonOverride(item: TResultItem): JSX.Element {

        if (!this.props.hideDeleteButton && !this.props.isViewMode && (!item.protegidoBorrado || ClientContext.Current.tipoUsuario === TipoUsuario.AdminGlobal)) {
            return <a onClick={() => this.setState({ showDeleteConfirmation: item.id })}>
                <DeleteIcon cssClass="pe-2" />
            </a>;
        }
        return null;
    }


    /**
     * Calcula la clase que hay que poner en cada fila de la tabla 
     * @param item El item que se va a pintar
     */
    protected _calculateRowClass(item: TResultItem): string {
        return "";
    }

    /**
     * Calcula la clase que hay que poner en cada fila de la tabla 
     * @param item El item que se va a pintar
     */
    protected _calculateCellClass(item: TResultItem, fieldName: string): string {
        return null;
    }

    protected renderSearchResultsDivs(columns: TableColumn<TResultItem>[], searchResults: PaginatedListBase<TResultItem>) {
        return <React.Fragment>
            <div className="row tit-lista-lic">
                {columns.map((col, index) => {
                    return <div key={"header" + index} className={"col-md-" + col.colMdWidth}>
                        {col.title}
                    </div>
                })}
            </div>
            {searchResults.items.map((item, index1) => {

                return <div key={"row" + index1} className={"row datos-lic " + this._calculateRowClass(item)} >
                    {columns.map((col, index2) => {
                        return <div key={"col" + index2} className={"col-md-" + col.colMdWidth}>
                            {col.renderField == null && <React.Fragment>item[col.FieldName]</React.Fragment>}
                            {col.renderField != null && <React.Fragment>{col.renderField(item)}</React.Fragment>}
                        </div>;
                    })}

                    {!this.hideGridButtonsOverride(item) &&
                        <div className="col-md-1">
                            <Link className="btn-edit" to={this._getEditionItemUrl(item)}>
                                {this.props.isViewMode && <SearchIcon />}
                                {!this.props.isViewMode && <EditIcon />}
                            </Link>
                            {!this.props.isViewMode && <a onClick={() => this.setState({ showDeleteConfirmation: item.id })} className="btn-delete">
                                <DeleteIcon cssClass="pe-2" />
                            </a>}
                        </div>
                    }
                </div>
            })}
        </React.Fragment>
    }

    protected abstract _renderSearchForm(): React.ReactNode;

    protected _getSearchForColumnClicked(fieldName: string): TSearchModel {

        var newSearch = { ...this.state.searchParams };

        if (newSearch.orderByColumn !== fieldName) {
            newSearch.orderMode = SortDirection.Asc;
            newSearch.orderByColumn = fieldName;
        }
        else {
            newSearch.orderMode = newSearch.orderMode === SortDirection.Asc ? SortDirection.Desc : SortDirection.Asc;
        }

        return newSearch;

    }

    protected _performDelete(id: number) {

        var state = this._cloneStateForSetState();
        state.showLoadingIcon = true;
        state.searchParams.currentPage = 0;

        this.setState(state, () => {
            var service = this._getApiService();
            service.delete(id)
                .then(() => {
                    this._performSearch();
                })
                .catch((error) => {
                    this._showError(error);
                });
        });
    }

    protected _exportExcel(searchParams: TSearchModel) {

        this.setState({ showLoadingIcon: true },
            () => {
                try {
                    var service = this._getApiService();
                    service.exportExcel(searchParams)
                        .then(() => this.setState({ showLoadingIcon: false }))
                        .catch((reason) => this._showError(reason));
                } catch (error) {
                    this._showError(error);
                }
            });
    }
}
