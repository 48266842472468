import { I18n } from "../utils/I18n";


export function isImage(fileName: string) {

    var extension = fileName.split('?')[0].toLowerCase().substr(fileName.lastIndexOf(".") + 1);

    if (extension === "jpg" || extension === "jpeg" || extension === "bmp" || extension === "png") {
        return true;
    }
    return false;
}

export function formatSize(value?: number): string {
    if (value) {
        let strVal = "";


        if (value > 1073741824) {
            value /= 1073741824;
            strVal = I18n.Strings.formatNumber(value) + " Gb";
        }
        else if (value > 1048576) {
            value /= 1048576;
            strVal = I18n.Strings.formatNumber(value) + " Mb";
        }
        else if (value > 1024) {
            value /= 1024;
            strVal = I18n.Strings.formatNumber(value) + " Kb";
        }
        else {
            strVal = I18n.Strings.formatNumber(value) + " bytes";;
        }


        return strVal;
    }
    return null;
}

function base64toBlob(base64Data, contentType) {
    contentType = contentType || "";
    const sliceSize = 1024;
    var byteCharacters = atob(base64Data);
    var bytesLength = byteCharacters.length;
    var slicesCount = Math.ceil(bytesLength / sliceSize);
    var byteArrays = new Array(slicesCount);

    for (var sliceIndex = 0; sliceIndex < slicesCount; ++sliceIndex) {
        var begin = sliceIndex * sliceSize;
        var end = Math.min(begin + sliceSize, bytesLength);

        var bytes = new Array(end - begin);
        for (var offset = begin, i = 0; offset < end; ++i, ++offset) {
            bytes[i] = byteCharacters[offset].charCodeAt(0);
        }
        byteArrays[sliceIndex] = new Uint8Array(bytes);
    }
    return new Blob(byteArrays, { type: contentType });
}

export function downloadFileFromBase64Array(data: any, filename: string, mime: string, elementId?: string) {
    var blob = base64toBlob(data, mime || "application/octet-stream");

    var msSaveBlob = (window.navigator as any).msSaveBlob;

    if (msSaveBlob != null) {
        // IE workaround for "HTML7007: One or more blob URLs were
        // revoked by closing the blob for which they were created.
        // These URLs will no longer resolve as the data backing
        // the URL has been freed."
        msSaveBlob(blob, filename);
    }
    else {

        var blobURL = window.URL.createObjectURL(blob);

        var tempLink = document.createElement("a");
        //tempLink.style.display = "none";
        tempLink.href = blobURL;
        tempLink.setAttribute("download", filename);
        tempLink.innerText = "Download";
        // Safari thinks _blank anchor are pop ups. We only want to set _blank
        // target if the browser does not support the HTML5 download attribute.
        // This allows you to download files in desktop safari if pop up blocking
        // is enabled.
        if (typeof tempLink.download === "undefined") {
            tempLink.setAttribute("target", "_blank");
        }

        var elementToAppend = document.body;

        if (elementId != null) {
            elementToAppend = document.getElementById(elementId).parentElement;
        }
        elementToAppend.appendChild(tempLink);

        tempLink.click();
        elementToAppend.removeChild(tempLink);
        window.URL.revokeObjectURL(blobURL);

    }
}


export function validateEmail(value: string): boolean {
    if (value == null) {
        return false;
    }

    let result = true;
    let mailformat = /^((([a-zA-Z0-9_\-])+)(\.{0,1}))+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,15})$/;

    if (!value.match(mailformat)) {
        result = false;
    }
    return result;
}

export function validateUrl(value: string): boolean {
    let url;

    try {
        url = new URL(value);
    } catch (_) {
        return false;
    }

    return url.protocol === "http:" || url.protocol === "https:";
}

export function getFileNameWithoutExtension(fileName: string): string {
    return fileName.substring(0, Math.min(fileName.length - 1, fileName.lastIndexOf(".")));
}


export function isValidNumber(numberTotTst: any) : boolean {
    return !isNaN(Number(numberTotTst));
}

export function logAndExtractInfoFromException(error: any) : string {

    console.error(error);
    let mensaje = error.message;
    
    if (error.response) {
        if (error.response.data && error.response.data.error) {
            if (error.response.data.showToUser) {
                mensaje = error.response.data.error;
            }
            else {
                mensaje = I18n.Strings.genericErrorMessage;
            }

        } else if (error.response.status == 404) {
            mensaje = I18n.Strings.errorNotFound;
        }
    }

    return mensaje || error;
}

export function toCamelCase(initial: string): string {

    return initial.replace(/\w\S*/g,
        function (txt) {
            return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
        });

}

export function normalizaDocumento(initial: string) {
    if (initial != null) {
        return initial.replace(/[\s\W]/g, "").toUpperCase();
    }
}


export function downloadFileFromBlob(blob: Blob, filename: string, elementId?: string) {

    var msSaveBlob = (window.navigator as any).msSaveBlob;

    if (msSaveBlob != null) {
        // IE workaround for "HTML7007: One or more blob URLs were
        // revoked by closing the blob for which they were created.
        // These URLs will no longer resolve as the data backing
        // the URL has been freed."
        msSaveBlob(blob, filename);
    }
    else {
        let blobURL = window.URL.createObjectURL(blob);
        let tempLink = document.createElement("a");
        //tempLink.style.display = "none";
        tempLink.href = blobURL;
        tempLink.setAttribute("download", filename);
        tempLink.innerText = "Download";
        // Safari thinks _blank anchor are pop ups. We only want to set _blank
        // target if the browser does not support the HTML5 download attribute.
        // This allows you to download files in desktop safari if pop up blocking
        // is enabled.
        if (typeof tempLink.download === "undefined") {
            tempLink.setAttribute("target", "_blank");
        }
        let elementToAppend = document.body;
        if (elementId != null) {
            elementToAppend = document.getElementById(elementId).parentElement;
        }
        elementToAppend.appendChild(tempLink);
        tempLink.click();
        elementToAppend.removeChild(tempLink);
        window.URL.revokeObjectURL(blobURL);
    }
}

