import * as React from "react";
import { Input } from "reactstrap";

import { CursoEscolarEditModel, CursoEscolarListItem, Editorial, GetTiposLicenciaResponse, TipoAcceso } from "../models/dataModels";
import { ClientContext } from "../utils/ClientContext";
import { LicenciasColegioService } from "./LicenciasColegioService";
import { ComponentBase } from "../utils/base/ComponentBase";
import { AlertsService } from "../utils/AlertsService";
import { I18n } from "../utils/I18n";

interface TipoAccesoDropDownProps {
    id: string;
    editorial: Editorial;
    value?: TipoAcceso;
    emptyText?: string;
    className?: string;
    disabled?: boolean;
    onChange: (tipoAcceso: string) => void;
    required?: boolean;
}

interface TipoAccesoDropDownState {
    loading: boolean;
    editorial: Editorial;
    options: TipoAcceso[];
}



export class TipoAccesoDropDown extends ComponentBase<TipoAccesoDropDownProps, TipoAccesoDropDownState> {

    constructor(props: TipoAccesoDropDownProps) {

        super(props);

        var initialState = {
            loading: true,
            editorial: this.props.editorial,
            options: []
        };


        if (props.id != null) {
            initialState.loading = false;
            initialState.options.push(this.props.value as TipoAcceso);
        }

        this.state = initialState;
    }

    componentDidMount() {
        this.loadTiposLicencias(this.props.editorial, false);
    }

    componentDidUpdate(prevProps: TipoAccesoDropDownProps) {
        if (prevProps.editorial !== this.props.editorial) {
            this.loadTiposLicencias(this.props.editorial, true);
        }
    }

    loadTiposLicencias(editorial: Editorial, refresh: boolean) {

        if (editorial != null) {

            var service = new LicenciasColegioService();

            service.getTiposLicencias(editorial)
                .then((data) => {

                    var tiposAcceso = data.tiposAcceso;
                    if ((this.props.value || "").length != 0 && tiposAcceso.indexOf(this.props.value) == -1) {
                        
                        tiposAcceso.push(this.props.value);
                    }

                    this.setState({
                        options: tiposAcceso,
                        loading: false,
                    }, () => {
                        if (refresh) {
                            this.props.onChange(data.tiposAcceso.length == 1 ? data.tiposAcceso[0] : null);
                        }
                    });
                })
                .catch((error) => AlertsService.showError(error));
        }
        else {
            this.setState({ loading: false });
        }

    }

    render() {

        if (this.state.loading) {
            return <React.Fragment><span>...</span></React.Fragment>;
        }

        var options = [] as JSX.Element[];
        var initialValue = this.props.value;

        if (this.state.options != null) {
            this.state.options.forEach((tipoAcceso) => {
                if (tipoAcceso != null) {
                    options.push(<option value={tipoAcceso} key={tipoAcceso}>{I18n.Strings.getEnumText(`TipoAcceso.${tipoAcceso}`)}</option>);
                }
            });
        }

        return <Input type="select"
            id={this.props.id}
            required={this.props.required}
            value={initialValue || ''}
            //disabled={this.props.disabled ||  (this.state.options != null && this.state.options.length == 1 && initialValue != TipoAcceso.SinConfigurar)}
            className={this.props.className || ""}
            onChange={(evt) => {

                let select = evt.target as any as HTMLSelectElement;
                let option = select.options[select.selectedIndex];
                
                let value = option.value;
                if ((value || "").length === 0) {
                    this.props.onChange(null);
                }
                else {
                    this.props.onChange(option.value);
                }
            }}
        >
            {this.props.emptyText == null && <option value='' />}
            {this.props.emptyText != null && <option value=''>{this.props.emptyText}</option>}
            {options.length != 0 && options}
            {options.length == 0 && this.props.value &&
                <option value={this.props.value}> {this.props.value}</option>
            }
        </Input>;
    }
}