import * as React from "react";
import { withRouter } from "react-router-dom";
import { Col, FormGroup, Label } from "reactstrap";
import nameof from "ts-nameof.macro";
import { ColegioUsuariosPicker } from "../colegioUsuarios/Picker";
import UserNameToolTip from "../colegioUsuarios/UserNameToolTip";
import { ClaseMateriaDropDown } from "../cursoEscolar/materia/ClaseMateriaDropDown";
import { NivelEducativoDropDown } from "../cursoEscolar/NivelEducativoDropDown";
import { RouteHelper } from "../IntranetRouter";
import { MatriculaListItem, MatriculaListItemPaginatedList, MatriculasSearch, TipoUsuario } from "../models/dataModels";
import { IServiceForIndex } from "../utils/base/BaseServices";
import { IndexPageBase, TableColumn } from "../utils/base/IndexPageBase";
import { ClientContext } from "../utils/ClientContext";
import { I18n } from "../utils/I18n";
import { MatriculasService } from "./MatriculasService";




interface MatriculasSearchEx extends MatriculasSearch {
    usuario?: string;
    clase?: string;
    materia?: string;
}

class IndexComp extends IndexPageBase<MatriculasSearchEx, MatriculaListItemPaginatedList, MatriculaListItem>
{
    protected _renderSearchForm(): React.ReactNode {
        if (this.props.isEmbedded) {
            return null;
        }
        return <React.Fragment>
            <Col xs="12" md="6" lg="4">
                <ColegioUsuariosPicker
                    label={I18n.Strings.matriculas.nombreUsuario}
                    required={false}
                    itemId={this.state.searchParams.usuarioId}
                    itemName={this.state.searchParams.usuario}
                    getIdFromItem={(item) => item.id}
                    getNameFromItem={(item) => `${item.nombre} ${item.apellidos}`}

                    errorMessage={null}
                    onSelected={(usuarioId, usuario, item) => {
                        var state = this._cloneStateForSetState();
                        state.searchParams.usuarioId = usuarioId;
                        state.searchParams.usuario = usuario;

                        this.setState(state, () => this._performSearch());
                    }}
                />
            </Col>
            <Col xs="12" md="6" lg="3">
                <FormGroup>
                    <Label for={nameof(this.state.searchParams.clase)}>
                        {I18n.Strings.matriculas.nombreClase}
                    </Label>

                    <NivelEducativoDropDown
                        id={nameof(this.state.searchParams.clase)}

                        cursoEscolarId={ClientContext.Current.cursoEscolarActivoId}
                        value={this.state.searchParams.claseId}

                        text={this.state.searchParams.clase}
                        seleccionClases={true}
                        onChange={(id, nombre) => {

                            var state = this._cloneStateForSetState();
                            state.searchParams.claseId = id;
                            state.searchParams.clase = nombre;
                            state.searchParams.materia = null;
                            state.searchParams.claseMateriaId = null;
                            this.setState(state, () => this._performSearch());
                        }}
                    />

                </FormGroup>
            </Col>
            <Col xs="12" md="6" lg="3">
                <ClaseMateriaDropDown
                    label={I18n.Strings.matriculas.nombreMateria}
                    key={this.state.searchParams.claseId}
                    selectedItemValue={this.state.searchParams.claseMateriaId}
                    claseId={this.state.searchParams.claseId}
                    selectedItemText={this.state.searchParams.materia}
                    onChanged={(materiaId, materia, item) => {
                        var state = this._cloneStateForSetState();
                        state.searchParams.claseMateriaId = materiaId;
                        state.searchParams.materia = materia;
                        this.setState(state, () => this._performSearch());
                    }}
                />
            </Col>
        </React.Fragment>;
    }

    protected _getApiService(): IServiceForIndex<MatriculasSearch, MatriculaListItemPaginatedList> {
        return new MatriculasService();
    }


    protected _getInitialSearchParams(): MatriculasSearch {

        let model = super._getInitialSearchParams();

        if (model.cursoEscolarId == null) {
            model.cursoEscolarId = ClientContext.Current.cursoEscolarActivoId;
        }

        if (this.props.searchModel?.usuarioId != null) {
            model.orderByColumn = nameof(this.state.searchResults.items[0].materia);
        }

        return model;
    }

    protected _getTableColumns(): TableColumn<MatriculaListItem>[] {
        
        let columns = [
            {
                fieldName: nameof(this.state.searchResults.items[0].clase),
                title: I18n.Strings.matriculas.nombreClase,
                renderField: (item) => item.clase
            },
            {
                fieldName: nameof(this.state.searchResults.items[0].materia),
                title: I18n.Strings.matriculas.nombreMateria,
                renderField: (item) => item.materia
            },
            {
                fieldName: nameof(this.state.searchResults.items[0].subgrupo),
                title: I18n.Strings.matriculas.subgrupo,
                renderField: (item) => item.subgrupo
            },
            {
                fieldName: nameof(this.state.searchResults.items[0].usuario),
                title: I18n.Strings.matriculas.nombreUsuario,
                renderField: (item) => <UserNameToolTip userName={item.usuario} userId={item.usuarioId} key={item.id} />,
            },
            {
                fieldName: nameof(this.state.searchResults.items[0].rol),
                title: I18n.Strings.matriculas.rol,
                renderField: (item) => item.rol
            },
            {
                fieldName: nameof(this.state.searchResults.items[0].fecha),
                title: I18n.Strings.matriculas.fecha,
                renderField: (item) => I18n.Strings.formatDate(item.fecha)
            }
        ] as TableColumn<MatriculaListItem>[];

        if ((this.props.searchModel?.usuarioId || 0) != 0) {
            columns = columns.filter(c => c.fieldName != nameof(this.state.searchResults.items[0].usuario));
        }

        return columns;
    }

    

    protected _getPageTitle(): string {
        return I18n.Strings.matriculas.title;
    }
    protected _getPageDescription(): string {
        return I18n.Strings.matriculas.description;
    }

    protected _canAddItems(): boolean {
        return ClientContext.Current.tipoUsuario == TipoUsuario.AdminGlobal;
    }

    protected _getEditionItemUrl(item: MatriculaListItem): string {
        return RouteHelper.editMatricula(item.id, this.props.searchModel?.usuarioId);
    }

    protected _getNewItemUrl(): string {
        return RouteHelper.createMatricula(this.props.searchModel?.usuarioId);
    }

}

export const Index = withRouter(IndexComp);