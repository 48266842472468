import * as React from "react";
import nameof from "ts-nameof.macro";

import { Row, Col, FormGroup, Label, Input, Button } from "reactstrap";

import { AliasEditorialService } from "./AliasEditorialesService";
import { I18n } from "../utils/I18n";
import { Editorial, AliasEditorialListItem, AliasEditorialListItemPaginatedList, AliasEditorialSearch, YesNo } from "../models/dataModels";
import { IServiceForIndex } from "../utils/base/BaseServices";
import { IndexPageBase, TableColumn } from "../utils/base/IndexPageBase";
import { RouteHelper } from "../IntranetRouter";

import { withRouter } from "react-router-dom";
import { YesNoDropDown } from "../helpers/EnumDropDown";
import { AliasEditorialEditTabs } from "./Edit";


export class IndexComp extends IndexPageBase<AliasEditorialSearch, AliasEditorialListItemPaginatedList, AliasEditorialListItem>
{

    protected _renderSearchForm(): React.ReactNode {
        return <React.Fragment>

            <Col xs="12" md="6" lg="4">
                <FormGroup>
                    <Label className="visually-hidden" for={nameof(this.state.searchParams.alias)}>
                        {I18n.Strings.aliasEditorial.alias}
                    </Label>
                    <Input
                        onChange={(evt) => {
                            var state = this._cloneStateForSetState();
                            state.searchParams.alias = evt.target.value;
                            this.setState(state);
                        }}
                        placeholder={I18n.Strings.aliasEditorial.alias + "..."}
                        type="text"
                        maxLength={100}
                        className={"form-control"}
                        name={nameof(this.state.searchParams.alias)}
                        id={nameof(this.state.searchParams.alias)}
                        value={this.state.searchParams.alias || ""}
                    />

                </FormGroup>
            </Col>
            <Col xs="12" md="6" lg="4">

                <FormGroup>

                    <Input
                        onChange={(evt) => {
                            var state = this._cloneStateForSetState();
                            state.searchParams.pendientes = evt.target.checked;
                            this.setState(state, () => this._performSearch());
                        }}
                        type="checkbox"
                        style={{ padding: 0, marginRight: 10 }}
                        name={nameof(this.state.searchParams.pendientes)}
                        id={nameof(this.state.searchParams.pendientes)}
                        checked={this.state.searchParams.pendientes || false}
                    />
                    <Label for={nameof(this.state.searchParams.pendientes)}>{I18n.Strings.aliasEditorial.pendientes}</Label>
                </FormGroup>

            </Col>
            <Col xs="12" md="6" lg="3">
                <YesNoDropDown
                    emptyText={I18n.Strings.aliasEditorial.enUso + "..."}
                    label={I18n.Strings.aliasEditorial.enUso}
                    id={nameof(this.state.searchParams.enUso)}
                    value={this.state.searchParams.enUso == null ? null : this.state.searchParams.enUso ? YesNo.Yes.toString() : YesNo.No.toString()}
                    className={"form-control"}
                    required={true}
                    onChanged={(value: YesNo) => {
                        var newState = this._cloneStateForSetState();
                        newState.searchParams.enUso = value == YesNo.Yes.toString() ? true : value == YesNo.No.toString() ? false : null;
                        this.setState(newState, () => this._performSearch());
                    }}
                />
            </Col>

        </React.Fragment>;
    }

    protected _getApiService(): IServiceForIndex<AliasEditorialSearch, AliasEditorialListItemPaginatedList> {
        return new AliasEditorialService();
    }

    protected _getTableColumns(): TableColumn<AliasEditorialListItem>[] {
        return [
            {
                fieldName: nameof(this.state.searchResults.items[0].alias),
                title: I18n.Strings.aliasEditorial.alias,
                renderField: (item) => item.alias
            },
            {
                fieldName: nameof(this.state.searchResults.items[0].editorial),
                title: I18n.Strings.aliasEditorial.editorial,
                renderField: (item) => I18n.Strings.getEnumText(`Editorial.${item.editorial}`),
            },
            {
                fieldName: nameof(this.state.searchResults.items[0].numContenidos),
                title: I18n.Strings.aliasEditorial.numContenidos,
                renderField: (item) => I18n.Strings.formatNumber(item.numContenidos)
            },
            {
                fieldName: nameof(this.state.searchResults.items[0].numUsos),
                title: I18n.Strings.aliasEditorial.numUsos,
                renderField: (item) => I18n.Strings.formatNumber(item.numUsos)
            },
        ];
    }

    protected _getPageTitle(): string {
        return I18n.Strings.aliasEditorial.title;
    }
    protected _getPageDescription(): string {
        return I18n.Strings.aliasEditorial.description;
    }


    protected _getEditionItemUrl(item: AliasEditorialListItem): string {


        return RouteHelper.editAliasEditorial(item.id, AliasEditorialEditTabs.Datos);
    }

    protected _getNewItemUrl(): string {
        return RouteHelper.createAliasEditorial();
    }

    protected _calculateCellClass(item: AliasEditorialListItem) {
        return item.editorial == Editorial.None ? "text-danger" : "";
    }

}

export const Index = withRouter(IndexComp);