import * as React from "react";
import { Col, FormGroup, Label, Row } from "reactstrap";
import nameof from "ts-nameof.macro";
import { ColegioUsuariosPicker } from "../colegioUsuarios/Picker";
import UserNameToolTip from "../colegioUsuarios/UserNameToolTip";
import { ClaseMateriaDropDown } from "../cursoEscolar/materia/ClaseMateriaDropDown";
import { NivelEducativoDropDown } from "../cursoEscolar/NivelEducativoDropDown";
import { MatriculaListItem, MatriculaListItemPaginatedList, MatriculasSearch } from "../models/dataModels";
import { IServiceForIndex } from "../utils/base/BaseServices";
import { GenericPicker, GenericPickerProps, IGenericPickerState } from "../utils/base/GenericPickerBase";
import { TableColumn } from "../utils/base/IndexPageBase";
import { ClientContext } from "../utils/ClientContext";
import { I18n } from "../utils/I18n";
import { MatriculasService } from "./MatriculasService";


interface MatriculasSearchEx extends MatriculasSearch {
    usuario: string;
    grupo: string;
    materia: string;
}

class ColegioUsuariosPickerProps extends GenericPickerProps<MatriculasSearchEx, MatriculaListItem> {

    public errorMessage?: string;
}


export class MatriculasPicker extends
    GenericPicker<MatriculasSearchEx, MatriculaListItemPaginatedList, MatriculaListItem, ColegioUsuariosPickerProps>
{
    protected GetTitulo(): string {
       
        return `Seleccionar matrícula`;
    }
    
    public componentWillReceiveProps(props: ColegioUsuariosPickerProps) {
        var newState = this._cloneStateForSetState();
        this.setState(newState);
    }

    protected GetInitialSearchParams(): MatriculasSearchEx {
        const searchParams = {
            ...{} as MatriculasSearchEx,   
            cursoEscolarId: ClientContext.Current.cursoEscolarActivoId,
            rowsPerPage: 10
        } as MatriculasSearchEx;

        return searchParams;
    }

    protected _createState(): IGenericPickerState<MatriculasSearchEx, MatriculaListItemPaginatedList> {
        const state = super._createState();
        return state;
    }

    protected _createApiService(): IServiceForIndex<MatriculasSearch, MatriculaListItemPaginatedList> {
        return new MatriculasService();
    }

    protected RenderSearchForm(): JSX.Element {
        return (
            <Row>
                <Col xs="12" md="6" lg="4">
                    <ColegioUsuariosPicker
                        label={I18n.Strings.matriculas.nombreUsuario}
                        required={false}
                        itemId={this.state.searchParams.usuarioId}
                        itemName={this.state.searchParams.usuario}
                        getIdFromItem={(item) => item.id}
                        getNameFromItem={(item) => item.nombre}

                        errorMessage={null}
                        onSelected={(usuarioId, usuario, item) => {
                            var newState = this._cloneStateForSetState();
                            newState.searchParams.usuarioId = usuarioId;
                            newState.searchParams.usuario = usuario;

                            this.setState(newState, () => this._performSearch());
                        }}
                    />
                </Col>
                <Col xs="12" md="6" lg="4">
                    <FormGroup>
                        <Label for={nameof(this.state.searchParams.grupo)}>
                            {I18n.Strings.matriculas.nombreClase}
                        </Label>

                        <NivelEducativoDropDown
                            id={nameof(this.state.searchParams.grupo)}

                            cursoEscolarId={ClientContext.Current.cursoEscolarActivoId}
                            value={this.state.searchParams.claseId}

                            text={this.state.searchParams.grupo}
                            seleccionClases={true}
                            onChange={(id, nombre) => {

                                var newState = this._cloneStateForSetState();
                                newState.searchParams.claseId = id;
                                newState.searchParams.grupo = nombre;

                                this.setState(newState, () => this._performSearch());
                            }}
                        />

                    </FormGroup>
                </Col>
                <Col xs="12" md="6" lg="4">
                    <ClaseMateriaDropDown
                        label={I18n.Strings.matriculas.nombreMateria}
                        key={this.state.searchParams.claseId}
                        selectedItemValue={this.state.searchParams.claseMateriaId}
                        claseId={this.state.searchParams.claseId}
                        selectedItemText={this.state.searchParams.materia}
                        onChanged={(materiaId, materia, item) => {
                            var state = this._cloneStateForSetState();
                            state.searchParams.claseMateriaId = materiaId;
                            state.searchParams.materia = materia;
                            this.setState(state);
                        }}
                    />
                </Col>
            </Row>
        );
    }

    protected _getColumns(): TableColumn<MatriculaListItem>[] {
        const columns: TableColumn<MatriculaListItem>[] = [];

        var sample: MatriculaListItem;

        columns.push(
            {
                fieldName: nameof(sample.clase),
                title: I18n.Strings.matriculas.nombreClase,
                renderField: (item) => item.clase
            });

        columns.push(
            {
                fieldName: nameof(sample.materia),
                title: I18n.Strings.matriculas.nombreMateria,
                renderField: (item) => item.materia
            });

        columns.push(
            {
                fieldName: nameof(sample.usuario),
                title: I18n.Strings.matriculas.nombreUsuario,
                renderField: (item) => <UserNameToolTip userName={item.usuario} userId={item.usuarioId} key={item.id} />,
            });

        columns.push(
            {
                fieldName: nameof(sample.rol),
                title: I18n.Strings.matriculas.rol,
                renderField: (item) => item.rol
            });

        return columns;
    }
}