import * as React from "react";
import { Input } from "reactstrap";


import { CursoEscolarEditModel, CursoEscolarListItem } from "../models/dataModels";
import { ComponentBase } from "../utils/base/ComponentBase";
import { ClientContext } from "../utils/ClientContext";
import { CursoEscolarService } from "./CursoEscolarService";


interface NivelEducativoDropDownProps {
    id: string;
    cursoEscolarId: number;
    disabled?: boolean;
    value?: number;
    text: string;
    seleccionClases: boolean;
    emptyText?: string;
    ocultarGrupoGeneral?: boolean;
    onChange: (nivelEducativoId: number, nivelEducativo: string, tipoSeleccion: TipoSeleccion) => void;
}

export enum TipoSeleccion {
    NivelEducativo = "NivelEducativo",
    Grupo = "Grupo"
}

interface NivelEducativoDropDownState {
    loading: boolean;
    cursoEscolarId: number;
}

export class NivelEducativoDropDown extends ComponentBase<NivelEducativoDropDownProps, NivelEducativoDropDownState> {

    private static dicNiveles: { [id: number]: CursoEscolarEditModel } = {};

    constructor(props: NivelEducativoDropDownProps) {

        super(props);

        this.state = {
            loading: true,
            cursoEscolarId: this.props.cursoEscolarId
        };
    }

    loadCursoEscolar(cursoEscolarId: number) {
        if (cursoEscolarId != null) {
            if (NivelEducativoDropDown.dicNiveles[cursoEscolarId] == null || ClientContext.Current.recargarNiveles) {
                var service = new CursoEscolarService();

                service.getById(cursoEscolarId)
                    .then((cursoEscolar) => {
                        ClientContext.Current.recargarNiveles = false;
                        NivelEducativoDropDown.dicNiveles[cursoEscolar.id] = cursoEscolar;
                        this.setState({
                            cursoEscolarId: cursoEscolar.id,
                            loading: false
                        });
                    });
            }
            else {
                this.setState({ cursoEscolarId: cursoEscolarId, loading: false });
            }
        }
        else {
            this.setState({ cursoEscolarId: null, loading: false });
        }
    }

    componentDidMount() {
        this.loadCursoEscolar(this.props.cursoEscolarId);
    }

    componentDidUpdate(prevProps: NivelEducativoDropDownProps) {
        if (prevProps.cursoEscolarId !== this.props.cursoEscolarId) {
            this.loadCursoEscolar(this.props.cursoEscolarId);
        }
    }

    render() {

        if (this.state.loading) {
            return <React.Fragment><span>...</span></React.Fragment>;
        }

        var options = [] as JSX.Element[];

        var initialValue = "";

        var cursoEscolar = NivelEducativoDropDown.dicNiveles[this.state.cursoEscolarId];


        {
            cursoEscolar != null && cursoEscolar.etapas != null && (cursoEscolar.etapas || []).forEach((etapa) => {

                options.push(<option key={`etapa${etapa.id}`} disabled>{etapa.nombre}</option>);

                (etapa.niveles || []).forEach((nivel) => {

                   

                    let props = {} as any;
                    if (this.props.seleccionClases) {
                        props.disabled = "disabled";
                    }

                    var nivelKey = `nivel${nivel.id}`;

                    let nivelOption = <option key={nivelKey} value={nivelKey}
                        {...props}
                        data-nombre={`${etapa.nombre} - ${nivel.nombre}`}
                        data-value={nivel.id}
                    >
                        &nbsp; &nbsp; {nivel.nombre}
                    </option>;

                    options.push(nivelOption);

                    if (!this.props.seleccionClases && this.props.value == nivel.id) {
                        initialValue = nivelKey;
                    }

                    if (this.props.seleccionClases) {
                        (nivel.clases || []).forEach((grupo) => {

                            if (this.props.ocultarGrupoGeneral && grupo.nombre.indexOf("General") > 0) {
                                return <></>;
                            }
                            var grupoKey = `grupo${grupo.id}`;

                            let nivelOption = <option
                                data-nombre={`${etapa.nombre} - ${nivel.nombre} - ${grupo.nombre}`}
                                data-value={grupo.id}
                                key={grupoKey}
                                value={grupoKey}
                            >
                                &nbsp; &nbsp; &nbsp; &nbsp; {grupo.nombre}
                            </option >

                            options.push(nivelOption);

                            if (this.props.seleccionClases && this.props.value == grupo.id) {
                                initialValue = grupoKey;
                            }
                        });
                    }
                })
            })
        }

        return <Input type="select"
            id={this.props.id}
            defaultValue={initialValue}
            disabled={this.props.disabled}
            onChange={(evt) => {

                let select = evt.target as any as HTMLSelectElement;
                let option = select.options[select.selectedIndex];

                let value = option.value;
                if ((value || "").length === 0) {
                    this.props.onChange(null, null, null);
                }
                else {
                    this.props.onChange(
                        parseInt(option.attributes["data-value"].value),
                        option.attributes["data-nombre"].value,
                        this.props.seleccionClases ? TipoSeleccion.Grupo : TipoSeleccion.NivelEducativo
                    );
                }
            }}
        >
            {this.props.emptyText == null && <option />}
            {this.props.emptyText != null && <option value="">{this.props.emptyText}</option>}
            {options.length != 0 && options}
            {options.length == 0 && this.props.value &&
                <option value={this.props.value}> {this.props.text}</option>
            }
        </Input>;

    }
}