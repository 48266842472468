import { cloneDeep, differenceWith } from "lodash-es";
import * as React from "react";
import { withRouter } from "react-router-dom";
import { Button, Col, Modal, ModalBody, ModalHeader, Row } from "reactstrap";
import { RouteHelper } from "../IntranetRouter";
import {
    EstadoNotificacion, NotificacionListItem, NotificacionMarcarLeidaModel, TipoUsuario
} from "../models/dataModels";
import { AlertsService } from "../utils/AlertsService";
import { ClientContext } from "../utils/ClientContext";
import { I18n } from "../utils/I18n";
import { CancelIcon, CheckCircleIcon, CheckIcon, SearchIcon, SpinnerIcon } from "../utils/Icons";
import { Loading } from "../utils/Loading";
import { NotificationService } from "./NotificationService";


interface NotificationIconProps {

};

interface NotificationIconState {
    mustShowIcon: boolean;
    showModal: boolean;
    items: NotificacionListItem[],
    loading: boolean;
}


/**
 * Pinta el paginador de los listados
 */
export class NotificationIconComp extends React.Component<any, NotificationIconState> {

    public constructor(props: NotificationIconProps) {

        super(props);

        var mustShowIcon = ClientContext.Current.tipoUsuario == TipoUsuario.AdminGlobal ||
            ClientContext.Current.tipoUsuario == TipoUsuario.AdminColegio;

        this.state = {
            loading: false,
            showModal: false,
            mustShowIcon: mustShowIcon,
            items: null
        };
    }

    public componentDidMount() {
        if (this.state.mustShowIcon) {

            if (window.location.href.toLowerCase().indexOf("localhost") < 0) {
                setInterval(() => this.readNotifications(), 10000);
            }

            this.readNotifications();
        }
        
    }

    private readNotifications() {

        // Realizo la lectura de las notificaciones
        var service = new NotificationService();

        service.myNotifications()
            .then(notificaciones => {
                this.checkNewNotifications(notificaciones.items);
                this.setState({
                    items: notificaciones.items
                });
            })
    }

    private _isEqualForNotification(itemA: NotificacionListItem, itemB: NotificacionListItem) {

        return (itemA.id == itemB.id && itemA.estado == itemB.estado);
    }

    private checkNewNotifications(newItems: NotificacionListItem[]) {
        var oldItems = this.state.items;

        if (oldItems != null) {
            var nuevosResultados = differenceWith(newItems, oldItems, this._isEqualForNotification) as NotificacionListItem[];

            if (nuevosResultados.length > 0) {
                nuevosResultados
                    .filter(f => f.usuarioId === ClientContext.Current.usuarioId)
                    .map((result, index) => {

                        if (result.estado == EstadoNotificacion.AcabadaError) {
                            //throw new Error(I18n.Strings.notificaciones.haFinalizadoError);
                            AlertsService.showError(new Error(I18n.Strings.notificaciones.haFinalizadoError),
                                () => this.setState({ items: newItems })
                            );
                        }
                        else if (result.estado == EstadoNotificacion.AcabadaOk && result.datos != null) {
                            this.alertNewNotification(result, () => () => this.setState({ items: newItems }));
                        }
                    });
            }
        }
    }


    private alertNewNotification(notification: NotificacionListItem, onClose: () => void) {
        try {
            var valueObj = JSON.parse(notification.datos);
            AlertsService.showSuccessMessage(I18n.Strings.notificaciones.haFinalizadoOk(notification.colegio, valueObj.OkMsg));
        } catch {
            AlertsService.showSuccessMessage(notification.texto);
        }
    }

    protected _cloneStateForSetState(): NotificationIconState {
        return cloneDeep(this.state) as NotificationIconState;
    }

    private checkNotification(id: number) {
        var service = new NotificationService();

        var data = {
            ids: [id]
        } as NotificacionMarcarLeidaModel;

        service.marcarComoLeidos(data)
            .then(notificaciones => {
                this.setState({
                    items: notificaciones.items
                });
            })
            .catch(error => {
                AlertsService.showError(error);
            })
    }

    protected _getAlertClass(): string {
        if (this.state.items != null) {
            var usuarioId = ClientContext.Current.usuarioId;
            var tienePendientes = this.state.items.some(p => p.pendiente && p.usuarioId === usuarioId);

            if (tienePendientes) {
                return "aviso";
            }
        }
        return '';
    }

    public render(): JSX.Element {

        if (!this.state.mustShowIcon) { return null; }

        return <React.Fragment>
            {this.state.loading && <Loading />}
            <div className="notificaciones">
                <a type="button" className="btn btn-rounded" onClick={() => {
                    if (this.state.items.length > 0) {
                        this.setState({ showModal: true });
                    }
                    else {
                        AlertsService.showAlertMessage("No hay notificaciones que mostrar");
                    }
                }}
                >
                    <span className={this._getAlertClass()}></span>
                    <i className="bi bi-bell"></i>
                </a>
            </div>

            {this.state.showModal &&
                (this.state.items || []).length != 0 &&
                <Modal isOpen={true} className="modal-xl" toggle={() => { this.setState({ showModal: !this.state.showModal }); }}>
                    <ModalHeader close={<button className="btn-close" onClick={() => this.setState({ showModal: false })} />}>
                        {I18n.Strings.notificaciones.titulo}
                    </ModalHeader>
                    <ModalBody>
                        <div className="licencias">
                            <Row className="tit-lista-lic">

                                <Col md={2}>
                                    {I18n.Strings.notificaciones.fecha}
                                </Col>
                                {ClientContext.Current.tipoUsuario == TipoUsuario.AdminGlobal &&
                                    <Col md={2}>
                                        {I18n.Strings.notificaciones.colegio}
                                    </Col>
                                }
                                <Col >
                                    {I18n.Strings.notificaciones.texto}
                                </Col>

                                <Col md={1}></Col>
                            </Row>

                            {this.state.items.map((notif, index) => {
                                return <Row key={index} className="datos-lic" >
                                    <Col md={2} className={notif.pendiente ? "negrita" : ""}>
                                        {I18n.Strings.formatDateTime(notif.fecha)}
                                    </Col>

                                    {ClientContext.Current.tipoUsuario == TipoUsuario.AdminGlobal && <React.Fragment>
                                        <Col md={2} className={notif.pendiente ? "negrita" : ""}>
                                            {notif.colegio}
                                        </Col>
                                    </React.Fragment>}

                                    <Col className={notif.pendiente ? "negrita" : ""}>
                                        <span className="me-3">
                                            {(notif.estado == EstadoNotificacion.EnProceso ||
                                                notif.estado == EstadoNotificacion.EnProcesoConErrores) && <SpinnerIcon />}
                                            {notif.estado == EstadoNotificacion.AcabadaError && <CancelIcon cssClass={"checkKO iconoGrande"} />}
                                            {notif.estado == EstadoNotificacion.AcabadaOk && <CheckCircleIcon cssClass={"checkOK iconoGrande"} />}
                                        </span>


                                        {notif.texto}

                                    </Col>

                                    <Col md={1}>
                                        {
                                            notif.pendiente && <a
                                                title={I18n.Strings.notificaciones.marcarComoLeida}
                                                className={"btn-niveles"}
                                                href="javaScript:void(0)"
                                                onClick={() => { this.checkNotification(notif.id) }}>
                                                <CheckIcon />
                                            </a>
                                        }
                                        <a
                                            title={I18n.Strings.notificaciones.editar}
                                            className={"btn-niveles"}
                                            href="javaScript:void(0)"
                                            onClick={() => {
                                                this.setState({ showModal: false });
                                                this.props.history.push(RouteHelper.viewNotificacion(notif.id));
                                            }}>
                                            <SearchIcon />
                                        </a>
                                    </Col>
                                </Row>;
                            })}
                            <Row>
                                <Col>
                                    <Button className="btn-rounded btn-primary"
                                        onClick={() => {
                                            this.setState({ showModal: false });
                                            this.props.history.push(RouteHelper.listNotificaciones());
                                        }}
                                    >

                                        <i className="bi bi-bell"></i>
                                        {I18n.Strings.notificaciones.verTodas}
                                    </Button>
                                </Col>
                            </Row>
                        </div>
                    </ModalBody>
                </Modal>

            }

        </React.Fragment>;
    }
};
export const NotificationIcon = withRouter(NotificationIconComp)

