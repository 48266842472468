import * as React from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { Alert } from "reactstrap";
import { Actividad, CalificacionInfo, ClaseMateriaGetCalificacionesResult, GetEstructuraContenidosClaseMateriaResult, Unidad, UsuarioInfo } from "../../models/dataModels";
import { AlertsService } from "../../utils/AlertsService";
import { ApiFetcher } from "../../utils/ApiFetcher";
import { ComponentBase } from "../../utils/base/ComponentBase";
import { I18n } from "../../utils/I18n";
import { Loading } from "../../utils/Loading";



interface ContenidosProps {
    claseMateriaId: number;
}

interface ContenidosState {
    loading: boolean;
    contenidos: GetEstructuraContenidosClaseMateriaResult;
    usuarios: UsuarioInfo[];
    calificaciones: CalificacionInfo[];
    anchoUnidad: number;
    anchoUsuarios: number;
}

class ContenidosComp extends ComponentBase<ContenidosProps & RouteComponentProps<any>, ContenidosState>
{
    public constructor(props: ContenidosProps & RouteComponentProps<any>) {
        super(props);
        this.state = {
            loading: true,
            contenidos: null,
            usuarios: [],
            calificaciones: [],
            anchoUnidad: 100,
            anchoUsuarios: 0
        };
    }

    componentDidMount() {
        var service = new ContenidosService();
        var contentsProm = service.ObtenerEstructuraContenidos(this.props.claseMateriaId);
        var calificacionesProm = service.ObtenerCalificaciones(this.props.claseMateriaId);

        Promise.all([contentsProm, calificacionesProm])
            .then(([contenidos, calificaciones]) => {
                var anchoUnidad = 25;
                if (calificaciones.usuarios.length < 10) {
                    anchoUnidad = 40
                } else 
                if (calificaciones.usuarios.length > 15) {
                    anchoUnidad = 15;
                }
                var anchoUsuario = (100 - anchoUnidad) / calificaciones.usuarios.length;

                this.setState({
                    loading: false,
                    contenidos: contenidos,
                    usuarios: calificaciones.usuarios,
                    calificaciones: calificaciones.calificaciones,
                    anchoUnidad: anchoUnidad,
                    anchoUsuarios: anchoUsuario
                });
            }).catch(error => this.setState({ loading: false }, () => AlertsService.showError(error)));
    }

    public render(): JSX.Element {
        if (this.state.loading) {
            return <Loading />;
        }
        if (this.state.contenidos == null) {
            return <div>No he encontrado nada</div>;
        }
        var contenidos = this.state.contenidos.contenidos || [];
        if (contenidos.length == 0) {
            return <div>La materia no tiene contenidos asociados</div>;
        }

        return <React.Fragment>
            {contenidos.map((contenido, index) => {
                return <React.Fragment key={index}>
                    <h4>{contenido.nombre}</h4>
                    {contenido.libros.length == 0 && <Alert color="warning">{I18n.Strings.misClases.noHayActividades}</Alert>}
                    {contenido.libros.map((libro, indexLibro) => {
                        return <React.Fragment key={indexLibro}>
                            {contenido.libros.length != 1 && <b>{libro.titulo}</b>}
                            <table className="table table-border table-striped">
                                <colgroup>
                                    <col width="2%" />
                                    <col width={`${this.state.anchoUnidad - 2}%`} />
                                    {this.state.usuarios.map((usuario, idxUsuario) => {
                                        return <col key={idxUsuario} width={`${this.state.anchoUsuarios}%`} />;
                                    })}
                                </colgroup>
                                <thead>
                                    <tr>
                                        <th colSpan={2} >Unidad/actividad</th>
                                        {this.state.usuarios.map((usuario, idxUsuario) => {
                                            return <th key={idxUsuario} className="col_alumno">
                                                <div><span>{usuario.nombre}</span></div>
                                            </th>;
                                        })}
                                    </tr>
                                </thead>
                                <tbody>
                                    {this._pintarUnidades(libro.unidades, this.state.usuarios.length)}
                                </tbody>
                            </table>
                        </React.Fragment>;
                    })}
                </React.Fragment>;
            })}

        </React.Fragment>;
    }

    private _pintarUnidades(unidades: Unidad[], numAlumnos: number): React.ReactNode {

        var unidadesOrdenadas = unidades.sort((u1, u2) => u1.orden >= u2.orden ? 1 : -1);

        return <React.Fragment>
            {unidadesOrdenadas.map((unidad, indexUnidad) => {
                return <React.Fragment key={indexUnidad}>
                    <tr>

                        <td colSpan={2}><b>{unidad.nombre}</b></td>
                        <td colSpan={numAlumnos} style={{ width: `${this.state.anchoUsuarios}%` }}></td>
                    </tr>
                    {this._pintarActividades(unidad.actividades, numAlumnos)}
                </React.Fragment>
            })}
        </React.Fragment>;
    }

    private _pintarActividades(actividades: Actividad[], numAlumnos: number): JSX.Element {

        var actividadesOrdenadas = actividades.sort((a1, a2) => a1.orden >= a2.orden ? 1 : -1);
        return <React.Fragment>
            {actividadesOrdenadas.map((actividad, indexAct) => {
                return <tr key={indexAct}>
                    <td></td>
                    <td>{actividad.nombre}</td>
                    {this._pintarResultados(actividad, numAlumnos)}
                </tr>
            })}
        </React.Fragment>;
    }

    private _pintarResultados(actividad: Actividad, numAlumnos: number): JSX.Element {
        var resultados = [] as JSX.Element[];

        {
            this.state.usuarios.forEach((usuario, idxUsuario) => {
                var resultado = null as number;

                var resItem = this.state.calificaciones.find((c) => c.actividadId == actividad.id && c.usuarioId == usuario.id);
                if (resItem) {
                    resultado = resItem.calificacion;
                }

                resultados.push(
                    <td key={idxUsuario} style={{ textAlign: "center" }}>
                        {this._pintarResultado(resultado, resItem)}
                    </td>
                );
            })
        }

        return <React.Fragment>{resultados}</React.Fragment>;
    }

    private _pintarResultado(resultado: number, resItem: CalificacionInfo): JSX.Element {
        if (resultado == null) {
            return <span>-</span>;
        }
        else {
            if (resItem.urlResultados == '') {
                return <span>{resultado.toLocaleString()}</span>;
            }
            else {
                return <a
                    target="_blank"
                    href={resItem.urlResultados}>
                    {resultado.toLocaleString()}
                </a>
            }
        }
    }

}

class ContenidosService {

    public ObtenerEstructuraContenidos(claseMateriaId: number): Promise<GetEstructuraContenidosClaseMateriaResult> {
        var apiFetcher = new ApiFetcher();
        var estructuraProm = apiFetcher.getByUrl<GetEstructuraContenidosClaseMateriaResult>(`intranet/grupoclase/${claseMateriaId}/estructura-contenidos`);

        return estructuraProm;
    }

    public ObtenerCalificaciones(claseMateriaId: number): Promise<ClaseMateriaGetCalificacionesResult> {
        var apiFetcher = new ApiFetcher();
        var estructuraProm = apiFetcher.getByUrl<ClaseMateriaGetCalificacionesResult>(`intranet/grupoclase/${claseMateriaId}/calificaciones`);

        return estructuraProm;
    }
}

export const Contenidos = withRouter(ContenidosComp);