
import { NotificacionesSearch, NotificacionListItemPaginatedList, NotificacionMarcarLeidaModel, NotificacionEditModel, ModelValidationResult } from "../models/dataModels";
import { ApiFetcher } from "../utils/ApiFetcher";
import { IServiceForEdit, IServiceForIndex } from "../utils/base/BaseServices";

export class NotificationService    
    implements IServiceForIndex<NotificacionesSearch, NotificacionListItemPaginatedList>, IServiceForEdit<NotificacionEditModel>
{
    
    myNotifications(): Promise<NotificacionListItemPaginatedList> {
        var apiFetcher = new ApiFetcher();
        var prom = apiFetcher.getByUrl<NotificacionListItemPaginatedList>("intranet/notificaciones/my");
        prom.then(result => {
            result.items.forEach(item => {
                item.fecha = item.fecha == null ? null : new Date(item.fecha);
            });
        });
        return prom;
    }


    search(searchData: NotificacionesSearch): Promise<NotificacionListItemPaginatedList> {
        var apiFetcher = new ApiFetcher();
        var prom = apiFetcher.post<NotificacionesSearch, NotificacionListItemPaginatedList>("intranet/notificaciones/search", searchData);
        prom.then(res => {
            res.items.forEach(item => {
                item.fecha = item.fecha == null ? null : new Date(item.fecha);
            });
        });
        return prom;
    }

    marcarComoLeidos(model: NotificacionMarcarLeidaModel): Promise<NotificacionListItemPaginatedList> {
        var apiFetcher = new ApiFetcher();
        var prom = apiFetcher.post<NotificacionMarcarLeidaModel, NotificacionListItemPaginatedList>("intranet/notificaciones/marcarLeida", model);
        prom.then(res => {
            res.items.forEach(item => {
                item.fecha = item.fecha == null ? null : new Date(item.fecha);
            });
        });
        return prom;
    }

    getById(id: string | number): Promise<NotificacionEditModel> {
        var apiFetcher = new ApiFetcher();
        var prom = apiFetcher.getByUrl<NotificacionEditModel>(`intranet/notificaciones/${id}`);
        prom.then(res => {
            res.fecha = res.fecha == null ? null : new Date(res.fecha);
        });
        return prom;
    }

    update(id: string | number, model: NotificacionEditModel): Promise<NotificacionEditModel> {
        var apiFetcher = new ApiFetcher();
        var prom = apiFetcher.put<NotificacionEditModel, NotificacionEditModel>(`intranet/notificaciones/${id}`, model);
        prom.then(res => {
            res.fecha = res.fecha == null ? null : new Date(res.fecha);
        });
        return prom;
    }

    create(model: NotificacionEditModel): Promise<NotificacionEditModel> {
        var apiFetcher = new ApiFetcher();
        var prom = apiFetcher.post<NotificacionEditModel, NotificacionEditModel>(`intranet/notificaciones/`, model);
        return prom;
    }

    validate(model: NotificacionEditModel): Promise<ModelValidationResult> {
        throw new Error("Method not implemented.");
    }

    delete(id: number): Promise<any> {
        throw new Error("Method not implemented.");
    }

    exportExcel(search: NotificacionesSearch): Promise<any> {
        throw new Error("Method not implemented.");
    }
}