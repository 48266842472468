import * as React from "react";
import {
    Card, CardHeader, CardText, CardBody
} from 'reactstrap';

class CardProps {
    public numberValue: number;
    public headerText: string;
    public className?: string;
    public icono?: JSX.Element;
}

export class NumericCardBox extends React.Component<CardProps, any> {

    public render(): JSX.Element {

        return (
            <Card className={this.props.className ?? this.props.numberValue > 0 ? "card-ok" : "card-ko"}>
                <CardBody className={"text-center"}>
                    <CardText>
                        {this.props.numberValue.toString()}
                        {this.props.icono != null &&
                            <span style={{ marginLeft: 10 }}>
                                {this.props.icono}
                            </span>}
                    </CardText>
                </CardBody>
                <CardHeader>{this.props.headerText}</CardHeader>
            </Card>
        );
    }
}