import * as React from "react";
import {
    ButtonDropdown, DropdownToggle, DropdownMenu, Button, DropdownItem
} from 'reactstrap';

class ButtonDropDownProps {
    public botones: JSX.Element[];
}

class ButtonDropDownState {
    public dropdownOpen: boolean;
}

export class DropDownButton extends React.Component<ButtonDropDownProps, ButtonDropDownState> {

    public constructor(props: ButtonDropDownProps) {
        super(props);

        this.state = {
            dropdownOpen: false
        }
    }

    public render(): JSX.Element {
        var botones = this.props.botones;

        if (botones == null || botones.length == 0) {
            return null;
        }

        if (botones.length == 1) {
            return botones[0];
        }
        else if (botones.length > 1) {
            return <ButtonDropdown
                isOpen={this.state.dropdownOpen}
                toggle={() => {
                    this.setState({ dropdownOpen: !this.state.dropdownOpen });
                }}>
                <DropdownToggle className="btn-rounded btn btn-secondary" caret color="primary">
                    Acciones
                </DropdownToggle>
                <DropdownMenu className="botones_acciones">
                    {botones.map((button, index) => {
                                                
                        if (button.props.divider) {
                            //(button as any).key = index;
                            return button;
                        }
                        else {
                            return <DropdownItem
                                key={index}
                                onClick={button.props.onClick}
                                disabled={button.props.disabled}

                            >
                                {button.props.children}
                            </DropdownItem>
                        }

                    })}
                </DropdownMenu>
            </ ButtonDropdown>
        }
    }
}