import * as React from "react";
import { withRouter } from "react-router-dom";
import { Button, Col, FormGroup, Input, Label } from "reactstrap";
import nameof from "ts-nameof.macro";
import UserNameToolTip from "../colegioUsuarios/UserNameToolTip";
import { CursoEscolarDropDown } from "../cursoEscolar/CursoEscolarDropDown";
import { NivelEducativoDropDown } from "../cursoEscolar/NivelEducativoDropDown";
import { EditorialDropDown, FormatoContenidoDropDown, OrigenLicenciaDropDown, TipoUsuarioContenidoDropDown, TodosTipoAccesoDropDown, YesNoDropDown } from "../helpers/EnumDropDown";
import { RouteHelper } from "../IntranetRouter";
import { CursoEscolarListItem, Editorial, FormatoContenido, LicenciasContenidosListItem, LicenciasContenidosListItemPaginatedList, LicenciasContenidosSearch, OrigenLicencia, TipoAcceso, TipoUsuario, TipoUsuarioContenido, YesNo } from "../models/dataModels";
import { AlertsService } from "../utils/AlertsService";
import { IServiceForIndex } from "../utils/base/BaseServices";
import { IndexPageBase, TableColumn } from "../utils/base/IndexPageBase";
import { ClientContext } from "../utils/ClientContext";
import { DeleteModal } from "../utils/DeleteModal";
import { I18n } from "../utils/I18n";
import { DeleteIcon } from "../utils/Icons";
import { LicenciaContenidosEditTabs } from "./Edit";
import { LicenciasColegioService } from "./LicenciasColegioService";
import { TipoAccesoDropDown } from "./TipoAccesoDropDown";




export interface LicenciasContenidosSearchEx extends LicenciasContenidosSearch {
    cursoEscolar: string;
    nivelEducativo: string;
    showBulkDeleteConfirmation: boolean;
}

class IndexComp extends IndexPageBase<LicenciasContenidosSearchEx, LicenciasContenidosListItemPaginatedList, LicenciasContenidosListItem>
{


    protected _getInitialSearchParams(): LicenciasContenidosSearchEx {

        var cursoEscolarActivo = ClientContext.Current.cursosEscolares.find(c => c.id == ClientContext.Current.cursoEscolarActivoId);

        return {
            ...{ contenidoId: this.props.searchModel?.contenidoId } as LicenciasContenidosSearchEx,
            usuarioId: this.props.searchModel?.usuarioId,
            cursoEscolar: cursoEscolarActivo.nombre,
            cursoEscolarId: cursoEscolarActivo.id,
            matriculaId: this.props.searchModel?.matriculaId,
            orderByColumn: nameof(this.state.searchResults.items[0].contenido)
        }
    }

    protected _canAddItems(): boolean {
        return ClientContext.Current.tipoUsuario == TipoUsuario.AdminGlobal;
    }


    protected _showCheckboxes(): boolean {
        return true;
    }

    protected _toolbarRightContents(): JSX.Element[] {

        var contents = super._toolbarRightContents();

        if (ClientContext.Current.tipoUsuario == TipoUsuario.AdminGlobal && (this.state.checkedElements || []).length !== 0) {
            contents.push(<Button
                id="borrar"
                color="secondary"
                
                className="btn btn-rounded btn-sm"
                onClick={() => {
                    var newState = this._cloneStateForSetState();
                    newState.searchParams.showBulkDeleteConfirmation = true;
                    this.setState(newState);
                }}>
                <DeleteIcon />
                {I18n.Strings.delete}
            </Button>);
        }

        return contents
    }

    protected _performDeleteMultiple(ids: number[]) {

        var state = this._cloneStateForSetState();
        state.showLoadingIcon = true;
        state.searchParams.currentPage = 0;

        this.setState(state, () => {
            var service = new LicenciasColegioService();

            service.deleteMultiple(ids)
                .then(() => {
                    var newState = this._cloneStateForSetState();
                    newState.searchParams.showBulkDeleteConfirmation = false;
                    this.setState(newState, () => {
                        this._performSearch();
                    });
                    
                })
                .catch((error) => {
                    this._showError(I18n.logAndExtractInfoFromException(error));
                });
        });
    }


    protected _renderSearchForm(): React.ReactNode {
        return <React.Fragment>

            {this.state.searchParams.showBulkDeleteConfirmation &&
                <DeleteModal
                    deleteConfirmBody="Va a eliminar los registros, ¿está seguro?"
                    onCancelDelete={() => {
                        var newstate = this._cloneStateForSetState();
                        newstate.searchParams.showBulkDeleteConfirmation = false;
                        this.setState(newstate);
                    }}
                    onConfirm={() => this._performDeleteMultiple(this.state.checkedElements)}
                />
            }

            <Col xs="12" md="4" lg="2">

                <EditorialDropDown
                    emptyText={I18n.Strings.licenciasContenido.editorial + "..."}
                    label={I18n.Strings.licenciasContenido.editorial}
                    id={nameof(this.state.searchParams.editorial)}
                    value={this.state.searchParams.editorial}
                    required={true}
                    onChanged={(value: string) => {
                        var newState = this._cloneStateForSetState();
                        newState.searchParams.editorial = value == "" ? null : value as Editorial;
                        newState.searchParams.currentPage = 0;
                        this.setState(newState, () => this._performSearch());
                    }}
                />

            </Col>

            <Col xs="12" md="4" lg="2">
                <CursoEscolarDropDown
                    label={null}
                    emptyText={I18n.Strings.licenciasContenido.cursoEscolar}
                    id={nameof(this.state.searchParams.cursoEscolarId)}

                    selectedItemValue={this.state.searchParams.cursoEscolarId}
                    selectedItemText={this.state.searchParams.cursoEscolar}

                    onChanged={(id, nombre, data: CursoEscolarListItem) => {
                        var state = this._cloneStateForSetState();
                        state.searchParams.cursoEscolarId = id;
                        state.searchParams.cursoEscolar = nombre;
                        state.searchParams.currentPage = 0;
                        this.setState(state, () => this._performSearch());
                    }}
                />
            </Col>
            <Col xs="12" md="3" lg="3">
                <FormGroup>
                    <Label className="visually-hidden" for={nameof(this.state.searchParams.nivelEducativoId)}>{I18n.Strings.licenciasContenido.nivelEducativo}</Label>
                    <NivelEducativoDropDown
                        key={`nivelEducativo_${this.state.searchParams.cursoEscolarId}`}
                        emptyText={I18n.Strings.licenciasContenido.nivelEducativo + "..."}
                        id={nameof(this.state.searchParams.nivelEducativoId)}
                        cursoEscolarId={this.state.searchParams.cursoEscolarId}
                        value={this.state.searchParams.nivelEducativoId}
                        text={this.state.searchParams.nivelEducativo}
                        seleccionClases={false}
                        onChange={(id, nombre) => {

                            var state = this._cloneStateForSetState();
                            state.searchParams.nivelEducativoId = id;
                            state.searchParams.nivelEducativo = nombre;
                            state.searchParams.currentPage = 0;
                            this.setState(state, () => this._performSearch());
                        }}
                    />
                </FormGroup>
            </Col>
            <Col xs="12" md="6" lg="5">
                <FormGroup>
                    <Label className="visually-hidden" for={nameof(this.state.searchParams.contenidoNombre)}>
                        {I18n.Strings.licenciasContenido.contenidoBuscador}
                    </Label>
                    <Input
                        placeholder={I18n.Strings.licenciasContenido.contenidoBuscador + "..."}
                        onChange={(evt) => {
                            var state = this._cloneStateForSetState();
                            state.searchParams.contenidoNombre = evt.target.value;
                            this.setState(state);
                        }}
                        type="text"
                        maxLength={100}
                        className={"form-control"}
                        name={nameof(this.state.searchParams.contenidoNombre)}
                        id={nameof(this.state.searchParams.contenidoNombre)}
                        value={this.state.searchParams.contenidoNombre || ""}
                    />

                </FormGroup>
            </Col>


            <Col xs="12" md="6" lg="3">
                <FormGroup>
                    <Label className="visually-hidden" for={nameof(this.state.searchParams.usuarioNombre)}>
                        {I18n.Strings.licenciasContenido.usuario}
                    </Label>
                    <Input
                        placeholder={I18n.Strings.licenciasContenido.usuario + "..."}
                        onChange={(evt) => {
                            var state = this._cloneStateForSetState();
                            state.searchParams.usuarioNombre = evt.target.value;
                            this.setState(state);
                        }}
                        type="text"
                        maxLength={100}
                        className={"form-control"}
                        name={nameof(this.state.searchParams.usuarioNombre)}
                        id={nameof(this.state.searchParams.usuarioNombre)}
                        value={this.state.searchParams.usuarioNombre || ""}
                    />

                </FormGroup>
            </Col>

            <Col xs="12" md="6" lg="3">
                <FormGroup>
                    <Label className="visually-hidden" for={nameof(this.state.searchParams.credenciales)}>
                        {I18n.Strings.licenciasContenido.credenciales}
                    </Label>
                    <Input
                        placeholder={I18n.Strings.licenciasContenido.credenciales + "..."}
                        onChange={(evt) => {
                            var state = this._cloneStateForSetState();
                            state.searchParams.credenciales = evt.target.value;
                            this.setState(state);
                        }}
                        type="text"
                        maxLength={100}
                        className={"form-control"}
                        name={nameof(this.state.searchParams.credenciales)}
                        id={nameof(this.state.searchParams.credenciales)}
                        value={this.state.searchParams.credenciales || ""}
                    />

                </FormGroup>
            </Col>

            <Col xs="12" md="4" lg="3">

                <OrigenLicenciaDropDown
                    emptyText={I18n.Strings.licenciasContenido.origen + "..."}
                    label={I18n.Strings.licenciasContenido.origen}
                    id={nameof(this.state.searchParams.origen)}
                    value={this.state.searchParams.origen}
                    required={true}
                    onChanged={(value: string) => {
                        var newState = this._cloneStateForSetState();
                        newState.searchParams.origen = value == "" ? null : value as OrigenLicencia;
                        newState.searchParams.currentPage = 0;
                        this.setState(newState, () => this._performSearch());
                    }}
                />

            </Col>

            <Col xs="12" md="4" lg="3">
                <FormGroup>
                    <Label className="visually-hidden" for={nameof(this.state.searchParams.numPedido)}>
                        {I18n.Strings.licenciasContenido.numPedido}
                    </Label>
                    <Input
                        placeholder={I18n.Strings.licenciasContenido.numPedido + "..."}
                        onChange={(evt) => {
                            var state = this._cloneStateForSetState();
                            state.searchParams.numPedido = evt.target.value;
                            this.setState(state);
                        }}
                        type="text"
                        maxLength={100}
                        className={"form-control"}
                        name={nameof(this.state.searchParams.numPedido)}
                        id={nameof(this.state.searchParams.numPedido)}
                        value={this.state.searchParams.numPedido || ""}
                    />

                </FormGroup>
            </Col>

            <Col xs="12" md="4" lg="2">
                <FormGroup>
                    <Label className="visually-hidden" for={nameof(this.state.searchParams.numPedido)}>
                        {I18n.Strings.licenciasContenido.numPedido}
                    </Label>
                    <TipoUsuarioContenidoDropDown
                        emptyText={I18n.Strings.matriculas.rol + "..."}
                        label={I18n.Strings.matriculas.rol}
                        id={nameof(this.state.searchParams.rol)}
                        value={this.state.searchParams.rol}
                        required={true}
                        onChanged={(value: string) => {
                            var newState = this._cloneStateForSetState();
                            newState.searchParams.rol = value == "" ? null : value as TipoUsuarioContenido;
                            newState.searchParams.currentPage = 0;
                            this.setState(newState, () => this._performSearch());
                        }}
                    />

                </FormGroup>
            </Col>

            <Col xs="12" md="4" lg="2">

                <FormatoContenidoDropDown
                    emptyText={I18n.Strings.licenciasContenido.formato + "..."}
                    label={I18n.Strings.licenciasContenido.formato}
                    id={nameof(this.state.searchParams.formatoContenido)}
                    value={this.state.searchParams.formatoContenido}
                    required={true}
                    onChanged={(value: string) => {
                        var newState = this._cloneStateForSetState();
                        newState.searchParams.formatoContenido = value == "" ? null : value as FormatoContenido;
                        newState.searchParams.currentPage = 0;
                        this.setState(newState, () => this._performSearch());
                    }}
                />

            </Col>

            <Col xs="12" md="6" lg="2">
                <YesNoDropDown
                    emptyText={I18n.Strings.licenciasContenido.credenciales + "..."}
                    label={I18n.Strings.licenciasContenido.credenciales}
                    id={nameof(this.state.searchParams.tieneCredenciales)}
                    value={this.state.searchParams.tieneCredenciales == null ? null : this.state.searchParams.tieneCredenciales ? YesNo.Yes.toString() : YesNo.No.toString()}
                    className={"form-control"}
                    required={true}
                    onChanged={(value: YesNo) => {
                        var newState = this._cloneStateForSetState();
                        newState.searchParams.tieneCredenciales = value == YesNo.Yes.toString() ? true : value == YesNo.No.toString() ? false : null;
                        this.setState(newState, () => this._performSearch());
                    }}
                />
            </Col>

            <Col xs="12" md="6" lg="2">
                <TodosTipoAccesoDropDown
                    emptyText={I18n.Strings.licenciasContenido.tipoAcceso + "..."}
                    label={I18n.Strings.licenciasContenido.tipoAcceso}
                    id={nameof(this.state.searchParams.tipoAcceso)}
                    value={this.state.searchParams.tipoAcceso}
                    required={true}

                    onChanged={(value: string) => {
                        var newState = this._cloneStateForSetState();
                        newState.searchParams.tipoAcceso = value == "" ? null : value as TipoAcceso;
                        newState.searchParams.currentPage = 0;
                        this.setState(newState, () => this._performSearch());
                    }}
                />
            </Col>

        </React.Fragment>;
    }

    protected _getApiService(): IServiceForIndex<LicenciasContenidosSearch, LicenciasContenidosListItemPaginatedList> {
        return new LicenciasColegioService();
    }

    protected _getTableColumns(): TableColumn<LicenciasContenidosListItem>[] {

        var columnas: TableColumn<LicenciasContenidosListItem>[];

        var item = {} as LicenciasContenidosListItem;
        // TODO MBERNAL: Quitar este engendro de IF y mostrar ocultar campos en funcion de los props por los que filtramos

        // si se muestra en pestaña de detalle de un alumno
        if (this.props.searchModel != null && this.props.searchModel.usuarioId != null) {
            columnas = [
                {
                    fieldName: nameof(item.isbn),
                    title: I18n.Strings.licenciasContenido.isbn,
                    renderField: (item: LicenciasContenidosListItem) => item.isbn,
                    colMdWidth: 4
                },
                {
                    fieldName: nameof(item.contenido),
                    title: I18n.Strings.licenciasContenido.contenido,
                    renderField: (item: LicenciasContenidosListItem) => {
                        var titulo = item.contenido;

                        if ((item.contenidoId || 0) == 0) {
                            titulo += " (Papel)";
                        }
                        return titulo
                    },
                    colMdWidth: 4
                },
                {
                    fieldName: nameof(item.clase),
                    title: I18n.Strings.licenciasContenido.grupoClase,
                    renderField: (item: LicenciasContenidosListItem) => item.clase,
                    colMdWidth: 2
                },
                {
                    fieldName: nameof(item.claseMateria),
                    title: I18n.Strings.licenciasContenido.claseMateria,
                    renderField: (item: LicenciasContenidosListItem) => item.claseMateria,
                    colMdWidth: 2
                },
                {
                    fieldName: nameof(item.subgrupo),
                    title: I18n.Strings.matriculas.subgrupo,
                    renderField: (item: LicenciasContenidosListItem) => item.subgrupo,
                    colMdWidth: 2
                },
                {
                    fieldName: nameof(item.usuario),
                    title: I18n.Strings.licenciasContenido.usuario,
                    renderField: (item: LicenciasContenidosListItem) => item.usuario,
                    colMdWidth: 4
                },
                {
                    fieldName: nameof(item.credenciales),
                    title: I18n.Strings.licenciasContenido.credenciales,
                    renderField: (item: LicenciasContenidosListItem) => {
                        if ((item.contenidoId || 0) == 0) {
                            return "Papel";
                        }

                        return item.credenciales;
                    },
                    colMdWidth: 3
                },
                {
                    fieldName: nameof(item.fechaPedido),
                    title: I18n.Strings.licenciasContenido.fechaPedido,
                    renderField: (item: LicenciasContenidosListItem) => I18n.Strings.formatDate(item.fechaPedido),
                    colMdWidth: 3
                },
                {
                    fieldName: nameof(item.editorial),
                    title: I18n.Strings.licenciasContenido.editorial,
                    renderField: (item: LicenciasContenidosListItem) => {
                        return I18n.Strings.getEnumText(`Editorial.${item.editorial}`);
                    },
                    colMdWidth: 2
                }
            ];
        }
        // si se muestra en listado de licencias
        else {
            columnas = [
                {
                    fieldName: nameof(item.isbn),
                    title: I18n.Strings.licenciasContenido.isbn,
                    renderField: (item: LicenciasContenidosListItem) => item.isbn,
                    colMdWidth: 4
                },
                {
                    fieldName: nameof(item.contenido),
                    title: I18n.Strings.licenciasContenido.contenido,
                    renderField: (item: LicenciasContenidosListItem) => {
                        var titulo = item.contenido;

                        if ((item.contenidoId || 0) == 0) {
                            titulo += " (Papel)";
                        }
                        return titulo
                    },
                    colMdWidth: 3
                },
                {
                    fieldName: nameof(item.editorial),
                    title: I18n.Strings.licenciasContenido.editorial,
                    renderField: (item: LicenciasContenidosListItem) => {
                        var editorial = I18n.Strings.getEnumText(`Editorial.${item.editorial}`);
                        if (item.contenidoId == null) {
                            return editorial;
                        }
                        var tipoAcceso = item.contenidoId == null ? "papel" : I18n.Strings.getEnumText(`TipoAcceso.${item.tipoAcceso}`);
                        return `${editorial} - ${tipoAcceso}`;
                    },
                    colMdWidth: 2
                },
                /*
                {
                    fieldName: nameof(item.nivelEducativo),
                    title: I18n.Strings.licenciasContenido.nivelEducativo,
                    renderField: (item: LicenciasContenidosListItem) => item.nivelEducativo,
                    colMdWidth: 2
                },
                */
                {
                    fieldName: nameof(item.clase),
                    title: I18n.Strings.licenciasContenido.grupoClase,
                    renderField: (item: LicenciasContenidosListItem) => item.clase,
                    colMdWidth: 2
                },
                {
                    fieldName: nameof(item.claseMateria),
                    title: I18n.Strings.licenciasContenido.claseMateria,
                    renderField: (item: LicenciasContenidosListItem) => item.claseMateria,
                    colMdWidth: 2
                },
                {
                    fieldName: nameof(item.subgrupo),
                    title: I18n.Strings.matriculas.subgrupo,
                    renderField: (item: LicenciasContenidosListItem) => item.subgrupo,
                    colMdWidth: 2
                },
                {
                    fieldName: nameof(item.credenciales),
                    title: I18n.Strings.licenciasContenido.credenciales,
                    renderField: (item: LicenciasContenidosListItem) => {

                        if ((item.contenidoId || 0) == 0) {
                            return "Papel";
                        }
                        return I18n.Strings.formatBool((item.credenciales || "").length != 0);
                    },
                    colMdWidth: 2
                },
                {
                    fieldName: nameof(item.usuario),
                    title: I18n.Strings.licenciasContenido.usuario,
                    renderField: (item: LicenciasContenidosListItem) => <UserNameToolTip userName={item.usuario} userId={item.usuarioId} key={item.usuarioId} />,
                    colMdWidth: 3
                },
                {
                    fieldName: nameof(item.fechaUltimoAcceso),
                    title: I18n.Strings.licenciasContenido.fechaUltimoAcceso,
                    renderField: (item: LicenciasContenidosListItem) => {
                        if ((item.contenidoId || 0) == 0) {
                            return "Papel";
                        }
                        return I18n.Strings.formatLastAccessDate(item.fechaUltimoAcceso);
                    },
                    colMdWidth: 2
                },

            ];
        }

        if (this.props.searchModel?.usuarioId != null) {
            columnas = columnas.filter(p => p.fieldName != nameof(item.usuario));
        }

        if (this.props.searchModel?.contenidoId != null) {
            columnas = columnas.filter(p => p.fieldName != nameof(item.contenido)
                && p.fieldName != nameof(item.editorial)
            );
        }

        if (this.props.searchModel?.matriculaId != null) {
            columnas = columnas.filter(p => p.fieldName != nameof(item.cursoEscolar)
                && p.fieldName != nameof(item.clase)
                && p.fieldName != nameof(item.nivelEducativo)
                && p.fieldName != nameof(item.claseMateria)
                && p.fieldName != nameof(item.usuario)
            );
        }


        if (this.props.searchModel?.claseMateriaId != null) {
            columnas = columnas.filter(p => p.fieldName != nameof(item.claseMateria)
                && p.fieldName != nameof(item.clase)
            );
        }

        return columnas;
    }

    protected _getPageTitle(): string {
        return I18n.Strings.licenciasContenido.entityPluralName;
    }



    protected _getPageDescription(): string {
        return "Aquí ves las licencias digitales + cont en papel de los usuarios";
    }

    protected hideGridButtonsOverride(item: LicenciasContenidosListItem) {
        if (item == null) return true;

        return (item.contenidoId || 0) == 0;
    }

    protected _getEditionItemUrl(item: LicenciasContenidosListItem): string {

        return RouteHelper.editLicenciasColegio(item.id,
            this.props.searchModel?.usuarioId,
            this.props.searchModel?.contenidoId,
            LicenciaContenidosEditTabs.Datos);
    }

    protected _getNewItemUrl(): string {
        if (ClientContext.Current.tipoUsuario != TipoUsuario.AdminGlobal) {
            return null;
        }
        return RouteHelper.createLicenciasColegio(this.props.searchModel?.usuarioId, this.props.searchModel?.contenidoId);
    }

    protected _enableExcelExport(): boolean {
        return true;
    }


    protected _calculateCellClass(item: LicenciasContenidosListItem) {
        return item.tieneError ? "text-danger" : "";
    }
}

export const Index = withRouter(IndexComp);