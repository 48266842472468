import * as React from "react";
import { Col, Row } from "reactstrap";
import { AccesosSistema } from "../accesos/AccesosSistema";
import { TipoGrafico } from "../models/dataModels";
import { I18n } from "../utils/I18n";



interface EstadisticasState {

}

interface EstadisticasProps {
    usuarioId: number;
}

export class TabEstadisticasAccesos extends React.Component<EstadisticasProps, EstadisticasState> {

    public constructor(props: any) {
        super(props);

        //this.state = {
        //    loading: true
        //};
    }

    render() {

        return <div className={"estadisticas"}>
            <h2>{I18n.Strings.estadisticas.accesosSistema}</h2>
            <Row>
                <Col md={12}>
                    <AccesosSistema usuarioId={this.props.usuarioId} tipo={TipoGrafico.Usuario} />
                </Col>
            </Row>
        </ div>
    }
}