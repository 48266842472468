import * as React from "react";

import { BarChart, Bar, LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import { I18n } from "../utils/I18n";
import { EstadisticaListItem } from "../models/dataModels";


interface AccesosGraphProps {
    data: EstadisticaListItem[];
}

export class AccesosSistemaGraph extends React.Component<AccesosGraphProps, any> {
    formatXAxis = (tickItem) => {
        return I18n.Strings.formatDate(new Date(tickItem));

    }
    render() {
        return <div className={"grafico_accesos" }>
            <ResponsiveContainer className="mt-lg"  width="100%">
                <BarChart
                    data={this.props.data}

                >
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="dia" tickFormatter={this.formatXAxis} ticks={['']} />
                    <Bar dataKey="numAccesos" name="Nº accesos" fill="#8884d8" />
                    <Tooltip labelFormatter={t => I18n.Strings.formatDate(new Date(t))} />
                </BarChart>
            </ResponsiveContainer>
        </div>;
    }
};