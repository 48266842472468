import { cloneDeep, differenceWith, isEqual } from "lodash-es";
import * as React from "react";
import { withRouter } from "react-router-dom";
import { Button, Card, CardBody, Col, Collapse, Modal, ModalBody, ModalFooter, ModalHeader, Row, UncontrolledCollapse } from "reactstrap";
import { AliasEditorialEditTabs } from "../aliasEditoriales/Edit";
import { ContenidoEditTabs } from "../contenidos/maestros/Edit";
import { RouteHelper } from "../IntranetRouter";
import { LicenciaContenidosEditTabs } from "../licenciasColegio/Edit";
import {
    ColegioDiagnosticoResult,
    EstadoNotificacion, NotificacionListItem, NotificacionMarcarLeidaModel, TipoAcceso, TipoUsuario, TipoUsuarioContenido
} from "../models/dataModels";
import { AlertsService } from "../utils/AlertsService";
import { ApiFetcher } from "../utils/ApiFetcher";
import { ClientContext } from "../utils/ClientContext";
import { I18n } from "../utils/I18n";
import { CancelIcon, CheckCircleIcon, CheckIcon, RefreshIcon, SearchIcon, SpinnerIcon } from "../utils/Icons";
import { Loading } from "../utils/Loading";


interface NotificationIconProps {

};

interface DiagnosticoState {

    showModal: boolean;
    diagnostico: ColegioDiagnosticoResult,
    loading: boolean;
}


/**
 * Pinta el paginador de los listados
 */
export class DiagnosticoComp extends React.Component<any, DiagnosticoState> {

    public constructor(props: NotificationIconProps) {

        super(props);


        this.state = {
            loading: false,
            showModal: false,
            diagnostico: null
        };
    }

    public componentDidMount() {

    }

    private runDiagnostics() {

        this.setState({ loading: true }, () => {
            // Realizo la lectura de las notificaciones
            var service = new DiagnosticoService();

            service.RunDiagnostics()
                .then(diagResult => {
                    this.setState({
                        loading: false,
                        showModal: true,
                        diagnostico: diagResult
                    });
                })
                .catch(error => this.setState({ loading: false }, () => AlertsService.showError(error)));
        });
    }



    protected _cloneStateForSetState(): DiagnosticoState {
        return cloneDeep(this.state) as DiagnosticoState;
    }


    public render(): JSX.Element {


        return <React.Fragment>
            {this.state.loading && <Loading />}
            <div className="diagnostico">
                <Button className="btn btn-rounded" onClick={() => this.runDiagnostics()}>
                    <i className="bi bi-bell"></i>
                    {I18n.Strings.diagnostico.title}
                </Button>
            </div>

            {this.state.showModal && this.state.diagnostico != null &&
                <Modal isOpen={true} className="modal-xl"
                    toggle={() => { this.setState({ showModal: !this.state.showModal }); }}
                >
                    <ModalHeader close={<button className="btn-close" onClick={() => this.setState({ showModal: false })} />}>
                        {I18n.Strings.diagnostico.title}

                    </ModalHeader>
                    <ModalBody>

                        <div className="licencias">
                            <h3>{I18n.Strings.diagnostico.alertasColegio}</h3>
                            {this._pintarProblemasCursoEscolar()}
                            {this._pintarMateriasNombreMal()}
                            {this._pintarEditorialesSinCredenciales()}

                            {this._pintarLicenciasSinCredenciales()}

                            {this._pintarMatriculasSinLicencias()}
                            {ClientContext.Current.tipoUsuario == TipoUsuario.AdminGlobal &&
                                <React.Fragment>
                                    <h3 className="pt-5">{I18n.Strings.diagnostico.alertasPlataforma}</h3>
                                    {this._pintarAliasEditorialSinCompletar()}
                                    {this._pintarContenidosSinEditorial()}
                                </React.Fragment>
                            }
                        </div>
                    </ModalBody>
                    <ModalFooter>
                        <Button onClick={() => this.runDiagnostics()}>
                            <RefreshIcon /> {I18n.Strings.refresh}
                        </Button>
                    </ModalFooter>
                </Modal>

            }

        </React.Fragment>;
    }

    private _pintarProblemasCursoEscolar(): JSX.Element {

        var diagnostico = this.state.diagnostico.cursosEscolaresDiag;

        var showError = (isKO: boolean, msg: string) => {
            if (!isKO) return null;

            return <Row className="tit-lista-lic pb-0">
                <Col>
                    <CheckCircleIcon cssClass={"checkKO iconoGrande pe-2"} />
                    <a href={RouteHelper.listCursosEscolares()} target="_blank">{msg}</a>
                </Col>
            </Row>;
        };

        return <React.Fragment>
            {showError(diagnostico.multiplesActivos, I18n.Strings.diagnostico.cursoEscolarMasDeUnActivo)}
            {showError(diagnostico.noHayActivos, I18n.Strings.diagnostico.cursoEscolarNoHayActivo)}
            {showError(diagnostico.activosSinAccesoAlumnos, I18n.Strings.diagnostico.cursoEscolarActivoSinAlumnos)}
        </React.Fragment>;
    }

    private _pintarMatriculasSinLicencias(): JSX.Element {

        return this._pintarBloque("aliasMatriculasSinLicencias",
            I18n.Strings.diagnostico.aliasEditorialOK,
            this.state.diagnostico.matriculasSinLicencia,
            (items) => I18n.Strings.diagnostico.matriculasSinLicenciasKO(items.length),
            (item) => <a target="_blank" href={RouteHelper.editMatricula(item.matriculaId, item.usuarioId)}>
                {item.usuario} ({I18n.Strings.getEnumText(`TipoUsuarioContenido.${item.rol}`) }) - {item.nivelEducativo} - {item.claseMateria}
            </a>
        );
    }

    private _pintarAliasEditorialSinCompletar(): JSX.Element {

        return this._pintarBloque("aliasEditorialSinCompletar",
            I18n.Strings.diagnostico.aliasEditorialOK,
            this.state.diagnostico.aliasEditorialesSinCompletar,
            (items) => I18n.Strings.diagnostico.aliasEditorialKO(items.length),
            (item) => <a target="_blank" href={RouteHelper.editAliasEditorial(item.id, AliasEditorialEditTabs.Datos)}>
                {item.alias}
            </a>
        );
    }

    private _pintarContenidosSinEditorial(): JSX.Element {

        return this._pintarBloque("contenidosSinEditorial",
            I18n.Strings.diagnostico.contenidosEditorialOK,
            this.state.diagnostico.contenidosSinEditorial,
            (items) => I18n.Strings.diagnostico.contenidosEditorialKO(items.length),
            (item) => <a target="_blank" href={RouteHelper.editContenidos(item.id, ContenidoEditTabs.Datos)}>
                {item.nombre}
            </a>
        );
    }

    private _pintarMateriasNombreMal(): JSX.Element {

        return this._pintarBloque("clasesMateriasNombreMal",
            I18n.Strings.diagnostico.materiasNombreOK,
            this.state.diagnostico.clasesMateriasNombreMal,
            (items) => I18n.Strings.diagnostico.materiasNombreKO(items.length),
            (item) => <a target="_blank" href={RouteHelper.editGrupoClase(item.claseId, item.cursoEscolarId)}>
                {item.clase} - {item.claseMateria}
            </a>
        );
    }

    private _pintarLicenciasSinCredenciales(): JSX.Element {

        return this._pintarBloque("licenciasSinCredenciales",
            I18n.Strings.diagnostico.licenciasSinCredencialesOK,
            this.state.diagnostico.licenciasSinConfigurar,
            (items) => I18n.Strings.diagnostico.licenciasSinCredencialesKO(items.length),
            (item) => <a target="_blank"
                href={RouteHelper.editLicenciasColegio(item.id, item.usuarioId, null, LicenciaContenidosEditTabs.Datos)}
            >
                {item.usuario} ({I18n.Strings.getEnumText(`TipoUsuarioContenido.${item.rol}`)}) - {item.contenido}
            </a>
        );
    }

    private _pintarEditorialesSinCredenciales(): JSX.Element {

        return this._pintarBloque("editorialesSinCredenciales",
            I18n.Strings.diagnostico.credencialesEditorialesOK,
            this.state.diagnostico.editorialesSinCredenciales,
            (items) => I18n.Strings.diagnostico.credencialesEditorialesKO(items.length),
            (item) => {
                var titulo = I18n.Strings.getEnumText(`Editorial.${item.editorial}`) + " - " + I18n.Strings.getEnumText(`TipoAcceso.${item.tipoAcceso}`);
                if (item.id == null) {

                    return <a target="_blank" href={RouteHelper.listCredencialesColegio()}>
                        {titulo}
                    </a>;
                }
                else {
                    return <a target="_blank" href={RouteHelper.editCredenciales(item.id)}>
                        {titulo}
                    </a>;

                }
            }
        );
    }

    private _pintarBloque<TItem>(
        id: string,
        textoOK: string,
        items: TItem[],
        fnPintaItemsHeader: (items: TItem[]) => string,
        fnPintaItem: (item: TItem) => JSX.Element): JSX.Element {

        if ((items || []).length == 0) {
            return <Row className="tit-lista-lic">
                <Col>
                    <CheckCircleIcon cssClass={"checkOK iconoGrande pe-2"} />
                    {textoOK}
                </Col>
            </Row>;
        }
        return <Row className="tit-lista-lic pb-0">
            <Col>
                <span className="pointer" id={id}>
                    <CancelIcon cssClass={"checkKO iconoGrande pe-2"} />
                    {fnPintaItemsHeader(items)}
                </span>
                <UncontrolledCollapse toggler={`#${id}`}>
                    <Card>
                        <CardBody>
                            <ul>
                                {items.map((cm, key) => {
                                    return <li key={key}>{fnPintaItem(cm)}</li>;
                                })}
                            </ul>
                        </CardBody>
                    </Card>
                </UncontrolledCollapse>
            </Col>
        </Row>;

    }

};
export const Diagnostico = withRouter(DiagnosticoComp)

class DiagnosticoService {

    public RunDiagnostics(): Promise<ColegioDiagnosticoResult> {

        var apiFetcher = new ApiFetcher();
        var prom = apiFetcher.getByUrl<ColegioDiagnosticoResult>("intranet/utils/diagnostico");
        return prom;
    }
}