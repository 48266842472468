import { NivelContenidosEditModel, NivelContenidosListItemPaginatedList, NivelContenidosDeleteResult, NivelContenidosSearch, ModelValidationResult } from "../models/dataModels";
import { AlertsService } from "../utils/AlertsService";
import { ApiFetcher } from "../utils/ApiFetcher";
import { IServiceForEdit, IServiceForIndex } from "../utils/base/BaseServices";

export class NivelContenidosService
    implements IServiceForIndex<NivelContenidosSearch, NivelContenidosListItemPaginatedList>, IServiceForEdit<NivelContenidosEditModel>
{
    
    search(searchData: NivelContenidosSearch): Promise<NivelContenidosListItemPaginatedList> {

        var apiFetcher = new ApiFetcher();
        var prom = apiFetcher.post<NivelContenidosSearch, NivelContenidosListItemPaginatedList>("intranet/colegio/nivelesContenidos/search", searchData);
        return prom;
    }

    delete(id: number): Promise<any> {
        var apiFetcher = new ApiFetcher();
        var prom = apiFetcher.delete<any>(`intranet/colegio/nivelesContenidos/${id}`);
        prom.then(ret => {

            var msg = ret as NivelContenidosDeleteResult;
            if (msg.errorMsg != '') {
                AlertsService.showErrorMessage(msg.errorMsg);
            }
        });
        return prom;
    }

    getById(id: string | number): Promise<NivelContenidosEditModel> {
        var apiFetcher = new ApiFetcher();
        var prom = apiFetcher.getByUrl<NivelContenidosEditModel>(`intranet/colegio/nivelesContenidos/${id}`);
        return prom;
    }
    validate(model: NivelContenidosEditModel): Promise<ModelValidationResult> {
        var apiFetcher = new ApiFetcher();
        var prom = apiFetcher.post<NivelContenidosEditModel, ModelValidationResult>(`intranet/colegio/nivelesContenidos/validate`, model);
        return prom;
    }

    create(model: NivelContenidosEditModel): Promise<NivelContenidosEditModel> {
        var apiFetcher = new ApiFetcher();
        var prom = apiFetcher.post<NivelContenidosEditModel, NivelContenidosEditModel>(`intranet/colegio/nivelesContenidos/`, model);
        return prom;
    }

    update(id: string | number, model: NivelContenidosEditModel): Promise<NivelContenidosEditModel> {
        var apiFetcher = new ApiFetcher();
        var prom = apiFetcher.put<NivelContenidosEditModel, NivelContenidosEditModel>(`intranet/colegio/nivelesContenidos/${id}`, model);
        return prom;
    }

    exportExcel(search: NivelContenidosSearch): Promise<any> {
        throw new Error("Method not implemented.");
    }

}

