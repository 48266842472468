import * as React from "react";
import { DropDownBase, GenericSelectItem, IDropDownProps } from "../utils/base/DropDownBase";

import { ClientContext } from "../utils/ClientContext";

export class CursoEscolarDropDown extends DropDownBase<IDropDownProps> {


    protected GetItems(): Promise<GenericSelectItem[]> {

        var items = ClientContext.Current.cursosEscolares.map((cp, index) => {
            return {
                id: cp.id,
                name: cp.nombre,
            } as GenericSelectItem;
        });

        return Promise.resolve(items);
    }

    protected shouldAddEmptyOption() {
        return true;
    }
};