import React from 'react'; // we need this to make JSX compile
import { FormFeedback, FormGroup, Input, Label } from 'reactstrap';
import { ModelValidation } from './base/ModelValidation';
import { I18n } from './I18n';


interface UserPasswordProps {
    value: string;
    required: boolean;
    disabled?: boolean;
    name: string;
    formValidation: ModelValidation;
    onChange: (json: string, user: string, pwd: string) => void;
}

export class UserPasswordClass {
    User: string;
    Password: string;
}

export const UserPassword = function (props: UserPasswordProps) {

    var valueObj = {
        User: "",
        Password :""
    } as UserPasswordClass;

    try {
        valueObj = JSON.parse(props.value) as UserPasswordClass;
    } catch { }
    
    var hasError = (props.formValidation.getError(props.name) || "").length !== 0;

    var errorUsuario = hasError && valueObj != null && (valueObj.User || "").length === 0;
    var errorPassword = hasError && valueObj != null && (valueObj.Password || "").length === 0;

    return <React.Fragment>
        <FormGroup>
            <Label for={"userPassUser"}>Usuario</Label>
            <Input
                onChange={(evt) => {
                    valueObj.User = evt.target.value;
                    props.onChange(JSON.stringify(valueObj), valueObj.User, valueObj.Password);
                }}
                type="text"
                required={true}
                disabled={props.disabled || false}
                maxLength={100}
                className={"form-control " + (errorUsuario ? "is-invalid" : "")}
                name={"userPassUser"}
                id={"userPassUser"}
                value={valueObj.User || ""}
            />

            {errorUsuario && <FormFeedback>
                {I18n.Strings.validationErrors.valueRequired(I18n.Strings.colegiocredenciales.user)}
            </FormFeedback>}

        </FormGroup>

        <FormGroup>
            <Label for="passPassUser">Password</Label>
            <Input
                onChange={(evt) => {
                    valueObj.Password = evt.target.value;
                    props.onChange(JSON.stringify(valueObj), valueObj.User, valueObj.Password);
                }}
                type="text"
                disabled={props.disabled || false}
                required={true}
                maxLength={100}
                className={"form-control " + (errorPassword ? "is-invalid" : "")}
                name={"passPassUser"}
                id={"passPassUser"}
                value={valueObj.Password || ""}
            />

            {errorPassword && <FormFeedback>
                {I18n.Strings.validationErrors.valueRequired(I18n.Strings.colegiocredenciales.pwd)}
            </FormFeedback>}

        </FormGroup>
    </React.Fragment>;
}
