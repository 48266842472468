import { debug } from 'node:console';
import React, { useEffect, useState } from 'react'; // we need this to make JSX compile
import { Col, FormFeedback, FormGroup, Input, Label } from 'reactstrap';
import { ModelValidation } from "../utils/base/ModelValidation";

interface Lti11ConfigCentroProps {
    value: string;
    required: boolean;

    formValidation: ModelValidation;
    onChange: (json: string) => void;
}

export const Lti11ConfigCentro = function (props: Lti11ConfigCentroProps) {

    class LtiCreds {
        Url: string;
        ConsumerKey: string;
        SharedSecret: string;
        MostrarCredencialAlAcceder: boolean;
    }

    var [creds, setCreds] = useState<LtiCreds>({} as LtiCreds);

    useEffect(() => {
        try {
            var valueObj = JSON.parse(props.value) as LtiCreds;
            setCreds(valueObj);
        } catch { }
    }, []);

    useEffect(() => {
        props.onChange(JSON.stringify(creds));
    }, [creds]);

   

    const url_creds = "urlCreds"
    const consumerKey_creds = "consumerKeyCreds"
    const sharedSecret_creds = "sharedSecretCreds"

    var errorUrl = props.formValidation.getError(url_creds) || "";
    var errorConsumerKey = props.formValidation.getError(consumerKey_creds) || "";
    var errorSharedSecret = props.formValidation.getError(sharedSecret_creds) || "";


    return <React.Fragment>
        <Col md="12" lg="4">
            <FormGroup>
                <Label for={"url"}>URL</Label>
                <Input
                    onChange={(evt) => {
                        setCreds({...creds, Url: evt.target.value});
                    }}
                    type="text"
                    required={true}
                    maxLength={100}
                    className={"form-control " + (errorUrl.length == 0 ? "" : "is-invalid")}
                    name={"url"}
                    id={"url"}
                    value={creds.Url || ""}
                />

                {(errorUrl || "").length != 0 && <FormFeedback>
                    {errorUrl}
                </FormFeedback>}

            </FormGroup>
        </Col>
        <Col md="12" lg="4">
            <FormGroup>
                <Label for={"consumerKey"}>Consumer Key</Label>
                <Input
                    onChange={(evt) => {
                        setCreds({ ...creds, ConsumerKey: evt.target.value });
                    }}
                    type="text"
                    required={true}
                    maxLength={100}
                    className={"form-control " + (errorConsumerKey.length == 0 ? "" : "is-invalid")}
                    name={"consumerKey"}
                    id={"consumerKey"}
                    value={creds.ConsumerKey || ""}
                />

                {(errorConsumerKey || "").length != 0 && <FormFeedback>
                    {errorConsumerKey}
                </FormFeedback>}

            </FormGroup>
        </Col>
        <Col md="12" lg="4">
            <FormGroup>
                <Label for={"sharedSecret"}> Shared secret </Label>
                <Input
                    onChange={(evt) => {
                        setCreds({ ...creds, SharedSecret: evt.target.value });
                    }}
                    type="text"
                    required={true}
                    maxLength={100}
                    className={"form-control " + (errorSharedSecret.length == 0 ? "" : "is-invalid")}
                    name={"sharedSecret"}
                    id={"sharedSecret"}
                    value={creds.SharedSecret || ""}
                />

                {(errorSharedSecret || "").length != 0 && <FormFeedback>
                    {errorSharedSecret}
                </FormFeedback>}

            </FormGroup>
        </Col>

        <Col md="12" lg="4">
            <FormGroup>
                <Label for={errorSharedSecret}> Mostrar Credencial Al Acceder </Label>
                <Input
                    onChange={(evt) => {
                        setCreds({ ...creds, MostrarCredencialAlAcceder: evt.target.checked });
                    }}
                    type="checkbox"
                    required={true}
                    maxLength={100}
                    className={"form-control"}
                    name={errorSharedSecret}
                    id={errorSharedSecret}
                    checked={creds.MostrarCredencialAlAcceder || true}
                />

            </FormGroup>
        </Col>


    </React.Fragment>;
}
