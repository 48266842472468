import * as React from "react";
import { Alert, Button, Col, FormFeedback, FormGroup, Input, Label, Nav, NavItem, NavLink, Row, TabContent, TabPane } from "reactstrap";
import nameof from "ts-nameof.macro";
import LicenciaEnlace from "../../home/LicenciaEnlace";
import { RouteHelper } from "../../IntranetRouter";
import { MisMatriculasService } from "../../miPerfil/profesor/matriculas/MisMatriculasService";
import { AlumnoListItemPaginatedList, AlumnosDeClaseSearch, MateriaProfesorEditModel, MatriculaEditModel, TipoUsuario } from "../../models/dataModels";
import { AlertsService } from "../../utils/AlertsService";
import { LinkItem, NavigationHelper } from "../../utils/base/Breadcrumb";
import { ComponentBase } from "../../utils/base/ComponentBase";
import { ModelValidation } from "../../utils/base/ModelValidation";
import { ClientContext } from "../../utils/ClientContext";
import { I18n } from "../../utils/I18n";
import { CancelIcon, SaveIcon } from "../../utils/Icons";
import { Loading } from "../../utils/Loading";
import { MisClasesService } from "../MisClasesService";
import { Alumnos } from "./Alumnos";
import { Contenidos } from "./Contenidos";



export enum MiClaseEditTabs {
    Datos = "datos",
    Alumnos = "alumnos",
    OtrosAlumnos = "otros",
    Contenidos = "contenidos"
}

interface ClaseState {
    activeTab?: MiClaseEditTabs;
    loading: boolean;
    matriculaProfe: MatriculaEditModel;
    validationResult: ModelValidation;

    numAlumnosClase: number;
    numAlumnosOtrasClases: number;
}

export class Edit extends ComponentBase<any, ClaseState> {

    public constructor(props: any) {
        super(props);

        var misClasesSrv = new MisClasesService();
        if (!misClasesSrv.puedeAccederAMisClasesProfe()) {
            window.location.href = "/";
            return;
        }

        this.state = {
            activeTab: this.props.match.params.tab,
            loading: true,
            matriculaProfe: null,
            validationResult: new ModelValidation(),
            numAlumnosClase: null,
            numAlumnosOtrasClases: null
        };
    }

    componentDidMount() {

        var srv = new MisMatriculasService();
        srv.getById(this.props.match.params.id)
            .then((result) => {
                this.setState({
                    matriculaProfe: result,
                    loading: false
                }, () => this.updateBreadCrumb());
            })
            .catch(error => this.setState({ matriculaProfe: null, loading: false }, () => AlertsService.showError(error)));
    }

    private _paintMainForm(): JSX.Element {
        return <React.Fragment>
            {this.state.loading && <Loading />}
            {this.state.matriculaProfe != null && <React.Fragment>
                <Row>
                    <Col md={6}>
                        <FormGroup>
                            <Label for={"clase" + nameof(this.state.matriculaProfe.clase)}>{I18n.Strings.grupoClase.grupoClase}</Label>
                            <Input
                                type="text"
                                disabled={true}
                                className={"form-control "}
                                name={nameof(this.state.matriculaProfe.clase)}
                                id={"clase" + nameof(this.state.matriculaProfe.clase)}
                                value={this.state.matriculaProfe.clase || ""}
                            />
                        </FormGroup>
                    </Col>
                    <Col md={6}>
                        <FormGroup>
                            <Label for={"clase" + nameof(this.state.matriculaProfe.materia)}>{I18n.Strings.grupoContenido.materia}</Label>
                            <Input
                                type="text"
                                onChange={(evt) => {
                                    var item = this.state.matriculaProfe;
                                    item.materia = evt.target.value;

                                    this.setState({ matriculaProfe: item });
                                }}
                                maxLength={100}
                                className={"form-control " + this._errorClass(nameof(this.state.matriculaProfe.materia))}
                                name={nameof(this.state.matriculaProfe.materia)}
                                id={"clase" + nameof(this.state.matriculaProfe.materia)}
                                value={this.state.matriculaProfe.materia || ""}
                            />
                            {this._errorMessage(nameof(this.state.matriculaProfe.materia))}
                        </FormGroup>
                    </Col>
                    <div className="d-flex fichas">
                        {this.state.matriculaProfe.licenciaContenidos != null && this.state.matriculaProfe.licenciaContenidos.map((contenido, index) => {
                            //return <Col md="6" key={"foto_" + index}><img className="mt-xl mb-2"
                            //    src={(contenido.caratula || "").length == 0 ? '/img/foto-libro.png' : contenido.caratula}
                            ///></Col>
                            return <LicenciaEnlace key={index} licencia={contenido} />;
                        })}
                    </div>
                </Row>
            </React.Fragment>}
        </React.Fragment>
    }

    public render(): JSX.Element {


        return <React.Fragment>
            <div className="head-seccion">
                <h2><strong>Detalle de la clase</strong></h2>
            </div>
            {this.state.matriculaProfe != null && <React.Fragment>
                <Nav tabs>
                    <NavItem>
                        <NavLink
                            active={(this.state.activeTab || MiClaseEditTabs.Datos) == MiClaseEditTabs.Datos}
                            onClick={() => this.setState(
                                { activeTab: MiClaseEditTabs.Datos },
                                () => this.props.history.push(RouteHelper.editMiClaseProfesor(this.state.matriculaProfe.id, MiClaseEditTabs.Datos))
                            )}
                        >
                            {I18n.Strings.grupoClase.tabDatos}
                        </NavLink>
                    </NavItem>
                    <NavItem>
                        <NavLink
                            active={this.state.activeTab == MiClaseEditTabs.Contenidos}
                            onClick={() => this.setState(
                                { activeTab: MiClaseEditTabs.Contenidos },
                                () => this.props.history.push(RouteHelper.editMiClaseProfesor(this.state.matriculaProfe.id, MiClaseEditTabs.Contenidos))
                            )}
                        >
                            {I18n.Strings.grupoClase.tabContenidos}
                        </NavLink>
                    </NavItem>
                    <NavItem>
                        <NavLink
                            active={this.state.activeTab == MiClaseEditTabs.Alumnos}
                            onClick={() => this.setState(
                                { activeTab: MiClaseEditTabs.Alumnos },
                                () => this.props.history.push(RouteHelper.editMiClaseProfesor(this.state.matriculaProfe.id, MiClaseEditTabs.Alumnos))
                            )}
                        >
                            {I18n.Strings.grupoClase.tabAlumnos} {this.state.numAlumnosClase != null && <React.Fragment>({this.state.numAlumnosClase})</React.Fragment>}
                        </NavLink>
                    </NavItem>


                    <NavItem>
                        <NavLink
                            active={this.state.activeTab == MiClaseEditTabs.OtrosAlumnos}
                            onClick={() => this.setState(
                                { activeTab: MiClaseEditTabs.OtrosAlumnos },
                                () => this.props.history.push(RouteHelper.editMiClaseProfesor(this.state.matriculaProfe.id, MiClaseEditTabs.OtrosAlumnos))
                            )}
                        >
                            {I18n.Strings.grupoClase.tabOtrosAlumnos} {this.state.numAlumnosOtrasClases != null && <React.Fragment>({this.state.numAlumnosOtrasClases})</React.Fragment>}
                        </NavLink>
                    </NavItem>
                </Nav>

                <TabContent activeTab={this.state.activeTab || MiClaseEditTabs.Datos}>
                    <TabPane tabId={MiClaseEditTabs.Datos}>
                        {this._paintMainForm()}
                    </TabPane>

                    <TabPane tabId={MiClaseEditTabs.Contenidos}>
                        {this.state.activeTab == MiClaseEditTabs.Contenidos &&
                            <Contenidos claseMateriaId={this.state.matriculaProfe.claseMateriaId} />
                        }
                    </TabPane>

                    <TabPane tabId={MiClaseEditTabs.Alumnos}>
                        <Alumnos
                            isViewMode={true}
                            hideGridButtons={true}
                            renderResultsDiv={true}
                            isEmbedded={true}
                            onReadData={(resultado: AlumnoListItemPaginatedList) => {
                                this.setState({ numAlumnosClase: resultado.totalCount })
                            }}
                            searchModel={{ ...{} as AlumnosDeClaseSearch, claseMateriaId: this.state.matriculaProfe.claseMateriaId }}
                        />
                    </TabPane>

                    <TabPane tabId={MiClaseEditTabs.OtrosAlumnos}>
                        <Alert color="warning">{I18n.Strings.misClases.alumnosOtrasClasesText}</Alert>
                        <Alumnos
                            isViewMode={true}
                            hideGridButtons={true}
                            renderResultsDiv={true}
                            isEmbedded={true}
                            onReadData={(resultado: AlumnoListItemPaginatedList) => {
                                this.setState({ numAlumnosOtrasClases: resultado.totalCount })
                            }}
                            searchModel={{ ...{} as AlumnosDeClaseSearch, repetidor: true, claseMateriaId: this.state.matriculaProfe.claseMateriaId }}
                        />
                    </TabPane>
                </TabContent>
            </React.Fragment>}
            <Row className="accesos-directos">
                <Col xs="6" md="8" lg="8">
                    <Button className={"btn-rounded btn"} id="save" color="primary" onClick={() => this._validateAndSubmit()}>
                        <SaveIcon />
                        {I18n.Strings.save}
                    </Button>
                </Col>
                <Col xs="6" md="4" lg="4" className="text-end">
                    <Button className={"btn-rounded btn"} id="cancel" color="secondary" onClick={() => {
                        this.props.history.push(RouteHelper.listMisClasesProfesor());
                    }}>
                        <CancelIcon />
                        {I18n.Strings.cancel}
                    </Button>
                </Col>
            </Row>
        </React.Fragment>;

    }

    protected _validateModelLocal(): ModelValidation {
        let validation = new ModelValidation();

        if ((this.state.matriculaProfe.materia || "").trim().length == 0) {
            validation.addError(nameof(this.state.matriculaProfe.materia), I18n.Strings.validationErrors.valueRequired(I18n.Strings.grupoContenido.materia));
        }

        return validation;
    }

    protected _validateState(): Promise<ModelValidation> {

        var validationProm = Promise.resolve<ModelValidation>(this._validateModelLocal())
            .then(localValidationResult => {
                return localValidationResult;
            });

        return validationProm;
    }


    protected _errorMessage(fieldName: string): JSX.Element {
        var error = this.state.validationResult.getError(fieldName);
        if (error) {
            return <FormFeedback>{error}</FormFeedback>;
        }
        return null;
    }

    protected _errorClass(fieldName: string): string {
        var error = this.state.validationResult.getError(fieldName);
        if (error) {
            return "is-invalid";
        }
        return null;
    }

    protected _validateAndSubmit(): void {
        this._validateState()
            .then(validationResultTemp => {

                if (validationResultTemp == null) {
                    alert("error validation");
                    return false;
                }
                var validationResult = new ModelValidation();
                validationResult.load(validationResultTemp);

                if (validationResult.isOk) {
                    console.info("La validación es correcta");
                    this.setState({ loading: true });

                    var model = {
                        materia: this.state.matriculaProfe.materia,
                        claseMateriaId: this.state.matriculaProfe.claseMateriaId
                    } as MateriaProfesorEditModel;

                    var srv = new MisMatriculasService();
                    srv.updateMateria(model).then((result) => {
                        this.setState({
                            validationResult: new ModelValidation(),
                            loading: false
                        });
                    });

                }
                else {
                    console.log("Validación no correcta");
                    console.warn(validationResult);
                    this.setState({ validationResult: validationResult, activeTab: MiClaseEditTabs.Datos });
                }
            });
    }


    private updateBreadCrumb() {
        var items = [
            {
                text: I18n.Strings.menu.misClases,
                link: RouteHelper.listMisClasesProfesor()
            },
            {
                text: `${this.state.matriculaProfe.clase} - ${this.state.matriculaProfe.materia}`,
                link: ""
            },

        ] as LinkItem[];

        NavigationHelper.setBreadcrumbItems(items);
    }
}