import React from 'react'; // we need this to make JSX compile

import { ResultError, ResultOk, ShowMessage } from './ActionResults';

import { logAndExtractInfoFromException } from "./ValuesFormatter";


export class AlertsService {
    private static master: AlertDisplayer;

    static setMaster(master: AlertDisplayer) {
        AlertsService.master = master;
    }

    static showError(error: Error, onClose?: () => void) {
        AlertsService.master.showError(logAndExtractInfoFromException(error), onClose);
    }

    static showErrorMessage(messageToShow: string, onClose?: () => void) {
        AlertsService.master.showError(messageToShow, onClose);
    }

    static showSuccessMessage(messageToShow: string, onClose?: () => void) {
        AlertsService.master.showSuccessMessage(messageToShow, onClose);
    }

    static showAlertMessage(messageToShow: string, onClose?: () => void) {
        AlertsService.master.showAlertMessage(messageToShow, onClose);
    }
}


interface DisplayerState {
    errorMessage: string;
    alertMessage: string;
    okMessage: string;
    onClose: () => void;
}


export class AlertDisplayer extends React.Component<any, DisplayerState> {

    constructor(props: any) {
        super(props);

        this.state = {
            errorMessage: null,
            alertMessage: null,
            okMessage: null,
            onClose: null
        };
    }

    public showError(errorMessage: string, onClose: () => void) {
        this.setState({ errorMessage: errorMessage, onClose: onClose });
    }

    public showAlertMessage(alertMessage: string, onClose: () => void) {
        this.setState({ alertMessage: alertMessage, onClose: onClose });
    }

    public showSuccessMessage(alertMessage: string, onClose: () => void) {
        this.setState({ okMessage: alertMessage, onClose: onClose });
    }

    render() {

        return <React.Fragment>

            {this.state.errorMessage &&
                <ResultError
                    messageToShow={this.state.errorMessage}
                    onDismiss={() => this.setState({ errorMessage: null }, () => {
                        if (this.state.onClose) {
                            this.state.onClose();
                        }
                    })}
                />
            }

            {this.state.alertMessage &&
                <ShowMessage
                    messageToShow={this.state.alertMessage}
                    onDismiss={() => this.setState({ alertMessage: null }, () => {
                        if (this.state.onClose) {
                            this.state.onClose();
                        }
                    })}
                />
            }

            {this.state.okMessage &&
                <ResultOk
                    messageToShow={this.state.okMessage}
                    onDismiss={() => this.setState({ okMessage: null }, () => {
                        if (this.state.onClose) {
                            this.state.onClose();
                        }
                    })}
                />
            }

        </React.Fragment>;
    }
}