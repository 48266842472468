import nameof from "ts-nameof.macro";
import { EntradaAuditoriaListItemPaginatedList, EntradaAuditoriaSearch, MatriculaEditModel, MatriculaListItem, MatriculaListItemPaginatedList, MatriculasSearch, ModelValidationResult } from "../models/dataModels";
import { ApiFetcher } from "../utils/ApiFetcher";
import { IServiceForIndex } from "../utils/base/BaseServices";


export class AuditoriasService
    implements IServiceForIndex<EntradaAuditoriaSearch, EntradaAuditoriaListItemPaginatedList>
{

    

    search(searchData: EntradaAuditoriaSearch): Promise<EntradaAuditoriaListItemPaginatedList> {

        var apiFetcher = new ApiFetcher();
        var prom = apiFetcher.post<EntradaAuditoriaSearch, EntradaAuditoriaListItemPaginatedList>("intranet/entradasAuditoria/search", searchData);

        prom.then(res => {

            res.items.forEach((val) => {
                val.fecha = new Date(val.fecha);
            });

            return res;
        });

        return prom;
    }

    delete(id: number): Promise<any> {
        throw new Error("Method not implemented.");
    }

    exportExcel(search: EntradaAuditoriaSearch): Promise<any> {
        throw new Error("Method not implemented.");
    }

}