import * as React from "react";
import nameof from "ts-nameof.macro";

import {
    TipoEstadistica, EstadisticasAccesoGraphSearchResult, StackedGraphBar, LicenciasContenidosListItem,
    TipoUsuario, TipoGrafico, EstadisticasContenidoGraphSearch, EstadisticasContenidoGraphSearchResult,
} from "../models/dataModels";
import { Col, FormGroup, Input, Label, Row, Button, FormFeedback } from "reactstrap";
import { I18n } from "../utils/I18n";
import { ModelValidation } from "../utils/base/ModelValidation";
import { Loading } from "../utils/Loading";
import { GraphIcon, ExcelIcon } from "../utils/Icons"; 
import { AccesosContenidoGraph } from "./AccesosContenidoGraph";
import { TipoUsuarioDropDown } from "../helpers/EnumDropDown";
import { NivelEducativoDropDown } from "../cursoEscolar/NivelEducativoDropDown";
import { ColegioUsuariosPicker } from "../colegioUsuarios/Picker";
import { ContenidosPicker } from "../contenidos/colegio/Picker";
import { ClientContext } from "../utils/ClientContext";
import { cloneDeep } from "lodash-es";
import { CustomDatePicker } from "../helpers/CustomDatePicker";
import { NumericCardBox } from "../helpers/NumericCardBox";
import { ClasesDropDown } from "../clases/profesor/ClasesDropDown";
import { ClaseMateriaDropDown } from "../cursoEscolar/materia/ClaseMateriaDropDown";
import { AccesosContenidoService } from "./AccesosContenidoService";

interface AccesosProps {
    tipo: TipoGrafico;
    usuarioId?: number;
    profesorId?: number;
    licenciaContenidoId?: number;
    emptyText?: string;
}

interface AccesosState {
    loading: boolean;
    graphItems: any[],
    graphBars: StackedGraphBar[],
    model: EstadisticasContenidoGraphSearchResult,
    fechaDesde: Date;
    fechaHasta: Date;
    validationResult: ModelValidation;

    usuarioId: number;
    profesorId: number;
    tipoUsuario: TipoUsuario;

    claseId: number[];
    clase: string;

    claseMateriaId: number;
    claseMateria: string;

    usuario: string;
    contenidosId: number[];
    contenido: string;
    fechaUltimoAcceso: Date;
    licencias: LicenciasContenidosListItem[];
    licenciaContenidosIds: number[];

    matriculaId: number;
    matricula: string;
}

export class AccesosContenido extends React.Component<AccesosProps, AccesosState> {

    readonly rgbColors = ['#8884d8', '#82ca9d', '#800000', '#008080', '#808000', '#191970', '#F5F5DC', '#778899', '#FF1493', '#AFEEEE'];

    public constructor(props: any) {
        super(props);

        var start = new Date();
        start.setMonth(start.getMonth() - 1);
        var end = new Date();

        var licenciasContenidosIds = [];
        if (this.props.licenciaContenidoId != null) {
            licenciasContenidosIds.push(this.props.licenciaContenidoId);
        }

        this.state = {
            loading: true,
            graphItems: [],
            graphBars: [],
            fechaDesde: start,
            fechaHasta: end,
            validationResult: new ModelValidation(),
            model: null,
            usuarioId: this.props.usuarioId,
            profesorId: this.props.profesorId,
            tipoUsuario: null,
            claseId: [],
            clase: null,

            claseMateriaId: null,
            claseMateria: null,

            usuario: null,
            contenidosId: [],
            contenido: this.props.emptyText ?? "0 contenidos seleccionados",
            fechaUltimoAcceso: null,
            licencias: [],
            licenciaContenidosIds: licenciasContenidosIds,
            matriculaId: null,
            matricula: null
        };
    }

    componentDidMount() {
        this._loadGraphData();
    }

    protected _exportExcel(): void {
        var service = new AccesosContenidoService();

        var searchData = this.getSearchData();
        service.exportExcel(searchData)
            .then(ret => {
                this.setState({ loading: false });
            });
    }

    protected getSearchData(): EstadisticasContenidoGraphSearch {
        var searchData = {
            busquedaGlobal: this.props.tipo == TipoGrafico.Global || this.props.tipo == TipoGrafico.Profesor,
            usuarioId: this.state.usuarioId,
            profesorId: this.state.profesorId,
            fechaDesde: this.state.fechaDesde,
            fechaHasta: this.state.fechaHasta,
            claseMateriaId: this.state.claseMateriaId,
            licenciaContenidoIds: this.state.licenciaContenidosIds,
            tipo: TipoEstadistica.AccesoContenido,
            tipoUsuario: this.state.tipoUsuario,
            claseId: this.state.claseId,
            contenidosId: this.state.contenidosId,
            tipoGrafico: this.props.tipo
        } as EstadisticasContenidoGraphSearch;

        return searchData;
    }

    protected _loadGraphData(): void {
        var service = new AccesosContenidoService();

        var searchData = this.getSearchData();
        service.accesosContenidosGraphSearch(searchData)
            .then(ret => {
                this.ProcessGraphData(ret);
            });
    }

    protected ProcessGraphData(ret: EstadisticasContenidoGraphSearchResult): void {
        var contenidos = ret.contenidos;

        var graphItems = [];
        for (const [key, value] of Object.entries(ret.items)) {
            var item = {
                dia: key
            };
            if (value != null && contenidos != null) {
                value.forEach(listItem => {
                    var contenido = contenidos[listItem.contenidoId];
                    item[contenido] = listItem.numAccesos
                });
            }
            else {
                item['Accesos'] = 0;
            }

            graphItems.push(item);
        }
        var graphBars = [] as StackedGraphBar[];
        if (contenidos != null) {
            var cont = 0;
            for (const [key, value] of Object.entries(contenidos)) {
                graphBars.push({
                    name: value,
                    rgbColor: this.rgbColors[cont]
                } as StackedGraphBar);
                cont++;
            };
        }
        this.setState({ loading: false, model: ret, graphItems: graphItems, graphBars: graphBars, fechaUltimoAcceso: ret.fechaUltimoAcceso });
    }

    protected _errorMessage(fieldName: string): JSX.Element {
        var error = this.state.validationResult.getError(fieldName);
        if (error) {
            return <FormFeedback>{error}</FormFeedback>;
        }
        return null;
    }

    protected _cloneStateForSetState(): AccesosState {
        return cloneDeep(this.state) as AccesosState;
    }

    protected _validateModelLocal(): ModelValidation {
        let validation = new ModelValidation();

        if (this.state.fechaDesde == null) {
            validation.addError(nameof(this.state.fechaDesde), I18n.Strings.validationErrors.valueRequired(I18n.Strings.estadisticas.fechaDesde));
        }

        if (this.state.fechaHasta == null) {
            validation.addError(nameof(this.state.fechaHasta), I18n.Strings.validationErrors.valueRequired(I18n.Strings.estadisticas.fechaHasta));
        }

        if (this.state.fechaDesde != null && this.state.fechaHasta != null && this.state.fechaDesde > this.state.fechaHasta) {
            validation.addError(nameof(this.state.fechaHasta), I18n.Strings.validationErrors.dateGreaterThan(I18n.Strings.estadisticas.fechaDesde, I18n.Strings.estadisticas.fechaHasta));
        }

        return validation;
    }

    protected _validateState(): Promise<ModelValidation> {

        var validationProm = Promise.resolve<ModelValidation>(this._validateModelLocal())
            .then(localValidationResult => {
                return localValidationResult;
            });

        return validationProm;
    }

    protected _validateAndSubmit(): void {
        this._validateState()
            .then(validationResultTemp => {

                if (validationResultTemp == null) {
                    alert("error validation");
                    return false;
                }
                var validationResult = new ModelValidation();
                validationResult.load(validationResultTemp);

                if (validationResult.isOk) {
                    console.info("La validación es correcta");
                    this.setState({ loading: true });
                    this._loadGraphData();
                }
                else {
                    console.log("Validación no correcta");
                    console.warn(validationResult);
                    this.setState({ validationResult: validationResult });
                }
            });
    }


    protected _renderTitulo(): JSX.Element {

        if (this.props.tipo == TipoGrafico.Global) {
            return;
        }

        if (this.props.tipo == TipoGrafico.Usuario) {
            return;
        }
    }

    protected _renderBotones(): JSX.Element {
        return <React.Fragment>
            {this.props.tipo != TipoGrafico.Licencia && <br />}
            <Button
                id="graph"
                color="primary"
                className="btn btn-rounded btn-sm mb-2"
                onClick={() => {
                    this._validateAndSubmit();
                }} >
                <GraphIcon />
                {I18n.Strings.estadisticas.grafico}
            </Button>
            <Button
                id="excel"
                color="secondary"
                className="btn btn-rounded btn-sm mb-2"
                onClick={() => {
                    this._exportExcel();
                }} >
                <ExcelIcon />
                {I18n.Strings.export}
            </Button>
        </React.Fragment>
    }

    protected _renderCamposBusqueda(): JSX.Element {

        if (this.state.model == null) {
            return;
        }

        if (this.props.tipo == TipoGrafico.Global) {
            return this._camposBusquedaEstadisticasGlobal();
        }

        if (this.props.tipo == TipoGrafico.Profesor) {
            return this._camposBusquedaEstadisticasProfesor();
        }

        if (this.props.tipo == TipoGrafico.Usuario) {
            return this._camposBusquedaEstadisticasUsuario();
        }

        if (this.props.tipo == TipoGrafico.Licencia) {
            return this._camposBusquedaEstadisticasLicencia();
        }
    }

    private _camposBusquedaEstadisticasGlobal(): JSX.Element {
        var modelo = this.state.model;

        return <React.Fragment>
            <Row className="mb-4">
                <Col md={4}>
                    <NumericCardBox
                        numberValue={modelo.accesosUltimodia}
                        headerText={I18n.Strings.colegioUsuarios.accesosUltimodia} />
                </Col>
                <Col md={4}>
                    <NumericCardBox
                        numberValue={modelo.accesosUltimos7dias}
                        headerText={I18n.Strings.colegioUsuarios.accesosUltimos7dias} />
                </Col>
                <Col md={4}>
                    <NumericCardBox
                        numberValue={modelo.accesosUltimos30dias}
                        headerText={I18n.Strings.colegioUsuarios.accesosUltimos30dias} />
                </Col>
            </Row>
            <Row>
                <Col md={4}>
                    <TipoUsuarioDropDown
                        label={I18n.Strings.colegioUsuarios.tipoUsuario}
                        showLabel={true}
                        id={nameof(this.state.tipoUsuario)}
                        value={this.state.tipoUsuario}
                        required={true}
                        errorMessage={this.state.validationResult.getError(nameof(this.state.tipoUsuario))}
                        onChanged={(value: string) => {
                            var newState = this._cloneStateForSetState();
                            if (value != '') {
                                newState.tipoUsuario = value as TipoUsuario;
                            }
                            else {
                                newState.tipoUsuario = null;
                            }
                            this.setState(newState, () => this._validateAndSubmit());
                        }}
                    />
                </Col>
                <Col md={4}>

                    <ColegioUsuariosPicker
                        label={I18n.Strings.licenciasContenido.usuario}
                        required={true}
                        itemId={this.state.usuarioId}
                        itemName={this.state.usuario}
                        getIdFromItem={(item) => item.id}
                        getNameFromItem={(item) => item.nombre}
                        getName2FromItem={(item) => item.apellidos}
                        errorMessage={this.state.validationResult.getError(nameof(this.state.usuarioId))}
                        onSelected={(usuarioId, usuario, item) => {
                            var state = this._cloneStateForSetState();
                            state.usuarioId = usuarioId;
                            state.usuario = usuario;
                            this.setState(state, () => this._validateAndSubmit());
                        }}
                    />

                </Col>
                <Col md={4}>
                    <FormGroup>
                        <Label for={"usuarios" + nameof(this.state.claseId)}>{I18n.Strings.matriculas.nombreClase}</Label>
                        <NivelEducativoDropDown
                            id={nameof(this.state.claseId)}
                            cursoEscolarId={ClientContext.Current.cursoEscolarActivoId}
                            value={this.state.claseId.length > 0 ? this.state.claseId[0] : null}
                            text={this.state.clase}
                            seleccionClases={true}
                            onChange={(id, nombre) => {
                                var clases = [];
                                if (nombre != null) {
                                    clases.push(id);
                                }

                                var state = this._cloneStateForSetState();
                                state.claseId = clases;
                                state.clase = nombre;

                                this.setState(state, () => this._validateAndSubmit());
                            }}
                        />
                    </FormGroup>
                </Col>
                <Col md={6}>
                    <ContenidosPicker
                        label={I18n.Strings.licenciasContenido.contenido}
                        required={true}
                        itemId={this.state.contenidosId.length > 0 ? this.state.contenidosId[0] : null}
                        itemName={this.state.contenido}
                        getIdFromItem={(item) => item.id}
                        showCheckboxes={true}
                        mostrarNiveles={true}
                        mostrarSoloColegio={true}
                        key={"contPicker"}
                        nivelEducativoId={null}
                        getNameFromItem={(item) => {
                            var enumName = I18n.Strings.getEnumText(`Editorial.${item.editorial}`);
                            return `${enumName} - ${item.nombre}`;
                        }}
                        cursoEscolarId={ClientContext.Current.cursoEscolarActivoId}
                        errorMessage={this.state.validationResult.getError(nameof(this.state.contenidosId))}
                        checkedElements={this.state.contenidosId}
                        onSelected={(contenidoId, contenido, item, checkedElements) => {
                            var state = this._cloneStateForSetState();
                            state.contenidosId = checkedElements;
                            if (checkedElements.length == 0) {
                                state.contenido = this.props.emptyText ?? "0 contenidos seleccionados"
                            }
                            else {
                                state.contenido = `${checkedElements.length} contenidos seleccionados`;
                            }

                            this.setState(state, () => {
                                this._validateAndSubmit();
                            });
                        }}
                    />
                </Col>
                <Col md={4} className={"text-center botones"}>
                    {this._renderBotones()}
                </Col>
            </Row>
        </React.Fragment>;
    }

    private _camposBusquedaEstadisticasProfesor(): JSX.Element {
        var modelo = this.state.model;

        return <React.Fragment>
            <Row>
                <Row className="mb-4">
                    <Col md={4}>
                        <NumericCardBox
                            numberValue={modelo.accesosUltimodia}
                            headerText={I18n.Strings.colegioUsuarios.accesosUltimodia} />
                    </Col>
                    <Col md={4}>
                        <NumericCardBox
                            numberValue={modelo.accesosUltimos7dias}
                            headerText={I18n.Strings.colegioUsuarios.accesosUltimos7dias} />
                    </Col>
                    <Col md={4}>
                        <NumericCardBox
                            numberValue={modelo.accesosUltimos30dias}
                            headerText={I18n.Strings.colegioUsuarios.accesosUltimos30dias} />
                    </Col>
                </Row>
                <Col md={3}>
                    <ClasesDropDown
                        label={I18n.Strings.matriculas.nombreClase}
                        selectedItemValue={this.state.claseId.length > 0 ? this.state.claseId[0] : 0}
                        selectedItemText={this.state.clase}
                        profesorId={this.props.profesorId}
                        onChanged={(claseId, clase, item) => {
                            var clases = [];
                            var state = this._cloneStateForSetState();

                            if (clase != null) {
                                clases.push(claseId);
                            }

                            state.claseMateriaId = null;
                            state.claseMateria = null;

                            state.claseId = clases;
                            state.clase = clase;

                            this.setState(state, () => this._validateAndSubmit());
                        }}
                    />
                </Col>
                <Col md={3}>

                    <ClaseMateriaDropDown
                        key={`claseMateria${(this.state.clase || "")}`}
                        label={I18n.Strings.matriculas.nombreMateria}
                        selectedItemValue={this.state.claseMateriaId}
                        selectedItemText={this.state.claseMateria}
                        claseId={this.state.claseId[0]}
                        profesorId={this.props.profesorId}
                        disabled={(this.state.claseId || []).length != 1}
                        onChanged={(claseMateriaId, claseMateria, item) => {
                            var state = this._cloneStateForSetState();

                            state.claseMateriaId = claseMateriaId;
                            state.claseMateria = claseMateria;

                            state.usuario = null;
                            state.usuarioId = null;

                            this.setState(state, () => this._validateAndSubmit());
                        }}
                    />
                </Col>
                <Col md={3}>
                    <ColegioUsuariosPicker
                        key={`colegioUsuarios${(this.state.clase || "")}_${(this.state.claseMateria)}`}
                        label={I18n.Strings.licenciasContenido.usuario}
                        required={true}
                        profesorId={this.props.profesorId}
                        disabled={this.state.claseMateriaId == null}
                        claseMateriaId={this.state.claseMateriaId}
                        itemId={this.state.usuarioId}
                        itemName={this.state.usuario}

                        getIdFromItem={(item) => item.id}
                        getNameFromItem={(item) => item.nombre}
                        getName2FromItem={(item) => item.apellidos}
                        errorMessage={this.state.validationResult.getError(nameof(this.state.usuarioId))}
                        onSelected={(usuarioId, usuario, item) => {
                            var state = this._cloneStateForSetState();
                            state.usuarioId = usuarioId;
                            state.usuario = usuario;
                            this.setState(state, () => this._validateAndSubmit());
                        }}
                    />
                </Col>
                <Col md={3}>
                    <ContenidosPicker
                        label={I18n.Strings.licenciasContenido.contenido}
                        required={true}
                        itemId={this.state.contenidosId.length > 0 ? this.state.contenidosId[0] : null}
                        itemName={this.state.contenido}
                        getIdFromItem={(item) => item.id}
                        showCheckboxes={true}
                        mostrarNiveles={true}
                        mostrarSoloColegio={true}
                        key={"contPicker"}
                        nivelEducativoId={null}
                        getNameFromItem={(item) => {
                            var enumName = I18n.Strings.getEnumText(`Editorial.${item.editorial}`);
                            return `${enumName} - ${item.nombre}`;
                        }}
                        cursoEscolarId={ClientContext.Current.cursoEscolarActivoId}
                        errorMessage={this.state.validationResult.getError(nameof(this.state.contenidosId))}
                        checkedElements={this.state.contenidosId}
                        onSelected={(contenidoId, contenido, item, checkedElements) => {
                            var state = this._cloneStateForSetState();
                            state.contenidosId = checkedElements;
                            if (checkedElements.length == 0) {
                                state.contenido = this.props.emptyText ?? "0 contenidos seleccionados"
                            }
                            else {
                                state.contenido = `${checkedElements.length} contenidos seleccionados`;
                            }

                            this.setState(state, () => {
                                this._validateAndSubmit();
                            });
                        }}
                    />
                </Col>
                <Col md={4} className={"text-center botones"}>
                    {this._renderBotones()}
                </Col>
            </Row>
        </React.Fragment>;
    }

    protected _camposBusquedaEstadisticasUsuario(): JSX.Element {
        return <React.Fragment>
            <Row>
                <Col md={2}>
                    <FormGroup>
                        <Label for={"usuarios" + nameof(this.state.fechaUltimoAcceso)}>{I18n.Strings.colegioUsuarios.fechaUltimoAcceso}</Label>
                        <Input
                            type="text"
                            disabled={true}
                            maxLength={100}
                            className={"form-control"}
                            name={nameof(this.state.fechaUltimoAcceso)}
                            id={"usuarios" + nameof(this.state.fechaUltimoAcceso)}
                            value={I18n.Strings.formatLastAccessDate(this.state.fechaUltimoAcceso)}
                        />
                    </FormGroup>
                </Col>
                <Col md={5}>
                    <FormGroup>
                        <Label for={"usuarios" + nameof(this.state.fechaDesde)}>{I18n.Strings.estadisticas.fechaDesde}</Label>
                        <CustomDatePicker
                            key={nameof(this.state.fechaDesde)}
                            value={this.state.fechaDesde}
                            yearsFront={2}
                            yearsBack={22}
                            onChange={(val) => {
                                this.setState({ fechaDesde: val })
                            }}
                        />
                        {this._errorMessage(nameof(this.state.fechaDesde))}
                    </FormGroup>
                </Col>
                <Col md={5}>
                    <FormGroup>
                        <Label for={"usuarios" + nameof(this.state.fechaHasta)}>{I18n.Strings.estadisticas.fechaHasta}</Label>
                        <CustomDatePicker
                            key={nameof(this.state.fechaHasta)}
                            value={this.state.fechaHasta}
                            yearsFront={2}
                            yearsBack={22}
                            onChange={(val) => {
                                this.setState({ fechaHasta: val })
                            }}
                        />
                        {this._errorMessage(nameof(this.state.fechaHasta))}
                    </FormGroup>
                </Col>
                <Col md={6}>
                    <ContenidosPicker
                        label={I18n.Strings.licenciasContenido.contenido}
                        usuarioColegioId={this.props.usuarioId}
                        required={true}
                        itemId={this.state.contenidosId.length > 0 ? this.state.contenidosId[0] : null}
                        itemName={this.state.contenido}
                        getIdFromItem={(item) => item.id}
                        showCheckboxes={true}
                        mostrarNiveles={true}
                        mostrarSoloColegio={true}
                        key={"contPicker"}
                        nivelEducativoId={null}
                        getNameFromItem={(item) => {
                            var enumName = I18n.Strings.getEnumText(`Editorial.${item.editorial}`);
                            return `${enumName} - ${item.nombre}`;
                        }}
                        cursoEscolarId={ClientContext.Current.cursoEscolarActivoId}
                        errorMessage={this.state.validationResult.getError(nameof(this.state.contenidosId))}
                        checkedElements={this.state.contenidosId}
                        onSelected={(contenidoId, contenido, item, checkedElements) => {
                            var state = this._cloneStateForSetState();
                            state.contenidosId = checkedElements;
                            if (checkedElements.length == 0) {
                                state.contenido = this.props.emptyText ?? "0 contenidos seleccionados"
                            }
                            else {
                                state.contenido = `${checkedElements.length} contenidos seleccionados`;
                            }
                            //state.licenciaContenidosIds = this.state.licencias.filter(function (el) {
                            //    return (checkedElements.find(x => x === el.contenidoId) != null)
                            //}).map(a => a.id);
                            this.setState(state, () => {
                                this._validateAndSubmit();
                            });
                        }}
                    />
                </Col>
                <Col md={4} className={"text-center botones"}>
                    {this._renderBotones()}
                </Col>
            </Row>
        </React.Fragment>;
    }

    protected _camposBusquedaEstadisticasLicencia(): JSX.Element {
        return <React.Fragment>
            <Row>
                <Col md={2}>
                    <FormGroup>
                        <Label for={"usuarios" + nameof(this.state.fechaUltimoAcceso)}>{I18n.Strings.colegioUsuarios.fechaUltimoAcceso}</Label>
                        <Input
                            type="text"
                            disabled={true}
                            maxLength={100}
                            className={"form-control"}
                            name={nameof(this.state.fechaUltimoAcceso)}
                            id={"usuarios" + nameof(this.state.fechaUltimoAcceso)}
                            value={I18n.Strings.formatLastAccessDate(this.state.fechaUltimoAcceso)}
                        />
                    </FormGroup>
                </Col>
                <Col md={4}>
                    <FormGroup>
                        <Label for={"usuarios" + nameof(this.state.fechaDesde)}>{I18n.Strings.estadisticas.fechaDesde}</Label>
                        <CustomDatePicker
                            key={nameof(this.state.fechaDesde)}
                            value={this.state.fechaDesde}
                            yearsFront={2}
                            yearsBack={22}
                            onChange={(val) => {
                                this.setState({ fechaDesde: val })
                            }}
                        />
                        {this._errorMessage(nameof(this.state.fechaDesde))}
                    </FormGroup>
                </Col>
                <Col md={4}>
                    <FormGroup>
                        <Label for={"usuarios" + nameof(this.state.fechaHasta)}>{I18n.Strings.estadisticas.fechaHasta}</Label>
                        <CustomDatePicker
                            key={nameof(this.state.fechaHasta)}
                            value={this.state.fechaHasta}
                            yearsFront={2}
                            yearsBack={22}
                            onChange={(val) => {
                                this.setState({ fechaHasta: val })
                            }}
                        />
                        {this._errorMessage(nameof(this.state.fechaHasta))}
                    </FormGroup>
                </Col>
                <Col md={2} className={"text-center botones"}>
                    {this._renderBotones()}
                </Col>
            </Row>
        </React.Fragment>;
    }

    protected _renderGrafica(): JSX.Element {
        if (this.state.model == null) {
            return;
        }

        return <Row>
            <Col md={12}>
                <AccesosContenidoGraph data={this.state.graphItems} bars={this.state.graphBars} />
            </Col>
        </Row>
    }

    render() {
        return <div className={"estadisticas_contenido"}>
            {this.state.loading && <Loading />}

            {this._renderTitulo()}

            {this._renderCamposBusqueda()}

            {this._renderGrafica()}
        </div>
    }
};