import { UserPasswordClass } from "../UserPassword";
import moment from "moment";
import 'moment/locale/es';

export interface ITranslationContent {

    readonly ok: string;
    readonly save: string;
    readonly saving: string;
    readonly edit: string;
    readonly search: string;
    readonly cancel: string;
    readonly validate: string;
    readonly changeClass: string;
    readonly delete: string;
    readonly deleteFisico: string;
    readonly deleteConfirmTitle: string;
    readonly deleteConfirmBody: string;
    readonly addNew: string;
    readonly results: string;
    readonly clean: string;
    readonly sync: string;
    readonly success: string;
    readonly savedOk: string;
    readonly export: string;
    readonly select: string;
    readonly noResults: string;
    readonly resultsPag: string;
    readonly genericErrorMessage: string;

    readonly errorNotFound: string;

    readonly myProfile: string;
    readonly schoolProfile: string;
    readonly refresh: string;

    formatBotonBorrarPendientes(value: number): string;
    formatBotonValidarPendientes(value: number): string;
    formatBotonCambiarClase(value: number): string;


    formatBool(value?: boolean): string;
    formatNumber(value: number): string;
    formatNumber(value: number, decimalDigits: number): string;

    formatCurrency(value: number): string;

    formatDate(value?: Date): string;
    formatDateTime(value?: Date): string;
    formatDateTime(value: Date, full: boolean): string;
    formatTime(value?: Date): string;
    formatLastAccessDate(value?: Date): string;

    grid: {
        readonly allCheckedWarning: string;        
    }

    validationErrors: {
        readonly thereAreErrors: string;
        readonly mustBeANumber: string;
        readonly rangeErrorTitle: string;
        readonly fieldRequired: string;
        readonly invalidDate: string;
        readonly validationError: string;

        valueDuplicated(fieldName: string): string;
        valueRequired(fieldName: string): string;
        numberFormatNotValid(fieldName: string): string;
        formatNotValid(fieldName: string): string;
        rangeError(minValue: number, maxValue: number): string;
        dateGreaterThan(minorField: string, majorField: string): string;
        numberGreaterThan(minorField: string, majorField: string): string;
        stringLengthGreaterThan(fieldName: string, minValue: number);
        valuesDontMatch(fieldName1: string, fieldName2: string): string;
    };

    unirUsuarios: {
        titulo: string
        usuarioEliminar: string;
        usuarioDestino: string;
        usuariosRepetidos: string;
    }

    emularUsuario: {
        emularUsuario: string;
        yaEstaEmulando: string;
        volverAMiUsuario: string;
    };

    datepicker: {
        monthNames: string[];
        month: string;
        day: string;
        year: string;
    };

    pedidos: {
        readonly sync: string;

        readonly title: string;
        readonly description: string;
        readonly estado: string;
        readonly estadoScholarum: string;

        readonly cliente: string;
        readonly fechaCompra: string;
        readonly fechaCompraFrom: string;
        readonly fechaCompraTo: string;

        readonly numPedido: string;
        readonly sku: string;
        readonly producto: string;
        readonly cantPedida: string;
        readonly cantDevuelta: string;
        readonly licencias: string;
        readonly tipo: string;
        readonly fechaDeProcesoEnJob: string;

        readonly numPedidos: string;
        readonly isbns: string;

        readonly syncEstePedido: string;
        readonly descuento: string;
        readonly descuentoDescrip: string;
    };

    pedidosColegio: {
        readonly description: string;
        readonly title: string;
        readonly devolucionTitle: string;
        readonly devolucionDescription: string;

        readonly fechaPresupuesto: string;
        readonly fechaCompromiso: string;
        readonly fechaPresupuestoFrom: string;
        readonly fechaPresupuestoTo: string;
        readonly fechaCompromisoFrom: string;
        readonly fechaCompromisoTo: string;
        readonly nombre: string;

        

        readonly total: string;
        readonly numUnidades: string;
        readonly numUnidadesEnPrep: string;
        readonly numUnidadesEnFinal: string;
        readonly precioUnidad: string;
        readonly producto: string;
        readonly lineasPedido: string;
        readonly editorial: string;

        readonly envios: string;
        readonly fecha: string;
        readonly transportista: string;
        readonly numSeguimiento: string;
        readonly isbn: string;
        readonly estado: string;
        readonly numUnidadesReservadas: string;
        readonly numUnidadesDevueltas: string;

        readonly fechaDevolucionFrom: string;
        readonly fechaDevolucionTo: string;
        readonly fechaDevolucion: string;

        readonly destinatario: string;
        readonly compannia: string;
        readonly calle: string;
        readonly calle2: string;

        readonly ciudad: string;
        readonly provincia: string;
        readonly codPostal: string;
        readonly pais: string;
    };

    entradasAuditoria: {
        fecha: string;
        tipoAuditoria: string;
        descripcion: string;
        usuarioAccion: string;
        usuarioDatos: string;
    }

    home: {
        saludo(nombre: string): string;
        subtituloPrincipal(nombreCentro: string): string;
        errorAlumnoSinNivelEducativo(direccion: string): string;
        errorUsuarioSinTipo: string;

        noHayCursoEscolarActivoTitle: string;
        noHayCursoEscolarActivoBody: string;
    };

    menu: {
        misClases: string;
    };

    campannas: {
        entityName: string;
    }

    colegioUsuarios: {

        readonly title: string;
        readonly description: string;

        readonly nombre: string;
        readonly activo: string;
        readonly apellidos: string;
        readonly login: string;
        readonly subirFoto: string;
        readonly claseActual: string;
        readonly claseNueva: string;
        readonly elegirClaseNueva: string;
        readonly tipoUsuario: string;
        readonly grupoClase: string;
        readonly estado: string;
        readonly repetidor: string;
        readonly pendienteValidar: string;
        readonly borrado: string;
        readonly usuarioEstaBorrado: string;
        readonly recuperar: string;
        readonly usuarioPendiente: string;
        readonly usuarioAlumno: string;
        readonly fechaUltimoAcceso: string;
        readonly fechaCreacion: string;
        readonly fechaModificacion: string;
        readonly cursoEscolar: string;
        readonly tabDatos: string;
        readonly tabAlumnos: string;
        readonly tabMatriculas: string;
        readonly tabMisClases: string;
        //readonly tabLicencias: string;
        readonly tabEstadisticasAccesos: string;
        readonly tabEstadisticasContenidos: string;
        readonly tabAuditoria: string;
        readonly deleteConfirmBody: string;
        readonly accesosUltimodia: string;
        readonly accesosUltimos30dias: string;
        readonly accesosUltimos7dias: string;
        readonly password: string;
        readonly passwordConfirmar: string;

        readonly errorNivelRequired: string;

        readonly soloAlumnosMatriculados: string;
    };

    colegiocredenciales: {
        readonly nombre: string;
        readonly editorial: string;
        readonly tipoLicencia: string;
        readonly user: string;
        readonly pwd: string;

        readonly deshabilitarAcceso: string;
        readonly mensajeDeshabilitarAcceso: string;

        readonly urlAcceso: string;
        readonly createDefault: string;
    };

    colegio: {
        readonly entityName: string;
        readonly nombre: string;
        readonly comunidadAutonoma: string;
        readonly subirFoto: string;
        readonly tabDatos: string;
        readonly tabCursoEscolar: string;
        readonly tabCredenciales: string;
        readonly identificadorTienda: string;
        readonly identificadorCategoriaTienda: string;
        readonly sincronizar: string;
        readonly sincronizarLicencias: string;
        readonly sincronizadoOK: string;
        readonly dominiosAutorizados: string;
        readonly allowGoogleLogin: string;
        readonly allowMicrosoftLogin: string;
        readonly allowCustomLogin: string;
        readonly comunicarAsignacionLicencias: string;
        readonly comunicarCredencialesLicenciasAlAsignar: string;
        readonly emailColegio: string;
        readonly codMinisterio: string;
        readonly nombreColegioPedidosTienda: string;
    };

    cargaFicheros: {
        readonly cargaFicheros: string;

        readonly tabUsuarios: string;
        readonly tabLicencias: string;
        readonly tabListado: string;

        readonly subirFicheroEtiqueta: string;
        readonly subirFichero: string;
        readonly descargarPlantilla: string;
        readonly cargarFichero: string;
        readonly cargarOtroFichero: string;
        readonly numRowsTotal: string;
        readonly numRowsOk: string;
        readonly numRowsError: string;
        readonly numRowsDuplicated: string;

        readonly fecha: string;
        readonly tipoCarga: string;

        readonly correctos: string;
        readonly duplicados: string;
        readonly erroneos: string;
    };

    misClases: {
        readonly title: string;
        readonly noHayActividades: string;
        readonly alumnosOtrasClasesText: string;
    }

    misLicencias: {
        readonly title: string;
        readonly edit: string;
    }

    licenciasContenido: {
        readonly entityPluralName: string;
        readonly contenidoBuscador: string;
        readonly contenido: string;
        readonly matricula: string;
        readonly editorial: string;
        readonly credenciales: string;
        readonly tipoAcceso: string;
        readonly usuario: string;
        readonly cursoEscolar: string;
        readonly numPedido: string;
        readonly fechaPedido: string;
        readonly nivelEducativo: string;
        readonly isbn: string;
        readonly estado: string;
        readonly grupoClase: string;
        readonly fechaUltimoAcceso: string;
        readonly claseMateria: string;
        readonly configuradoProveedor: string;
        readonly origen: string;
        readonly tabDatos: string;
        readonly tabEstadisticas: string;
        readonly tabBurlington: string;
        readonly borrarCredenciales: string;
        readonly borrarCredencialesWarning: string;
        readonly formato: string;
        readonly fechaAsignacionCredenciales: string;
        readonly asignacionCredencialesNotificada: string;

        readonly asignacionLicenciaManual: string;
        readonly asignacionLicenciaManualExplicacion: string;
        readonly mensajeAccesoManual: string;
        readonly accesoPlataformaManual: string;
    };

    contenidos: {
        readonly nombre: string;
        readonly descripcion: string;
        readonly activo: string;
        readonly isbn: string;

        readonly contenidosColegioTitle: string;
        readonly contenidosMaestrosTitle: string;

        readonly cursoEscolar: string;
        readonly nivelesEducativos: string;
        readonly clases: string;
        readonly todosSeleccionados: string;
        readonly editorial: string;
        readonly tipoUsuario: string;
        readonly tipoLicencia: string;
        readonly tipoContenido: string;
        readonly subirFoto: string;
        readonly tabDatos: string;
        readonly tabEstructura: string;

        readonly tabNiveles: string;
        readonly noHayEstructura: string;
        readonly sincronizarEstructura: string;
        readonly sinImagenYEnUso: string;

        readonly cambiarTipoAcceso: string;

        readonly identificadorEditorial: string;

        cambiarTipoAccesoNum(numContenidos): string;
    };

    contenidosPapel: {
        contenidosPapelColegioTitle: string;
    }

    setClaseActual: {
        readonly titulo: string;
        readonly texto: string;
    }

    cursoEscolar: {
        readonly title: string;
        readonly nombre: string;
        readonly activo: string;
        readonly visibleAlumnos: string;
        readonly contenidoAccesibleAlumnos: string;
        readonly syncroPorDefecto: string;
        readonly fechaInicio: string;
        readonly fechaFin: string;
        readonly fechaInicioCampagna: string;
        readonly fechaFinCampagna: string;
        numeroContenidosDigitales: (numContenidos: number) => string;
        numeroContenidosPapel: (numContenidos: number) => string;

        numeroMatriculas: (numMatriculas: number) => string;
        numeroMaterias: (numMaterias: number) => string;
        numeroLicencias: (numLicencias: number) => string;
    }

    estadisticas: {
        readonly titulo: string;
        readonly subTituloSistema: string;
        readonly subTituloContenido: string;
        readonly cuadroMando: string;
        readonly accesosSistema: string;
        readonly accesosLibros: string;
        readonly grafico: string;
        readonly fechaDesde: string;
        readonly fechaHasta: string;
        readonly noHayLicencias: string;
    };

    etapa: {
        readonly etapa: string;
        readonly nombre: string;
        readonly identificadorTienda: string;
        readonly sincronizar: string;
        readonly omitirNotificacionLicencias: string;
        readonly omitirNotificacionCredenciales: string;
        readonly addEtapa: string;
        deleteEtapa(nombreEtapa: string): string;
        deleteEtapaConfirmText(nombreEtapa: string): string;
    };

    nivelEducativo: {
        readonly nivelEducativo: string;
        readonly nombre: string;
        readonly addNivel: string;
        readonly omitirNotificacionLicencias: string;
        readonly omitirNotificacionCredenciales: string;
        deleteNivel(nombreNivel: string): string;
        deleteNivelConfirmText(nombreNivel: string): string;
    }

    grupoClase: {
        readonly grupoClase: string;
        readonly nombre: string;
        readonly addGrupo: string;
        deleteGrupo(nombreGrupo: string): string;
        deleteGrupoConfirmText(nombreGrupo: string): string;
        readonly misClasesProfesorTitulo: string;
        readonly misClasesProfesorTexto: string;
        readonly tabAlumnos: string;
        readonly tabOtrosAlumnos: string;
        readonly tabDatos: string;
        readonly tabContenidos: string;
        readonly materias: string;
    },

    claseMateria: {
        deleteClaseMateria(nombreClaseMateria: string): string,
        deleteClaseMateriaConfirmText(nombreGrupo: string): string;
    },

    grupoContenido: {
        readonly materia: string;
        readonly lista: string;
        readonly nombre: string;
        readonly contenido: string;
        readonly borrado: string;
        readonly addMateria: string;
        readonly addContenido: string;
        readonly deleteContenido: string;
        readonly deleteMateria: string;
        readonly numAlumnos: string;
    }

    documentUpload: {
        dropHere: string;
        errorRequired: string;
        dropNotSupported: string;
        dropFolderNotSupported: string;
    };

    matriculas: {
        entityName: string;
        nombreClase: string;
        nombreMateria: string;
        nombreUsuario: string;
        title: string;
        description: string;
        rol: string;
        fecha: string;
        subgrupo: string;
    };

    accesosDirectos: {
        title: string;
        description: string;
        titulo: string;
        urlEnlace: string;
        abrirEnNuevaVentana: string;
        noTieneCredenciales: string;
        noTieneContenidoAccesible: string;
        accesoLicenciaNoAccedePlataformaMuestraCredenciales: string;
        accesoLicenciaNoAccedePlataformaNoMuestraCredenciales: string;
        accesoVirtualProduct: string;

        formatAccesoLicenciaNoAccedePlataforma(credencialesEnBruto: string): string;
        formatCredenciales(value?: string): string;
    }

    nivelesContenidos: {
        contenido: string;
        nivel: string;
        title: string;
        description: string;
        mostrarAccesoDirecto: string;
        tipoAccesoPorDefecto: string;
        accedePlataforma: string;
    };

    aliasEditorial: {
        title: string,
        description: string,
        editorial: string;
        alias: string;
        pendientes: string;
        enUso: string;
        numContenidos: string;
        numUsos: string;
        tabUsos: string;
        tabDatos: string;
        colegio: string;
        nivelEducativo: string;
        contenido: string;
        tipoContenido: string;
        isbn: string;
        proveedor: string;
    };

    diagnostico: {
        title: string;
        alertasColegio: string;
        alertasPlataforma: string;
        cursoEscolarMasDeUnActivo: string;
        cursoEscolarNoHayActivo: string;
        cursoEscolarActivoSinAlumnos: string;

        contenidosEditorialOK: string;
        contenidosEditorialKO: (num: number) => string;

        materiasNombreOK: string;
        materiasNombreKO: (num: number) => string;

        licenciasSinCredencialesOK: string;
        licenciasSinCredencialesKO: (num: number) => string;

        credencialesEditorialesOK: string;
        credencialesEditorialesKO: (num: number) => string;

        aliasEditorialOK: string;
        aliasEditorialKO: (num: number) => string;

        matriculasSinLicenciasOK: string;
        matriculasSinLicenciasKO: (num: number) => string;
    };

    notificaciones: {
        readonly titulo: string;
        readonly colegio: string;
        readonly descripcion: string;
        readonly terminado: string;
        readonly correcto: string;
        readonly marcarComoLeida: string;
        readonly marcarSeleccionadosComoLeidos: string;
        readonly editar: string;
        readonly tipoNotificacion: string;
        readonly tipoLinea: string;
        readonly todosTiposLineas: string;
        readonly estado: string;
        readonly texto: string;
        readonly fecha: string;
        readonly verTodas: string;
        readonly notificacion: string;
        readonly haFinalizadoError: string;

        readonly procesoEnCola: string;

        haFinalizadoOk(colegio: string, resultado: string): string;
    }
    /**
     * Obtiene el texto de un enum
     * @param enumValueFullName
     * @param singular
     */
    getEnumText(enumValueFullName: string): string;

    utils: {
        readonly getServerIp: string;
    }
}

export class esES implements ITranslationContent {

    static enumTexts: { [key: string]: string };

    ok = "Aceptar";
    save = "Guardar";
    saving = "Guardando";
    edit = "Editar";
    search = "Buscar";
    cancel = "Cancelar";
    validate = "Validar";
    changeClass = "Cambiar clase";
    delete = "Borrar";
    deleteFisico = "Borrado Definitivo";
    deleteConfirmTitle = "Confirmación de borrado";
    deleteConfirmBody = "Va a eliminar el elemento, ¿está seguro?";
    success: "Ok";
    addNew = "Nuevo";
    results = "resultados";
    clean = "Limpiar selección";
    savedOk = "Datos guardados correctamente";
    sync = "Sincronizar";
    noResults = "No hay resultados que cumplan los parámetros de búsqueda";
    export = "Exportar";
    resultsPag = "result/pág:";
    select = "Seleccionar";
    genericErrorMessage = "Ha sucedido un error, inténtelo mas tarde";
    errorNotFound = "El elemento solicitado no se encuentra en el colegio actual";
    myProfile = "Mi perfil";
    schoolProfile = "Perfil colegio";
    refresh = "Refrescar";

    public colegioUsuarios = {

        title: "Usuarios del colegio",
        description: "Los usuarios del colegio con acceso al Hub",

        nombre: "Nombre",
        activo: "Activo",
        apellidos: "Apellidos",
        login: "Login",
        subirFoto: "Arrastre aquí la foto del perfil o haga clic para seleccionar una de su ordenador",
        grupoClase: "Clase",
        claseActual: "Grupo actual",
        claseNueva: "Nueva clase",
        elegirClaseNueva: "Elegir nueva clase",
        tipoUsuario: "Tipo de usuario",
        cursoEscolar: "Curso escolar",
        estado: "Estado",
        repetidor: "Repetidor",
        pendienteValidar: "Pendiente validar",
        borrado: "Borrado",
        recuperar: "Recuperar",
        usuarioEstaBorrado: "Este usuario está borrado",
        usuarioPendiente: "Pendiente",
        usuarioAlumno: "alumno",
        fechaUltimoAcceso: "Últ. acceso",
        fechaCreacion: "Creado",
        fechaModificacion: "Modificado",
        tabDatos: "Datos",
        tabMatriculas: "Matrículas",
        tabMisClases: "Mis clases",
        tabAlumnos: "Alumnos",
        tabAuditoria: "Auditoría",
        tabEstadisticasAccesos: "Estadísticas acceso al Hub",
        tabEstadisticasContenidos: "Estadísticas acceso a contenidos",
        deleteConfirmBody: "Va a eliminar estos usuarios, ¿está seguro?",
        errorNivelRequired: "Los alumnos necesitan tener asignado un curso por defecto",
        accesosUltimodia: "Nº de accesos en las últimas 24 horas",
        accesosUltimos7dias: "Nº de accesos en los últimos 7 días",
        accesosUltimos30dias: "Nº de accesos en los últimos 30 días",
        soloAlumnosMatriculados: "Solo alumnos matriculados",
        password: "Contraseña",
        passwordConfirmar: "Confirmar contraseña"
    };

    public menu = {
        misClases: "Mis clases"
    };

    public campannas = {
        entityName: "campaña",
    };

    public pedidos = {
        sync: "Sincronizar pedidos",
        cliente: "Cliente",

        title: "Pedidos",
        description: "Los pedidos de la tienda Scholarum",
        estado: "Estado",
        estadoScholarum: "Estado Scholarum",
        fechaCompra: "Fecha de compra",
        fechaCompraFrom: "Fecha desde",
        fechaCompraTo: "Fecha hasta",

        numPedido: "Num. Pedido",
        sku: "SKU",
        producto: "Producto",
        cantPedida: "Cant.Pedida",
        cantDevuelta: "Cant.Devuelta",
        licencias: "Licencias",
        fechaDeProcesoEnJob: "Fecha de proceso",
        tipo: "Tipo",

        numPedidos: "Nº de los pedidos (separado por coma)",
        isbns: "Isbns de productos",
        syncEstePedido: "Sincronizar este pedido",
        descuento: "Descuento",
        descuentoDescrip: "Descuento/Cupones",
    };

    public pedidosColegio = {
        title: "Pedidos colegio",
        devolucionTitle: "Devoluciones colegio",

        description: "Los pedidos del colegio",
        devolucionDescription: "Las devoluciones del colegio",

        fechaPresupuesto: "Fecha de pedido",
        fechaCompromiso: "Fecha de compromiso",
        total: "Total",
        nombre: "Nombre",
        
        isbn: "Isbn",
        fechaPresupuestoFrom: "Fecha pedido desde",
        fechaPresupuestoTo: "Fecha pedido hasta",
        fechaCompromisoFrom: "Fecha compromiso desde",
        fechaCompromisoTo: "Fecha compromiso hasta",
        
        precioUnidad: "Precio / Unidad",
        producto: "Producto",
        lineasPedido: "Productos del pedido",
        editorial: "Editorial",
        numUnidades: "Nº Uds. Pedidas",
        numUnidadesEnPrep: "Nº Uds. en Preparación",
        numUnidadesEnFinal: "Nº Uds. Entregadas",
        envios: "Envíos",
        fecha: "Fecha",
        transportista: "Transportista",
        numSeguimiento: "Num. Seguimiento",
        estado: "Estado",
        numUnidadesReservadas: "Nº Uds. Pendientes (en stock)",
        numUnidadesDevueltas: "Nº Uds. Devueltas",

        fechaDevolucion: "Fecha de devolución",
        fechaDevolucionFrom: "Fecha devolución desde",
        fechaDevolucionTo: "Fecha devolución hasta",

        destinatario: "Destinatario",
        compannia: "Compañía", 
        calle: "Calle",
        calle2: "Calle2",

        ciudad: "Ciudad",
        provincia: "Provincia",
        codPostal: "Cod. Postal",
        pais: "País"
    };

    public entradasAuditoria = {
        fecha: "Fecha",
        tipoAuditoria: "Tipo",
        descripcion: "Descripción",
        usuarioAccion: "¿Quien lo hizo?",
        usuarioDatos: "¿A quien se lo hicieron?"
    }
    public unirUsuarios = {
        titulo: "Unir usuarios",
        usuarioEliminar: "Usuario a eliminar",
        usuarioDestino: "Usuario de destino",
        usuariosRepetidos: "El usuario de destino y el usuario a eliminar no pueden ser el mismo"
    }

    public emularUsuario = {
        emularUsuario: "Emular usuario",
        yaEstaEmulando: "Se encuentra emulando a un usuario",
        volverAMiUsuario: "Volver a mi usuario"
    };

    public home = {
        saludo(nombre: string) { return `¡Hola, ${nombre}!` },

        subtituloPrincipal(nombreCentro: string) {
            return `Bienvenido al HUB del "${nombreCentro}", el sitio de acceso para tus contenidos educativos.`;
        },
        errorAlumnoSinNivelEducativo(direccion: string) {
            return `Este usuario es un alumno pero no tiene asociado un grupo, por favor seleccionelo en su <a href='${direccion}'>Perfil</a>`;
        },
        errorUsuarioSinTipo: "Este usuario no tiene tipo asignado, actuará como alumno, por favor contacte con su administrador",

        noHayCursoEscolarActivoTitle: "Curso escolar activo",
        noHayCursoEscolarActivoBody: "No hay un curso escolar activo, contacte con su administrador."

    };

    public colegiocredenciales = {
        nombre: "Nombre",
        editorial: "Editorial",
        tipoLicencia: "Tipo de acceso",
        user: "Usuario",
        pwd: "Password",

        deshabilitarAcceso: "Deshabilitar acceso",
        mensajeDeshabilitarAcceso: "Mensaje deshabilitado",
        urlAcceso: "Url de acceso a la plataforma",
        createDefault: "Crear por defecto"
    };

    public accesosDirectos = {
        title: "Accesos directos",
        description: "Los accesos directos a mostrar en la home",
        titulo: "Título",
        urlEnlace: "Url del enlace",
        abrirEnNuevaVentana: "Abrir en nueva ventana",
        noTieneCredenciales: "La licencia no tiene credenciales",
        noTieneContenidoAccesible: "El acceso a este contenido no está habilitado",
        accesoLicenciaNoAccedePlataformaMuestraCredenciales: "Para acceder al contenido, entra en la plataforma de la editorial y utiliza las siguientes credenciales para activar tu contenido:",
        accesoLicenciaNoAccedePlataformaNoMuestraCredenciales: "Para acceder al contenido, entra en la plataforma de la editorial.",
        accesoVirtualProduct: "Dispones del acceso a este contenido directamente en la plataforma editorial. Consulta con el colegio la forma de llegar al contenido",

        formatAccesoLicenciaNoAccedePlataforma(credencialesEnBruto: string): string {
            var credenciales = this.formatCredenciales(credencialesEnBruto);

            return `${this.accesosDirectos.accesoLicenciaNoAccedePlataformaMuestraCredenciales}<div class='fw-bold mt-4 text-center mb-3'>${credenciales}</div>`;
        },

        formatCredenciales(value?: string): string {

            if (value !== null) {
                var valueObj = {
                    User: "",
                    Password: ""
                } as UserPasswordClass;

                try {
                    valueObj = JSON.parse(value) as UserPasswordClass;
                    return `<p>${this.colegiocredenciales.user}: ${valueObj.User}</p><p>${this.colegiocredenciales.pwd}: ${valueObj.Password}</p>`;
                } catch {
                    return value;
                }
            }

            return null;
        }
    }

    public diagnostico = {
        title: "Diagnostico de centro",
        alertasColegio: "Alertas del colegio",
        alertasPlataforma: "Alertas de la plataforma",

        cursoEscolarMasDeUnActivo: "Hay más de un curso escolar activo",
        cursoEscolarNoHayActivo: "No hay ningún curso escolar activo",
        cursoEscolarActivoSinAlumnos: "El curso escolar activo no tiene habilitado el acceso para los alumnos",

        contenidosEditorialOK: "Todos los contenidos con editorial OK",
        contenidosEditorialKO: (num: number) => `Hay ${num} contenidos sin editorial`,

        materiasNombreOK: "Todas materias con nombre OK",
        materiasNombreKO: (num: number) => `Hay ${num} clases con el nombre sin cambiar`,

        licenciasSinCredencialesOK: "Todas las licencias tienen credenciales",
        licenciasSinCredencialesKO: (num: number) => `Hay ${num} licencias sin credenciales`,

        credencialesEditorialesOK: "Credenciales editoriales OK",
        credencialesEditorialesKO: (num: number) => `Hay ${num} editoriales sin las credenciales introducidas`,

        aliasEditorialOK: "Todos los alias de editorial están completos",
        aliasEditorialKO: (num: number) => `Hay ${num} alias de editorial que no están definidos`,

        matriculasSinLicenciasOK: "Todas las matrículas tienen licencias",
        matriculasSinLicenciasKO: (num: number) => `Hay ${num} matrículas que no tienen licencias asociadas`,
    };

    public colegio = {
        nombre: "Nombre",
        comunidadAutonoma: "Comunidad Autónoma",
        entityName: "Colegio",
        subirFoto: "Arrastre aquí la foto del colegio o haga clic para seleccionar una de su ordenador",
        tabDatos: "Datos del colegio",
        tabCursoEscolar: "Cursos escolares",
        tabCredenciales: "Credenciales de editoriales",
        identificadorTienda: "Identificador tienda",
        identificadorCategoriaTienda: "Identificador categoría",
        sincronizar: "Sincronizar",
        sincronizarLicencias: "Sincronizar licencias",
        sincronizadoOK: "Los datos han sido sincronizados",
        dominiosAutorizados: "Dominios autorizados",
        allowGoogleLogin: "Login con google",
        allowMicrosoftLogin: "Login con Microsoft",
        allowCustomLogin: "Login con usuario/contraseña",
        comunicarAsignacionLicencias: "Notificar asignación de licencias",
        comunicarCredencialesLicenciasAlAsignar: "Comunicar credenciales al notificar",
        emailColegio: "E-mail del colegio",
        codMinisterio: "Cód. Ministerio",
        nombreColegioPedidosTienda: "Nombre del colegio en los pedidos de la tienda"
    };

    public cargaFicheros = {
        cargaFicheros: "Carga de ficheros",
        tabUsuarios: "Creación de usuarios",
        tabLicencias: "Carga de licencias",
        tabListado: "Listado",
        subirFicheroEtiqueta: "Seleccionar fichero (formato aceptado: .xlsx)",
        subirFichero: "Arrastre aquí el fichero o haga clic para seleccionar uno de su ordenador",
        descargarPlantilla: "Descargar plantilla",
        cargarFichero: "Cargar fichero",
        cargarOtroFichero: "Cargar otro fichero",
        numRowsTotal: "Nº de registros totales en el fichero",
        numRowsOk: "Nº de registros cargados correctamente",
        numRowsError: "Nº de registros erróneos no cargados",
        numRowsDuplicated: "Nº de registros duplicados no cargados",
        fecha: "Fecha",
        tipoCarga: "Tipo de carga",

        correctos: "Correctos",
        duplicados: "Duplicados",
        erroneos: "Erroneos"
    };

    public cursoEscolar = {
        title: "Cursos escolares",
        nombre: "Nombre",
        activo: "Activo",
        visibleAlumnos: "Visible alumnos",
        fechaInicio: "Fecha de inicio",
        fechaFin: "Fecha de fin",
        fechaInicioCampagna: "Fecha inicio de campaña",
        fechaFinCampagna: "Fecha fin de campaña",
        syncroPorDefecto: "Sincronizable",
        contenidoAccesibleAlumnos: "Contenido accesible alumnos",
        numeroContenidosDigitales: (numContenidos: number) => {
            return `Contenidos digitales: ${numContenidos}`;
        },
        numeroContenidosPapel: (numContenidos: number) => {
            return `Contenidos papel: ${numContenidos}`;
        },

        numeroMatriculas: (numMatriculas: number) => {
            return `Matriculas: ${numMatriculas}`;
        },
        numeroMaterias: (numMaterias: number) => {
            return `Materias: ${numMaterias}`;
        },
        numeroLicencias: (numLicencias: number) => {
            return `Licencias: ${numLicencias || "N/A"}`;
        }
    };

    public estadisticas = {
        titulo: "Estadísticas",
        subTituloSistema: "Estadísticas de acceso al sistema",
        subTituloContenido: "Estadísticas de acceso a contenidos",
        grafico: "Actualizar",
        fechaDesde: "Fecha desde",
        fechaHasta: "Fecha hasta",
        accesosSistema: "Accesos al sistema",
        accesosLibros: "Accesos por contenido",
        noHayLicencias: "El usuario no tiene asociado ningún contenido",
        cuadroMando: "Cuadro de mando"
    };

    public setClaseActual = {
        titulo: "Seleccionar clase",
        texto: "Parece que no tienes una clase asignada. Por favor, elige una:",
    }

    public etapa = {
        etapa: "Etapa",
        nombre: "Nombre",
        addEtapa: "Añadir etapa",
        deleteEtapa(nombreEtapa: string) {
            return `Borrar etapa ${nombreEtapa}`;
        },
        deleteEtapaConfirmText(nombreEtapa: string) {
            return `Va a borrar la etapa ${nombreEtapa}, ¿está seguro?`;
        },
        identificadorTienda: "Identificador tienda",
        sincronizar: "Sincronizar",
        omitirNotificacionLicencias: "Omitir notificación licencia",
        omitirNotificacionCredenciales: "Omitir notificación credenciales",
    };

    public nivelEducativo = {
        nivelEducativo: "Curso",
        nombre: "Nombre",
        addNivel: "Añadir curso",
        omitirNotificacionLicencias: "Omitir notificación licencia",
        omitirNotificacionCredenciales: "Omitir notificación credenciales",
        deleteNivel(nombreNivel: string) {
            return `Borrar el curso ${nombreNivel}`;
        },
        deleteNivelConfirmText(nombreNivel: string) {
            return `Va a borrar el curso ${nombreNivel}, ¿está seguro?`;
        }
    };

    public grupoClase = {
        grupoClase: "Clase",
        nombre: "Nombre",
        addGrupo: "Añadir clase",
        deleteGrupo(nombreGrupo: string) {
            return `Borrar el grupo ${nombreGrupo}`;
        },
        deleteGrupoConfirmText(nombreGrupo: string) {
            return `Va a borrar el grupo ${nombreGrupo}, ¿está seguro?`;
        },
        misClasesProfesorTitulo: "Mis clases",
        misClasesProfesorTexto: "Aquí puedes ver todas las clases que tienes asignadas.",
        tabAlumnos: "Alumnos de la clase",
        tabOtrosAlumnos: "Alumnos de otras clases",
        tabDatos: "Datos",
        tabContenidos: "Resultado de las actividades",
        materias: "Materias"
    };

    public claseMateria = {
        deleteClaseMateria(nombreClaseMateria: string) {
            return `Borrar la materia ${nombreClaseMateria}`;
        },
        deleteClaseMateriaConfirmText(nombreClaseMateria: string) {
            return `Va a borrar la materia ${nombreClaseMateria}, ¿está seguro?`;
        }
    }

    public grupoContenido = {
        materia: "Materia",
        lista: "Materias",
        nombre: "Nombre",
        addMateria: "Añadir materia",
        deleteMateria: "Borrar materia",
        borrado: "Oculto",
        addContenido: "Añadir contenido",
        contenido: "Contenido",
        deleteContenido: "Borrar contenido",
        numAlumnos: "Núm. Alumnos"
    }

    public misClases = {
        title: "Mis clases",
        noHayActividades: "No hay actividades para mostrar porque ningún alumno ha accecido al contenido, o la editorial no soporta bajarse los contenidos.",
        alumnosOtrasClasesText: "En este listado se muestran los alumnos de otros cursos, que además están matrículados en esta clase (como por ejemplo, alumnos repetidores)"
    }

    public misLicencias = {
        title: "Mis licencias",
        edit: "Detalle"
    }

    public licenciasContenido = {
        entityPluralName: "Licencias digitales + papel",
        usuario: "Usuario",
        contenidoBuscador: "Contenido (nombre, isbn)",
        contenido: "Contenido",
        matricula: "Matrícula",
        editorial: "Editorial",
        credenciales: "Creds.",
        tipoAcceso: "Tipo de acceso",
        cursoEscolar: "Curso escolar",
        numPedido: "Nº de pedido",
        fechaPedido: "Fecha pedido",
        configuradoProveedor: "Configurado en proveedor",
        nivelEducativo: "Curso",
        isbn: "ISBN",
        estado: "Estado",
        grupoClase: "Grupo",
        fechaUltimoAcceso: "Últ. acceso",
        claseMateria: "Materia",
        origen: "Origen",
        tabDatos: "Datos",
        tabEstadisticas: "Estadísticas",
        tabBurlington: "Burlington",
        borrarCredenciales: "Borrar credenciales",
        borrarCredencialesWarning: "Va a borrar las credenciales de esta licencia, ¿está seguro?",
        formato: "Formato",
        fechaAsignacionCredenciales: "Credenciales asignadas",
        asignacionCredencialesNotificada: "Notificación credenciales",
        asignacionLicenciaManual: "Asignacion manual de licencia",
        asignacionLicenciaManualExplicacion: "Marcar para hacer una configuración diferente de esta licencia en concreto: contenido de otros cursos, etc.",
        mensajeAccesoManual: "Mensaje para mostrar cuando no se accede al contenido",        
        accesoPlataformaManual: "Accede a plataforma editorial"
    };

    public contenidos = {

        contenidosColegioTitle: "Contenidos digitales del colegio",
        contenidosMaestrosTitle: "Maestro de contenidos",

        nombre: "Nombre",
        descripcion: "Descripción",
        activo: "Activo",
        isbn: "ISBN",
        editorial: "Editorial",
        tipoLicencia: "Tipo de acceso",
        tipoContenido: "Tipo de contenido",
        subirFoto: "Arrastre aquí la foto de la carátula o haga clic para seleccionar una de su ordenador",
        tabDatos: "Datos del contenido",
        tabEstructura: "Estructura",
        tabNiveles: "Cursos en los que se aplica",
        //tabLicencias: "Licencias",

        todosSeleccionados: "Todos seleccionados",
        cursoEscolar: "Curso escolar",
        nivelesEducativos: "Cursos",
        clases: "Clases",
        tipoUsuario: "Tipo de usuario",
        noHayEstructura: "No se ha cargado la estructura de contenidos",
        sincronizarEstructura: "Sincronizar estructura",
        sinImagenYEnUso: "Sin imagen y en uso",
        identificadorEditorial: "Identificador editorial",
        cambiarTipoAcceso: "Cambiar acceso a contenidos",
        cambiarTipoAccesoNum(numContenidos): string {
            return `Cambiar acceso a ${numContenidos} contenidos`;
        }

    };

    public contenidosPapel = {
        contenidosPapelColegioTitle: "Contenidos papel",
    };

    public matriculas = {
        entityName: "Matrícula",
        nombreClase: "Clase",
        nombreMateria: "Materia",
        nombreUsuario: "Usuario",
        title: "Matrículas",
        description: "Selecciona en que grupos de clase está cada persona",
        rol: "Rol",
        fecha: "Fecha",
        subgrupo: "Subgrupo"
    };

    public nivelesContenidos = {
        contenido: "Contenido",
        nivel: "Nivel",
        title: "Contenidos por curso",
        description: "Puede ver los contenidos en cada nivel",
        mostrarAccesoDirecto: "Mostrar acceso directo",
        tipoAccesoPorDefecto: "Acceso por defecto",
        accedePlataforma: "Accede a plataforma editorial"
    };

    public documentUpload = {
        dropHere: "Arrastre aquí los ficheros o haga clic para seleccionarlos",
        errorRequired: "Debe seleccionar un documento",
        dropNotSupported: "Parece que está intentando arrastrar desde un archivo comprimido o desde otro programa. El navegador no permite realizar esta acción. Para realizarla debe copiar el archivo a una carpeta y arrastrar desde ella.",
        dropFolderNotSupported: "Parece que está intentando arrastrar una carpeta completa. El navegador no permite realizar esta acción. Para realizarla debe seleccionar los ficheros dentro de la carpeta y arrastrarlos.",
    };


    public aliasEditorial = {
        title: "Alias para editoriales",
        description: "Especifique los nombres de las editoriales en Magento y su correspondencia con las editoriales de Scholarum Hub",
        editorial: "Editorial",
        alias: "Nombre en Magento",
        pendientes: "Sin asignar editorial!",
        enUso: "Con contenidos en uso",
        numContenidos: "Nº Contenidos",
        numUsos: "Nº Usos",
        tabUsos: "Usos de contenidos",
        tabDatos: "Datos",
        colegio: "Colegio",
        nivelEducativo: "Nivel educativo",
        contenido: "Contenido",
        tipoContenido: "Tipo de contenido",
        isbn: "Isbn",
        proveedor: "Proveedor de contenidos"

    };

    public notificaciones = {
        texto: "Descripción",
        titulo: "Notificaciones",
        colegio: "Colegio",
        descripcion: "Aquí puedes ver todas las notificaciones existentes.",
        terminado: "Terminado",
        correcto: "Correcto",
        marcarComoLeida: "Marcar como leída",
        marcarSeleccionadosComoLeidos: "Marcar seleccionadas como leídas",
        editar: "Ver notificación",
        tipoNotificacion: "Tipo",
        estado: "Estado",
        fecha: "Fecha",
        verTodas: "Ver todas",
        haFinalizadoError: "Su proceso ha finalizado con error",
        notificacion: "Detalle notificación",
        procesoEnCola: "Su proceso ha sido puesto en cola para ser ejecutado.",
        tipoLinea: "Filtrar líneas",
        todosTiposLineas: "--Todos los tipos--",
        haFinalizadoOk: (colegio: string, proceso: string) => {
            return `Su proceso ha finalizado: ${colegio}: ${proceso}`;
        },

    };

    public utils = {
        getServerIp: "Get server IP"
    };

    public datepicker = {
        monthNames: [
            "Enero",
            "Febrero",
            "Marzo",
            "Abril",
            "Mayo",
            "Junio",
            "Julio",
            "Agosto",
            "Septiembre",
            "Octubre",
            "Noviembre",
            "Diciembre"
        ],
        month: "Mes",
        day: "Dia",
        year: "Año"
    };

    formatBool(value?: boolean): string {
        if (value !== null) {
            if (value === true) {
                return "Si";
            }
            else {
                return "No";
            }
        }
        return null;
    };

    formatBotonBorrarPendientes(value: number): string {
        return `${this.delete} ${value} ${this.colegioUsuarios.usuarioPendiente}${(value === 1 ? "" : "s")}`;
    }

    formatBotonValidarPendientes(value: number): string {
        return `${this.validate} ${value} ${this.colegioUsuarios.usuarioPendiente}${(value === 1 ? "" : "s")}`;
    }

    formatBotonCambiarClase(value: number): string {
        return `${this.changeClass} ${value} ${this.colegioUsuarios.usuarioAlumno}${(value === 1 ? "" : "s")}`;
    }



    formatNumber(value: number, decimalDigits = 2): string {

        if (value != null) {
            return value.toLocaleString(
                "es",
                {
                    maximumFractionDigits: decimalDigits,

                } as Intl.NumberFormatOptions
            );
        }
        return null;
    };

    formatCurrency(value: number) {
        return this.formatNumber(value, 2);
    };


    static formatDateStatic(value?: Date) {

        if (value == null) return null;

        if (value.toLocaleDateString == null) {
            value = new Date(value);
        }

        let valorFormateado = null;
        if (value) {
            valorFormateado = moment(value).format('L');
        }
        return valorFormateado;
    };

    formatLastAccessDate(value?: Date): string {
        if (value == null) {
            return 'Nunca';
        }

        let momentValue = moment(value);
        let now = moment();

        let diffMinutes = now.diff(momentValue, 'minutes');
        if (diffMinutes < 60) {
            return 'Hace ' + diffMinutes + ' minutos';
        }

        let diffHours = now.diff(momentValue, 'hours');
        if (diffHours < 24) {
            return 'Hace ' + diffHours + ' horas';
        }

        let diffDays = now.diff(momentValue, 'days');
        return 'Hace ' + diffDays + ' días';
    }

    formatDate(value?: Date): string {
        return esES.formatDateStatic(value);
    };

    formatDateTime(value?: Date, full = false): string {
        let valorFormateado = null;
        if (value) {

            valorFormateado = moment(value).format('DD/MM/YYYY HH:mm');
        }
        return valorFormateado;
    };

    formatTime(value?: Date): string {
        let valorFormateado = null;
        if (value) {

            valorFormateado = moment(value).format('HH:mm');
        }
        return valorFormateado;
    };

    public grid = {
        allCheckedWarning: "La selección solo afecta a los elementos mostrados en esta página. Para seleccionar todos, elija un nº de resultados por página mayor y vuelva a marcar todos."
    };

    public validationErrors = {

        thereAreErrors: "Hay errores de validación. Revise el formulario",
        mustBeANumber: "Introduzca un número válido",
        rangeErrorTitle: "Valor no válido",
        fieldRequired: "Valor obligatorio",
        invalidDate: "Fecha no válida",
        validationError: "Error de validación",

        valueRequired(fieldName: string): string {
            return `El campo ${fieldName} es obligatorio`;
        },

        valueDuplicated(fieldName: string): string {
            return `El campo ${fieldName} no puede estar repetido`;
        },

        numberFormatNotValid(fieldName: string): string {
            return `El campo ${fieldName} deber tener 9 dígitos y no puede contener letras, simbolos ni espacios`;
        },

        formatNotValid(fieldName: string): string {
            return `El campo ${fieldName} no tiene un formato válido`;
        },

        rangeError(minValue: number, maxValue: number) {
            return `El valor debe estar entre ${minValue} y ${maxValue}`;
        },

        dateGreaterThan(minorField: string, majorField: string) {
            return `La fecha ${majorField} debe ser posterior a ${minorField}`;
        },

        numberGreaterThan(minorField: string, majorField: string) {
            return `El valor de ${majorField} debe ser mayor que ${minorField}`;
        },
        stringLengthGreaterThan(fieldName: string, minValue: number) {
            return `La longitud del campo ${fieldName} debe ser por lo menos de ${minValue} caracteres`;
        },
        valuesDontMatch(fieldName1: string, fieldName2: string) {
            return `Los valores de ${fieldName1} y ${fieldName2} deben ser iguales`;
        },
    };

    public getEnumText(enumValueFullName: string): string {

        if (esES.enumTexts == null) {
            esES.enumTexts = {};

            esES.enumTexts["Gender.None"] = "";
            esES.enumTexts["Gender.Male"] = "Hombre";
            esES.enumTexts["Gender.Female"] = "Mujer";

            esES.enumTexts["YesNo.None"] = "";
            esES.enumTexts["YesNo.Yes"] = "Sí";
            esES.enumTexts["YesNo.No"] = "No";

            esES.enumTexts["EstadoUsuario.Nuevo"] = "Nuevo";
            esES.enumTexts["EstadoUsuario.PendienteValidacion"] = "Pendiente";
            esES.enumTexts["EstadoUsuario.Activo"] = "Activo";

            esES.enumTexts["TipoUsuario.None"] = "Niguno";
            esES.enumTexts["TipoUsuario.Alumno"] = "Alumno";
            esES.enumTexts["TipoUsuario.Profesor"] = "Profesor";
            esES.enumTexts["TipoUsuario.AdminColegio"] = "Admin. Colegio";
            esES.enumTexts["TipoUsuario.AdminGlobal"] = "Admin. Global";
            esES.enumTexts["TipoUsuario.VisorPedidos"] = "Visor Pedidos";
            esES.enumTexts["TipoUsuario.AdminInstitucion"] = "Admin. Institución";

            esES.enumTexts["Origen.Administrador"] = "Administrador";
            esES.enumTexts["Origen.Tienda"] = "Tienda";
            esES.enumTexts["Origen.CargaFichero"] = "Fichero";

            esES.enumTexts["RolGrupo.Alumno"] = "Alumno";
            esES.enumTexts["RolGrupo.Profesor"] = "Profesor";

            esES.enumTexts["TipoUsuarioContenido.Alumno"] = "Alumno";
            esES.enumTexts["TipoUsuarioContenido.Profesor"] = "Profesor";

            esES.enumTexts["Editorial.None"] = "Ninguna";
            esES.enumTexts["Editorial.Santillana"] = "Santillana";
            esES.enumTexts["Editorial.SM"] = "SM";
            esES.enumTexts["Editorial.Anaya"] = "Anaya";
            esES.enumTexts["Editorial.Edelvives"] = "Edelvives";
            esES.enumTexts["Editorial.Edebe"] = "Edebe";
            esES.enumTexts["Editorial.TekmanBooks"] = "Tekman Books";
            esES.enumTexts["Editorial.Burlington"] = "Burlington";
            esES.enumTexts["Editorial.Casals"] = "Casals";
            esES.enumTexts["Editorial.Cambridge"] = "Cambridge";
            esES.enumTexts["Editorial.Donostiarra"] = "Donostiarra";
            esES.enumTexts["Editorial.McGrawHill"] = "McGraw Hill";
            esES.enumTexts["Editorial.Editex"] = "Editex";
            esES.enumTexts["Editorial.Macmillan"] = "Macmillan";
            esES.enumTexts["Editorial.Teide"] = "Teide";
            esES.enumTexts["Editorial.ScienceBits"] = "Science Bits";
            esES.enumTexts["Editorial.VicensVives"] = "Vicens Vives";
            esES.enumTexts["Editorial.Ramatics"] = "Ramatics";
            esES.enumTexts["Editorial.Brunno"] = "Bruño";
            esES.enumTexts["Editorial.Oxford"] = "Oxford";
            esES.enumTexts["Editorial.Planeta"] = "Planeta";
            esES.enumTexts["Editorial.Milton"] = "Milton";
            esES.enumTexts["Editorial.Esc"] = "ESC";
            esES.enumTexts["Editorial.ContenidoPropio"] = "Contenido Propio";
            esES.enumTexts["Editorial.Bromera"] = "Bromera";
            esES.enumTexts["Editorial.Tabarca"] = "Tabarca";
            esES.enumTexts["Editorial.Klett"] = "Klett";

            esES.enumTexts["TipoAcceso.SinConfigurar"] = "No configurado";
            esES.enumTexts["TipoAcceso.SinAcceso"] = "Sin acceso a plataforma";
            esES.enumTexts["TipoAcceso.Marsupial"] = "Marsupial de la editorial";
            esES.enumTexts["TipoAcceso.MarsupialBlink"] = "Marsupial de Blink";
            esES.enumTexts["TipoAcceso.BlinkDirecto"] = "Blink directo";
            esES.enumTexts["TipoAcceso.Custom"] = "Personalizado editorial";
            esES.enumTexts["TipoAcceso.MacMillanLive"] = "MacMillan Live";
            esES.enumTexts["TipoAcceso.AccesoManual"] = "Acceso Manual";
            esES.enumTexts["TipoAcceso.Lti11"] = "LTI 1.1";
            esES.enumTexts["TipoAcceso.Lti13"] = "LTI 1.3";
            esES.enumTexts["TipoAcceso.SantillanaPrisma"] = "Santillana Prisma";
            esES.enumTexts["TipoAcceso.SantillanaAV4"] = "Santillana AV4";

            esES.enumTexts["TipoAcceso.NoAccedeSiMuestraCredenciales"] = "No Accede Si Muestra Credenciales";
            esES.enumTexts["TipoAcceso.NoAccedeNoMuestraCredenciales"] = "No Accede No Muestra Credenciales";
            esES.enumTexts["TipoAcceso.NoAccedeMuestraMensaje"] = "No Accede Muestra Mensaje";
                

            esES.enumTexts["EstadoNotificacion.EnProceso"] = "En proceso";
            esES.enumTexts["EstadoNotificacion.EnProcesoConErrores"] = "En proceso con errores";
            esES.enumTexts["EstadoNotificacion.AcabadaOk"] = "Terminado sin error";
            esES.enumTexts["EstadoNotificacion.AcabadaError"] = "Terminado con error";

            esES.enumTexts["TipoNotificacion.SincronizacionContenidos"] = "Sincronización contenidos";
            esES.enumTexts["TipoNotificacion.SincronizacionPedidos"] = "Sincronización pedidos";
            esES.enumTexts["TipoNotificacion.SincronizacionCursoEscolar"] = "Sincronización curso escolar";

            esES.enumTexts["OrigenLicencia.Administrador"] = "Administrador";
            esES.enumTexts["OrigenLicencia.Tienda"] = "Tienda Scholarum";
            esES.enumTexts["OrigenLicencia.CargaFichero"] = "Carga Fichero";

            esES.enumTexts["TipoContenido.Virtual"] = "Prod. virtual";
            esES.enumTexts["TipoContenido.Licencia"] = "Licencia";

            esES.enumTexts["AccedePlataforma.SiAccede"] = "Sí accede";
            esES.enumTexts["AccedePlataforma.NoAccedeSiMuestraCredenciales"] = "No accede, SI muestra credenciales";
            esES.enumTexts["AccedePlataforma.NoAccedeNoMuestraCredenciales"] = "No accede, NO muestra credenciales";
            esES.enumTexts["AccedePlataforma.NoAccedeMuestraMensaje"] = "No accede, y muestra mensaje";

            esES.enumTexts["TipoCarga.Usuario"] = "Carga de usuarios";
            esES.enumTexts["TipoCarga.Licencia"] = "Carga de licencias";

            esES.enumTexts["TipoAuditoria.CrearLicencia"] = "Crear Licencia";
            esES.enumTexts["TipoAuditoria.BorrarLicencia"] = "Borrar Licencia";
            esES.enumTexts["TipoAuditoria.ModificarLicencia"] = "Modificar Licencia";

            esES.enumTexts["EstadoPedidoScholarum.Cancelado"] = "Cancelado";
            esES.enumTexts["EstadoPedidoScholarum.Completo"] = "Completo";
            esES.enumTexts["EstadoPedidoScholarum.EnProceso"] = "En Proceso";

            esES.enumTexts["EstadoPedido.Canceled"] = "Canceled";
            esES.enumTexts["EstadoPedido.Closed"] = "Closed";
            esES.enumTexts["EstadoPedido.Complete"] = "Complete";
            esES.enumTexts["EstadoPedido.Holded"] = "Holded";
            esES.enumTexts["EstadoPedido.PaymentReview"] = "PaymentReview";
            esES.enumTexts["EstadoPedido.Pending"] = "Pending";
            esES.enumTexts["EstadoPedido.Processing"] = "Processing";
            esES.enumTexts["EstadoPedido.ServiredOk"] = "ServiredOk";
            esES.enumTexts["EstadoPedido.ServiredPending"] = "ServiredPending";
            esES.enumTexts["EstadoPedido.SentToSeur"] = "SentToSeur";
            esES.enumTexts["EstadoPedido.PayTpvError"] = "PayTpvError";
            esES.enumTexts["EstadoPedido.SeurInfoReceived"] = "SeurInfoReceived";


            esES.enumTexts["TipoProductoTienda.Virtual"] = "Virtual";
            esES.enumTexts["TipoProductoTienda.Simple"] = "Simple";
            esES.enumTexts["TipoProductoTienda.License"] = "License";
            esES.enumTexts["TipoProductoTienda.Grouped"] = "Grouped";


            esES.enumTexts["FormatoContenido.Digital"] = "Digital";
            esES.enumTexts["FormatoContenido.Papel"] = "Papel";


            esES.enumTexts["EstadoLicencia.Vacio"] = "N/A";
            esES.enumTexts["EstadoLicencia.Activo"] = "Activo";
            esES.enumTexts["EstadoLicencia.PedidoCancelado"] = "PedidoCancelado";
            esES.enumTexts["EstadoLicencia.Caducada"] = "Caducada";
            esES.enumTexts["EstadoLicencia.BurlingtonActivaOk"] = "BurlingtonActiva - Ok";
            esES.enumTexts["EstadoLicencia.BurlingtonInvalidActivationCode"] = "Burlington - InvalidActivationCode";
            esES.enumTexts["EstadoLicencia.BurlingtonErrorActivacion"] = "Burlington - ErrorActivacion";
            esES.enumTexts["EstadoLicencia.NecesitaSerRevisada"] = "Necesita Ser Revisada";


            esES.enumTexts["Transportista.LocalPickup"] = "Transportista local";
            esES.enumTexts["Transportista.CorreosExpress"] = "Correos Express";

            esES.enumTexts["EstadoAlbaran.Hecho"] = "Hecho";
            esES.enumTexts["EstadoAlbaran.Borrador"] = "Borrador";
            esES.enumTexts["EstadoAlbaran.EnEspera"] = "En Espera";
            esES.enumTexts["EstadoAlbaran.Preparado"] = "Preparado";
            esES.enumTexts["EstadoAlbaran.Hecho"] = "Hecho";
            esES.enumTexts["EstadoAlbaran.Cancelado"] = "Cancelado";

            esES.enumTexts["EstadoEnvio.None"] = "n/d";
            esES.enumTexts["EstadoEnvio.EsperandoRecodida"] = "Esperando Recodida";
            esES.enumTexts["EstadoEnvio.EnTransito"] = "En Tránsito";
            esES.enumTexts["EstadoEnvio.Entregado"] = "Entregado";
        }


        var elem = esES.enumTexts[enumValueFullName];
        if (elem != null) {
            return elem;
        }
        else {
            return enumValueFullName.substring(enumValueFullName.lastIndexOf("."));
        }
    };
}