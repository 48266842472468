import React from "react";
import { RouteComponentProps } from "react-router-dom";
import { Nav, NavItem, NavLink, TabContent, TabPane } from "reactstrap";
import { RouteHelper } from "../IntranetRouter";
import { NavigationHelper } from "../utils/base/Breadcrumb";
import { ComponentBase } from "../utils/base/ComponentBase";
import { I18n } from "../utils/I18n";
import { LicenciasUsuarios } from "./LicenciasUsuarios";
import { Listing } from "./Listing";
import { Usuarios } from "./Usuarios";


export enum CargaFicherosTabs {
    Listado = "listado",
    Usuarios = "usuarios",
    Licencias = "licencias",
}

interface CargaFicherosState {
    activeTab: CargaFicherosTabs;
}

export class Index extends ComponentBase<any, CargaFicherosState> {

    public constructor(props: any) {
        super(props);

        this.state = {
            activeTab: props.match.params.tab || CargaFicherosTabs.Listado
        };
    }

    // TODO: Esto creo que ya esá obsoleto ver como lo hacemos con lo de los usuarios
    static getDerivedStateFromProps(props: RouteComponentProps<any>, state: CargaFicherosState) {

        if (state != null && props.match.params.tab != null && props.match.params.tab != state.activeTab) {
            var newState = {
                activeTab: props.match.params.tab
            };
            return newState;
        }
        return null;
    }

    protected updateBreadCrumb() {
        var items = [{
            text: I18n.Strings.cargaFicheros.cargaFicheros,
            link: RouteHelper.cargaFicheros()
        }];

        switch (this.state.activeTab) {
            case CargaFicherosTabs.Usuarios: {
                items.push({ text: I18n.Strings.cargaFicheros.tabListado, link: RouteHelper.cargaFicheros(CargaFicherosTabs.Listado) });
                break;
            }
            case CargaFicherosTabs.Usuarios: {
                items.push({ text: I18n.Strings.cargaFicheros.tabUsuarios, link: RouteHelper.cargaFicheros(CargaFicherosTabs.Usuarios) });
                break;
            }
            case CargaFicherosTabs.Licencias: {
                items.push({ text: I18n.Strings.cargaFicheros.tabLicencias, link: RouteHelper.cargaFicheros(CargaFicherosTabs.Licencias) });
                break;
            }
        }

        NavigationHelper.setBreadcrumbItems(items);
    }

    componentDidMount() {
        this.setState({
            activeTab: this.props.match.params.tab
        }, () => this.updateBreadCrumb());
    }

    render() {

        this.updateBreadCrumb();

        return <React.Fragment>
            <div className="head-seccion">
                <h2>
                    <strong>{I18n.Strings.cargaFicheros.cargaFicheros}</strong>
                </h2>
            </div>
            <Nav tabs>

                <NavItem>
                    <NavLink
                        active={this.state.activeTab == CargaFicherosTabs.Listado}
                        onClick={() => this.props.history.push(RouteHelper.cargaFicheros(CargaFicherosTabs.Listado))}
                    >
                        {I18n.Strings.cargaFicheros.tabListado}
                    </NavLink>
                </NavItem>
                <NavItem>
                    <NavLink
                        active={this.state.activeTab == CargaFicherosTabs.Usuarios}
                        onClick={() => this.props.history.push(RouteHelper.cargaFicheros(CargaFicherosTabs.Usuarios))}
                    >
                        {I18n.Strings.cargaFicheros.tabUsuarios}
                    </NavLink>
                </NavItem>

                <NavItem>
                    <NavLink
                        active={this.state.activeTab == CargaFicherosTabs.Licencias}
                        onClick={() => this.props.history.push(RouteHelper.cargaFicheros(CargaFicherosTabs.Licencias))}
                    >
                        {I18n.Strings.cargaFicheros.tabLicencias}
                    </NavLink>
                </NavItem>
            </Nav>

            <TabContent activeTab={this.state.activeTab}>

                <TabPane tabId={CargaFicherosTabs.Listado}>
                    <Listing isEmbedded={true} isViewMode={true} />
                </TabPane>

                <TabPane tabId={CargaFicherosTabs.Usuarios}>
                    {this.state.activeTab == CargaFicherosTabs.Usuarios &&
                        <Usuarios />
                    }
                </TabPane>

                <TabPane tabId={CargaFicherosTabs.Licencias}>
                    {this.state.activeTab == CargaFicherosTabs.Licencias &&
                        <LicenciasUsuarios />
                    }
                </TabPane>
            </TabContent>
        </React.Fragment >;
    };
}