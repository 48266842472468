import React from "react";
import { withRouter } from "react-router-dom";
import { ClientContext } from "../utils/ClientContext";
import { RouteComponentProps } from "react-router";

interface SectionProps {
    userContext: ClientContext,
    children: any;
}


export const AuthenticatedSection = withRouter((props: SectionProps & RouteComponentProps<any>) => {
    if (props.userContext != null) {
        return <React.Fragment>{props.children}</React.Fragment>;
    }

    return null;
});

//export const AuthenticatedSection(props) = withRouter(AuthenticatedSectionFn)(props);


export const UnAuthenticatedSection = withRouter((props: SectionProps & RouteComponentProps<any>) => {

    if (props.userContext == null) {
        return <React.Fragment>{props.children}</React.Fragment>;
    }

    return <React.Fragment></React.Fragment>;
})
//export const UnAuthenticatedSection = withRouter(UnAuthenticatedSectionFn);