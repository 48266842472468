import * as React from "react";
import nameof from "ts-nameof.macro";

import { Row, Col, FormGroup, Label, Input, Button } from "reactstrap";

import { MisMatriculasService } from "../../miPerfil/profesor/matriculas/MisMatriculasService";
import { I18n } from "../../utils/I18n";
import { MatriculaProfesorListItem, MatriculaProfesorListItemPaginatedList, MatriculasProfesorSearch, TipoUsuario } from "../../models/dataModels";
import { IServiceForIndex } from "../../utils/base/BaseServices";
import { IndexPageBase, TableColumn } from "../../utils/base/IndexPageBase";
import { RouteHelper } from "../../IntranetRouter";
import { ClientContext } from "../../utils/ClientContext";
import { withRouter } from "react-router-dom";
import { ClasesDropDown } from "./ClasesDropDown";
import { MisClasesService } from "../MisClasesService";

interface MatriculasProfesorSearchSearchEx extends MatriculasProfesorSearch {
    nivelEducativo?: string;
    clase?: string;
}

class MisClasesComp extends IndexPageBase<MatriculasProfesorSearchSearchEx, MatriculaProfesorListItemPaginatedList, MatriculaProfesorListItem>
{
    private _misClasesSrv: MisClasesService;

    public constructor(props: any) {
        super(props);
        this._misClasesSrv = new MisClasesService();

        if (!this._misClasesSrv.puedeAccederAMisClasesProfe()) {
            window.location.href = "/";
        }
    }

    componentDidMount() {
        if (this._misClasesSrv.puedeAccederAMisClasesProfe()) {
            super.componentDidMount();
        }
    }

    protected _getInitialSearchParams(): MatriculasProfesorSearch {

        var search = {
            colegioId: ClientContext.Current.cursoEscolarActivoId,
            usuarioId: ClientContext.Current.usuarioId
        } as MatriculasProfesorSearch;

        return search;
    }

    protected _renderSearchForm(): React.ReactNode {
        if (this.props.isEmbedded) {
            return null;
        }
        return <React.Fragment>
            <Col xs="12" md="6" lg="4">
                <ClasesDropDown
                    label={null}
                    emptyText={I18n.Strings.matriculas.nombreClase + "..."}
                    selectedItemValue={this.state.searchParams.claseId}
                    selectedItemText={this.state.searchParams.clase}
                    profesorId={this.state.searchParams.usuarioId}
                    onChanged={(claseId, clase, item) => {
                        var state = this._cloneStateForSetState();

                        state.searchParams.claseId = claseId;
                        state.searchParams.clase = clase;

                        this.setState(state, () => this._performSearch());
                    }}
                />
                
            </Col>
        </React.Fragment>;
    }

    protected _getApiService(): IServiceForIndex<MatriculasProfesorSearch, MatriculaProfesorListItemPaginatedList> {
        return new MisMatriculasService();
    }

    protected _getTableColumns(): TableColumn<MatriculaProfesorListItem>[] {
        return [
            {
                fieldName: nameof(this.state.searchResults.items[0].cursoEscolar),
                title: I18n.Strings.colegioUsuarios.cursoEscolar,
                renderField: (item) => item.cursoEscolar,
                colMdWidth: 2
            },
            {
                fieldName: nameof(this.state.searchResults.items[0].clase),
                title: I18n.Strings.grupoClase.grupoClase,
                renderField: (item) => item.clase,
                colMdWidth: 3
            },
            {
                fieldName: nameof(this.state.searchResults.items[0].materia),
                title: I18n.Strings.grupoContenido.materia,
                renderField: (item) => item.materia,
                colMdWidth: 3
            },
            {
                fieldName: nameof(this.state.searchResults.items[0].numAlumnos),
                title: I18n.Strings.grupoContenido.numAlumnos,
                renderField: (item) => I18n.Strings.formatNumber(item.numAlumnos),
                colMdWidth: 2
            },
            {
                fieldName: null,
                title: null,
                renderField: (item) => null,
                colMdWidth: 1
            }
        ];
    }

    protected _getPageTitle(): string {
        return I18n.Strings.grupoClase.misClasesProfesorTitulo;
    }
    protected _getPageDescription(): string {
        return I18n.Strings.grupoClase.misClasesProfesorTexto;
    }


    protected _getEditionItemUrl(item: MatriculaProfesorListItem): string {
        return RouteHelper.editMiClaseProfesor(item.id);
    }

    protected _getNewItemUrl(): string {
        return null;
    }

}

export const MisClases = withRouter(MisClasesComp);