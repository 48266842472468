import * as React from "react";

import { ComponentBase } from "./ComponentBase";
import { FormGroup, Label, FormFeedback } from "reactstrap";
import { AlertsService } from "../AlertsService";

export interface GenericSelectItem {
    id: number;
    name: string | undefined;
    data?: any;
}


export interface IDropDownProps {
    label: string;
    emptyText?: string;

    selectedItemValue: number;
    selectedItemText: string;
    

    required?: boolean;
    onChanged: (value: any, text: string, data?: any) => void;
    errorMessage?: string;
    id?: string;
    disabled?: boolean;
}

export interface IDropDownState {
    value: number;
    text: string;
    options: IDropdownOption[];
}

export interface IDropdownOption {
    key: number;
    text: string;
    data: any;
}

export abstract class DropDownBase<IProps extends IDropDownProps> extends ComponentBase<IProps, IDropDownState> {

    constructor(props: IProps) {
        super(props);

        var key = this.props.selectedItemValue == null ? 0 : this.props.selectedItemValue;
        var text = this.props.selectedItemText == null ? "-" : this.props.selectedItemText;

        const state = {
            options: [
                {
                    key: key,
                    text: text,
                    data: null
                }
            ],
            value: key,
            text: text
        } as IDropDownState;

        this.state = state;

    }

    protected shouldAddEmptyOption(): boolean {
        return true;
    };

    public componentDidMount() {
        this._getAndPaintItems();
    }

    protected _getAndPaintItems() {

        this.GetItems()
            .then((items) => {
                this.BindItems(items, null);
            })
            .catch((err) => {
                AlertsService.showError(err);
            });
    }


    protected abstract GetItems(): Promise<GenericSelectItem[]>;


    protected _getOptions(items: GenericSelectItem[]): IDropdownOption[] {

        var options: IDropdownOption[] = [];

        items.forEach((item) => {
            options.push({
                key: item.id,
                text: item.name,
                data: item.data || item,

            } as IDropdownOption);
        });

        if (this.shouldAddEmptyOption() && !options.some((p) => p.key == null)) {
            options.unshift({
                key: null,
                text: this.props.emptyText
            } as IDropdownOption);
        }

        return options;
    }

    protected BindItems(items: GenericSelectItem[], callback: () => void) {

        var options = this._getOptions(items);

        var newState = this._cloneStateForSetState() || {} as IDropDownState;

        newState.options = options;

        this.setState(newState, () => callback == null ? null : callback());
    }

    public shouldComponentUpdate(nextProps: IDropDownProps, nextState: IDropDownState): boolean {
        return true;
    }

    public render(): JSX.Element {
        var value = this.props.selectedItemValue == null ? 0 : this.props.selectedItemValue;

        return this._pintaDropDown(value);
    }

    private _pintaDropDown(value: any): JSX.Element {

        var labelClassName = "";
        if (this.props.required) {
            labelClassName = "required";
        }
        var formGroupClassName = "";
        if (this.props.label == null) {
            formGroupClassName = "mb-0";
        }

        return <FormGroup className={formGroupClassName} >

            {this.props.label != null &&
                <Label className={labelClassName} for={this.props.id} disabled={this.props.disabled}>
                    {this.props.label}
                </Label>}
                
            <select disabled={this.props.disabled}
                id={this.props.id}
                value={this.state.value || ""}
                onChange={(evt) => {
                    var option = evt.target.options[evt.target.selectedIndex];

                    var value = option.value;

                    var data = this.state.options.find(o => (o.key || "").toString() == value);

                    this.setState({
                        text: option.text,
                        value: data?.key
                    }, () => {
                        this.props.onChanged(data?.key, data?.text, data);
                    });
                }}
                className={"form-select " + (!this.props.errorMessage ? "" : "is-invalid")}
            >
                {this.state.options.map((option, index) => {
                    return <option key={index} value={option.key ?? ""}>{option.text}</option>;
                })}
            </select>

            {this.props.errorMessage && <FormFeedback>{this.props.errorMessage}</FormFeedback>}
        </FormGroup>;
    }


}
