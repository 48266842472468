import * as React from "react";
import { cloneDeep, set } from "lodash-es";

export class ComponentBase<TProps, TState extends object>
    extends React.Component<TProps, TState> {

    constructor(props: TProps) {
        super(props);
    }

    protected _cloneStateForSetState(): TState {
        return cloneDeep(this.state) as TState;
    }

    protected _updateStateValue(value: any, path: string, callback?: () => void) {
        const newState = this._cloneStateForSetState();
        set(newState, path, value);

        this.setState(newState, callback);
    }


}