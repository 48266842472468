import * as React from "react";
import nameof from "ts-nameof.macro";
import { NivelContenidosListItem, SortDirection } from "../models/dataModels";
import { DropDownBase, GenericSelectItem, IDropDownProps } from "../utils/base/DropDownBase";

import { ClientContext } from "../utils/ClientContext";
import { NivelContenidosService } from "./NivelesContenidosService";

export interface NivelContenidoDropDownDropDownProps extends IDropDownProps {
    claseId: number;
    cursoEscolarId: number;
}

export class NivelContenidoDropDown extends DropDownBase<NivelContenidoDropDownDropDownProps> {



    protected GetItems(): Promise<GenericSelectItem[]> {

        var item = {} as NivelContenidosListItem;

        var nivelContenidoSrv = new NivelContenidosService();
        var dataProm = nivelContenidoSrv.search({
            colegioId: ClientContext.Current.colegioId,
            claseId: this.props.claseId,
            cursoEscolarId: this.props.cursoEscolarId,
            contenidoId: null,
            nivelId: null,
            noTotalCount: true,
            currentPage: 0,
            rowsPerPage: -1,
            orderByColumn: nameof(item.contenido),
            orderMode: SortDirection.Asc,
            bypassAutomaticSort: false,
            currentPageRows: -1
        });

        var resultProm = dataProm.then(data => {
            return data.items.map((item, index) => {
                return {
                    id: item.id,
                    name: `${item.isbn} - ${item.contenido}`,
                    data: item
                }
            });
        });

        return Promise.resolve(resultProm);
    }

    protected shouldAddEmptyOption() {
        return true;
    }
};


;
