import * as React from "react";

import nameof from "ts-nameof.macro";

import { Link, withRouter } from "react-router-dom";
import { CursoEscolarEditModel, EtapaEditModel, GrupoClaseEditModel, ModelValidationResult, NivelEducativoEditModel, TipoUsuario } from "../models/dataModels";
import { GenericEditState } from "../utils/base/GenericEditState";
import { EditPageBase } from "../utils/base/EditPageBase";
import { IServiceForEdit } from "../utils/base/BaseServices";
import { CursoEscolarService } from "./CursoEscolarService";
import { RouteHelper } from "../IntranetRouter";
import { Button, Col, FormGroup, Input, Label, Row, UncontrolledTooltip } from "reactstrap";
import { I18n } from "../utils/I18n";
import { ModelValidation } from "../utils/base/ModelValidation";
import { CustomDatePicker } from "../helpers/CustomDatePicker";
import { AddNewIcon, DeleteIcon, EditIcon } from "../utils/Icons";
import { DeleteModal } from "../utils/DeleteModal";
import { LinkItem, NavigationHelper } from "../utils/base/Breadcrumb";
import { ClientContext } from "../utils/ClientContext";
import { CampannaDropDown } from "./CampannaDropDown";
import { toCamelCase } from "../utils/ValuesFormatter";
import { AlertsService } from "../utils/AlertsService";

interface CursoEscolarEditModelEx extends CursoEscolarEditModel {

    indexEtapaBorrar?: number;
    indexNivelBorrar?: number;
    indexClaseBorrar?: number;
}

export class EditComp extends EditPageBase<CursoEscolarEditModelEx> {


    protected _setPageTitleForNew(): string {
        return "Nuevo"
    }

    protected _setPageTitleForExisting(editModel: CursoEscolarEditModel): string {
        return `Editar ${editModel.nombre}`;
    }

    protected _createApiService(): IServiceForEdit<CursoEscolarEditModel> {
        return new CursoEscolarService();
    }

    protected _generateForm(): JSX.Element {
        return <React.Fragment>
            {this.state.showDeleteConfirmation != null &&
                <DeleteModal
                    onCancelDelete={() => this.setState({ showDeleteConfirmation: null })}
                    deleteConfirmBody={(typeof this.state.showDeleteConfirmation == "boolean") ? null : this.state.showDeleteConfirmation}
                    onConfirm={() => {
                        var state = this._cloneStateForSetState();

                        state.showDeleteConfirmation = null;
                        if (this.state.editModel.indexEtapaBorrar != null) {
                            if (this.state.editModel.indexNivelBorrar != null) {
                                if (this.state.editModel.indexClaseBorrar != null) {
                                    state.editModel.etapas[this.state.editModel.indexEtapaBorrar].niveles[this.state.editModel.indexNivelBorrar].clases.splice(this.state.editModel.indexClaseBorrar, 1);
                                }
                                else {
                                    state.editModel.etapas[this.state.editModel.indexEtapaBorrar].niveles.splice(this.state.editModel.indexNivelBorrar, 1);
                                }
                            }
                            else {
                                state.editModel.etapas.splice(this.state.editModel.indexEtapaBorrar, 1);
                            }

                            state.editModel.indexEtapaBorrar = null;
                            state.editModel.indexNivelBorrar = null;
                            state.editModel.indexClaseBorrar = null;
                        }
                        this.setState(state);
                    }}
                />
            }
            <Row>
                <Col md={9}>
                    <FormGroup>
                        <Label for={"cursos" + nameof(this.state.editModel.nombre)}>{I18n.Strings.cursoEscolar.nombre}</Label>
                        <Input
                            onChange={(evt) => {
                                var state = this._cloneStateForSetState();
                                state.editModel.nombre = evt.target.value;
                                this.setState(state);
                            }}
                            type="text"
                            required={true}
                            maxLength={100}
                            className={"form-control " + this._errorClass(nameof(this.state.editModel.nombre))}
                            name={nameof(this.state.editModel.nombre)}
                            id={"cursos" + nameof(this.state.editModel.nombre)}
                            value={this.state.editModel.nombre || ""}
                        />
                        {this._errorMessage(nameof(this.state.editModel.nombre))}
                    </FormGroup>
                </Col>
                <Col md={4}>
                    <FormGroup className={"dateBlock"}>
                        <Label for={"cursos" + nameof(this.state.editModel.fechaInicio)}>{I18n.Strings.cursoEscolar.fechaInicio}</Label>
                        <CustomDatePicker
                            key={nameof(this.state.editModel.fechaInicio)}
                            value={this.state.editModel.fechaInicio}
                            yearsFront={2}
                            yearsBack={22}
                            onChange={(val) => {
                                var state = this._cloneStateForSetState();
                                state.editModel.fechaInicio = val == null ? null : new Date(val);
                                if (state.editModel.fechaInicio != null) {
                                    state.editModel.fechaInicio.setHours(12);
                                }
                                this.setState(state);
                            }}
                        />
                        {this._errorMessage(nameof(this.state.editModel.fechaInicio))}
                    </FormGroup>
                </Col>
                <Col md={4}>
                    <FormGroup className={"dateBlock"}>
                        <Label for={"cursos" + nameof(this.state.editModel.fechaFin)}>{I18n.Strings.cursoEscolar.fechaFin}</Label>
                        <CustomDatePicker
                            key={nameof(this.state.editModel.fechaFin)}
                            value={this.state.editModel.fechaFin}
                            yearsFront={2}
                            yearsBack={22}
                            onChange={(val) => {
                                var state = this._cloneStateForSetState();
                                state.editModel.fechaFin = val == null ? null : new Date(val);
                                if (state.editModel.fechaFin != null) {
                                    state.editModel.fechaFin.setHours(12);
                                }
                                this.setState(state);
                            }}
                        />
                        {this._errorMessage(nameof(this.state.editModel.fechaFin))}
                    </FormGroup>
                </Col>
                {ClientContext.Current.tipoUsuario == TipoUsuario.AdminGlobal && <React.Fragment>
                    <Col md={4}>
                        <CampannaDropDown
                            label={toCamelCase(I18n.Strings.campannas.entityName)}
                            emptyText={toCamelCase(I18n.Strings.campannas.entityName)}
                            id={nameof(this.state.editModel.campannaId)}

                            selectedItemValue={this.state.editModel.campannaId}
                            selectedItemText={this.state.editModel.campanna}

                            onChanged={(id, nombre) => {

                                var state = this._cloneStateForSetState();
                                state.editModel.campanna = nombre;
                                state.editModel.campannaId = id;
                                this.setState(state);
                            }}
                        />
                    </Col>
                    <Col md={4}>
                        <FormGroup className={"dateBlock"}>
                            <Label for={"cursos" + nameof(this.state.editModel.fechaInicioCampagna)}>{I18n.Strings.cursoEscolar.fechaInicioCampagna}</Label>
                            <CustomDatePicker
                                key={nameof(this.state.editModel.fechaInicioCampagna)}
                                value={this.state.editModel.fechaInicioCampagna}
                                yearsFront={2}
                                yearsBack={22}
                                onChange={(val) => {
                                    var state = this._cloneStateForSetState();
                                    state.editModel.fechaInicioCampagna = val == null ? null : new Date(val);
                                    if (state.editModel.fechaInicioCampagna != null) {
                                        state.editModel.fechaInicioCampagna.setHours(12);
                                    }
                                    this.setState(state);
                                }}
                            />
                            {this._errorMessage(nameof(this.state.editModel.fechaInicioCampagna))}
                        </FormGroup>
                    </Col>
                    <Col md={4}>
                        <FormGroup className={"dateBlock"}>
                            <Label for={"cursos" + nameof(this.state.editModel.fechaFinCampagna)}>{I18n.Strings.cursoEscolar.fechaFinCampagna}</Label>
                            <CustomDatePicker
                                key={nameof(this.state.editModel.fechaFinCampagna)}
                                value={this.state.editModel.fechaFinCampagna}
                                yearsFront={2}
                                yearsBack={22}
                                onChange={(val) => {
                                    var state = this._cloneStateForSetState();
                                    state.editModel.fechaFinCampagna = val == null ? null : new Date(val);
                                    if (state.editModel.fechaFinCampagna != null) {
                                        state.editModel.fechaFinCampagna.setHours(12);
                                    }
                                    this.setState(state);
                                }}
                            />
                            {this._errorMessage(nameof(this.state.editModel.fechaFinCampagna))}
                        </FormGroup>
                    </Col>
                </React.Fragment>}

            </Row>
            <Row>
                <Col md={2}>
                    <FormGroup>
                        <Label for={"cursos" + nameof(this.state.editModel.activo)}>{I18n.Strings.cursoEscolar.activo}</Label>
                        <Input
                            onChange={(evt) => {
                                var state = this._cloneStateForSetState();
                                state.editModel.activo = evt.target.checked;
                                this.setState(state);
                            }}
                            type="checkbox"
                            style={{ padding: 0 }}
                            className={"form-control"}
                            name={nameof(this.state.editModel.activo)}
                            id={"cursos" + nameof(this.state.editModel.nombre)}
                            checked={this.state.editModel.activo || false}
                        />
                        {this._errorMessage(nameof(this.state.editModel.activo))}
                    </FormGroup>
                </Col>
                <Col md={2}>
                    <FormGroup>
                        <Label for={"cursos" + nameof(this.state.editModel.visibleAlumnos)}>{I18n.Strings.cursoEscolar.visibleAlumnos}</Label>
                        <Input
                            onChange={(evt) => {
                                var state = this._cloneStateForSetState();
                                state.editModel.visibleAlumnos = evt.target.checked;
                                this.setState(state);
                            }}
                            type="checkbox"
                            style={{ padding: 0 }}
                            className={"form-control"}
                            name={nameof(this.state.editModel.visibleAlumnos)}
                            id={"cursos" + nameof(this.state.editModel.visibleAlumnos)}
                            checked={this.state.editModel.visibleAlumnos || false}
                        />
                        {this._errorMessage(nameof(this.state.editModel.visibleAlumnos))}
                    </FormGroup>
                </Col>
                <Col md={3}>
                    <FormGroup>
                        <Label for={"cursos" + nameof(this.state.editModel.contenidoAccesibleAlumnos)}>{I18n.Strings.cursoEscolar.contenidoAccesibleAlumnos}</Label>
                        <Input
                            onChange={(evt) => {
                                var state = this._cloneStateForSetState();
                                state.editModel.contenidoAccesibleAlumnos = evt.target.checked;
                                this.setState(state);
                            }}
                            type="checkbox"
                            style={{ padding: 0 }}
                            className={"form-control"}
                            name={nameof(this.state.editModel.contenidoAccesibleAlumnos)}
                            id={"cursos" + nameof(this.state.editModel.contenidoAccesibleAlumnos)}
                            checked={this.state.editModel.contenidoAccesibleAlumnos || false}
                        />
                        {this._errorMessage(nameof(this.state.editModel.contenidoAccesibleAlumnos))}
                    </FormGroup>
                </Col>
                <Col md={3}>
                    <FormGroup>
                        <Label for={"cursos" + nameof(this.state.editModel.syncroPorDefecto)}>{I18n.Strings.cursoEscolar.syncroPorDefecto}</Label>
                        <Input
                            onChange={(evt) => {
                                var state = this._cloneStateForSetState();
                                state.editModel.syncroPorDefecto = evt.target.checked;
                                this.setState(state);
                            }}
                            type="checkbox"
                            style={{ padding: 0 }}
                            className={"form-control"}
                            name={nameof(this.state.editModel.syncroPorDefecto)}
                            id={"cursos" + nameof(this.state.editModel.syncroPorDefecto)}
                            checked={this.state.editModel.syncroPorDefecto || false}
                        />
                        {this._errorMessage(nameof(this.state.editModel.syncroPorDefecto))}
                    </FormGroup>
                </Col>
            </Row>
            {this.renderEtapas()}
        </React.Fragment>;
    }

    protected renderEtapas(): JSX.Element {
        return <React.Fragment>
            {this.state.editModel.etapas != null && this.state.editModel.etapas.map((etapa, indexEtapa) => {
                return <div key={"etapa" + indexEtapa} className="licencias lista_etapas pb-2">
                    <Row>
                        <Col md={5}>
                            <FormGroup>
                                <Input
                                    onChange={(evt) => {
                                        var state = this._cloneStateForSetState();
                                        var etapa = state.editModel.etapas[indexEtapa];
                                        etapa.nombre = evt.target.value;
                                        this.setState(state);
                                    }}
                                    placeholder={I18n.Strings.etapa.nombre}
                                    type="text"
                                    required={true}
                                    maxLength={255}
                                    className={"form-control " + this._errorClass(nameof(etapa.nombre) + indexEtapa)}
                                    name={nameof(etapa.nombre) + indexEtapa}
                                    id={"etapa" + indexEtapa}
                                    value={etapa.nombre || ""}
                                />
                                {this._errorMessage(nameof(etapa.nombre) + indexEtapa)}
                            </FormGroup>
                        </Col>
                        <Col md={1}>
                            <FormGroup>
                                <Input
                                    onChange={(evt) => {
                                        var state = this._cloneStateForSetState();
                                        var etapa = state.editModel.etapas[indexEtapa];
                                        etapa.identificadorTienda = evt.target.value;
                                        this.setState(state);
                                    }}
                                    placeholder={I18n.Strings.etapa.identificadorTienda}
                                    type="text"
                                    required={false}
                                    maxLength={255}
                                    className={"form-control " + this._errorClass(nameof(etapa.identificadorTienda))}
                                    name={nameof(etapa.identificadorTienda) + indexEtapa}
                                    id={"identificadorTienda" + indexEtapa}
                                    value={etapa.identificadorTienda || ""}
                                />
                            </FormGroup>
                        </Col>
                        <Col md={2}>
                            <FormGroup>
                                <Input
                                    onChange={(evt) => {
                                        var state = this._cloneStateForSetState();
                                        var etapa = state.editModel.etapas[indexEtapa];
                                        etapa.sincronizar = evt.target.checked;
                                        this.setState(state);
                                    }}
                                    type="checkbox"
                                    style={{ padding: 0, display: 'inline-block', marginRight: '5px' }}
                                    className={"form-control " + this._errorClass(nameof(etapa.identificadorTienda))}
                                    name={nameof(etapa.sincronizar) + indexEtapa}
                                    id={"sincronizar" + indexEtapa}
                                    checked={etapa.sincronizar || false}
                                />
                                <UncontrolledTooltip placement="top" target={"sincronizar" + indexEtapa}>
                                    {I18n.Strings.etapa.sincronizar}
                                </UncontrolledTooltip>
                                <Input
                                    onChange={(evt) => {
                                        var state = this._cloneStateForSetState();
                                        var etapa = state.editModel.etapas[indexEtapa];
                                        etapa.omitirNotificacionLicencias = evt.target.checked;
                                        this.setState(state);
                                    }}
                                    type="checkbox"
                                    style={{ padding: 0, display: 'inline-block', marginRight: '5px' }}
                                    className={"form-control " + this._errorClass(nameof(etapa.omitirNotificacionLicencias))}
                                    name={nameof(etapa.omitirNotificacionCredenciales) + indexEtapa}
                                    id={"omitirNotificacionLicencias" + indexEtapa}
                                    checked={etapa.omitirNotificacionLicencias || false}
                                />
                                <UncontrolledTooltip placement="top" target={"omitirNotificacionLicencias" + indexEtapa}>
                                    {I18n.Strings.etapa.omitirNotificacionLicencias}
                                </UncontrolledTooltip>
                                <Input
                                    onChange={(evt) => {
                                        var state = this._cloneStateForSetState();
                                        var etapa = state.editModel.etapas[indexEtapa];
                                        etapa.omitirNotificacionCredenciales = evt.target.checked;
                                        this.setState(state);
                                    }}
                                    type="checkbox"
                                    style={{ padding: 0, display: 'inline-block' }}
                                    className={"form-control " + this._errorClass(nameof(etapa.identificadorTienda))}
                                    name={nameof(etapa.omitirNotificacionCredenciales) + indexEtapa}
                                    id={"omitirNotificacionCredenciales" + indexEtapa}
                                    checked={etapa.omitirNotificacionCredenciales || false}
                                />
                                <UncontrolledTooltip placement="top" target={"omitirNotificacionCredenciales" + indexEtapa}>
                                    {I18n.Strings.etapa.omitirNotificacionCredenciales}
                                </UncontrolledTooltip>
                            </FormGroup>
                        </Col>
                        <Col md={2}>
                            <FormGroup>
                                <a href="javaScript:void(0)"
                                    className={"btn-curso btn-borrarnivel"}
                                    id={"btnBorrarEtapa" + indexEtapa}
                                    style={{ border: "none" }}
                                    onClick={() => {
                                        var state = this._cloneStateForSetState();

                                        state.showDeleteConfirmation = I18n.Strings.etapa.deleteEtapaConfirmText(etapa.nombre);
                                        state.editModel.indexEtapaBorrar = indexEtapa;

                                        this.setState(state);
                                    }}>
                                    <DeleteIcon />
                                </a>
                                <UncontrolledTooltip placement="top" target={"btnBorrarEtapa" + indexEtapa}>
                                    {I18n.Strings.etapa.deleteEtapa(etapa.nombre)}
                                </UncontrolledTooltip>
                            </FormGroup>
                        </Col>
                    </Row>
                    {this.renderNiveles(indexEtapa)}
                    <Row>
                        <Col sm={1}></Col>
                        <Col>
                            <Button className="btn-sm btn-curso btn-rounded"
                                onClick={() => {
                                    var state = this._cloneStateForSetState();
                                    var etapa = state.editModel.etapas[indexEtapa];
                                    if (etapa.niveles == null) {
                                        etapa.niveles = [];
                                    }
                                    etapa.niveles.push({
                                        nombre: '',
                                        orden: 1,
                                        id: 0,
                                        etapaId: etapa.id,
                                        clases: []
                                    } as NivelEducativoEditModel);
                                    this.setState(state);
                                }}>
                                <AddNewIcon />
                                {I18n.Strings.nivelEducativo.addNivel}
                            </Button>
                        </Col>
                    </Row>
                </div>
            })}
            <Row>
                <Col md={12}>
                    <Button
                        className="btn-secondary btn-rounded btn-sm"
                        onClick={() => {
                            var state = this._cloneStateForSetState();
                            if (state.editModel.etapas == null) {
                                state.editModel.etapas = [];
                            }
                            state.editModel.etapas.push({
                                nombre: '',
                                orden: 1,
                                id: 0,
                                cursoEscolarId: this.state.editModel.id,
                                niveles: []
                            } as EtapaEditModel);
                            this.setState(state);
                        }}>
                        <AddNewIcon />
                        {I18n.Strings.etapa.addEtapa}
                    </Button>
                </Col>
            </Row>
        </React.Fragment>
    }

    protected renderNiveles(etapaIndex: number): JSX.Element {
        return <React.Fragment>
            {this.state.editModel.etapas[etapaIndex].niveles != null
                && this.state.editModel.etapas[etapaIndex].niveles.map((nivel, nivelIndex) => {
                    return <React.Fragment key={"nivel" + etapaIndex + nivelIndex}>
                        <Row>
                            <Col md={1}>
                            </Col>
                            <Col md={4}>
                                <FormGroup>
                                    <Input
                                        onChange={(evt) => {
                                            var state = this._cloneStateForSetState();
                                            var nivel = state.editModel.etapas[etapaIndex].niveles[nivelIndex];
                                            nivel.nombre = evt.target.value;
                                            this.setState(state);
                                        }}
                                        placeholder={I18n.Strings.cursoEscolar.nombre}
                                        type="text"
                                        required={true}
                                        maxLength={255}
                                        className={"form-control " + this._errorClass(nameof(nivel.nombre) + etapaIndex + nivelIndex)}
                                        name={nameof(nivel.nombre) + etapaIndex + nivelIndex}
                                        id={"nivel" + etapaIndex + nivelIndex}
                                        value={nivel.nombre || ""}
                                    />
                                    {this._errorMessage(nameof(nivel.nombre) + etapaIndex + nivelIndex)}
                                </FormGroup>
                            </Col>
                            <Col md={1}>
                                <FormGroup>
                                    <Input
                                        onChange={(evt) => {
                                            var state = this._cloneStateForSetState();
                                            var nivel = state.editModel.etapas[etapaIndex].niveles[nivelIndex];
                                            nivel.identificadorTienda = evt.target.value;
                                            this.setState(state);
                                        }}
                                        placeholder={I18n.Strings.etapa.identificadorTienda}
                                        type="text"
                                        required={false}
                                        maxLength={255}
                                        className={"form-control " + this._errorClass(nameof(nivel.identificadorTienda))}
                                        name={nameof(nivel.identificadorTienda) + etapaIndex + nivelIndex}
                                        id={"identificadorTienda" + etapaIndex + nivelIndex}
                                        value={nivel.identificadorTienda || ""}
                                    />
                                </FormGroup>
                            </Col>
                            <Col md={2}>
                                <FormGroup>
                                    <Input
                                        onChange={(evt) => {
                                            var state = this._cloneStateForSetState();
                                            var nivel = state.editModel.etapas[etapaIndex].niveles[nivelIndex];
                                            nivel.sincronizar = evt.target.checked;
                                            this.setState(state);
                                        }}
                                        type="checkbox"
                                        style={{ padding: 0, display: 'inline-block', marginRight: '5px' }}
                                        className={"form-control " + this._errorClass(nameof(nivel.identificadorTienda))}
                                        name={nameof(nivel.sincronizar) + etapaIndex + nivelIndex}
                                        id={"sincronizar" + etapaIndex + nivelIndex}
                                        checked={nivel.sincronizar || false}
                                    />
                                    <UncontrolledTooltip placement="top" target={"sincronizar" + etapaIndex + nivelIndex}>
                                        {I18n.Strings.etapa.sincronizar}
                                    </UncontrolledTooltip>
                                    <Input
                                        onChange={(evt) => {
                                            var state = this._cloneStateForSetState();
                                            var nivel = state.editModel.etapas[etapaIndex].niveles[nivelIndex];
                                            nivel.omitirNotificacionLicencias = evt.target.checked;
                                            this.setState(state);
                                        }}
                                        type="checkbox"
                                        style={{ padding: 0, display: 'inline-block', marginRight: '5px' }}
                                        className={"form-control " + this._errorClass(nameof(nivel.omitirNotificacionLicencias))}
                                        name={nameof(nivel.omitirNotificacionLicencias) + etapaIndex + nivelIndex}
                                        id={"omitirNotificacionLicencias" + etapaIndex + nivelIndex}
                                        checked={nivel.omitirNotificacionLicencias || false}
                                    />
                                    <UncontrolledTooltip placement="top" target={"omitirNotificacionLicencias" + etapaIndex + nivelIndex}>
                                        {I18n.Strings.nivelEducativo.omitirNotificacionLicencias}
                                    </UncontrolledTooltip>
                                    <Input
                                        onChange={(evt) => {
                                            var state = this._cloneStateForSetState();
                                            var nivel = state.editModel.etapas[etapaIndex].niveles[nivelIndex];
                                            nivel.omitirNotificacionCredenciales = evt.target.checked;
                                            this.setState(state);
                                        }}
                                        type="checkbox"
                                        style={{ padding: 0, display: 'inline-block' }}
                                        className={"form-control " + this._errorClass(nameof(nivel.omitirNotificacionCredenciales))}
                                        name={nameof(nivel.omitirNotificacionCredenciales) + etapaIndex + nivelIndex}
                                        id={"omitirNotificacionCredenciales" + etapaIndex + nivelIndex}
                                        checked={nivel.omitirNotificacionCredenciales || false}
                                    />
                                    <UncontrolledTooltip placement="top" target={"omitirNotificacionCredenciales" + etapaIndex + nivelIndex}>
                                        {I18n.Strings.nivelEducativo.omitirNotificacionCredenciales}
                                    </UncontrolledTooltip>
                                </FormGroup>
                            </Col>
                            <Col md={1}>
                                <FormGroup>
                                    <a href="javaScript:void(0)"
                                        id={"btnBorrarNivel" + etapaIndex + nivelIndex}
                                        className={"btn-curso btn-borrarnivel"}
                                        style={{ border: "none" }}
                                        onClick={() => {
                                            var state = this._cloneStateForSetState();

                                            state.showDeleteConfirmation = I18n.Strings.nivelEducativo.deleteNivelConfirmText(nivel.nombre);
                                            state.editModel.indexEtapaBorrar = etapaIndex;
                                            state.editModel.indexNivelBorrar = nivelIndex;

                                            this.setState(state);
                                        }}>
                                        <DeleteIcon />
                                    </a>
                                    <UncontrolledTooltip placement="top" target={"btnBorrarNivel" + etapaIndex + nivelIndex}>
                                        {I18n.Strings.nivelEducativo.deleteNivel(nivel.nombre)}
                                    </UncontrolledTooltip>
                                </FormGroup>
                            </Col>
                            {nivel.id > 0 && <Col>
                                <span title={`Nivel educativo: ${nivel.id}`}>
                                    {I18n.Strings.cursoEscolar.numeroContenidosDigitales(nivel.numContenidosDigitales)} <br />
                                    {I18n.Strings.cursoEscolar.numeroContenidosPapel(nivel.numContenidosPapel)}
                                </span>
                            </Col>}
                        </Row>
                        {this.renderClases(etapaIndex, nivelIndex)}

                    </React.Fragment>
                })}
        </React.Fragment>
    }

    protected renderClases(etapaIndex: number, cursoIndex: number): JSX.Element {
        return <React.Fragment>
            {this.state.editModel.etapas[etapaIndex].niveles[cursoIndex].clases != null && this.state.editModel.etapas[etapaIndex].niveles[cursoIndex].clases.map((grupo, claseIndex) => {
                return <React.Fragment key={"grupo" + etapaIndex + cursoIndex + claseIndex}>
                    <Row>
                        <Col md={2}>
                        </Col>
                        <Col md={6}>
                            <FormGroup>
                                <Input
                                    onChange={(evt) => {
                                        var state = this._cloneStateForSetState();
                                        var grupo = state.editModel.etapas[etapaIndex].niveles[cursoIndex].clases[claseIndex];
                                        grupo.nombre = evt.target.value;
                                        this.setState(state);
                                    }}
                                    placeholder={I18n.Strings.grupoClase.nombre}
                                    type="text"
                                    required={true}
                                    disabled={grupo.nombre.indexOf('General') > 0 && claseIndex == 0}
                                    maxLength={255}
                                    className={"form-control " + this._errorClass("grupo" + etapaIndex + cursoIndex + claseIndex)}
                                    name={"grupo" + etapaIndex + cursoIndex + claseIndex}
                                    id={"grupo" + etapaIndex + cursoIndex + claseIndex}
                                    value={grupo.nombre || ""}
                                />
                                {this._errorMessage("grupo" + etapaIndex + cursoIndex + claseIndex)}
                            </FormGroup>
                        </Col>
                        {/*<Col md={3}>*/}
                        {/*    <FormGroup>*/}
                        {/*        <Input*/}
                        {/*            onChange={(evt) => {*/}
                        {/*                var state = this._cloneStateForSetState();*/}
                        {/*                var grupo = state.editModel.etapas[etapaIndex].niveles[cursoIndex].clases[claseIndex];*/}
                        {/*                grupo.identificadorTienda = evt.target.value;*/}
                        {/*                this.setState(state);*/}
                        {/*            }}*/}
                        {/*            placeholder={I18n.Strings.etapa.identificadorTienda}*/}
                        {/*            type="text"*/}
                        {/*            required={false}*/}
                        {/*            maxLength={255}*/}
                        {/*            className={"form-control " + this._errorClass(nameof(grupo.identificadorTienda))}*/}
                        {/*            name={"identificadorTienda" + etapaIndex + cursoIndex + claseIndex}*/}
                        {/*            id={"identificadorTienda" + etapaIndex + cursoIndex + claseIndex}*/}
                        {/*            value={grupo.identificadorTienda || ""}*/}
                        {/*        />*/}
                        {/*        {this._errorMessage("identificadorTienda" + etapaIndex + cursoIndex + claseIndex)}*/}
                        {/*    </FormGroup>*/}
                        {/*</Col>*/}
                        {/*<Col md={1}>*/}
                        {/*    <FormGroup>*/}
                        {/*        <Input*/}
                        {/*            onChange={(evt) => {*/}
                        {/*                var state = this._cloneStateForSetState();*/}
                        {/*                var grupo = state.editModel.etapas[etapaIndex].niveles[cursoIndex].clases[claseIndex];*/}
                        {/*                grupo.sincronizar = evt.target.checked;*/}
                        {/*                this.setState(state);*/}
                        {/*            }}*/}
                        {/*            type="checkbox"*/}
                        {/*            title={I18n.Strings.etapa.sincronizar}*/}
                        {/*            style={{ padding: 0 }}*/}
                        {/*            className={"form-control " + this._errorClass(nameof(grupo.identificadorTienda))}*/}
                        {/*            name={"identificadorTienda" + etapaIndex + cursoIndex + claseIndex}*/}
                        {/*            id={"identificadorTienda" + etapaIndex + cursoIndex + claseIndex}*/}
                        {/*            checked={grupo.sincronizar || false}*/}
                        {/*        />*/}
                        {/*        {this._errorMessage("identificadorTienda" + etapaIndex + cursoIndex + claseIndex)}*/}
                        {/*    </FormGroup>*/}
                        {/*</Col>*/}
                        <Col md={1}>
                            <FormGroup>
                                {grupo.id > 0 && <React.Fragment>
                                    <a href="javaScript:void(0)"
                                        id={"btnEditar" + grupo.id}
                                        className={"btn-curso"}
                                        style={{ border: "none" }}
                                        onClick={() => {
                                            this.props.history.push(RouteHelper.editGrupoClase(grupo.id, this.state.editModel.id));
                                        }}>
                                        <EditIcon />
                                    </a>
                                    <UncontrolledTooltip placement="top" target={"btnEditar" + grupo.id}>
                                        {I18n.Strings.edit}
                                    </UncontrolledTooltip>
                                </React.Fragment>}
                                {(grupo.nombre.indexOf('General') < 0) && <React.Fragment>
                                    <a href="javaScript:void(0)"
                                        id={"btnBorrar" + grupo.id}
                                        className={"btn-curso btn-borrarnivel"}
                                        style={{ border: "none" }}
                                        onClick={() => {
                                            var state = this._cloneStateForSetState();

                                            state.showDeleteConfirmation = I18n.Strings.grupoClase.deleteGrupoConfirmText(grupo.nombre);
                                            state.editModel.indexEtapaBorrar = etapaIndex;
                                            state.editModel.indexNivelBorrar = cursoIndex;
                                            state.editModel.indexClaseBorrar = claseIndex;

                                            this.setState(state);
                                        }}>
                                        <DeleteIcon />
                                    </a>
                                    <UncontrolledTooltip placement="top" target={"btnBorrar" + grupo.id}>
                                        {I18n.Strings.grupoClase.deleteGrupo(grupo.nombre)}
                                    </UncontrolledTooltip>
                                </React.Fragment>}
                            </FormGroup>
                        </Col>
                        {grupo.id > 0 && <Col md={3}>
                            <span title={`Grupo de clase: ${grupo.id}`}>
                                {I18n.Strings.cursoEscolar.numeroMaterias(grupo.numMaterias)} - {I18n.Strings.cursoEscolar.numeroMatriculas(grupo.numMatriculas)}
                            </span>
                        </Col>}
                    </Row>

                </React.Fragment>
            })}
            <Row className="mb-4">
                <Col md={2}></Col>
                <Col>
                    <Button
                        className="btn-secondary btn-rounded btn-sm"
                        onClick={() => {
                            var state = this._cloneStateForSetState();
                            var nivel = state.editModel.etapas[etapaIndex].niveles[cursoIndex];
                            if (nivel.clases == null) {
                                nivel.clases = [];
                            }
                            nivel.clases.push({
                                nombre: '',
                                orden: 1,
                                id: 0,
                            } as GrupoClaseEditModel);
                            this.setState(state);
                        }}>
                        <AddNewIcon />
                        {I18n.Strings.grupoClase.addGrupo}
                    </Button>
                </Col>
            </Row>
        </React.Fragment>
    }

    protected _getEditUrl(id: number): string {
        return RouteHelper.editCursoEscolar(id);
    }

    protected _getListUrl(): string {
        return RouteHelper.listCursosEscolares();
    }

    protected _createStateForNew(): Promise<GenericEditState<CursoEscolarEditModel>> {

        var model = new GenericEditState<CursoEscolarEditModel>();
        model.editModel = {
            id: 0,
            nombre: "",
            activo: false,
            visibleAlumnos: false,
            fechaInicio: null,
            fechaInicioCampagna: null,
            fechaFinCampagna: null
        } as CursoEscolarEditModel;

        return Promise.resolve(model);
    }

    private _checkDuplicated(list: any[], name: string, validation: ModelValidation, fieldName: string, errorText: string): ModelValidation {
        var countfiltered = list.filter(function (element) {
            return element.nombre == name;
        }).length;

        if (countfiltered > 1) {
            validation.addError(fieldName, errorText);
        }

        return validation;
    }

    protected _validateModelLocal(): ModelValidation {
        let validation = new ModelValidation();

        if ((this.state.editModel.nombre || "").trim().length == 0) {
            validation.addError(nameof(this.state.editModel.nombre), I18n.Strings.validationErrors.valueRequired(I18n.Strings.cursoEscolar.nombre));
        }

        if (this.state.editModel.fechaInicio == null) {
            validation.addError(nameof(this.state.editModel.fechaInicio), I18n.Strings.validationErrors.valueRequired(I18n.Strings.cursoEscolar.fechaInicio));
        }

        if (this.state.editModel.fechaFin == null) {
            validation.addError(nameof(this.state.editModel.fechaFin), I18n.Strings.validationErrors.valueRequired(I18n.Strings.cursoEscolar.fechaFin));
        }

        if (ClientContext.Current.tipoUsuario == TipoUsuario.AdminGlobal) {
            if (this.state.editModel.fechaInicioCampagna == null) {
                validation.addError(nameof(this.state.editModel.fechaInicioCampagna), I18n.Strings.validationErrors.valueRequired(I18n.Strings.cursoEscolar.fechaInicioCampagna));
            }

            if (this.state.editModel.fechaFinCampagna == null) {
                validation.addError(nameof(this.state.editModel.fechaFinCampagna), I18n.Strings.validationErrors.valueRequired(I18n.Strings.cursoEscolar.fechaFinCampagna));
            }

            if (this.state.editModel.fechaInicioCampagna != null && this.state.editModel.fechaFinCampagna != null && this.state.editModel.fechaInicioCampagna > this.state.editModel.fechaFinCampagna) {
                validation.addError(nameof(this.state.editModel.fechaFinCampagna), I18n.Strings.validationErrors.dateGreaterThan(I18n.Strings.cursoEscolar.fechaInicioCampagna, I18n.Strings.cursoEscolar.fechaFinCampagna));
            }
        }

        if (this.state.editModel.etapas != null) {
            this.state.editModel.etapas.map((etapa, index1) => {
                if ((etapa.nombre || "").trim().length == 0) {
                    validation.addError(nameof(etapa.nombre) + index1, I18n.Strings.validationErrors.valueRequired(I18n.Strings.nivelEducativo.nombre));
                }
                else {
                    validation = this._checkDuplicated(this.state.editModel.etapas, etapa.nombre, validation, nameof(etapa.nombre) + index1, I18n.Strings.validationErrors.valueDuplicated(I18n.Strings.grupoContenido.nombre));
                }

                if (etapa.niveles != null) {
                    etapa.niveles.map((nivel, index2) => {
                        if ((nivel.nombre || "").trim().length == 0) {
                            validation.addError(nameof(nivel.nombre) + index1 + "" + index2, I18n.Strings.validationErrors.valueRequired(I18n.Strings.nivelEducativo.nombre));
                        }
                        else {
                            validation = this._checkDuplicated(etapa.niveles, nivel.nombre, validation, nameof(nivel.nombre) + index1 + "" + index2, I18n.Strings.validationErrors.valueDuplicated(I18n.Strings.grupoContenido.nombre));
                        }

                        if (nivel.clases != null) {
                            nivel.clases.map((grupo, index3) => {
                                if ((grupo.nombre || "").trim().length == 0) {
                                    validation.addError("grupo" + index1 + "" + index2 + "" + index3, I18n.Strings.validationErrors.valueRequired(I18n.Strings.grupoClase.nombre));
                                }
                                else {
                                    validation = this._checkDuplicated(nivel.clases, grupo.nombre, validation, "grupo" + + index1 + "" + index2 + "" + index3, I18n.Strings.validationErrors.valueDuplicated(I18n.Strings.grupoContenido.nombre));
                                }
                            });
                        }
                    });
                }
            });
        }

        if (!validation.isOk) {
            AlertsService.showAlertMessage("Hay errores de validación. Revise el formulario.");
        }

        return validation;
    }

    protected updateBreadCrumb(model: CursoEscolarEditModel) {
        var items = [
            {
                text: I18n.Strings.cursoEscolar.title,
                link: RouteHelper.listCursosEscolares()
            },
            {
                text: this.state.editModel.id == 0 ? I18n.Strings.addNew : this.state.editModel.nombre,
                link: ""
            },

        ] as LinkItem[];

        NavigationHelper.setBreadcrumbItems(items);
    }
}

export const Edit = withRouter(EditComp)