import { link } from "node:fs/promises";
import * as React from "react";
import { withRouter } from "react-router";
import { Breadcrumb, BreadcrumbItem } from "reactstrap";
import { ComponentBase } from "./ComponentBase";


interface BreadCrumbState {
    linkItems: LinkItem[],
};

class BreadCrumbComp extends ComponentBase<any, BreadCrumbState> {

    
    constructor(props: any) {
        super(props);

        this.state = {
            linkItems: []
        };

        NavigationHelper.SetAddItemHandler((items: LinkItem[]) => this.breadCrumbSetItems(items));
        NavigationHelper.SetClearItemsHandler(() => this.breadCrumClearItems());
    }

    breadCrumbSetItems(items: LinkItem[]) {
        var state = this._cloneStateForSetState();
        state.linkItems = [];
        items.forEach((item, index) => {
            state.linkItems.push(item);
        })
        this.setState(state);
    }

    breadCrumClearItems() {
        this.setState({
            linkItems:[]
        });
    }



    public render(): JSX.Element {

        if (this.state.linkItems.length == 0) return <React.Fragment />;

        return <Breadcrumb listTag="div">
            <BreadcrumbItem
                href="/"
                onClick={() => this.props.history.push("/")}
                tag="a"
            >
                Home
            </BreadcrumbItem>
            {this.state.linkItems.map((item, index) => {

                var isLastItem = index == this.state.linkItems.length - 1;

                return <BreadcrumbItem key={index}
                    href={"#"}
                    onClick={() => {
                        if (isLastItem) return null;

                        if ((item.link || []).length !== 0) {
                            this.props.history.push(item.link)
                        }
                    }}
                    tag={isLastItem ? "span": "a"}
                >
                    {item.text}
                </BreadcrumbItem>
            })}
        </Breadcrumb>;

    }


}

export const SHubBreadcrumb = withRouter(BreadCrumbComp)

/**
* Cada uno de los items extras a añadir tras el "breadcrumb" estándar
*/
export class LinkItem {
    public text: string;
    public link: string;
}

export class NavigationHelper {

    constructor() {
        this._items = [];
    }

    private _items: LinkItem[];

    private static SetItems: (links: LinkItem[]) => void;

    private static ClearItems: () => void;


    public static SetAddItemHandler(fnAddItem: (links: LinkItem[]) => void) {
        this.SetItems = fnAddItem;
    }


    public static setBreadcrumbItems(links: LinkItem[]) {
        if (this.SetItems != null) {
            this.SetItems(links);
        }
    }


    public static SetClearItemsHandler(fnClearItems: () => void) {
        this.ClearItems = fnClearItems;
    }


    public static clearItems() {
        if (this.ClearItems != null) {
            this.ClearItems();
        }
    }

}