import * as React from "react";
import {
    Card, CardHeader, CardText, CardBody, Modal, ModalHeader, ModalFooter, Button, ModalBody, Row, Col
} from 'reactstrap';
import { I18n } from "../../utils/I18n";
import { EditIcon, ViewIcon } from "../../utils/Icons";
import { cloneDeep, set } from "lodash-es";
import { ClaseMateriaService } from "../materia/ClaseMateriaService";
import { Loading } from "../../utils/Loading";
import { LicenciasContenidosListItemPaginatedList, LicenciasContenidosListItem } from "../../models/dataModels";
import { Link } from "react-router-dom";
import { RouteHelper } from "../../IntranetRouter";
import { UsuarioEditTabs } from "../../colegioUsuarios/Edit";

class LicenciasState {
    public loading: boolean;
    public showModal: boolean;
    public items: LicenciasContenidosListItem[];
}

class LicenciasProps {
    public numLicencias: number;
    public claseMateriaId: number;
    public claseId: number;
}

export class Licencias extends React.Component<LicenciasProps, LicenciasState> {

    public constructor(props: any) {
        super(props);

        this.state = {
            loading: false,
            showModal: false,
            items: null,
        };
    }

    public render(): JSX.Element {

        return (
            <div>
                {this.state.loading && <Loading />}
                {this.renderPopUp()}
                {I18n.Strings.cursoEscolar.numeroLicencias(this.props.numLicencias)}
                <a href="javaScript:void(0)"
                    title={"ver listado"}
                    className={"btn-niveles btn-borrarnivel"}
                    style={{ border: "none", marginLeft: "5px" }}
                    onClick={() => {
                        this.showModal();
                    }}>
                    <ViewIcon />
                </a>
            </div>
        );
    }

    protected showModal() {
        var state = this._cloneStateForSetState();
        state.loading = true;
        state.showModal = true;
        this.setState(state, () => {
            var service = new ClaseMateriaService();
            service.getClaseMateriaLicencias(this.props.claseMateriaId)
                .then(ret => {
                    this.setState({ loading: false, items: ret.items });
                });
        });
    }

    protected renderPopUp(): JSX.Element {
        return <Modal
            title={I18n.Strings.cursoEscolar.numeroLicencias(this.props.numLicencias)}
            isOpen={this.state.showModal}
            size="lg"
            toggle={() => { this.setState({ showModal: !this.state.showModal }) }}
        >
            {
                <ModalHeader close={<button className="btn-close" onClick={() => this.setState({ showModal: false })} />}>
                    {I18n.Strings.cursoEscolar.numeroLicencias(this.props.numLicencias)}
                </ModalHeader>
            }
            <ModalBody>
                {(this.state.items || []).length == 0 && <Row>
                    <p>No hay licencias</p>
                </Row>}

                {(this.state.items || []).length != 0 && <Row>
                    <Col md={12} className="notificaciones-items">

                        <table className="table table-striped table-hover table-sm">
                            <thead>
                                <tr>
                                    <th></th>
                                    <th>Usuario</th>
                                    <th>Credenciales</th>
                                </tr>
                            </thead>
                            <tbody>
                                {this.state.items.map((item, index) => {
                                    return <tr key={index}>
                                        <td>
                                            <Link to={RouteHelper.editUsuarioColegio(item.usuarioId, UsuarioEditTabs.Datos)}>
                                                <EditIcon />
                                            </Link>
                                        </td>
                                        <td>
                                            {item.usuario}
                                        </td>
                                        <td>
                                            {item.credenciales}
                                        </td>
                                    </tr>;
                                })}
                            </tbody>
                        </table>
                    </Col>
                </Row>}
            </ModalBody>
            <ModalFooter>
                <Button color="primary" size="lg" onClick={() => this.setState({ showModal: false })}>
                    {I18n.Strings.cancel}
                </Button>
            </ModalFooter>
        </Modal>;
    }

    protected _cloneStateForSetState(): LicenciasState {
        return cloneDeep(this.state) as LicenciasState;
    }
}