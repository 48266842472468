import nameof from "ts-nameof.macro";
import { NivelProductosTiendasListItem, SortDirection } from "../models/dataModels";
import { DropDownBase, GenericSelectItem } from "../utils/base/DropDownBase";
import { ClientContext } from "../utils/ClientContext";
import { NivelContenidoDropDownDropDownProps } from "./NivelContenidoDropDown";
import { NivelProductosTiendaService } from "./NivelProductosTiendaService";



export class NivelProductoTiendaDropDown extends DropDownBase<NivelContenidoDropDownDropDownProps> {



    protected GetItems(): Promise<GenericSelectItem[]> {

        var item = {} as NivelProductosTiendasListItem;

        var nivelContenidoSrv = new NivelProductosTiendaService();
        var dataProm = nivelContenidoSrv.search({
            colegioId: ClientContext.Current.colegioId,
            claseId: this.props.claseId,
            cursoEscolarId: this.props.cursoEscolarId,
            productoTiendaId: null,
            nivelId: null,
            noTotalCount: true,
            currentPage: 0,
            rowsPerPage: -1,
            orderByColumn: nameof(item.productoTiendaSku),
            orderMode: SortDirection.Asc,
            bypassAutomaticSort: false,
            currentPageRows: -1
        });

        var resultProm = dataProm.then(data => {
            return data.items.map((item, index) => {
                return {
                    id: item.id,
                    name: `${item.productoTiendaSku} - ${item.productoTienda}`,
                    data: item
                };
            });
        });

        return Promise.resolve(resultProm);
    }

    protected shouldAddEmptyOption() {
        return true;
    }
}
