import * as React from "react";

import nameof from "ts-nameof.macro";

import { withRouter } from "react-router-dom";
import { AccedePlataforma, NivelContenidosEditModel, TipoAcceso } from "../models/dataModels";
import { GenericEditState } from "../utils/base/GenericEditState";
import { EditPageBase } from "../utils/base/EditPageBase";
import { IServiceForEdit } from "../utils/base/BaseServices";
import { NivelContenidosService } from "./NivelesContenidosService";
import { RouteHelper } from "../IntranetRouter";
import { Col, FormGroup, Input, Label, Row } from "reactstrap";
import { I18n } from "../utils/I18n";
import { ModelValidation } from "../utils/base/ModelValidation";

import { ColegioUsuariosPicker } from "../colegioUsuarios/Picker";
import { NivelEducativoDropDown } from "../cursoEscolar/NivelEducativoDropDown";
import { ClientContext } from "../utils/ClientContext";
import { AccedePlataformaDropDown, TipoAccesoDropDownAll } from "../helpers/EnumDropDown";
import { ContenidosPicker } from "../contenidos/colegio/Picker";
import { ContenidosService } from "../contenidos/maestros/ContenidosService";

import { Location as HistoryLocation } from "history";

export class NivelContenidoEditComp extends EditPageBase<NivelContenidosEditModel> {

    protected _setPageTitleForNew(): string {
        return "Nuevo"
    }

    protected _setPageTitleForExisting(editModel: NivelContenidosEditModel): string {
        return `Editar ${editModel.nivelEducativo}`;
    }

    protected _createApiService(): IServiceForEdit<NivelContenidosEditModel> {
        return new NivelContenidosService();
    }

    protected _generateForm(): JSX.Element {
        return <React.Fragment>
            <Row>
                <Col md="4">

                    <ContenidosPicker
                        label={I18n.Strings.licenciasContenido.contenido}
                        required={true}
                        itemId={this.state.editModel.contenidoId}
                        itemName={this.state.editModel.contenido}
                        getIdFromItem={(item) => item.id}
                        getNameFromItem={(item) => {
                            var enumName = I18n.Strings.getEnumText(`Editorial.${item.editorial}`);
                            return `${enumName} - ${item.nombre}`;
                        }}
                        cursoEscolarId={ClientContext.Current.cursoEscolarActivoId}
                        nivelEducativoId={null}
                        mostrarNiveles={true}
                        mostrarSoloColegio={false}
                        errorMessage={this.state.validationResult.getError(nameof(this.state.editModel.contenidoId))}
                        onSelected={(contenidoId, contenido, item) => {
                            var state = this._cloneStateForSetState();
                            state.editModel.contenidoId = contenidoId;
                            state.editModel.contenido = contenido;

                            this.setState(state);
                        }}
                    />

                </Col>
                <Col md="4">
                    <FormGroup>
                        <Label for={nameof(this.state.editModel.nivelEducativo)}>
                            {I18n.Strings.matriculas.nombreClase}
                        </Label>

                        <NivelEducativoDropDown
                            id={nameof(this.state.editModel.nivelEducativoId)}

                            cursoEscolarId={ClientContext.Current.cursoEscolarActivoId}
                            value={this.state.editModel.nivelEducativoId}

                            text={this.state.editModel.nivelEducativo}
                            seleccionClases={false}
                            onChange={(id, nombre) => {
                                var state = this._cloneStateForSetState();
                                state.editModel.nivelEducativoId = id;
                                state.editModel.nivelEducativo = nombre;
                                this.setState(state);
                            }}
                        />

                        {this._errorMessage(nameof(this.state.editModel.nivelEducativoId))}
                    </FormGroup>
                </Col>
                <Col md="4">
                    <TipoAccesoDropDownAll
                        label={I18n.Strings.nivelesContenidos.tipoAccesoPorDefecto}
                        showLabel={true}
                        id={nameof(this.state.editModel.tipoAccesoPorDefecto)}
                        value={this.state.editModel.tipoAccesoPorDefecto}
                        required={true}
                        errorMessage={this.state.validationResult.getError(nameof(this.state.editModel.tipoAccesoPorDefecto))}
                        onChanged={(value: string) => {
                            var newState = this._cloneStateForSetState();
                            if (value != '') {
                                newState.editModel.tipoAccesoPorDefecto = value as TipoAcceso;
                            }
                            else {
                                newState.editModel.tipoAccesoPorDefecto = null;
                            }
                            this.setState(newState);
                        }}
                    />
                </ Col>
                <Col md="4">
                    <FormGroup>
                        <Label for={"nivelEducativo" + nameof(this.state.editModel.mostrarAccesoDirecto)}>{I18n.Strings.nivelesContenidos.mostrarAccesoDirecto}</Label>
                        <Input
                            onChange={(evt) => {
                                var state = this._cloneStateForSetState();
                                state.editModel.mostrarAccesoDirecto = evt.target.checked;
                                this.setState(state);
                            }}
                            type="checkbox"
                            style={{ padding: 0 }}
                            className={"form-control"}
                            name={nameof(this.state.editModel.mostrarAccesoDirecto)}
                            id={"nivelEducativo" + nameof(this.state.editModel.mostrarAccesoDirecto)}
                            checked={this.state.editModel.mostrarAccesoDirecto || false}
                        />
                    </FormGroup>
                </Col>
                <Col md="4">
                    <FormGroup>
                        <AccedePlataformaDropDown
                            label={I18n.Strings.nivelesContenidos.accedePlataforma}
                            showLabel={true}
                            id={"nivelEducativo" + nameof(this.state.editModel.accedePlataforma)}
                            value={this.state.editModel.accedePlataforma}
                            className={"form-control"}
                            disabled={true}
                            required={true}
                            onChanged={(value: AccedePlataforma) => {
                                var newState = this._cloneStateForSetState();
                                newState.editModel.accedePlataforma = value;
                                if (value != AccedePlataforma.NoAccedeMuestraMensaje) {
                                    newState.editModel.mensajeAcceso = "";
                                }
                                this.setState(newState);
                            }}
                        />

                    </FormGroup>
                </Col>
                <Col md="6">
                    <FormGroup>
                        <Label for={nameof(this.state.editModel.mensajeAcceso)}>
                            {I18n.Strings.colegiocredenciales.mensajeDeshabilitarAcceso}
                        </Label>
                        <Input
                            onChange={(evt) => {
                                var state = this._cloneStateForSetState();
                                state.editModel.mensajeAcceso = evt.target.value;
                                this.setState(state);
                            }}
                            type="textarea"
                            required={this.state.editModel.tipoAccesoPorDefecto == TipoAcceso.NoAccedeMuestraMensaje}
                            disabled={this.state.editModel.tipoAccesoPorDefecto != TipoAcceso.NoAccedeMuestraMensaje}
                            maxLength={500}
                            className={"form-control " + this._errorClass(nameof(this.state.editModel.mensajeAcceso))}
                            name={nameof(this.state.editModel.mensajeAcceso)}
                            id={nameof(this.state.editModel.mensajeAcceso)}
                            value={this.state.editModel.mensajeAcceso || ""}
                        />
                        {this._errorMessage(nameof(this.state.editModel.mensajeAcceso))}
                    </FormGroup>
                </Col>
            </Row>
        </React.Fragment>;
    }

    protected _skipExitPrompt(location: HistoryLocation): boolean {
        if (location.pathname == RouteHelper.editUsuarioColegio(this.state.editModel.id)) {
            return true;
        }
        return super._skipExitPrompt(location);
    }

    protected _getEditUrl(id: number): string {
        return RouteHelper.editNivelContenido(id, this.props.match.params.contenidoId);
    }

    protected _getListUrl(): string {
        return RouteHelper.listNivelesContenidos(this.props.match.params.contenidoId);
    }

    protected _createStateForNew(): Promise<GenericEditState<NivelContenidosEditModel>> {

        var model = new GenericEditState<NivelContenidosEditModel>();
        model.editModel = {
            id: 0,
            colegioId: 0,
            contenido: "",
            contenidoId: null,
            nivelEducativo: "",
            nivelEducativoId: null,
            mostrarAccesoDirecto: true
        } as NivelContenidosEditModel;

        if (this.props.match.params.contenidoId != null) {
            let contenidosSrv = new ContenidosService();
            let promData = contenidosSrv.getBasicById(this.props.match.params.contenidoId);

            let modelWithDataProm = promData.then(data => {
                model.editModel.contenido = data.nombre;
                model.editModel.contenidoId = data.id;
                return model;
            });

            return modelWithDataProm;
        }

        return Promise.resolve(model);
    }

    protected _validateModelLocal(): ModelValidation {
        let validation = new ModelValidation();

        if (this.state.editModel.nivelEducativoId == null) {
            validation.addError(nameof(this.state.editModel.nivelEducativoId), I18n.Strings.validationErrors.valueRequired(I18n.Strings.nivelesContenidos.nivel));
        }

        if (this.state.editModel.contenidoId == null) {
            validation.addError(nameof(this.state.editModel.contenidoId), I18n.Strings.validationErrors.valueRequired(I18n.Strings.nivelesContenidos.contenido));
        }

        return validation;
    }

}

export const Edit = withRouter(NivelContenidoEditComp)