import * as React from "react";
import nameof from "ts-nameof.macro";

import { Row, Col, FormGroup, Label, Input, Button } from "reactstrap";

import { ColegioCredencialesEditorialesService } from "./ColegioCredencialesEditorialesService";
import { I18n } from "../utils/I18n";
import { Editorial, ColegioCredencialesEditorialesListItem, ColegioCredencialesEditorialesListItemPaginatedList, ColegioCredencialesEditorialesSearch } from "../models/dataModels";
import { IServiceForIndex } from "../utils/base/BaseServices";
import { IndexPageBase, TableColumn } from "../utils/base/IndexPageBase";
import { RouteHelper } from "../IntranetRouter";
import { EditorialDropDown } from "../helpers/EnumDropDown";
import { withRouter } from "react-router-dom";
import { AddNewIcon } from "../utils/Icons";


export class IndexComp extends IndexPageBase<ColegioCredencialesEditorialesSearch, ColegioCredencialesEditorialesListItemPaginatedList, ColegioCredencialesEditorialesListItem>
{

    protected _renderSearchForm(): React.ReactNode {
        return <React.Fragment>
            <Col xs="12" md="6" lg="4">
                <FormGroup>
                    <Label className="visually-hidden" for={"credenciales" + nameof(this.state.searchParams.nombre)}>
                        {I18n.Strings.colegioUsuarios.nombre}
                    </Label>
                    <Input
                        onChange={(evt) => {
                            var state = this._cloneStateForSetState();
                            state.searchParams.nombre = evt.target.value;
                            this.setState(state);
                        }}
                        placeholder={I18n.Strings.colegioUsuarios.nombre + "..."}
                        type="text"
                        maxLength={100}
                        className={"form-control"}
                        name={nameof(this.state.searchParams.nombre)}
                        id={"credenciales" + nameof(this.state.searchParams.nombre)}
                        value={this.state.searchParams.nombre || ""}
                    />
                </FormGroup>
            </Col>
            <Col xs="12" md="6" lg="3">
                <EditorialDropDown
                    emptyText={I18n.Strings.colegiocredenciales.editorial + "..."}
                    label={I18n.Strings.colegiocredenciales.editorial}
                    id={nameof(this.state.searchParams.editorial)}
                    value={this.state.searchParams.editorial}
                    required={false}
                    onChanged={(value: string) => {
                        var newState = this._cloneStateForSetState();
                        if (value != '') {
                            newState.searchParams.editorial = value as Editorial;
                        }
                        else {
                            newState.searchParams.editorial = null;
                        }
                        this.setState(newState);
                    }}
                />
            </Col>

        </React.Fragment>;
    }

    protected _getApiService(): IServiceForIndex<ColegioCredencialesEditorialesSearch, ColegioCredencialesEditorialesListItemPaginatedList> {
        return new ColegioCredencialesEditorialesService();
    }

    protected _getTableColumns(): TableColumn<ColegioCredencialesEditorialesListItem>[] {
        return [
            {
                fieldName: nameof(this.state.searchResults.items[0].nombre),
                title: I18n.Strings.colegiocredenciales.nombre,
                renderField: (item) => item.nombre
            },
            {
                fieldName: nameof(this.state.searchResults.items[0].editorial),
                title: I18n.Strings.colegiocredenciales.editorial,
                renderField: (item) => {
                    return I18n.Strings.getEnumText(`Editorial.${item.editorial}`);
                }
            },
            {
                fieldName: nameof(this.state.searchResults.items[0].tipoLicencia),
                title: I18n.Strings.colegiocredenciales.tipoLicencia,
                renderField: (item) => {
                    return I18n.Strings.getEnumText(`TipoAcceso.${item.tipoLicencia}`);
                }
            }
        ];
    }

    protected _getPageTitle(): string {
        return "Credenciales del colegio"
    }
    protected _getPageDescription(): string {
        return "Aqui ves las credenciales de las editoriales del colegio";
    }


    protected _getEditionItemUrl(item: ColegioCredencialesEditorialesListItem): string {
        return RouteHelper.editCredenciales(item.id);
    }

    protected _getNewItemUrl(): string {
        return RouteHelper.createCredenciales();
    }

    protected _toolbarRightContents(): JSX.Element[] {
        var elements = super._toolbarRightContents();

        elements.push(<Button
            key="createDefaultExport"
            className="btn-rounded"
            color="secondary"
            onClick={() => this.generateDefaultCredentials()}
        >
           
            {I18n.Strings.colegiocredenciales.createDefault}
        </Button>);

        return elements;
    }

    /**
     * Genera las credenciales por defecto de las editoriales, en caso de que no existan
     */
    private generateDefaultCredentials() {
        var service = new ColegioCredencialesEditorialesService();
        var prom = service.generateDefaultCredentials();
        prom.then(p => this._performSearch())
            .catch(error => this._showError(error));
    }
}

export const Index = withRouter(IndexComp);