import { ClientContext } from "./ClientContext";
import { esES, ITranslationContent } from "./langs/ITranslationContent";
import { InterfaceLanguage } from "../models/dataModels";

export class I18n {


    public static get Strings(): ITranslationContent {
        if (this._strings == null) {
            if (ClientContext.Current == null) {
                I18n.Initialize(InterfaceLanguage.Espannol);
            }
            else {
                I18n.Initialize(ClientContext.Current.idioma)
            }
        }

        return this._strings;
    }

    private static _strings: ITranslationContent;

    public static Initialize(language: InterfaceLanguage) {

        let content: ITranslationContent;

        switch (language) {
            case InterfaceLanguage.Espannol: {
                content = new esES();
                break;
            }          
            default: {
                throw new Error(`Language ${language} not implemented`)
            }
        }

        this._strings = content;
    }

    public static logAndExtractInfoFromException(error: any) {

        console.error(error);

        let mensaje = error.message;

        if (error.response && error.response.data && error.response.data.error) {
            mensaje = error.response.data.error;
        }

        return mensaje;
    }
}
