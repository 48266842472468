import * as React from "react";
import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from "reactstrap";


import { I18n } from "./I18n";

class ResultProps {
    messageToShow: string;
    onDismiss: () => void;
}


export class ResultOk extends React.Component<ResultProps, any> {
    public render(): JSX.Element {
        if (this.props.messageToShow == null || this.props.messageToShow == "") {
            return null;
        }
        return (

            <Modal isOpen={true} toggle={() => this.props.onDismiss()}>
                <ModalHeader
                    close={<button className="btn-close" onClick={() => this.props.onDismiss()} />}
                >
                    </ModalHeader>
                <ModalBody>
                    <div dangerouslySetInnerHTML={{ __html: this.props.messageToShow }}></div>
                </ModalBody>
                <ModalFooter>
                    <Button onClick={() => this.props.onDismiss()} >
                        { I18n.Strings.ok }
                    </Button>
                </ModalFooter>
            </Modal>
        );
    }
}

export class ResultError extends React.Component<ResultProps, any> {

    public render(): JSX.Element {

        if (this.props.messageToShow == null || this.props.messageToShow == "") {
            return null;
        }
        return (
            <Modal isOpen={true} toggle={() => this.props.onDismiss()}>
                <ModalHeader
                    
                    close={<button className="btn-close" onClick={() => this.props.onDismiss()} />}
                >
                </ModalHeader>
                <ModalBody>
                    {this.props.messageToShow}
                </ModalBody>
                <ModalFooter>
                    <Button onClick={() => this.props.onDismiss()} >
                        {I18n.Strings.ok}
                    </Button>
                </ModalFooter>
            </Modal>

        );
    }
}

export class ShowMessage extends React.Component<ResultProps, any> {

    public render(): JSX.Element {

        if (this.props.messageToShow == null || this.props.messageToShow == "") {
            return null;
        }

        return (
            <Modal isOpen={true} toggle={() => this.props.onDismiss()}>
                <ModalHeader
                    close={<button className="btn-close" onClick={() => this.props.onDismiss()} />}
                >
                </ModalHeader>
                <ModalBody>
                    <div
                        dangerouslySetInnerHTML={{ __html: this.props.messageToShow }}
                    />
                </ModalBody>
                <ModalFooter>
                    <Button onClick={() => this.props.onDismiss()} >
                        {I18n.Strings.ok}
                    </Button>
                </ModalFooter>
            </Modal >
        );
    }
}