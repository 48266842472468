import * as React from "react";
import { withRouter } from "react-router-dom";
import { Col, FormGroup, Label } from "reactstrap";
import nameof from "ts-nameof.macro";
import { ColegioUsuariosPicker } from "../colegioUsuarios/Picker";
import UserNameToolTip from "../colegioUsuarios/UserNameToolTip";
import { ClaseMateriaDropDown } from "../cursoEscolar/materia/ClaseMateriaDropDown";
import { NivelEducativoDropDown } from "../cursoEscolar/NivelEducativoDropDown";
import { RouteHelper } from "../IntranetRouter";
import { AliasEditorialContenidosListItem, AliasEditorialContenidosListItemPaginatedList, AliasEditorialContenidosSearch } from "../models/dataModels";
import { IServiceForIndex } from "../utils/base/BaseServices";
import { IndexPageBase, TableColumn } from "../utils/base/IndexPageBase";
import { ClientContext } from "../utils/ClientContext";
import { I18n } from "../utils/I18n";
import { AliasEditorialContenidosService } from "./AliasEditorialContenidosService";


class AliasEditorialesContenidosComp extends IndexPageBase<AliasEditorialContenidosSearch, AliasEditorialContenidosListItemPaginatedList, AliasEditorialContenidosListItem>
{
    protected _renderSearchForm(): React.ReactNode {
        if (this.props.isEmbedded) {
            return null;
        }
        return <React.Fragment>            
        </React.Fragment>;
    }

    protected _getApiService(): IServiceForIndex<AliasEditorialContenidosSearch, AliasEditorialContenidosListItemPaginatedList> {
        return new AliasEditorialContenidosService();
    }

    protected _getTableColumns(): TableColumn<AliasEditorialContenidosListItem>[] {
        return [
            {
                fieldName: nameof(this.state.searchResults.items[0].colegio),
                title: I18n.Strings.aliasEditorial.colegio,
                renderField: (item) => item.colegio
            },
            {
                fieldName: nameof(this.state.searchResults.items[0].nivelEducativo),
                title: I18n.Strings.aliasEditorial.nivelEducativo,
                renderField: (item) => item.nivelEducativo
            },
            {
                fieldName: nameof(this.state.searchResults.items[0].contenido),
                title: I18n.Strings.aliasEditorial.contenido,
                renderField: (item) => item.contenido,
            },
            {
                fieldName: nameof(this.state.searchResults.items[0].tipoContenido),
                title: I18n.Strings.aliasEditorial.tipoContenido,
                renderField: (item) => I18n.Strings.getEnumText(`TipoContenido.${item.tipoContenido}`),
            },
            {
                fieldName: nameof(this.state.searchResults.items[0].isbn),
                title: I18n.Strings.aliasEditorial.isbn,
                renderField: (item) => item.isbn,
            },
            
            
        ];
    }

    protected _getPageTitle(): string {
        return null;
    }
    protected _getPageDescription(): string {
        return null;
    }

   
    protected _getEditionItemUrl(item: AliasEditorialContenidosListItem): string {
        return null;
    }

    protected _getNewItemUrl(): string {
        return null;
    }

}

export const AliasEditorialesContenidos = withRouter(AliasEditorialesContenidosComp);