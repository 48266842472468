import * as React from "react";
import { withRouter } from "react-router-dom";
import nameof from "ts-nameof.macro";
import { RouteHelper } from "../../IntranetRouter";
import { AlumnoListItem, AlumnoListItemPaginatedList, AlumnosDeClaseSearch } from "../../models/dataModels";
import { IServiceForIndex } from "../../utils/base/BaseServices";
import { IndexPageBase, TableColumn } from "../../utils/base/IndexPageBase";
import { I18n } from "../../utils/I18n";
import { AlumnosService } from "./AlumnosService";



interface AlumnosDeClaseSearchEx extends AlumnosDeClaseSearch {
    nivelEducativo?: string;
    clase?: string;
}

class AlumnosComp extends IndexPageBase<AlumnosDeClaseSearchEx, AlumnoListItemPaginatedList, AlumnoListItem>
{
    protected _getInitialSearchParams(): AlumnosDeClaseSearch {
        var search = {
            claseMateriaId: this.props.searchModel?.claseMateriaId,
            repetidor: this.props.searchModel?.repetidor,
        } as AlumnosDeClaseSearch;

        return search;
    }

    protected _getApiService(): IServiceForIndex<AlumnosDeClaseSearchEx, AlumnoListItemPaginatedList> {
        return new AlumnosService();
    }

    protected _getTableColumns(): TableColumn<AlumnoListItem>[] {
        return [
            {
                fieldName: nameof(this.state.searchResults.items[0].nombre),
                title: I18n.Strings.colegioUsuarios.nombre,
                renderField: (item) => item.nombre + ' ' + item.apellidos,
                colMdWidth: 7
            },
            
            {
                fieldName: nameof(this.state.searchResults.items[0].haAccedido),
                title: I18n.Strings.colegioUsuarios.activo,
                renderField: (item) => item.haAccedido ? "Sí" : "No",
                colMdWidth: 2
            },
            {
                fieldName: nameof(this.state.searchResults.items[0].fechaUltimoAcceso),
                title: I18n.Strings.colegioUsuarios.fechaUltimoAcceso,
                renderField: (item) => I18n.Strings.formatLastAccessDate(item.fechaUltimoAcceso),
                colMdWidth: 3
            }
        ];
    }

    protected _renderSearchForm(): React.ReactNode {
        return null;
    }

    protected _getPageTitle(): string {
        return I18n.Strings.grupoClase.misClasesProfesorTitulo;
    }
    protected _getPageDescription(): string {
        return I18n.Strings.grupoClase.misClasesProfesorTexto;
    }

   
    protected _getEditionItemUrl(item: AlumnoListItem): string {
        return RouteHelper.editMiClaseProfesor(item.id);
    }

    protected _getNewItemUrl(): string {
        return null;
    }

}

export const Alumnos = withRouter(AlumnosComp);