import * as React from "react";
import { Col, Row, FormFeedback } from "reactstrap";


import { I18n } from "../utils/I18n";
import { ComponentBase } from "../utils/base/ComponentBase";

export interface IDateSelectorProps {
    value: Date;
    className?: string;
    onChange: (value: Date) => void;
    id?: string;

    yearsBack?: number;
    yearsFront?: number;
    disabled?: boolean;
}



export interface IDateSelectorState {
    day: number;
    month: number;
    year: number;
    showAlert: boolean;
}


export abstract class CustomDatePicker extends ComponentBase<IDateSelectorProps, IDateSelectorState>
{

    private days: number[];
    private months: number[];
    private years: number[];

    public constructor(props: IDateSelectorProps) {
        super(props);


        this.days = [];
        for (let i = 1; i <= 31; i++) {
            this.days.push(i);
        }

        this.months = [];
        for (let i = 0; i < 12; i++) {
            this.months.push(i);
        }

        var today = new Date();
        var yearLatest = today.getFullYear() + (props.yearsFront == null ? 2 : props.yearsFront);
        var yearFirst = today.getFullYear() + (-(props.yearsBack == null ? 55 : props.yearsBack));

        this.years = [];

        for (let i = yearLatest; i > yearFirst; i--) {
            this.years.push(i);
        }

        this.state = {
            day: props.value == null ? null : props.value.getDate(),
            month: props.value == null ? null : props.value.getMonth(),
            year: props.value == null ? null : props.value.getFullYear(),
            showAlert: false
        }
    }


    public render(): JSX.Element {

        var monthName = this.state.month == null ? I18n.Strings.datepicker.month : I18n.Strings.datepicker.monthNames[this.state.month]

        return <div className="dateBlock" id={this.props.id}>
            <Row>
                <Col md={12}>
                    <div className="dateComponent">
                        <select disabled={this.props.disabled}
                            id={this.props.id}
                            defaultValue={this.state.day == null ? null : this.state.day.toString()}
                            onChange={(evt) => {
                                if (evt.target.value != '') {
                                    var newState = this._cloneStateForSetState();
                                    newState.day = parseInt(evt.target.value);
                                    this.setState(newState);
                                }
                            }}
                            className="form-select"
                        >
                            <option value="-1"> {I18n.Strings.datepicker.day} </option>
                            {this.days.map((day, index) => {
                                return <option
                                    key={index}
                                    value={day.toString()}
                                >
                                    {day.toString()}
                                </option>
                            })}
                        </select>
                    </div>
                    <div className="dateComponent">
                        <select disabled={this.props.disabled}
                            id={this.props.id}
                            defaultValue={this.state.month == null ? null : this.state.month.toString()}
                            onChange={(evt) => {
                                if (evt.target.value != '') {
                                    var newState = this._cloneStateForSetState();
                                    newState.month = parseInt(evt.target.value);
                                    this.setState(newState);
                                }

                            }}
                            className="form-select"
                        >
                            <option value="-1"> {I18n.Strings.datepicker.month} </option>
                            {this.months.map((month, index) => {

                                return <option
                                    key={index}
                                    value={month.toString()}
                                >
                                    {I18n.Strings.datepicker.monthNames[index]}
                                </option>
                            })}
                        </select>
                    </div>
                    <div className="dateComponent">
                        <select disabled={this.props.disabled}
                            id={this.props.id}
                            defaultValue={this.state.year == null ? null : this.state.year.toString()}
                            onChange={(evt) => {
                                if (evt.target.value != '') {
                                    var newState = this._cloneStateForSetState();
                                    newState.year = parseInt(evt.target.value);
                                    this.setState(newState);
                                }

                            }}
                            className="form-select"
                        >
                            <option value="-1" > {I18n.Strings.datepicker.year} </option>
                            {this.years.map((year, index) => {
                                return <option
                                    key={index}
                                    value={year.toString()}
                                >
                                    {year.toString()}
                                </option>
                            })}
                        </select>
                    </div>
                </Col>
            </Row>
            <Row>
                <Col md={12}>
                    {this.state.showAlert && <FormFeedback>{I18n.Strings.validationErrors.invalidDate}</FormFeedback>}
                </Col>
            </Row>
        </div>;
    }

    public setState(state: IDateSelectorState) {
        let fechaCorrecta = false;
        if (state.day != null && state.month != null && state.year != null && state.day != -1 && state.month != -1 && state.year != -1) {

            var date = new Date(state.year, state.month, state.day);

            if (state.month != date.getMonth()) {
                state.showAlert = true;


            } else {
                state.showAlert = false;
                fechaCorrecta = true;
            }
        }

        super.setState(state, () => {
            if (fechaCorrecta) {
                 date.setHours(12);
                this.props.onChange(date);
            }
            else {
                this.props.onChange(null);
            }
        })
    }

}