import * as React from "react";

import nameof from "ts-nameof.macro";

import { withRouter } from "react-router-dom";
import { ColegioUsuarioEditModel, TipoUsuario, EstadoUsuario, Origen, MatriculasSearch, EntradaAuditoriaSearch } from "../models/dataModels";
import { GenericEditState } from "../utils/base/GenericEditState";
import { EditPageBase } from "../utils/base/EditPageBase";
import { IServiceForEdit } from "../utils/base/BaseServices";
import { ColegioUsuariosService } from "./ColegioUsuariosService";
import { RouteHelper } from "../IntranetRouter";
import { Alert, Col, FormGroup, Input, Label, Nav, NavItem, NavLink, Row, TabContent, TabPane, Button, DropdownItem, FormFeedback } from "reactstrap";
import { I18n } from "../utils/I18n";
import { ModelValidation } from "../utils/base/ModelValidation";
import { DocumentUpload } from "../helpers/DocumentUpload";
import { TipoUsuarioDropDown } from "../helpers/EnumDropDown";
import { NivelEducativoDropDown } from "../cursoEscolar/NivelEducativoDropDown";
import { ClientContext } from "../utils/ClientContext";
import { CheckIcon, DeleteIcon } from "../utils/Icons";
import { DeleteModal } from "../utils/DeleteModal";

import { Index as LicenciasColegioIndex, LicenciasContenidosSearchEx } from "../licenciasColegio/Index";
import { Index as MatriculasIndex } from "../matriculas/Index";
import { TabEstadisticasAccesos } from "./TabEstadisticasAccesos";
import { TabEstadisiticasContenidos } from "./TabEstadisticasContenidos";
import { Location as HistoryLocation } from "history";
import { AlertsService } from "../utils/AlertsService";
import { NavigationHelper } from "../utils/base/Breadcrumb";
import { ImpersonationService } from "../login/Impersonate";
import { DropDownButton } from "../helpers/DropDownButton";
import { EntradaAuditoriaSearchEx, Index as AuditoriaIndex } from "../auditoria/Index";

export enum UsuarioEditTabs {
    Datos = "datos",
    Licencias = "licencias",
    Matriculas = "matriculas",
    EstadisticasAccesos = "estadisticas-accesos",
    EstadisticasContenidos = "estadisticas-contenidos",
    Auditoria = "auditoria"
}

interface ColegioUsuarioEditModelEx extends ColegioUsuarioEditModel {
    borradoFisico?: boolean;
}

export class EditComp extends EditPageBase<ColegioUsuarioEditModelEx> {

    protected _setPageTitleForNew(): string {
        return "Nuevo usuario";
    }

    protected _setPageTitleForExisting(editModel: ColegioUsuarioEditModel): string {
        return `Editar ${editModel.nombre} ${editModel.apellidos}`;
    }

    protected _createApiService(): IServiceForEdit<ColegioUsuarioEditModel> {
        return new ColegioUsuariosService() as any as IServiceForEdit<ColegioUsuarioEditModel>;
    }

    protected _skipExitPrompt(location: HistoryLocation): boolean {

        if (location.pathname == RouteHelper.editUsuarioColegio(this.state.editModel.id, UsuarioEditTabs.Datos) ||
            location.pathname == RouteHelper.editUsuarioColegio(this.state.editModel.id, UsuarioEditTabs.Licencias) ||
            location.pathname == RouteHelper.editUsuarioColegio(this.state.editModel.id, UsuarioEditTabs.Matriculas) ||
            location.pathname == RouteHelper.editUsuarioColegio(this.state.editModel.id, UsuarioEditTabs.EstadisticasAccesos) ||
            location.pathname == RouteHelper.editUsuarioColegio(this.state.editModel.id, UsuarioEditTabs.EstadisticasContenidos) ||
            location.pathname == RouteHelper.editUsuarioColegio(this.state.editModel.id)) {
            return true;
        }
        return super._skipExitPrompt(location);
    }

    protected _generateForm(): JSX.Element {

        if ((this.state.editModel.id || 0) == 0 || this.state.editModel.borrado) {
            return this._paintMainForm();
        }

        return <React.Fragment>
            <Nav tabs>
                <NavItem>
                    <NavLink
                        active={(this.state.activeTab || UsuarioEditTabs.Datos) == UsuarioEditTabs.Datos}
                        onClick={() => this.props.history.push(RouteHelper.editUsuarioColegio(this.state.editModel.id, UsuarioEditTabs.Datos))}
                    >
                        {I18n.Strings.colegioUsuarios.tabDatos}
                    </NavLink>
                </NavItem>

                <NavItem>
                    <NavLink
                        active={this.state.activeTab == UsuarioEditTabs.Matriculas}
                        onClick={() => this.props.history.push(RouteHelper.editUsuarioColegio(this.state.editModel.id, UsuarioEditTabs.Matriculas))}
                    >
                        {I18n.Strings.colegioUsuarios.tabMatriculas}
                    </NavLink>
                </NavItem>

                <NavItem>
                    <NavLink
                        active={this.state.activeTab == UsuarioEditTabs.Licencias}
                        onClick={() => this.props.history.push(RouteHelper.editUsuarioColegio(this.state.editModel.id, UsuarioEditTabs.Licencias))}
                    >
                        {I18n.Strings.licenciasContenido.entityPluralName}
                    </NavLink>
                </NavItem>

                <NavItem>
                    <NavLink
                        active={this.state.activeTab == UsuarioEditTabs.EstadisticasAccesos}
                        onClick={() => this.props.history.push(RouteHelper.editUsuarioColegio(this.state.editModel.id, UsuarioEditTabs.EstadisticasAccesos))}
                    >
                        {I18n.Strings.colegioUsuarios.tabEstadisticasAccesos}
                    </NavLink>
                </NavItem>

                <NavItem>
                    <NavLink
                        active={this.state.activeTab == UsuarioEditTabs.EstadisticasContenidos}
                        onClick={() => this.props.history.push(RouteHelper.editUsuarioColegio(this.state.editModel.id, UsuarioEditTabs.EstadisticasContenidos))}
                    >
                        {I18n.Strings.colegioUsuarios.tabEstadisticasContenidos}
                    </NavLink>
                </NavItem>

                <NavItem>
                    <NavLink
                        active={this.state.activeTab == UsuarioEditTabs.Auditoria}
                        onClick={() => this.props.history.push(RouteHelper.editUsuarioColegio(this.state.editModel.id, UsuarioEditTabs.Auditoria))}
                    >
                        {I18n.Strings.colegioUsuarios.tabAuditoria}
                    </NavLink>
                </NavItem>
            </Nav>

            <TabContent activeTab={this.state.activeTab || UsuarioEditTabs.Datos}>
                <TabPane tabId={UsuarioEditTabs.Datos}>
                    {this._paintMainForm()}
                </TabPane>



                <TabPane tabId={UsuarioEditTabs.Matriculas}>

                    {this.state.activeTab == UsuarioEditTabs.Matriculas &&
                        <MatriculasIndex
                            isEmbedded={true}
                            searchModel={
                                {
                                    ...{} as MatriculasSearch,
                                    usuarioId: this.state.editModel.id,
                                    usuario: `${this.state.editModel.nombre} ${this.state.editModel.apellidos}`,
                                    cursoEscolarId: ClientContext.Current.cursoEscolarActivoId
                                }
                            } />
                    }
                </TabPane>

                <TabPane tabId={UsuarioEditTabs.Licencias}>
                    {this.state.activeTab == UsuarioEditTabs.Licencias &&
                        <LicenciasColegioIndex isEmbedded={true} searchModel={{ ...{} as LicenciasContenidosSearchEx, usuarioId: this.state.editModel.id }} />
                    }
                </TabPane>

                <TabPane tabId={UsuarioEditTabs.EstadisticasAccesos}>
                    {this.state.activeTab == UsuarioEditTabs.EstadisticasAccesos &&
                        <TabEstadisticasAccesos usuarioId={this.state.editModel.id} />
                    }
                </TabPane>

                <TabPane tabId={UsuarioEditTabs.EstadisticasContenidos}>
                    {this.state.activeTab == UsuarioEditTabs.EstadisticasContenidos &&
                        <TabEstadisiticasContenidos usuarioId={this.state.editModel.id} />
                    }
                </TabPane>

                <TabPane tabId={UsuarioEditTabs.Auditoria}>
                    {this.state.activeTab == UsuarioEditTabs.Auditoria &&
                        <AuditoriaIndex
                            isEmbedded={true}
                            hideToolbar={true}
                            searchModel={{ ...{} as EntradaAuditoriaSearchEx, usuarioDatosId: this.state.editModel.id }}
                        />
                    }
                </TabPane>

            </TabContent>
        </React.Fragment>;
    }

    protected _renderDeleteModal(): JSX.Element {
        if (this.state.showDeleteConfirmation != null) {
            return <DeleteModal
                onCancelDelete={() => this.setState({ showDeleteConfirmation: null })}
                onConfirm={() => {
                    let borradoFisico = this.state.editModel.borradoFisico;

                    var newState = this._cloneStateForSetState();
                    newState.showLoading = true;

                    // Para que no salte lo que si quiero salvar los cambios
                    delete newState.editModel.borradoFisico;

                    this.setState(newState, () => {
                        var service = new ColegioUsuariosService();

                        var prom = borradoFisico
                            ? service.deleteFisico(this.state.editModel.id)
                            : service.delete(this.state.editModel.id);

                        prom.then(() => {
                            this.props.history.push(this._getListUrl());
                        })
                            .catch((error) => {
                                this.setState({ showLoading: false }, () => {
                                    AlertsService.showError(error);
                                })
                            });
                    })
                }}
            />
        }
    }

    private _paintMainForm(): JSX.Element {
        return <React.Fragment>
            {this._renderDeleteModal()}
            <Row>
                <Col md={9}>
                    {this.state.editModel.borrado && <Alert color="danger">
                        <Row>
                            <Col md={9}>
                                {I18n.Strings.colegioUsuarios.usuarioEstaBorrado}
                            </Col>
                            <Col md={3}>
                                <Button
                                    id="validate"
                                    color="primary"
                                    className="btn-rounded text-end"
                                    onClick={() => {
                                        this.setState({ showLoading: true }, () => {
                                            var service = new ColegioUsuariosService();
                                            var ids = [];
                                            ids.push(this.state.editModel.id);
                                            service.undelete(ids)
                                                .then((ret) => {
                                                    var state = this._cloneStateForSetState();
                                                    state.showLoading = false;
                                                    state.editModel.borrado = false;
                                                    this.setState(state);
                                                });
                                        })
                                    }}>
                                    <CheckIcon />
                                    {I18n.Strings.colegioUsuarios.recuperar}
                                </Button>
                            </Col>
                        </Row>
                    </Alert>}
                    <Row>
                        {this.state.editModel.id > 0 && <React.Fragment>
                            <Col md={4}>
                                <FormGroup>
                                    <Label for={"usuarios" + nameof(this.state.editModel.fechaUltimoAcceso)}>{I18n.Strings.colegioUsuarios.fechaUltimoAcceso}</Label>
                                    <Input
                                        type="text"
                                        disabled={true}
                                        maxLength={100}
                                        className={"form-control"}
                                        name={nameof(this.state.editModel.fechaUltimoAcceso)}
                                        id={"usuarios" + nameof(this.state.editModel.fechaUltimoAcceso)}
                                        value={I18n.Strings.formatLastAccessDate(this.state.editModel.fechaUltimoAcceso)}
                                    />
                                </FormGroup>
                            </Col>
                            <Col md={4}>
                                <FormGroup>
                                    <Label for={"usuarios" + nameof(this.state.editModel.created)}>{I18n.Strings.colegioUsuarios.fechaCreacion}</Label>
                                    <Input
                                        type="text"
                                        disabled={true}
                                        maxLength={100}
                                        className={"form-control"}
                                        name={nameof(this.state.editModel.created)}
                                        id={"usuarios" + nameof(this.state.editModel.created)}
                                        value={I18n.Strings.formatDate(this.state.editModel.created) + ' - ' + I18n.Strings.getEnumText("Origen." + this.state.editModel.origen)}
                                    />
                                </FormGroup>
                            </Col>
                            <Col md={4}>
                                <FormGroup>
                                    <Label for={"usuarios" + nameof(this.state.editModel.modified)}>{I18n.Strings.colegioUsuarios.fechaModificacion}</Label>
                                    <Input
                                        type="text"
                                        disabled={true}
                                        maxLength={100}
                                        className={"form-control"}
                                        name={nameof(this.state.editModel.modified)}
                                        id={"usuarios" + nameof(this.state.editModel.modified)}
                                        value={I18n.Strings.formatDate(this.state.editModel.modified) || ""}
                                    />
                                </FormGroup>
                            </Col>
                        </React.Fragment>}
                        <Col md={4}>
                            <FormGroup>
                                <Label for={"usuarios" + nameof(this.state.editModel.nombre)}>{I18n.Strings.colegioUsuarios.nombre}</Label>
                                <Input
                                    onChange={(evt) => {
                                        var state = this._cloneStateForSetState();
                                        state.editModel.nombre = evt.target.value;
                                        this.setState(state);
                                    }}
                                    type="text"
                                    required={true}
                                    maxLength={100}
                                    className={"form-control " + this._errorClass(nameof(this.state.editModel.nombre))}
                                    name={nameof(this.state.editModel.nombre)}
                                    id={"usuarios" + nameof(this.state.editModel.nombre)}
                                    value={this.state.editModel.nombre || ""}
                                />
                                {this._errorMessage(nameof(this.state.editModel.nombre))}
                            </FormGroup>
                        </Col>
                        <Col md={4}>
                            <FormGroup>
                                <Label for={"usuarios" + nameof(this.state.editModel.nombre)}>{I18n.Strings.colegioUsuarios.apellidos}</Label>
                                <Input
                                    onChange={(evt) => {
                                        var state = this._cloneStateForSetState();
                                        state.editModel.apellidos = evt.target.value;
                                        this.setState(state);
                                    }}
                                    type="text"
                                    required={true}
                                    maxLength={100}
                                    className={"form-control " + this._errorClass(nameof(this.state.editModel.apellidos))}
                                    name={nameof(this.state.editModel.apellidos)}
                                    id={"usuarios" + nameof(this.state.editModel.apellidos)}
                                    value={this.state.editModel.apellidos || ""}
                                />
                                {this._errorMessage(nameof(this.state.editModel.apellidos))}
                            </FormGroup>
                        </Col>
                        <Col md={4}>
                            <TipoUsuarioDropDown
                                label={I18n.Strings.colegioUsuarios.tipoUsuario}
                                showLabel={true}
                                id={nameof(this.state.editModel.tipoUsuario)}
                                value={this.state.editModel.tipoUsuario}
                                required={true}
                                errorMessage={this.state.validationResult.getError(nameof(this.state.editModel.tipoUsuario))}
                                onChanged={(value: string) => {
                                    var newState = this._cloneStateForSetState();
                                    if (value != '') {
                                        newState.editModel.tipoUsuario = value as TipoUsuario;
                                    }
                                    else {
                                        newState.editModel.tipoUsuario = null;
                                    }
                                    this.setState(newState);
                                }}
                            />
                        </Col>

                        <Col md={4}>
                            <FormGroup>
                                <Label for={nameof(this.state.editModel.claseActualId)}>
                                    {I18n.Strings.colegioUsuarios.claseActual}
                                </Label>

                                <NivelEducativoDropDown
                                    id={nameof(this.state.editModel.claseActualId)}

                                    cursoEscolarId={ClientContext.Current.cursoEscolarActivoId}
                                    value={this.state.editModel.claseActualId}

                                    text={this.state.editModel.claseActual}
                                    seleccionClases={true}
                                    onChange={(id, nombre) => {
                                        var state = this._cloneStateForSetState();
                                        state.editModel.claseActualId = id;
                                        state.editModel.claseActual = nombre;
                                        this.setState(state);
                                    }}
                                />

                                {this._errorMessage(nameof(this.state.editModel.claseActualId))}
                            </FormGroup>
                        </Col>

                        {/*
                        <Col md={4}>
                            <FormGroup>
                                <Label for={"usuarios" + nameof(this.state.editModel.estado)}>{I18n.Strings.colegioUsuarios.estado}</Label>
                                <Input
                                    type="text"
                                    disabled={true}
                                    className={"form-control"}
                                    name={nameof(this.state.editModel.estado)}
                                    id={"usuarios" + nameof(this.state.editModel.estado)}
                                    value={this.state.editModel.estado || ""}
                                />
                            </FormGroup>
                        </Col>
                        */}
                    </Row>
                    <Row>
                        <Col md={8}>
                            <FormGroup>
                                <Label for={"usuarios" + nameof(this.state.editModel.login)}>{I18n.Strings.colegioUsuarios.login}</Label>
                                <Input
                                    type="text"
                                    disabled={ClientContext.Current.tipoUsuario == TipoUsuario.AdminColegio || ClientContext.Current.tipoUsuario == TipoUsuario.AdminGlobal ? false : true}
                                    onChange={(evt) => {
                                        var state = this._cloneStateForSetState();
                                        state.editModel.login = evt.target.value;
                                        this.setState(state);
                                    }}
                                    maxLength={100}
                                    className={"form-control " + this._errorClass(nameof(this.state.editModel.login))}
                                    name={nameof(this.state.editModel.login)}
                                    id={"usuarios" + nameof(this.state.editModel.login)}
                                    value={this.state.editModel.login || ""}
                                />
                                {this._errorMessage(nameof(this.state.editModel.login))}
                            </FormGroup>
                        </Col>
                        {this.state.editModel.allowCustomLogin && <Col md={4}>
                            <FormGroup>
                                <Label for={"usuarios" + nameof(this.state.editModel.password)}>{I18n.Strings.colegioUsuarios.password}</Label>
                                <Input
                                    onChange={(evt) => {
                                        var state = this._cloneStateForSetState();
                                        state.editModel.password = evt.target.value;
                                        this.setState(state);
                                    }}
                                    type="text"
                                    required={false}
                                    maxLength={20}
                                    className={"form-control"}
                                    name={nameof(this.state.editModel.password)}
                                    id={"usuarios" + nameof(this.state.editModel.password)}
                                    value={this.state.editModel.password || ""}
                                />
                                {this._errorMessage(nameof(this.state.editModel.password))}
                                {(this.state.editModel.passwordError || "").trim().length > 0 && <FormFeedback>{this.state.editModel.passwordError}</FormFeedback>}
                            </FormGroup>
                        </Col>}
                    </Row>
                </Col>
                <Col md={3}>
                    <div>
                        <div className="mainPhoto">
                            <img
                                className="mt-xl"
                                src={this.state.editModel.foto == null ? '/img/caratula.png' : this.state.editModel.foto}
                                alt="foto"
                            />
                            {this.state.editModel.foto != null && <div className="mainPhotoMenu">

                            </div>
                            }
                        </div>
                        {this.state.editModel.id > 0 && !this.state.editModel.borrado && <div>
                            <DocumentUpload
                                manualUpload={false}
                                style={{ fontSize: 14, overflow: "ellipsis" }}
                                title={I18n.Strings.colegioUsuarios.subirFoto}
                                zoneStyle={{ margin: 0, padding: 10 }}
                                uploadUrl={`intranet/colegio/usuarios/${this.state.editModel.id}/profilePhoto`}
                                multiple={false}
                                onUpload={(data) => {
                                    this._updateStateValue(data.documentUrl, nameof.full(this.state.editModel.foto, 2));
                                }}
                            />
                        </div>}
                    </div>
                </Col>
            </Row>
        </React.Fragment>;
    }

    protected _getBotones(): JSX.Element[] {
        var botones = [];
        /*
        if (!this.state.editModel.borrado && this.state.editModel.estado == EstadoUsuario.PendienteValidacion) {

            
            botones.push(
                <Button
                    id="validate"
                    color="primary"
                    className="btn-rounded text-end"
                    onClick={() => {
                        this.setState({ showLoading: true }, () => {
                            var service = new ColegioUsuariosService();
                            var ids = [];
                            ids.push(this.state.editModel.id);
                            service.activate(ids)
                                .then((ret) => {
                                    var state = this._cloneStateForSetState();
                                    state.showLoading = false;
                                    state.editModel.estado = EstadoUsuario.Activo;
                                    this.setState(state);
                                });
                        })
                    }}>
                    <CheckIcon />
                    {I18n.Strings.validate}
                </Button>
            );
        }
        */

        if (this.state.editModel.id != 0 && (
            ClientContext.Current.tipoUsuario == TipoUsuario.AdminGlobal || ClientContext.Current.tipoUsuario == TipoUsuario.AdminColegio
        )) {
            botones.push(
                <Button
                    key={botones.length}
                    onClick={() => {
                        var impService = new ImpersonationService();
                        impService.impersonate(this.state.editModel.id);
                    }}>
                    {I18n.Strings.emularUsuario.emularUsuario}
                </Button>
            );
        }

        //if (this.state.editModel.id != 0 && !this.state.editModel.borrado && this.state.editModel.estado == EstadoUsuario.PendienteValidacion) {

        botones.push(<DropdownItem divider />);

        botones.push(
            <Button
                id="delete"
                key={botones.length}
                color="secondary"
                className="btn-rounded text-end"
                onClick={() => {
                    var newState = this._cloneStateForSetState();
                    newState.showDeleteConfirmation = true;
                    newState.editModel.borradoFisico = false;
                    this.setState(newState);
                }}>

                <DeleteIcon />
                {I18n.Strings.delete}
            </Button>
        );
        //}


        if (this.state.editModel.id != 0 && ClientContext.Current.tipoUsuario == TipoUsuario.AdminGlobal) {

            botones.push(<DropdownItem key={botones.length} divider={true} />);

            botones.push(
                <Button
                    key={botones.length}
                    id="delete"
                    color="secondary"
                    className="btn-rounded text-end"
                    onClick={() => {
                        var newState = this._cloneStateForSetState();
                        newState.showDeleteConfirmation = true;
                        newState.editModel.borradoFisico = true;
                        this.setState(newState);
                    }}>
                    <DeleteIcon />
                    {I18n.Strings.deleteFisico}
                </Button>
            );


        }

        return botones;
    }

    protected _extraButtons(): JSX.Element {
        var botones = this._getBotones();

        return <DropDownButton botones={botones} />
    }

    protected _getEditUrl(id: number): string {
        return RouteHelper.editUsuarioColegio(id);
    }

    protected _getListUrl(): string {
        return RouteHelper.listUsuariosColegio();
    }

    protected async _createStateForNew(): Promise<GenericEditState<ColegioUsuarioEditModel>> {
        const service = new ColegioUsuariosService();
        const model = new GenericEditState<ColegioUsuarioEditModel>();
        model.activeTab = UsuarioEditTabs.Datos;

        try {
            const data = await service.new();
            model.editModel = data;
        } catch (error) {

            model.editModel = {
                id: 0,
                nombre: "",
                login: "",
                estado: EstadoUsuario.Nuevo,
                origen: Origen.Administrador,
                allowCustomLogin: true
            } as ColegioUsuarioEditModel;
        }

        return model;
    }

    protected _validateModelLocal(): ModelValidation {
        let validation = new ModelValidation();

        if ((this.state.editModel.nombre || "").trim().length == 0) {
            validation.addError(nameof(this.state.editModel.nombre), I18n.Strings.validationErrors.valueRequired(I18n.Strings.colegioUsuarios.nombre));
        }

        if ((this.state.editModel.apellidos || "").trim().length == 0) {
            validation.addError(nameof(this.state.editModel.apellidos), I18n.Strings.validationErrors.valueRequired(I18n.Strings.colegioUsuarios.apellidos));
        }

        if ((this.state.editModel.login || "").trim().length == 0) {
            validation.addError(nameof(this.state.editModel.login), I18n.Strings.validationErrors.valueRequired(I18n.Strings.colegioUsuarios.login));
        }

        if (this.state.editModel.tipoUsuario == null) {
            validation.addError(nameof(this.state.editModel.tipoUsuario), I18n.Strings.validationErrors.valueRequired(I18n.Strings.colegioUsuarios.tipoUsuario));
        }
        else if (this.state.editModel.tipoUsuario == TipoUsuario.Alumno && (this.state.editModel.claseActualId || 0) == 0) {
            validation.addError(nameof(this.state.editModel.claseActualId), I18n.Strings.colegioUsuarios.errorNivelRequired)
        }

        if ((this.state.editModel.password || "").trim().length > 0 && (this.state.editModel.password || "").trim().length < 8) {
            validation.addError(nameof(this.state.editModel.password), I18n.Strings.validationErrors.stringLengthGreaterThan(I18n.Strings.colegioUsuarios.password, 8));
        }
        return validation;
    }

    protected updateBreadCrumb(model: ColegioUsuarioEditModel) {
        var items = [{
            text: I18n.Strings.colegioUsuarios.title,
            link: RouteHelper.listUsuariosColegio()
        }];
        if (model.id == 0) {
            items.push({
                text: I18n.Strings.addNew,
                link: ""
            });
        }
        else {
            items.push({
                text: `${model.nombre} ${model.apellidos}`,
                link: RouteHelper.editUsuarioColegio(model.id)
            });

            switch (this.state.activeTab) {
                case UsuarioEditTabs.Datos: {
                    items.push({ text: I18n.Strings.colegioUsuarios.tabDatos, link: RouteHelper.editUsuarioColegio(this.state.editModel.id, UsuarioEditTabs.Datos) });
                    break;
                }
                case UsuarioEditTabs.Licencias: {
                    items.push({ text: I18n.Strings.licenciasContenido.entityPluralName, link: RouteHelper.editUsuarioColegio(this.state.editModel.id, UsuarioEditTabs.Licencias) });
                    break;
                }
                case UsuarioEditTabs.Matriculas: {
                    items.push({ text: I18n.Strings.colegioUsuarios.tabMatriculas, link: RouteHelper.editUsuarioColegio(this.state.editModel.id, UsuarioEditTabs.Matriculas) });
                    break;
                }
                case UsuarioEditTabs.EstadisticasAccesos: {
                    items.push({ text: I18n.Strings.colegioUsuarios.tabEstadisticasAccesos, link: RouteHelper.editUsuarioColegio(this.state.editModel.id, UsuarioEditTabs.EstadisticasAccesos) });
                    break;
                }
                case UsuarioEditTabs.EstadisticasContenidos: {
                    items.push({ text: I18n.Strings.colegioUsuarios.tabEstadisticasContenidos, link: RouteHelper.editUsuarioColegio(this.state.editModel.id, UsuarioEditTabs.EstadisticasContenidos) });
                    break;
                }
            }
        }

        NavigationHelper.setBreadcrumbItems(items);
    }
}

export const Edit = withRouter(EditComp)