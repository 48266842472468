import React from "react";
import { Button, Col, FormFeedback, FormGroup, Input, Label, Row } from "reactstrap";
import nameof from "ts-nameof.macro";
import { DocumentUpload } from "../../helpers/DocumentUpload";
import { RouteHelper } from "../../IntranetRouter";
import { MiPerfilEditModel } from "../../models/dataModels";
import { AlertsService } from "../../utils/AlertsService";
import { NavigationHelper } from "../../utils/base/Breadcrumb";
import { ComponentBase } from "../../utils/base/ComponentBase";
import { ModelValidation } from "../../utils/base/ModelValidation";
import { ClientContext } from "../../utils/ClientContext";
import { I18n } from "../../utils/I18n";
import { SaveIcon } from "../../utils/Icons";
import { MiPerfilService } from "../MiPerfilService";


interface MyProfileState {
    showLoading: boolean;
    editModel: MiPerfilEditModel;
    validationResult: ModelValidation;
}

export class Edit extends ComponentBase<any, MyProfileState> {

    public constructor(props: any) {
        super(props);

        this.state = {
            editModel: null,
            showLoading: true,
            validationResult: new ModelValidation(),
        };

        NavigationHelper.setBreadcrumbItems([
            {
                text: I18n.Strings.myProfile,
                link: ""
            }
        ]);
    
    }

    componentDidMount() {
        var service = new MiPerfilService();
        service.getMyEditModel()
            .then(myData => {
                this.setState({
                    showLoading: false,
                    editModel: myData,
                });
            });
    }

    protected _validateModelLocal(): ModelValidation {
        let validation = new ModelValidation();

        if ((this.state.editModel.nombre || "").trim().length === 0) {
            validation.addError(nameof(this.state.editModel.nombre), I18n.Strings.validationErrors.valueRequired(I18n.Strings.colegioUsuarios.nombre));
        }

        if ((this.state.editModel.apellidos || "").trim().length === 0) {
            validation.addError(nameof(this.state.editModel.apellidos), I18n.Strings.validationErrors.valueRequired(I18n.Strings.colegioUsuarios.apellidos));
        }

        if ((this.state.editModel.password || "").trim().length > 0) {
            if ((this.state.editModel.password || "").trim().length < 8) {
                validation.addError(nameof(this.state.editModel.password), I18n.Strings.validationErrors.stringLengthGreaterThan(I18n.Strings.colegioUsuarios.password, 8));
            }
            if ((this.state.editModel.passwordConfirmar || "").trim().length === 0) {
                validation.addError(nameof(this.state.editModel.passwordConfirmar), I18n.Strings.validationErrors.valueRequired(I18n.Strings.colegioUsuarios.passwordConfirmar));
            }
            if ((this.state.editModel.passwordConfirmar || "").trim().length > 0 && this.state.editModel.passwordConfirmar != this.state.editModel.password) {
                validation.addError(nameof(this.state.editModel.passwordConfirmar), I18n.Strings.validationErrors.valuesDontMatch(I18n.Strings.colegioUsuarios.password, I18n.Strings.colegioUsuarios.passwordConfirmar));
            }
        }

        return validation;
    }

    protected _validateState(): Promise<ModelValidation> {

        var validationProm = Promise.resolve<ModelValidation>(this._validateModelLocal())
            .then(localValidationResult => {
                if (localValidationResult.isOk) {
                    var service = new MiPerfilService();

                    // Si en local ha ido bien, probamos en remoto, si no, no lo intentamos
                    return service.validate(this.state.editModel)
                        .then(serverValResult => {
                            var validation = new ModelValidation();
                            validation.load(serverValResult);
                            return validation;
                        });
                }
                else {
                    return localValidationResult;
                }
            })
            .catch((reason) => {
                AlertsService.showError(reason);
                return reason;
            });

        return validationProm;
    }

    protected _validateAndSubmit(): void {
        this._validateState()
            .then(validationResultTemp => {

                if (validationResultTemp == null) {
                    alert("error validation");
                    return false;
                }
                var validationResult = new ModelValidation();
                validationResult.load(validationResultTemp);

                if (validationResult.isOk) {
                    console.info("La validación es correcta");

                    var state = this._cloneStateForSetState();
                    state.showLoading = true;
                    state.validationResult = validationResult;
                    this.setState(state, () => {
                        var service = new MiPerfilService();
                        service.update(this.state.editModel)
                            .then((data) => {
                                var state = this._cloneStateForSetState();
                                state.editModel.password = null;
                                state.editModel.passwordConfirmar = null;
                                state.editModel.passwordError = data.passwordError;
                                state.showLoading = false;
                                this.setState(state);

                                ClientContext.Current.nombre = this.state.editModel.nombre;
                                ClientContext.Current.apellidos = this.state.editModel.apellidos;
                                AlertsService.showSuccessMessage(I18n.Strings.savedOk);

                                return;
                            })
                            .catch((reason) => AlertsService.showError(reason));
                    });
                }
                else {
                    console.log("Validación no correcta");
                    console.warn(validationResult);
                    this._processValidationError(validationResult);
                }
            })
            .catch((reason) => AlertsService.showError(reason));
    }


    protected _processValidationError(validation: ModelValidation): void {
        var newState = this._cloneStateForSetState();
        newState.validationResult = validation;
        this.setState(newState);
    }

    protected _errorMessage(fieldName: string): JSX.Element {
        var error = this.state.validationResult.getError(fieldName);
        if (error) {
            return <FormFeedback>{error}</FormFeedback>;
        }
        return null;
    }

    protected _errorClass(fieldName: string): string {
        var error = this.state.validationResult.getError(fieldName);
        if (error) {
            return "is-invalid";
        }
        return null;
    }

    render() {
        return <React.Fragment>
            <h2><strong>Mi Perfil</strong></h2>
            {this.state.editModel != null && <Row>
                <Col md={9}>
                    <Row>
                        <Col md={6}>
                            <FormGroup>
                                <Label for={"usuarios" + nameof(this.state.editModel.nombre)}>{I18n.Strings.colegioUsuarios.nombre}</Label>
                                <Input
                                    onChange={(evt) => {
                                        var state = this._cloneStateForSetState();
                                        state.editModel.nombre = evt.target.value;
                                        this.setState(state);
                                    }}
                                    type="text"
                                    required={true}
                                    maxLength={100}
                                    className={"form-control " + this._errorClass(nameof(this.state.editModel.nombre))}
                                    name={nameof(this.state.editModel.nombre)}
                                    id={"usuarios" + nameof(this.state.editModel.nombre)}
                                    value={this.state.editModel.nombre || ""}
                                />
                                {this._errorMessage(nameof(this.state.editModel.nombre))}
                            </FormGroup>
                        </Col>
                        <Col md={6}>
                            <FormGroup>
                                <Label for={"usuarios" + nameof(this.state.editModel.nombre)}>{I18n.Strings.colegioUsuarios.apellidos}</Label>
                                <Input
                                    onChange={(evt) => {
                                        var state = this._cloneStateForSetState();
                                        state.editModel.apellidos = evt.target.value;
                                        this.setState(state);
                                    }}
                                    type="text"
                                    required={true}
                                    maxLength={100}
                                    className={"form-control " + this._errorClass(nameof(this.state.editModel.apellidos))}
                                    name={nameof(this.state.editModel.apellidos)}
                                    id={"usuarios" + nameof(this.state.editModel.apellidos)}
                                    value={this.state.editModel.apellidos || ""}
                                />
                                {this._errorMessage(nameof(this.state.editModel.apellidos))}
                            </FormGroup>
                        </Col>
                        <Col md={6}>
                            <FormGroup>
                                <Label for={"usuarios" + nameof(this.state.editModel.login)}>{I18n.Strings.colegioUsuarios.login}</Label>
                                <Input
                                    type="text"
                                    disabled={true}
                                    maxLength={100}
                                    className={"form-control " + this._errorClass(nameof(this.state.editModel.login))}
                                    name={nameof(this.state.editModel.login)}
                                    id={"usuarios" + nameof(this.state.editModel.login)}
                                    value={this.state.editModel.login || ""}
                                />
                                {this._errorMessage(nameof(this.state.editModel.login))}
                            </FormGroup>
                        </Col>
                    </Row>
                    {this.state.editModel.allowCustomLogin && <Row>
                        <Col md={6}>
                            <FormGroup>
                                <Label for={"usuarios" + nameof(this.state.editModel.password)}>{I18n.Strings.colegioUsuarios.password}</Label>
                                <Input
                                    onChange={(evt) => {
                                        var state = this._cloneStateForSetState();
                                        state.editModel.password = evt.target.value;
                                        this.setState(state);
                                    }}
                                    type="text"
                                    required={false}
                                    maxLength={20}
                                    className={"form-control"}
                                    name={nameof(this.state.editModel.password)}
                                    id={"usuarios" + nameof(this.state.editModel.password)}
                                    value={this.state.editModel.password || ""}
                                />
                                {this._errorMessage(nameof(this.state.editModel.password))}
                                {(this.state.editModel.passwordError || "").trim().length > 0 && <FormFeedback>{this.state.editModel.passwordError}</FormFeedback>}
                            </FormGroup>
                        </Col>
                        <Col md={6}>
                            <FormGroup>
                                <Label for={"usuarios" + nameof(this.state.editModel.passwordConfirmar)}>{I18n.Strings.colegioUsuarios.passwordConfirmar}</Label>
                                <Input
                                    onChange={(evt) => {
                                        var state = this._cloneStateForSetState();
                                        state.editModel.passwordConfirmar = evt.target.value;
                                        this.setState(state);
                                    }}
                                    type="text"
                                    required={false}
                                    maxLength={20}
                                    className={"form-control"}
                                    name={nameof(this.state.editModel.passwordConfirmar)}
                                    id={"usuarios" + nameof(this.state.editModel.passwordConfirmar)}
                                    value={this.state.editModel.passwordConfirmar || ""}
                                />
                                {this._errorMessage(nameof(this.state.editModel.passwordConfirmar))}
                            </FormGroup>
                        </Col>
                    </Row>}
                </Col>
                <Col md={3}>
                    <div>
                        <div className="mainPhoto">
                            <img className="mt-xl"
                                alt={`${this.state.editModel.nombre} ${this.state.editModel.apellidos}`}
                                src={this.state.editModel.foto == null ? '/img/caratula.png' : this.state.editModel.foto}
                            />
                            {this.state.editModel.foto != null && <div className="mainPhotoMenu"></div>}
                        </div>
                        {this.state.editModel.id > 0 && <div>
                            <DocumentUpload
                                manualUpload={false}
                                style={{ fontSize: 14, overflow: "ellipsis" }}
                                title={I18n.Strings.colegioUsuarios.subirFoto}
                                zoneStyle={{ margin: 0, padding: 10 }}
                                uploadUrl={`intranet/colegio/usuarios/${this.state.editModel.id}/profilePhoto`}
                                multiple={false}
                                onUpload={(data) => {
                                    this._updateStateValue(data.documentUrl, nameof.full(this.state.editModel.foto, 2));
                                    ClientContext.Current.imagenPerfilUrl = this.state.editModel.foto;
                                }}
                            />
                        </div>}
                    </div>
                </Col>
            </Row>}
            <Row>
                <Col md={6}>
                    <Button id="save" color="primary" className="btn-rounded" onClick={() => this._validateAndSubmit()} >
                        <SaveIcon />
                        {I18n.Strings.save}
                    </Button>
                </Col>
            </Row>
        </React.Fragment>
    };
}