import * as React from "react";
//import { PaginatedListBase } from "../models/dataModels";
//import * as Formatter from "./ValuesFormater";
import { I18n } from "./I18n";
import { Pagination, PaginationItem, PaginationLink, Row, Col } from "reactstrap";
import { SortDirection } from "../models/dataModels";


export class PagerProps {
    paginatedList: any;
    onClick: (pNum: number) => void;
    pageSize: number;
    onPageSizeChange: (newSize: number) => void;
    numberOfPages?: number;
    warningAfterNumResults?: string;
};

export interface PaginatedListBase<Item extends IEntity> {
    resultsPerPage: number;
    resultsCurrentPage: number;
    currentPage: number;
    totalCount: number;
    orderByColumn: string | undefined;
    orderMode: SortDirection;
    items: Item[];
}

export interface IEntity {
    id: number;
    protegidoBorrado?: boolean;
}

/**
 * Pinta el paginador de los listados
 */
export class Pager extends React.Component<PagerProps, any> {


    public shouldComponentUpdate() {
        return true;
    }



    public render(): JSX.Element {


        let maxNumPages = this.props.numberOfPages == null ? 5 : this.props.numberOfPages;

        const elems = [] as number[];

        let pagList = this.props.paginatedList as any as PaginatedListBase<any>;
        let numPages = Math.ceil(pagList.totalCount / pagList.resultsPerPage);

        const pageSizes = [10, 20, 50, 100, 500];


        const firstPageToRender = Math.max(0, pagList.currentPage - Math.floor(maxNumPages / 2));
        const lastPageToRender = Math.min(numPages, firstPageToRender + maxNumPages);

        for (let i = firstPageToRender; i < lastPageToRender; i++) {
            elems.push(i + 1);
        }

        return (
            <Row>


                <Col xs="hidden" md={2}>
                    {pagList.totalCount} {I18n.Strings.results}
                </Col>

                <Col xs="10" md={8}>
                    {numPages > 1 &&

                        <Pagination>
                            {firstPageToRender > 0 &&
                                <React.Fragment>
                                    <PaginationItem active={0 == pagList.currentPage}>
                                        <PaginationLink href="#" onClick={(evt) => this._pageChanged(evt, 0)}>1</PaginationLink>
                                    </PaginationItem>
                                    {firstPageToRender > 1 &&
                                        <PaginationItem>
                                            <PaginationLink>
                                                <span>...</span>
                                            </PaginationLink>
                                        </PaginationItem>
                                    }
                                </React.Fragment>
                            }

                            {elems.map(function (pageValue, i) {
                                var pageIndex = i + firstPageToRender;

                                if (pageIndex !== pagList.currentPage) {
                                    return <PaginationItem key={i}>
                                        <PaginationLink
                                            onClick={(evt) => this._pageChanged(evt, pageIndex)}
                                            className={this._getClassForPager(pagList.currentPage, pageIndex)} href="#"
                                        >
                                            {I18n.Strings.formatNumber(pageValue)}
                                        </PaginationLink>
                                    </PaginationItem>
                                }
                                else {
                                    return <PaginationItem key={i} active={true}>
                                        <PaginationLink>
                                            {I18n.Strings.formatNumber(pageValue)}
                                        </PaginationLink>
                                    </PaginationItem>;
                                }

                            }, this)}

                            {lastPageToRender < numPages &&
                                <React.Fragment>
                                    {lastPageToRender < numPages - 1 &&
                                        <PaginationItem>
                                            <PaginationLink>
                                                <span>...</span>
                                            </PaginationLink>
                                        </PaginationItem>
                                    }
                                    <PaginationItem>
                                        <PaginationLink
                                            onClick={(evt) => this._pageChanged(evt, numPages - 1)}
                                            className={this._getClassForPager(pagList.currentPage, numPages - 1)} href="#"
                                        >
                                            {I18n.Strings.formatNumber(numPages)}
                                        </PaginationLink>
                                    </PaginationItem>
                                </React.Fragment>
                            }
                        </Pagination>
                    }
                </Col>
                <Col xs="hidden" md={2}>
                    <div className="pagesize">
                        <span>{I18n.Strings.resultsPag} </span>
                        <select
                            onChange={(elem) => { this.props.onPageSizeChange(parseInt(elem.target.value)); }}
                            value={this.props.pageSize}>
                            {pageSizes.map((pageSize, index) => {
                                return <option key={index} value={pageSize}>{pageSize}</option>;
                            }, this)}
                        </select>
                    </div>
                </Col>

            </Row >
        );
    }

    private _pageChanged(evt: React.MouseEvent<HTMLAnchorElement>, pageNum: number): boolean {
        evt.preventDefault();
        this.props.onClick(pageNum);
        return false;
    }

    private _getClassForPager(selectedPage: number, pageIndex): string {

        var init = "";
        if (Math.abs(selectedPage - pageIndex) >= 2) {
            init += " hidden";
        }

        return init;
    }
};



