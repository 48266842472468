import * as React from "react";
import { withRouter } from "react-router-dom";
import { Button, Col, FormGroup, Input, Label, Row } from "reactstrap";
import nameof from "ts-nameof.macro";
import { RouteHelper } from "../IntranetRouter";
import { NotificacionEditModel, NotificacionItemEditModel, TipoNotificacionItem } from "../models/dataModels";
import { NavigationHelper } from "../utils/base/Breadcrumb";
import { ModelValidation } from "../utils/base/ModelValidation";
import { I18n } from "../utils/I18n";
import { CancelIcon } from "../utils/Icons";
import { Loading } from "../utils/Loading";
import { NotificationService } from "./NotificationService";
import { cloneDeep } from "lodash-es";


interface NotificacionState {
    loading: boolean;
    editModel: NotificacionEditModel;
    validationResult: ModelValidation;
    tipoLinea: TipoNotificacionItem;
    texto: string;
}

export class ViewComp extends React.Component<any, NotificacionState> {

    public constructor(props: any) {
        super(props);

        this.state = {
            loading: true,
            validationResult: new ModelValidation(),
            editModel: null,
            tipoLinea: null,
            texto: null
        };
    }

    componentDidMount() {
        this._loadData();
    }

    protected _loadData(): void {
        var service = new NotificationService();

        service.getById(this.props.match.params.id)
            .then(ret => {
                this.setState({ loading: false, editModel: ret });
            });
    }

    protected _cloneStateForSetState(): NotificacionState {
        return cloneDeep(this.state) as NotificacionState;
    }

    render() {
        NavigationHelper.setBreadcrumbItems([{
            link: RouteHelper.listNotificaciones(),
            text: I18n.Strings.notificaciones.titulo
        },
        {
            link: null,
            text: I18n.Strings.notificaciones.notificacion
        }]);

        return <React.Fragment>
            {this.state.loading && <Loading />}
            <h1>{I18n.Strings.notificaciones.notificacion}</h1>
            {this.state.editModel != null && <React.Fragment>
                <Row>
                    <Col md={12}>
                        <FormGroup>
                            <Label for={"notificacion" + nameof(this.state.editModel.texto)}>{I18n.Strings.notificaciones.texto}</Label>
                            <Input
                                type="text"
                                disabled={true}
                                className={"form-control"}
                                name={nameof(this.state.editModel.texto)}
                                id={"notificacion" + nameof(this.state.editModel.texto)}
                                value={this.state.editModel.texto || ""}
                            />
                        </FormGroup>
                    </Col>
                    <Col md={5}>
                        <FormGroup>
                            <Label for={"notificacion" + nameof(this.state.editModel.colegio)}>{I18n.Strings.notificaciones.colegio}</Label>
                            <Input
                                type="text"
                                disabled={true}
                                className={"form-control"}
                                name={nameof(this.state.editModel.colegio)}
                                id={"notificacion" + nameof(this.state.editModel.colegio)}
                                value={this.state.editModel.colegio || ""}
                            />
                        </FormGroup>
                    </Col>
                    <Col md={2}>
                        <FormGroup>
                            <Label for={"notificacion" + nameof(this.state.editModel.fecha)}>{I18n.Strings.notificaciones.fecha}</Label>
                            <Input
                                type="text"
                                disabled={true}
                                className={"form-control"}
                                name={nameof(this.state.editModel.fecha)}
                                id={"notificacion" + nameof(this.state.editModel.fecha)}
                                value={I18n.Strings.formatDateTime(this.state.editModel.fecha)}
                            />
                        </FormGroup>
                    </Col>
                    <Col md={3}>
                        <FormGroup>
                            <Label for={"notificacion" + nameof(this.state.editModel.tipoNotificacion)}>{I18n.Strings.notificaciones.tipoNotificacion}</Label>
                            <Input
                                type="text"
                                disabled={true}
                                className={"form-control"}
                                name={nameof(this.state.editModel.tipoNotificacion)}
                                id={"notificacion" + nameof(this.state.editModel.tipoNotificacion)}
                                value={I18n.Strings.getEnumText(`TipoNotificacion.${this.state.editModel.tipoNotificacion}`)}
                            />
                        </FormGroup>
                    </Col>
                    <Col md={2}>
                        <FormGroup>
                            <Label for={"notificacion" + nameof(this.state.editModel.estado)}>{I18n.Strings.notificaciones.estado}</Label>
                            <Input
                                type="text"
                                disabled={true}
                                className={"form-control"}
                                name={nameof(this.state.editModel.estado)}
                                id={"notificacion" + nameof(this.state.editModel.estado)}
                                value={I18n.Strings.getEnumText(`EstadoNotificacion.${this.state.editModel.estado}`)}
                            />
                        </FormGroup>
                    </Col>
                </Row>
                <Row>
                    <Col md={12}>
                        <hr />
                    </Col>
                </Row>
                <Row>
                    <Col md={4}>
                        <FormGroup>
                            <Label for={"notificacion" + nameof(this.state.tipoLinea)}>{I18n.Strings.notificaciones.tipoLinea}</Label>
                            <select
                                id={"notificacion" + nameof(this.state.tipoLinea)}
                                onChange={(evt) => {
                                    if (evt.target.value != '') {
                                        var newState = this._cloneStateForSetState();
                                        var value = evt.target.value;
                                        if (value == "-1") {
                                            newState.tipoLinea = null;
                                        }
                                        else {
                                            newState.tipoLinea = (evt.target.value) as TipoNotificacionItem;
                                        }

                                        this.setState(newState);
                                    }
                                }}
                                className="form-select"
                            >
                                <option value="-1"> {I18n.Strings.notificaciones.todosTiposLineas} </option>
                                {this.state.editModel.items.map((item) => item.tipoItem).filter((name, index, currentVal) => currentVal.indexOf(name) === index)
                                    .map((item, index) => {
                                        return <option
                                            key={index}
                                            value={item.toString()}
                                        >
                                            {item.toString()}
                                        </option>
                                    })
                                }
                            </select>
                        </FormGroup>
                    </Col>
                    <Col md={6}>
                        <Label for={"notificacion" + nameof(this.state.texto)}>{I18n.Strings.notificaciones.texto}</Label>
                        <Input
                            onChange={(evt) => {
                                var state = this._cloneStateForSetState();
                                state.texto = evt.target.value;
                                this.setState(state);
                            }}
                            placeholder={I18n.Strings.notificaciones.texto + "..."}
                            type="text"
                            maxLength={100}
                            className={"form-control"}
                            name={nameof(this.state.texto)}
                            id={"notificacion" + nameof(this.state.texto)}
                            value={this.state.texto || ""}
                        />
                    </Col>

                </Row>
                {this.state.editModel.items != null && <Row>
                    <Col md={12} className="notificaciones-items">

                        <table className="table table-striped table-hover table-sm">
                            <thead>
                                <tr>
                                    <th>Tipo</th>
                                    <th>Descripción</th>
                                </tr>
                            </thead>
                            <tbody>
                                {this.state.editModel.items.map((item, index) => {
                                    if ((this.state.tipoLinea == undefined || item.tipoItem.toString() == this.state.tipoLinea) &&
                                        (this.state.texto == undefined || item.texto.includes(this.state.texto))) {
                                        return <tr key={index}>
                                            <td className={this._calculateCellClass(item)}>
                                                {item.tipoItem}
                                            </td>
                                            <td className={this._calculateCellClass(item)}>
                                                {item.texto}
                                            </td>
                                        </tr>;
                                    }

                                })}
                            </tbody>
                        </table>
                    </Col>
                </Row>
                }
            </React.Fragment>}
            <Row className="accesos-directos">
                <Col>
                    <Button
                        id="cancel"
                        color="primary"
                        className="btn-rounded btn-sm mb-2"
                        onClick={() => {
                            this.props.history.push(RouteHelper.listNotificaciones());
                        }} >
                        <CancelIcon />
                        {I18n.Strings.cancel}
                    </Button>
                </Col>
            </Row>
        </React.Fragment>
    }

    private _calculateCellClass(item: NotificacionItemEditModel): string {
        return item.tipoItem == TipoNotificacionItem.Error
            ? "text-danger"
            : (item.tipoItem == TipoNotificacionItem.Warning
                ? "text-warning"
                : "text-info");
    }
}
export const View = withRouter(ViewComp)