import { AuthService } from "../services/AuthService";
import { ApiFetcher } from "./ApiFetcher";
import { CursoEscolarListItem, GetUserContextResponse, InterfaceLanguage, TipoUsuario } from "../models/dataModels";
import { AlertsService } from "./AlertsService";
import { debug } from "node:console";

// TODO: Esto lo podemos pasar a una clase y guardarlo en algun storage en cliente
export interface IHubCache {
    [key: string]: any;
}

class ClientContext {

    private constructor() {
        this.imagenPerfilUrl = "";
        this.nombre = "";
        this.idioma = InterfaceLanguage.Espannol;
    }


    public usuarioId: number;
    public imagenPerfilUrl: string;
    public nombre: string;
    public apellidos: string;
    public claseActualId: number;
    public claseActual: string;
    public estoyImpersonado: boolean;

    public tipoUsuario: TipoUsuario;

    public cursoEscolarActivoId: number;

    public idioma: InterfaceLanguage;

    public colegioId: number;
    public nombreColegio: string;
    public logoColegioUrl: string;
    public estoyClaseGeneral: boolean;
    public recargarNiveles: boolean;

    public cursosEscolares: CursoEscolarListItem[]

    public static getCursoEscolarActivo(): CursoEscolarListItem {
        return this.Current.cursosEscolares.find(p => p.id == this.Current.cursoEscolarActivoId);
    }

    public static setCursoEscolarActivo(cursoEscolarId: number) {
        this.Current.cursoEscolarActivoId = cursoEscolarId;
    }

    /**
     * El contexto actual
     */
    public static Current: ClientContext;


    /**
     * La url para realizar las peticiones a la web api
     */
    public static WebApiUrlBase: string = "https://localhost:44370/api";

    public static init(): Promise<ClientContext> {

        //this.WebApiUrlBase = urlBase || this.WebApiUrlBase;

        var authService = new AuthService();
        var userPromise = authService.getUser();

        var promContext = userPromise.then(user => {

            if (user == null) {
                return null;
            }

            var fetcher = new ApiFetcher();
            var promise = fetcher.getByUrl<GetUserContextResponse>("intranet/myData/context")
                .then(response => {
                    if (response == null || response as any as string == "") {
                        window.document.location.href = "/loginUserError";
                        return;
                    }

                    if (response.cursosEscolares != null) {
                        response.cursosEscolares.forEach(ce => {
                            ce.fechaInicio = ce.fechaInicio == null ? null : new Date(ce.fechaInicio);

                            ce.fechaInicioCampagna = ce.fechaInicioCampagna == null ? null : new Date(ce.fechaInicioCampagna);
                            ce.fechaFinCampagna = ce.fechaFinCampagna == null ? null : new Date(ce.fechaFinCampagna);
                        })
                    }

                    ClientContext.Current = {
                        usuarioId: response.usuarioId,
                        nombre: response.nombre,
                        apellidos: response.apellidos,
                        tipoUsuario: response.tipoUsuario,
                        imagenPerfilUrl: response.fotoUsuario,
                        idioma: response.interfaceLanguage,
                        claseActualId: response.claseActualId,
                        claseActual: response.claseActual,
                        estoyClaseGeneral: response.isDefaultClass,
                        cursosEscolares: response.cursosEscolares,
                        cursoEscolarActivoId: response.cursoEscolarActivoId,
                        recargarNiveles: false,

                        colegioId: response.colegioId,
                        nombreColegio: response.nombreColegio,
                        logoColegioUrl: response.fotoColegio,
                        estoyImpersonado: response.isImpersonated,

                    };

                    ClientContext.globalCache = {};

                    return ClientContext.Current;
                });

            return promise;
        })


        return promContext;
    }

    public static globalCache: IHubCache;
}


export { ClientContext };