import React from "react";

import nameof from "ts-nameof.macro";
import { ClientContext } from "../utils/ClientContext";
import { ComponentBase } from "../utils/base/ComponentBase";
import { ModelValidation } from "../utils/base/ModelValidation";
import { ColegioUsuarioEditModel, CargaEditModel, ResultadosCargaUsuariosExcelExport, EstadoCargaItem, ExcelTemplateGetMessage, TipoCarga} from "../models/dataModels";
import { CargaFicherosService } from "./CargaFicherosService";
import { Col, FormGroup, Input, Label, Row, FormFeedback, Button, Nav, NavItem, NavLink, TabContent, TabPane } from "reactstrap";
import { I18n } from "../utils/I18n";
import { DocumentUpload } from "../helpers/DocumentUpload";
import { CancelIcon, DownloadIcon, FileIcon } from "../utils/Icons";
import { AlertsService } from "../utils/AlertsService";
import { NivelEducativoDropDown } from "../cursoEscolar/NivelEducativoDropDown";
import { NumericCardBox } from "../helpers/NumericCardBox";
import { Loading } from "../utils/Loading";

interface UsuariosState {
    showLoading: boolean;

    // TODO MBERNAL: ¿Esto para que es, no pega ni con cola?
    editModel: ColegioUsuarioEditModel;

    numItemsRead: number;
    fileUrl: string;
    actualizarSiExiste: boolean;
    noCrearLicencias: boolean;
    cargaId?: number | null;
    numRowsOk?: number | null;
    numRowsError?: number | null;
    numRowsDuplicated?: number | null;
    ficheroSeleccionado: boolean;
}

export class Usuarios extends ComponentBase<any, UsuariosState> {

    public constructor(props: any) {
        super(props);

        this.state = {
            editModel: null,
            showLoading: false,
            numItemsRead: 0,
            fileUrl: null,
            ficheroSeleccionado: false,
            actualizarSiExiste: false,
            noCrearLicencias: false
        };
    }

    protected _getTemplate() {

        this.setState({ showLoading: true },
            () => {
                var service = new CargaFicherosService();
                var model = {
                    tipoCarga: TipoCarga.Usuario
                } as ExcelTemplateGetMessage;
                service.getTemplate(model)
                    .then(() => this.setState({ showLoading: false }))
            });
    }

    protected _uploadFile() {
        this.setState({ showLoading: true },
            () => {
                var service = new CargaFicherosService();

                var model = {
                    fileUrl: this.state.fileUrl,
                    tipoCarga: TipoCarga.Usuario,
                    actualizarSiExiste: this.state.actualizarSiExiste,
                    noCrearLicencias: this.state.noCrearLicencias
                } as CargaEditModel;

                service.cargarExcel(model)
                    .then((data) => {
                        var state = this._cloneStateForSetState();
                        state.showLoading = false;
                        state.numRowsOk = data.numRowsOk;
                        state.numRowsError = data.numRowsError;
                        state.numRowsDuplicated = data.numRowsDuplicated;
                        state.cargaId = data.cargaId;
                        state.editModel = null;

                        this.setState(state);
                    });
            });
    }

    protected _downloadResultsExcel(estado: EstadoCargaItem) {
        this.setState({ showLoading: true },
            () => {
                var service = new CargaFicherosService();
                var msg = {
                    cargaId: this.state.cargaId,
                    estadoCarga: estado,
                    tipoCarga: TipoCarga.Usuario
                } as ResultadosCargaUsuariosExcelExport;
                service.descargarResultadosCargaUsuarios(msg)
                    .then(() => this.setState({ showLoading: false }))
            });
    }

    protected _renderResult(): JSX.Element {
        return <React.Fragment>
            
            <Row className="mb-4">
                <Col md={3}>
                    <NumericCardBox
                        numberValue={this.state.numRowsOk + this.state.numRowsError + this.state.numRowsDuplicated}
                        headerText={I18n.Strings.cargaFicheros.numRowsTotal} />
                </Col>
                <Col md={3}
                    className={"pointer"}
                    onClick={() => {
                        if (this.state.numRowsOk > 0) {
                            this._downloadResultsExcel(EstadoCargaItem.Correcto);
                        }
                    }}
                >
                    <NumericCardBox
                        icono={this.state.numRowsOk > 0 ? <DownloadIcon /> : null}
                        numberValue={this.state.numRowsOk}
                        headerText={I18n.Strings.cargaFicheros.numRowsOk} />
                </Col>
                <Col md={3}
                    className={"pointer"}
                    onClick={() => {
                        if (this.state.numRowsDuplicated > 0) {
                            this._downloadResultsExcel(EstadoCargaItem.Duplicado);
                        }
                    }}
                >
                    <NumericCardBox
                        icono={this.state.numRowsDuplicated > 0 ? <DownloadIcon /> : null}
                        numberValue={this.state.numRowsDuplicated}
                        headerText={I18n.Strings.cargaFicheros.numRowsDuplicated} />
                </Col>
                <Col md={3}
                    className={"pointer"}
                    onClick={() => {
                        if (this.state.numRowsError > 0) {
                            this._downloadResultsExcel(EstadoCargaItem.Erroneo);
                        }
                    }}
                >
                    <NumericCardBox
                        icono={this.state.numRowsError > 0 ? <DownloadIcon /> : null}
                        numberValue={this.state.numRowsError}
                        headerText={I18n.Strings.cargaFicheros.numRowsError} />
                </Col>
            </Row>
            <Row>
                <Col md={12} className={"text-center"}>
                    <Button className={"btn-rounded btn"} id="save" color="primary" onClick={() => {
                        var state = this._cloneStateForSetState();
                        state.numItemsRead = 0;
                        state.editModel = null;
                        state.cargaId = null;
                        state.numRowsOk = 0;
                        state.numRowsError = 0;
                        state.numRowsDuplicated = 0;
                        state.ficheroSeleccionado = false;
                        this.setState(state);
                    }}>
                        <FileIcon />
                        {I18n.Strings.cargaFicheros.cargarOtroFichero}
                    </Button>
                </Col>
            </Row>
        </React.Fragment>
    }

    protected _renderPreviewItem(): JSX.Element {

        if (this.state.editModel == null) {
            return <React.Fragment>
                <Row>
                    <Col md={12}>
                        <Label>Se han leído 0 registros.</Label>
                    </Col>
                </Row>
                <Row>
                    <Col md={12} className={"text-center"}>
                        <Button className={"btn-rounded btn"} id="save" color="primary" onClick={() => {
                            var state = this._cloneStateForSetState();
                            state.numItemsRead = 0;
                            state.editModel = null;
                            state.cargaId = null;
                            state.numRowsOk = 0;
                            state.numRowsError = 0;
                            state.numRowsDuplicated = 0;
                            state.ficheroSeleccionado = false;
                            this.setState(state);
                        }}>
                            <FileIcon />
                            {I18n.Strings.cargaFicheros.cargarOtroFichero}
                        </Button>
                    </Col>
                </Row>
            </React.Fragment>
        }
        else {
            return <React.Fragment>
                <Row>
                    <Col md={12}>
                        <Label>Se han leído {this.state.numItemsRead} registros:</Label>
                    </Col>
                </Row>
                <Row className="tit-lista-lic">
                    <Col md={2}>
                        {I18n.Strings.colegioUsuarios.nombre}
                    </Col>
                    <Col md={2}>
                        {I18n.Strings.colegioUsuarios.apellidos}
                    </Col>
                    <Col md={3}>
                        {I18n.Strings.colegioUsuarios.login}
                    </Col>
                    <Col md={2}>
                        {I18n.Strings.colegioUsuarios.tipoUsuario}
                    </Col>
                    <Col md={3}>
                        {I18n.Strings.colegioUsuarios.grupoClase}
                    </Col>
                </Row>
                <Row className="datos-lic">
                    <Col md={2}>
                        {this.state.editModel.nombre}
                    </Col>
                    <Col md={2}>
                        {this.state.editModel.apellidos}
                    </Col>
                    <Col md={3}>
                        {this.state.editModel.login}
                    </Col>
                    <Col md={2}>
                        {this.state.editModel.tipoUsuario}
                    </Col>
                    <Col md={3}>
                        {this.state.editModel.claseActual}
                    </Col>
                </Row>
                <Row>
                    <Col md={12} className={"text-center"}>
                        <Button className={"btn-rounded btn"} id="save" color="primary" onClick={() => {
                            this._uploadFile();
                        }}>
                            <FileIcon />
                            {I18n.Strings.cargaFicheros.cargarFichero}
                        </Button>
                        <Button className={"btn-rounded btn"} id="save" color="secondary" onClick={() => {
                            var state = this._cloneStateForSetState();
                            state.numItemsRead = 0;
                            state.editModel = null;
                            state.ficheroSeleccionado = false;
                            this.setState(state);
                        }}>
                            <CancelIcon />
                            {I18n.Strings.cancel}
                        </Button>
                    </Col>
                </Row>
            </React.Fragment>
        }
    }

    render() {
        return <React.Fragment>
            {this.state.showLoading && <Loading />}

            <h3>Carga de ficheros de usuarios</h3>
            <Row>
                <Col md={12} className={"text-end"}>
                    <Button className={"btn-rounded btn"} id="save" color="primary" onClick={() => {
                        this._getTemplate();
                    }}>
                        <FileIcon />
                        {I18n.Strings.cargaFicheros.descargarPlantilla}
                    </Button>
                </Col>

                <Col md={12}>
                    <FormGroup>

                        <Input
                            onChange={(evt) => {
                                var state = this._cloneStateForSetState();
                                state.actualizarSiExiste = evt.target.checked;
                                this.setState(state);
                            }}
                            type="checkbox"
                            style={{ padding: 0, marginRight: 10 }}
                            name={nameof(this.state.actualizarSiExiste)}
                            id={nameof(this.state.actualizarSiExiste)}
                            checked={this.state.actualizarSiExiste || false}
                        />
                        <Label for={nameof(this.state.actualizarSiExiste)}>Actualizar usuarios si existen</Label>
                    </FormGroup>
                </Col>
                <Col md={12}>
                    <FormGroup>

                        <Input
                            onChange={(evt) => {
                                var state = this._cloneStateForSetState();
                                state.noCrearLicencias = evt.target.checked;
                                this.setState(state);
                            }}
                            type="checkbox"
                            style={{ padding: 0, marginRight: 10 }}
                            name={nameof(this.state.noCrearLicencias)}
                            id={nameof(this.state.noCrearLicencias)}
                            checked={this.state.noCrearLicencias || false}
                        />
                        <Label for={nameof(this.state.noCrearLicencias)}>No crear licencias</Label>
                    </FormGroup>
                </Col>

                <div className={"carga-ficheros"}>
                    {!this.state.ficheroSeleccionado && this.state.cargaId == null && <Col md={12}>
                        <Label>{I18n.Strings.cargaFicheros.subirFicheroEtiqueta}</Label>
                        <DocumentUpload
                            manualUpload={false}
                            style={{ fontSize: 14, overflow: "ellipsis" }}
                            title={I18n.Strings.cargaFicheros.subirFichero}
                            formatosAceptados={"application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"}
                            zoneStyle={{ margin: 0, padding: 10 }}
                            uploadUrl={`intranet/cargaFichero/analizarExcel`}
                            tipoCarga={TipoCarga.Usuario}
                            multiple={false}
                            onUpload={(data) => {
                                if (data != null) {
                                    var state = this._cloneStateForSetState();
                                    if (data.previewItem != null) {
                                        var item = data.previewItem as ColegioUsuarioEditModel;
                                        state.editModel = item;
                                    }
                                    state.ficheroSeleccionado = true;
                                    state.numItemsRead = data.numItemsRead;
                                    state.fileUrl = data.documentUrl;
                                    this.setState(state);
                                }
                            }}
                        />
                    </Col>}
                    {this.state.ficheroSeleccionado && this.state.cargaId == null && <Col md={12}>
                        {this._renderPreviewItem()}
                    </Col>}
                    {this.state.ficheroSeleccionado && this.state.cargaId != null && <Col md={12}>
                        {this._renderResult()}
                    </Col>}
                </div>
            </Row>
        </React.Fragment>
    };
}