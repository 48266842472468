import React from "react";
import { withRouter } from "react-router-dom";
import { Button, Col, Row } from "reactstrap";
import { ExportFileResult } from "../models/dataModels";
import { ApiFetcher } from "../utils/ApiFetcher";
import { ClientContext } from "../utils/ClientContext";
import { ExcelIcon } from "../utils/Icons";
import { Loading } from "../utils/Loading";
import { downloadFileFromBase64Array, logAndExtractInfoFromException } from "../utils/ValuesFormatter";


interface ReportsIndexCompState {
    showLoading: boolean;
}


class ReportsIndexComp extends React.Component<any, ReportsIndexCompState> {

    public constructor(props: any) {

        super(props);
        this.state = {
            showLoading: false
        }
    }


    public render(): JSX.Element {

        var cursoEscolarActivo = ClientContext.getCursoEscolarActivo();

        return <React.Fragment>
            {this.state.showLoading && <Loading />}
            <h2>
                <strong>Informes - {cursoEscolarActivo.nombre}</strong>
            </h2>
            <Row>
                <Col md={6}>
                    <div className='accesos-directos'>
                        <Row>
                            <Col md={12}>
                                <div>
                                    <p className='titulo-informe'>
                                        Listado de accesos a contenidos
                                    </p>
                                </div>
                            </Col>
                        </Row>
                        <Row >
                            <Col md={7}>
                                <div>
                                    <p>
                                        Muestra, para <b>todos los colegios</b>, los accesos que han hecho los usuarios a los contenidos.
                                    </p>
                                </div>
                            </Col>
                            <Col md={5} className='align-self-center'>
                                <Button
                                    key="excelExportAccesosContenidos"
                                    className="btn-rounded"
                                    color="secondary"
                                    onClick={() => {
                                        this.setState({ showLoading: true },
                                            () => {
                                                this._downloadAccesosContenidos(cursoEscolarActivo.campannaId)                                                   
                                                    .catch(error => {
                                                        window.alert(logAndExtractInfoFromException(error))
                                                    })
                                                    .finally(() => {
                                                        this.setState({ showLoading: false });
                                                    });
                                            }
                                        );
                                    }}
                                >
                                    <ExcelIcon cssClass="pe-2" />
                                    Descargar
                                </Button>
                            </Col>
                        </Row>
                    </div>
                </Col>
                <Col md={6}>
                    <div className='accesos-directos'>
                        <Row>
                            <Col md={12}>
                                <div>
                                    <p className='titulo-informe'>
                                        Listado de pedidos global
                                    </p>
                                </div>
                            </Col>
                        </Row>
                        <Row>
                            <Col md={7}>
                                <p>
                                    Muestra todos los pedidos que se han realizado en <b>todos los colegios</b>.
                                </p>
                            </Col>
                            <Col md={5} className='align-self-center'>
                                <Button
                                    key="excelExportAccesoPedidos"
                                    className="btn-rounded"
                                    color="secondary"
                                    onClick={() => {
                                        this.setState({ showLoading: true },
                                            () => {
                                                this._downloadAccesosPedidos(cursoEscolarActivo.campannaId)
                                                    .catch(error => {
                                                        window.alert(logAndExtractInfoFromException(error))
                                                    })
                                                    .finally(() => {
                                                        this.setState({ showLoading: false });
                                                    });

                                            }
                                        );
                                    }}
                                >
                                    <ExcelIcon cssClass="pe-2" />
                                    Descargar
                                </Button>

                            </Col>
                        </Row>
                    </div>
                </Col>
                <Col md={6}>
                    <div className='accesos-directos'>
                        <Row >
                            <Col md={12}>
                                <p className='titulo-informe'>
                                    Listado de licencias global
                                </p>
                            </Col>
                        </Row>
                        <Row>
                            <Col md={7}>
                                <p>
                                    Muestra todas las licencias de usuarios existentes en <b>todos los colegios</b>.
                                </p>
                            </Col>
                            <Col md={5} className='align-self-center'>
                                <Button
                                    key="excelExportAccesoPedidos"
                                    className="btn-rounded"
                                    color="secondary"
                                    onClick={() => {
                                        this.setState({ showLoading: true },
                                            () => {
                                                this._downloadLicencias(cursoEscolarActivo.campannaId)
                                                    .catch(error => {
                                                        window.alert(logAndExtractInfoFromException(error))
                                                    })
                                                    .finally(() => {
                                                        this.setState({ showLoading: false });
                                                    });

                                            }
                                        );
                                    }}
                                >
                                    <ExcelIcon cssClass="pe-2" />
                                    Descargar
                                </Button>

                            </Col>
                        </Row>
                    </div>
                </Col>
            </Row>
        </React.Fragment>;
    }

    private _downloadAccesosPedidos(campannaId: number) {

        var url = `intranet/informes/downloadPedidos/${campannaId}`;

        var apiFetcher = new ApiFetcher();
        var prom = apiFetcher.getByUrl<ExportFileResult>(url)
            .then((result) => {
                downloadFileFromBase64Array(result.content, result.fileName, "application/octet-stream");
                return result;
            });
        return prom;
    }

    private _downloadAccesosContenidos(cursoEscolarId: number) {

        var url = `intranet/informes/downloadAccesosContenidos/${cursoEscolarId}`;

        var apiFetcher = new ApiFetcher();
        var prom = apiFetcher.getByUrl<ExportFileResult>(url)
            .then((result) => {
                downloadFileFromBase64Array(result.content, result.fileName, "application/octet-stream");
                return result;
            });
        return prom;
    }

    private _downloadLicencias(campannaId: number) {

        var url = `intranet/informes/downloadLicencias/${campannaId}`;

        var apiFetcher = new ApiFetcher();
        var prom = apiFetcher.getByUrl<ExportFileResult>(url)
            .then((result) => {
                downloadFileFromBase64Array(result.content, result.fileName, "application/octet-stream");
                return result;
            });
        return prom;
    }
}



export const ReportsIndex = withRouter(ReportsIndexComp);
