import { ColegioCredencialesEditorialesEditModel, ColegioCredencialesEditorialesListItemPaginatedList, ColegioCredencialesEditorialesSearch, InfoConexion, ModelValidationResult } from "../models/dataModels";
import { ApiFetcher } from "../utils/ApiFetcher";
import { IServiceForEdit, IServiceForIndex } from "../utils/base/BaseServices";

export class ColegioCredencialesEditorialesService
    implements IServiceForIndex<ColegioCredencialesEditorialesSearch, ColegioCredencialesEditorialesListItemPaginatedList>, IServiceForEdit<ColegioCredencialesEditorialesEditModel>
{
    
    private apiFetcher: ApiFetcher;
    constructor() {
        this.apiFetcher = new ApiFetcher();
    }

    search(searchData: ColegioCredencialesEditorialesSearch): Promise<ColegioCredencialesEditorialesListItemPaginatedList> {
        var prom = this.apiFetcher.post<ColegioCredencialesEditorialesSearch, ColegioCredencialesEditorialesListItemPaginatedList>("intranet/colegio/credenciales/search", searchData);
        return prom;
    }

    delete(id: number) : Promise<any> {
        var prom = this.apiFetcher.delete<any>(`intranet/colegio/credenciales/${id}`);
        return prom;
    }

    getById(id: string | number): Promise<ColegioCredencialesEditorialesEditModel> {
        var prom = this.apiFetcher.getByUrl<ColegioCredencialesEditorialesEditModel>(`intranet/colegio/credenciales/${id}`);
        return prom;
    }
    validate(model: ColegioCredencialesEditorialesEditModel): Promise<ModelValidationResult> {
        var prom = this.apiFetcher.post<ColegioCredencialesEditorialesEditModel, ModelValidationResult>(`intranet/colegio/credenciales/validate`, model);
        return prom;
    }

    create(model: ColegioCredencialesEditorialesEditModel): Promise<ColegioCredencialesEditorialesEditModel> {
        var prom = this.apiFetcher.post<ColegioCredencialesEditorialesEditModel, ColegioCredencialesEditorialesEditModel>(`intranet/colegio/credenciales/`, model);
        return prom;
    }

    update(id: string | number, model: ColegioCredencialesEditorialesEditModel): Promise<ColegioCredencialesEditorialesEditModel> {
        var prom = this.apiFetcher.put<ColegioCredencialesEditorialesEditModel, ColegioCredencialesEditorialesEditModel>(`intranet/colegio/credenciales/${id}`, model);
        return prom;
    }

    getLti13ConfigCentro(): Promise<InfoConexion> {
        var prom = this.apiFetcher.getByUrl<any>(`lti13/infoConexion`);
        return prom;
    }

    exportExcel(search: ColegioCredencialesEditorialesSearch): Promise<any> {
        throw new Error("Method not implemented.");
    }

    generateDefaultCredentials() {
        var prom = this.apiFetcher.post<any, boolean>(`intranet/colegio/credenciales/generateDefaultCredentials`, null);
        return prom;
    }
}