
import {
    ExportFileResult, GraphSearch,
    EstadisticasContenidoGraphExcelExport, EstadisticasContenidoGraphSearch, EstadisticasContenidoGraphSearchResult
} from "../models/dataModels";
import { ApiFetcher } from "../utils/ApiFetcher";
import * as Utils from "../utils/ValuesFormatter";

export class AccesosContenidoService
{
    accesosContenidosGraphSearch(searchData: EstadisticasContenidoGraphSearch): Promise<EstadisticasContenidoGraphSearchResult> {

        var apiFetcher = new ApiFetcher();

        var prom = apiFetcher.post<EstadisticasContenidoGraphSearch, EstadisticasContenidoGraphSearchResult>("intranet/colegio/usuarios/estadisticasContenidosGraphSearch", searchData);
        return prom;
    }

    exportExcel(searchData: EstadisticasContenidoGraphExcelExport): Promise<ExportFileResult> {

        var apiFetcher = new ApiFetcher();

        var prom = apiFetcher.post<EstadisticasContenidoGraphExcelExport, ExportFileResult>("intranet/colegio/usuarios/estadisticasContenidoExportExcel", searchData)
            .then((result) => {
                Utils.downloadFileFromBase64Array(result.content, result.fileName, "application/octet-stream");
                return result;
            });
        return prom;
    }
}