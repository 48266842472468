import nameof from "ts-nameof.macro";
import { MatriculaEditModel, MatriculaListItem, MatriculaListItemPaginatedList, MatriculasSearch, ModelValidationResult } from "../models/dataModels";
import { ApiFetcher } from "../utils/ApiFetcher";
import { IServiceForEdit, IServiceForIndex } from "../utils/base/BaseServices";


export class MatriculasService
    implements IServiceForIndex<MatriculasSearch, MatriculaListItemPaginatedList>, IServiceForEdit<MatriculaEditModel>
{

    getByClaseMateriaId(claseMateriaId: number, cursoEscolarId: number): Promise<MatriculaListItem[]> {

        var item = {} as MatriculaListItem;
        var serchData = {
            claseMateriaId: claseMateriaId,
            cursoEscolarId:cursoEscolarId,
            rowsPerPage: -1,
            noTotalCount: true,
            orderByColumn: nameof(item.usuario)
        } as MatriculasSearch;

        var result = this.search(serchData)
            .then(res => {
                return res.items
            });

        return result;
    }

    search(searchData: MatriculasSearch): Promise<MatriculaListItemPaginatedList> {

        var apiFetcher = new ApiFetcher();
        var prom = apiFetcher.post<MatriculasSearch, MatriculaListItemPaginatedList>("intranet/colegio/matriculas/search", searchData);

        prom.then(res => {

            res.items.forEach((val) => {
                val.fecha = new Date(val.fecha);
            });

            return res;
        });

        return prom;
    }

    delete(id: number): Promise<any> {
        var apiFetcher = new ApiFetcher();
        var prom = apiFetcher.delete<any>(`intranet/colegio/matriculas/${id}`);
        return prom;
    }

    getById(id: string | number): Promise<MatriculaEditModel> {
        var apiFetcher = new ApiFetcher();
        var prom = apiFetcher.getByUrl<MatriculaEditModel>(`intranet/colegio/matriculas/${id}`);
        prom = prom.then(model => {
            model.fecha = new Date(model.fecha);
            return model;
        });
        return prom;
    }
    validate(model: MatriculaEditModel): Promise<ModelValidationResult> {
        var apiFetcher = new ApiFetcher();
        var prom = apiFetcher.post<MatriculaEditModel, ModelValidationResult>(`intranet/colegio/matriculas/validate`, model);
        return prom;
    }

    create(model: MatriculaEditModel): Promise<MatriculaEditModel> {
        var apiFetcher = new ApiFetcher();
        var prom = apiFetcher.post<MatriculaEditModel, MatriculaEditModel>(`intranet/colegio/matriculas/`, model);
        return prom;
    }

    update(id: string | number, model: MatriculaEditModel): Promise<MatriculaEditModel> {
        var apiFetcher = new ApiFetcher();
        var prom = apiFetcher.put<MatriculaEditModel, MatriculaEditModel>(`intranet/colegio/matriculas/${id}`, model);
        return prom;
    }

    exportExcel(search: MatriculasSearch): Promise<any> {
        throw new Error("Method not implemented.");
    }

}