import * as React from "react";
import nameof from "ts-nameof.macro";
import { ClaseListItem, SortDirection } from "../../models/dataModels";
import { DropDownBase, GenericSelectItem, IDropDownProps } from "../../utils/base/DropDownBase";

import { ClientContext } from "../../utils/ClientContext";
import { MisClasesService } from "../MisClasesService";

interface ClasesDropDownProps extends IDropDownProps {
    profesorId?: number;
}

export class ClasesDropDown extends DropDownBase<ClasesDropDownProps> {



    protected GetItems(): Promise<GenericSelectItem[]> {

        var item = {} as ClaseListItem;

        var misClasesService = new MisClasesService();

        var dataProm = misClasesService.search({
            colegioId: ClientContext.Current.colegioId,
            alumnoId: null,
            posibles: false,
            profesorId: this.props.profesorId,
            nivelEducativoId: null,
            noTotalCount: true,
            currentPage: 0,
            rowsPerPage: -1,
            orderByColumn: nameof(item.clase),
            orderMode: SortDirection.Asc,
            bypassAutomaticSort: false,
            currentPageRows: -1
        });

        var resultProm = dataProm.then(data => {
            return data.items.map((item, index) => {
                return {
                    id: item.id,
                    name: `${item.clase}`,
                    data: item
                }
            });
        });

        return Promise.resolve(resultProm);
    }

    protected shouldAddEmptyOption() {
        return true;
    }
};